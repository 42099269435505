import React, { useState, useMemo, memo, useCallback } from 'react';
import { useCachedCallback } from 'hooks/use-cached-callback';
import {
  ProviderLogo,
  ExpandCollapseProvider,
  ExpandCollapseArrow,
  ProviderForm,
  ButtonsContainer,
  StyledTextInput,
  StyledButton,
  ProviderName,
  ProviderInfo,
  AccountStatusLabel,
} from './styles';
import capitalize from 'lodash/capitalize';
import maestroLogoSvg from 'assets/icons/maestro-blank.svg';
import genericLogoSvg from 'assets/icons/adminbar-stream.svg';
import { useSelector, useDispatch } from 'hooks';
import { getPrimaryToken } from 'services/auth';
import { registerProviderAccount, testProviderAccount } from 'services/livestream';
import { showAdminErrorModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import isEqual from 'lodash/isEqual';
import TranslatedText from 'components/i18n/TranslatedText';
import { useAdminTranslation } from 'hooks/use-translation';

const providerLogos: Record<string, string> = {
  maestro: `data:image/svg+xml,${encodeURIComponent(maestroLogoSvg)}`,
  brightcove: 'https://www.brightcove.com/favicon.svg',
  default: `data:image/svg+xml,${encodeURIComponent(genericLogoSvg)}`,
};

const MemoedTextInput = memo(StyledTextInput);

export type IStreamingProviderAccountFormProps = {
  accountCredentials?: Record<string, string>;
  active?: boolean;
  providerName: string;
  savedCredentials?: Record<string, string>;
  valid?: boolean;
};

type TFormValues = Record<string, string>;

export const StreamingProviderAccountForm = (
  {
    accountCredentials,
    providerName,
    savedCredentials,
    active,
    valid,
  }: IStreamingProviderAccountFormProps,
) => {
  const { t } = useAdminTranslation();
  const [expanded, setExpanded] = useState(false);
  const [formValues, setFormValues] = useState<TFormValues>(savedCredentials || {});
  const [testedOk, setTestedOk] = useState(false);
  const [testing, setTesting] = useState(false);
  const [activating, setActivating] = useState(false);
  const token = useSelector(getPrimaryToken);
  const dispatch = useDispatch();

  const toggleExpanded = useCallback(
    () => setExpanded((s) => !s),
    [],
  );

  const credentialsIterable = useMemo(
    () => Object.entries(accountCredentials || {})
      .map(
        ([fieldName, label]) => ({
          label,
          fieldName,
        }),
      ),
    [accountCredentials],
  );

  const fieldChangeHandler = useCachedCallback(
    useCallback(
      (field: keyof TFormValues) =>
        (value: string) => {
          setTestedOk(false);
          setFormValues(
            (prevData) => ({
              ...prevData,
              [field]: value,
            }),
          );
        },
      [],
    ),
  );

  const testDisabled = useMemo(
    () => (
      testedOk
      || testing
      || Object.keys(accountCredentials || {}).some(
        (field) => !formValues[field],
      )
      || isEqual(formValues, savedCredentials)
    ),
    [accountCredentials, formValues, testing, savedCredentials],
  );

  const activateDisabled = useMemo(
    () => !testedOk || activating || isEqual(formValues, savedCredentials),
    [testedOk, activating, savedCredentials],
  );

  const testAccount = useCallback(
    async () => {
      setTesting(true);
      try {
        await testProviderAccount(
          {
            providerName,
            credentials: formValues,
          },
          token!,
        );
        setTestedOk(true);
        dispatch(
          showModal({
            kind: ModalKinds.adminSuccess,
            data: {
              message: t(
                'ADMIN_THIRDPARTY_STREAM_PROVIDERS_CONNECTION_SUCCESSFUL',
                { provider: capitalize(providerName) },
              ),
            },
          }),
        );
      } catch (error) {
        dispatch(
          showAdminErrorModal(t('ADMIN_THIRDPARTY_STREAM_PROVIDERS_CONNECTION_FAILED')),
        );
        setTestedOk(false);
      }
      setTesting(false);
    },
    [formValues, token, t],
  );

  const activateAccount = useCallback(
    async () => {
      setActivating(true);
      try {
        await registerProviderAccount(
          {
            providerName,
            credentials: formValues,
          },
          token!,
        );
        dispatch(
          showModal({
            kind: ModalKinds.adminSuccess,
            data: {
              message: t(
                'ADMIN_THIRDPARTY_STREAM_PROVIDERS_ACCOUNT_ADDED',
                { provider: capitalize(providerName) },
              ),
            },
          }),
        );
      } catch (error) {
        dispatch(
          showAdminErrorModal(`${t('ADMIN_THIRDPARY_STREAM_PROVIDERS_CAN_ADD_ACCOUNT')} ${error?.message || 'UNKNOWN'}`),
        );
      }
      setActivating(false);
    },
    [providerName, formValues, t],
  );

  const shouldShowForm = useMemo(
    () => accountCredentials && Object.keys(accountCredentials).length > 0,
    [],
  );

  return (
    <>
      <ExpandCollapseProvider onClick={toggleExpanded}>
        <ProviderName>
          <ProviderLogo src={providerLogos[providerName.split(' ')[0].toLowerCase()] || providerLogos.default} />
          {capitalize(providerName)}
        </ProviderName>
        <ProviderInfo>
          {
            active && (
              <TranslatedText
                stringKey={valid ? 'ADMIN_LABEL_ACTIVE' : 'ADMIN_LABEL_INVALID'}
                component={AccountStatusLabel}
                componentProps={{ shouldShowForm, valid }}
              />
            )
          }
          {shouldShowForm && <ExpandCollapseArrow expanded={expanded} />}
        </ProviderInfo>
      </ExpandCollapseProvider>
      {shouldShowForm && expanded && (
        <ProviderForm>
          {credentialsIterable.map(
            ({ label, fieldName }) => (
              <MemoedTextInput
                key={fieldName}
                description={label}
                placeholderText={`Enter ${label}`}
                onChange={fieldChangeHandler(fieldName)}
                value={formValues[fieldName]}
                marginTop={-10}
                padding="10px 0"
              />
            ),
          )}
          <ButtonsContainer>
            {accountCredentials && (
              <>
                <StyledButton type="button" disabled={testDisabled} onClick={testAccount}>
                  {t(testing ? 'ADMIN_THIRDPARY_STREAM_PROVIDERS_TESTING' : 'ADMIN_THIRDPARY_STREAM_PROVIDERS_TEST')}
                </StyledButton>
                <span style={{ width: '10px', display: 'block' }} />
              </>
            )}
            <StyledButton type="button" disabled={activateDisabled} onClick={activateAccount}>
              {t('ADMIN_THIRDPARY_STREAM_PROVIDERS_MAKE_ACTIVE')}
            </StyledButton>
          </ButtonsContainer>
        </ProviderForm>
      )}
    </>
  );
};

import React, { useRef, useState, ComponentType, useEffect } from 'react';
import {
  ButtonViewProps,
  OptionViewProps,
} from 'components/admin2/ui/Dropdown/interfaces';
import { InnerSearchInput, Sizer, StyledBoxTag } from './styles';

interface ButtonProps {
  boxTagOptions?: any;
  getOptionLabel?: any;
  removeOption?: any;
}

function ButtonRenderer({
  isButton,
  isOpen,
  boxTagOptions,
  getOptionLabel,
  removeOption,
  searchInputProps,
}: OptionViewProps<ButtonProps> & ButtonViewProps<ButtonProps> & ButtonProps & {searchInputProps?: any}) {
  const sizerRef = useRef<HTMLSpanElement>(null);
  const [selectedTag, selectTag] = useState(boxTagOptions.length);

  const resizeInput = (search: string) => {
    const input = searchInputProps.inputRef.current;
    const sizer = sizerRef.current;
    if (!(input && sizer)) {
      return;
    }
    sizer.innerText = searchInputProps.suggestion || search;
    input.style.width = `${sizer.getBoundingClientRect().width + 25}px`;
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    searchInputProps.onKeyDown(e);
    const { currentTarget: input, key } = e;
    if (input.selectionStart || input.selectionEnd) {
      return;
    }
    const last = boxTagOptions.length;
    if (key === 'Backspace' && selectedTag >= 0) {
      e.preventDefault();
      if (selectedTag < last) {
        return removeOption(null, selectedTag);
      }
      if (last - 1 >= 0) {
        return removeOption(null, last - 1);
      }
    }
    if (key === 'ArrowLeft') {
      e.preventDefault();
      const prev = selectedTag - 1;
      selectTag(prev >= 0 ? prev : 0);
    }
    if (key === 'ArrowRight') {
      const next = selectedTag + 1;
      if (next < last) {
        e.preventDefault();
      }
      selectTag(next < last ? next : last);
    }
  };

  useEffect(() => {
    selectTag(boxTagOptions.length);
  }, [boxTagOptions.length]);

  return isButton ? (
    <StyledBoxTag
      content={boxTagOptions}
      getLabel={getOptionLabel}
      onRemove={removeOption}
      selectedIndex={selectedTag}
    >
      <InnerSearchInput {...searchInputProps} isOpen={isOpen} onInputChange={resizeInput} onKeyDown={onKeyDown} />
      <Sizer ref={sizerRef} />
    </StyledBoxTag>
  ) : null;
}

export default ButtonRenderer as ComponentType<ButtonProps>;

/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AjaxFetch from 'components/core/AjaxFetch';
import { SUBSCRIPTION_V2_BASE_URL } from 'config';
import CmsNavHeader from 'components/admin2/CmsNavHeader';
import { getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';
import { createSlugFromString } from 'utils';
import Label from 'components/admin2/ui/Label';
import { SubscriptionType } from 'models/ISubscription';
import MultiCurrencyPriceInput from 'components/admin2/MultiCurrencyPrice';
import JsonDebug from 'components/dev/JsonDebug';
import useForm from 'hooks/use-form';
import { useTrackTicketCreated, useTrackSubscriptionCreated } from 'hooks';
import { showAdminErrorModal } from 'services/modals';
import { getAdminText100 } from 'services/themes';
import { useAdminTranslation } from 'hooks/use-translation';
import {
  NAME_ERRORS,
  SKU_ERRORS,
  DATE_ERRORS,
  SERVER_ERRORS_MAP,
  FREQUENCY_OPTIONS,
} from './constant';
import {
  ContentContainer,
  FrequencyContainer,
  StyledContainer,
  StyledRadioGroup,
  StyledSkuInput,
  StyledTextInput,
  StyledTextArea,
  StyledDateTimePicker,
  StyledIconLabel,
  TextInputSection,
  CannotEditIcon,
  StyledNeedHelpSection,
} from './styles';

const getEntitlementFromUseFormState = (body) => {
  const result = {};
  for (const item in body) {
    if (item === 'prices') {
      const formatPrices = body[item].value.map(
        (price) => ({ ...price, value: Number(price.value) }),
      );
      result[item] = formatPrices;
    } else {
      result[item] = body[item].value;
    }
  }
  const { skuEditable, ...requestPayload } = result;
  return requestPayload;
};

const serializeEntitlementBody = ({ selectedSubscription, type }) => {
  let ticketBody;
  let subscriptionBody;
  const subscription = selectedSubscription || {};
  const commonSharedData = {
    ...subscription,
    _id: subscription._id || '',
    description: subscription.description || '',
    name: subscription.name || '',
    prices: subscription.prices ? subscription.prices.map(price => ({
      ...price,
      value: price.value.toString(),
    })) : [],
    sku: subscription.sku || '',
    subscriptionProvider: subscription.subscriptionProvider || 'maestro',
    type: selectedSubscription?.type || type,
  };

  if (type === SubscriptionType.ticket) {
    ticketBody = {
      ...commonSharedData,
      eventDate: subscription.eventDate || null,
      recurrence: subscription.recurrence || 'once',
    };
  } else {
    subscriptionBody = {
      ...commonSharedData,
      recurrence: subscription.recurrence || 'month',
    };
  }

  return ticketBody || subscriptionBody;
};

const SetSubscriptionModal = ({
  onClose,
  selectedSubscription = {},
  type,
}) => {
  const adminText100 = useSelector(getAdminText100);
  const isTicket = useMemo(() => type === SubscriptionType.ticket, [type]);
  const isEditing = useMemo(() => Boolean(selectedSubscription?._id), [selectedSubscription]);

  const [currencyErrors, setCurrencyErrors] = useState({});
  const body = useMemo(() => serializeEntitlementBody({
    selectedSubscription,
    type,
  }), [selectedSubscription, type]);

  const [isSaved, setIsSaved] = useState(false);
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();

  const trackTicketCreated = useTrackTicketCreated();
  const trackSubscriptionCreated = useTrackSubscriptionCreated();

  const getUrl = React.useCallback(() => isEditing ? `${SUBSCRIPTION_V2_BASE_URL}/${body._id}` : SUBSCRIPTION_V2_BASE_URL, [body._id, isEditing]);

  const handleNameChange = (newName, state) => {
    return !state.skuEditable.value && !isEditing ?
      { name: newName, sku: createSlugFromString(newName) } :
      newName;
  };

  const handleSkuChange = (newSku) => ({ sku: newSku.replace(/[^a-zA-Z0-9-]/g, ''), skuEditable: true });
  const handleSkuBlur = (newSku) => ({ sku: createSlugFromString(newSku), skuEditable: true });

  const {
    state,
    handlers,
    blurs,
    isValidForm,
    handleSubmit,
    handleErrors,
  } = useForm({
    fields: {
      description: { value: body.description },
      eventDate: isTicket &&
        { error: false, errorMessage: DATE_ERRORS.NO_DATE, value: body.eventDate },
      name: {
        error: false,
        errorMessage: NAME_ERRORS.NO_NAME,
        onChange: handleNameChange,
        value: body.name,
      },
      prices: {
        checkFieldErrors: () => !!Object.keys(currencyErrors).length,
        error: false,
        value: body.prices,
      },
      recurrence: { value: body.recurrence },
      sku: {
        error: false,
        errorMessage: SKU_ERRORS.NO_SKU,
        onBlur: handleSkuBlur,
        onChange: handleSkuChange,
        value: body.sku,
      },
      skuEditable: { value: false },
      subscriptionProvider: {
        value: body.subscriptionProvider,
      },
      type: {
        value: body.type,
      },
    },
    onError: (_, errors) => {
      const message = errors?.[0]?.response?.data?.message;
      let fieldsWithErrors = {};

      if (message?.includes(SERVER_ERRORS_MAP.DUPLICATED_SKU)) {
        fieldsWithErrors = { sku: SKU_ERRORS.DUPLICATED_SKU };
      } else {
        dispatch(showAdminErrorModal(message || t('ENTITLEMENT_FATAL_ERROR_WHEN_UPDATING')));
      }

      setIsSaved(false);
      return fieldsWithErrors;
    },
    onSubmit: () => {
      if (isValidForm()) {
        setIsSaved(true);
      }
    },
  });

  const handleTrackSubscriptionCreated = () => {
    const { prices, sku, name, eventDate: date, recurrence } = state;

    const defaultMultiCurrency = prices.value.find(price => price.default);
    const defaultCurrency = defaultMultiCurrency?.currency;
    const defaultPrice = defaultMultiCurrency?.value;
    const additionalPrices = prices.value.filter(price => !price.default).map(price => price.value);
    const eventDate = date ? new Date(date.value).toString() : null;
    const billingCycle = recurrence.value === 'month' ? 'monthly' : 'annually';

    if (isTicket) {
      trackTicketCreated({
        additionalPrices,
        defaultCurrency,
        defaultPrice,
        eventDate,
        sku: sku.value,
        ticketName: name.value,
      });
    } else {
      trackSubscriptionCreated({
        additionalPrices,
        billingCycle,
        defaultCurrency,
        defaultPrice,
        sku: sku.name,
        subscriptionName: name.value,
      });
    }
  };

  const handleSaveResponse = () => {
    handleTrackSubscriptionCreated();
    onClose();
  };

  const headerActions = [
    {
      labelKey: 'ADMIN_ACTION_SAVE',
      noMemoizedClick: true,
      onClick: () => {
        handleSubmit();
      },
      testId: 'saveButton',
    },
  ];

  const titleKey = useMemo(() => {
    if (!isEditing) {
      return isTicket ? 'ADMIN_GATE_ENTITLEMENT_TICKETS' : 'ADMIN_GATE_ENTITLEMENT_SUBSCRIPTIONS';
    }
    return isTicket ? 'ADMIN_GATE_ENTITLEMENT_TICKETS_EDIT' : 'ADMIN_GATE_ENTITLEMENT_SUBSCRIPTIONS_EDIT';
  }, [isTicket, isEditing]);

  const disableFrequency = useMemo(() => !isTicket && isEditing, [isTicket, isEditing]);
  const { frequencyDescriptionKey, frequencyLabelKey } = useMemo(() => {
    return {
      frequencyDescriptionKey: isTicket ? 'ADMIN_LABEL_DATE_DESCRIPTION' : 'ADMIN_LABEL_FREQUENCY_DESCRIPTION',
      frequencyLabelKey: isTicket ? 'ADMIN_LABEL_DATE' : 'ADMIN_LABEL_FREQUENCY',
    };
  }, [isTicket]);

  return (
    <>
      <CmsNavHeader actions={headerActions} onBack={onClose} />
      <ContentContainer>
        <TextInputSection>
          <Label
            color={adminText100}
            labelKey={titleKey}
            textSize="h2"
          />
          <StyledTextInput
            data-testid="nameInput"
            descriptionKey="ADMIN_ENTITLEMENT_UPDATE_NAME_DESCRIPTION"
            errorKey={state.name.errorMessage}
            labelKey="ADMIN_LABEL_NAME"
            maxlength="25"
            onChange={handlers.name}
            placeholderKey="ADMIN_PLACEHOLDER_ENTER_NAME"
            showDangerErrorMessage={state.name.error}
            type="text"
            value={state.name.value}
          />
          <StyledTextArea
            data-testid="descriptionInput"
            descriptionKey="ADMIN_GATE_ENTITLEMENT_SUBSCRIPTIONS_DESCRIPTION"
            labelKey="ADMIN_LABEL_DESCRIPTION"
            maxlength="250"
            onChange={handlers.description}
            placeholderKey="ADMIN_PLACEHOLDER_ENTER_A_DESCRIPTION"
            rows={5}
            value={state.description.value}
          />
          {isEditing && <CannotEditIcon name="nav2Access" tooltipKey="YOU_CAN_NOT_EDIT_THIS" />}
          <StyledSkuInput
            data-testid="skuInput"
            descriptionKey="ADMIN_ENTITLEMENT_UPDATE_SKU_DESCRIPTION"
            disabled={isEditing}
            errorKey={state.sku.errorMessage}
            labelKey="ADMIN_LABEL_SKU"
            maxlength="25"
            onBlur={blurs.sku}
            onChange={handlers.sku}
            placeholderKey="ADMIN_PLACEHOLDER_SKU"
            showDangerErrorMessage={state.sku.error}
            type="text"
            value={state.sku.value}
          />
          {disableFrequency && <CannotEditIcon name="nav2Access" tooltipKey="YOU_CAN_NOT_EDIT_THIS" />}
          <FrequencyContainer disabled={disableFrequency}>
            <Label descriptionKey={frequencyDescriptionKey} labelKey={frequencyLabelKey} />
            {isTicket ? (
              <StyledDateTimePicker
                data-testid="poppingUpCalendar"
                errorMessageKey={state.eventDate.errorMessage}
                footerTextKey="ADMIN_LABEL_FREQUENCY_MULT_DAY"
                hideClearButton
                hideTime
                hideTimezone
                iconButtonRight="calendar"
                noLabel
                onTimeChange={handlers.eventDate}
                showDangerErrorMessage={state.eventDate.error}
                timestamp={state.eventDate.value}
              />
            ) : (
              <StyledRadioGroup
                disabled={isEditing}
                onChange={handlers.recurrence}
                options={FREQUENCY_OPTIONS}
                selection={
                  FREQUENCY_OPTIONS.find((option) => {
                    return option?.target?.value === state.recurrence.value;
                  }) || FREQUENCY_OPTIONS[0]
                }
              />
            )}
          </FrequencyContainer>
          <StyledContainer style={{ marginBottom: 0 }}>
            <StyledIconLabel
              descriptionKey="ADMIN_LABEL_PRICING_DESCRIPTION"
              icon="union"
              labelKey="ADMIN_LABEL_PRICING"
              whiteIcon
            />
          </StyledContainer>
          <MultiCurrencyPriceInput
            disableRemovePrice={isEditing}
            displayErrors={state.prices.error}
            errors={currencyErrors}
            handlePrices={handlers.prices}
            initialPrices={state.prices.value}
            setErrors={setCurrencyErrors}
          />
          <StyledNeedHelpSection />
        </TextInputSection>
        {isSaved && (
          <AjaxFetch
            body={getEntitlementFromUseFormState(state)}
            headers={{
              Authorization: `Bearer ${primaryToken}`,
              'x-maestro-client-id': siteId,
            }}
            method="POST"
            onError={handleErrors}
            onValue={handleSaveResponse}
            url={getUrl()}
          />
        )}
        <JsonDebug value={state} />
      </ContentContainer>
    </>
  );
};

SetSubscriptionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedSubscription: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.oneOf([SubscriptionType.subscription, SubscriptionType.ticket]),
};

SetSubscriptionModal.defaultProps = {
  selectedSubscription: null,
  type: SubscriptionType.subscription,
};

export default SetSubscriptionModal;

import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { Button, LargeButton, BorderButton } from 'components/admin2/ui/Button';
import { ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { ADMIN_BUTTON_TEXT_CAPITALIZATION, ADMIN_HIGHLIGHT_PRIMARY } from 'style/constants';

export const StyledButton = styled(Button as any)`
  padding: 0 5px;
` as typeof Button;

export const StyledIcon = styled(Icon)<{iconSize?: number, isTextOverflowing?: boolean, spacing?: number}>`
  margin-right: ${({ spacing = 5, isTextOverflowing }) => isTextOverflowing ? 0 : spacing}px;
  & svg {
    ${({ iconSize }) => iconSize && css`
      width: ${iconSize}px !important;
      height: ${iconSize}px !important;
    `}
    & > path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY} !important;
    }
  }
`;

export const ButtonLabel = styled.div<{isTextOverflowing?: boolean}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: initial;
  ${ADMIN_TEXT_LABEL_S_MEDIUM}
  text-transform: ${ADMIN_BUTTON_TEXT_CAPITALIZATION};
  ${({ color }) => color && css`
    color: ${color};
  `}
  ${({ isTextOverflowing }) => isTextOverflowing && css`
    display: none;
  `}
`;

export const BUTTON_MAP: { [key: string]: typeof Button } = {
  border: BorderButton,
  default: StyledButton,
  large: LargeButton,
};

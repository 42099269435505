import Calendar from 'components/ui/Calendar';
import styled from 'styled-components';

export type { CalendarProps } from 'components/ui/Calendar';

const AdminCalendar = styled(Calendar).attrs({
  admin: true,
})``;

export default AdminCalendar;

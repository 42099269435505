import { Dropdown } from 'components/admin2/ui/Dropdown';
import styled from 'styled-components';

export const FolderParentPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;

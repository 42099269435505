import IconButton from 'components/admin2/ui/IconButton';
import {
  ADMIN_ALERT_ERROR,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_300,
  ADMIN_TEXT_200,
  ADMIN_SURFACE_1,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  ADMIN_ALERT_CONFIRMATION,
  TAB_PADDING,
  TEXT_100,
} from 'style/constants';
import {
  ADMIN_SCROLL_BAR,
} from 'style/mixins';
import { LargeButton } from 'components/admin2/ui/Button';
import styled, { css } from 'styled-components';
import TextInput from 'components/admin2/TextInput';
import BoxTagDropdown from 'components/admin2/ui/BoxTagDropdown';
import Icon from 'components/ui/Icon';
import { CloseButton } from 'components/admin2/ui/Button';
import { ADMIN_TEXT_BODY_M_BOLD, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';

export const InviteAdminButton = styled(IconButton)`
margin-bottom: 24px;
height: 40px;
`;

export const UsersContainer = styled.div`
margin-bottom: ${TAB_PADDING};
`;

export const ExtendAnimation = styled.div`
${ADMIN_SCROLL_BAR};
background-color: ${ADMIN_SURFACE_4};
height: 100%;
transition: 0.1s;
position: absolute;
max-width: 445px;
width: 80%;
z-index: 999;
top: 52px;
& > * {
min-height: 100%;
}
`;

export const StyledCloseButton = styled(CloseButton).attrs({
  containerCss: css`
    position: absolute;
    top: 10px;
    right: 36px;
  `,
})``;

export const TabTitle = styled.div`
  ${ADMIN_TEXT_BODY_M_BOLD}
  text-transform: uppercase;
  text-transform: unset;
  margin-top: 40px;
  margin-bottom: 10px;
  color: ${ADMIN_TEXT_100};
`;

export const TabContent = styled.div`
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${ADMIN_TEXT_300};
`;

export const TabSubtitle = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD}
  margin: 9px 0;
  color: ${ADMIN_TEXT_100};
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;
`;

export const StyledLargeButton = styled(LargeButton).attrs(props => ({
  disabledBackground: ADMIN_SURFACE_5(props),
  disabledColor: ADMIN_TEXT_300(props),
}))<{ success?: boolean }>`
  width: 100%;
  margin: 10px auto;
  ${({ success }) => success && css`
    background: ${ADMIN_ALERT_CONFIRMATION};
    &:hover {
      background: ${ADMIN_ALERT_CONFIRMATION};
    }
  `}
`;

export const OrTag = styled(TabSubtitle)`
  color: ${ADMIN_TEXT_200};
  text-align: center;
`;

export const EmailTextInput = styled(TextInput)<{accountExist?: boolean, valid?: boolean }>`
& input {
  border: none;
${({ accountExist }) => accountExist && css`border: 1px solid ${TEXT_100};`}
${({ valid }) => valid && css`border: 1px solid red`}
}
`;

export const ChannelsDropdown = styled(BoxTagDropdown)<{ valid?: boolean }>`
& {
${({ valid }) => valid && css`border: 1px solid red`}
}`;

export const LinkInput = styled.div`
  display: flex;
  justify-content: center;
  color: ${ADMIN_ACCENT_PRIMARY};
  ${ADMIN_TEXT_BODY_S_REGULAR}
  min-height: 40px;
  padding: 12px;
  border-radius: 4px;
  background: ${ADMIN_SURFACE_1};
  & p {
    display: inline;
    margin: 0;
    word-break: break-all;
    text-align: center;
  }
`;

export const ValidateMessage = styled.div`
  color: ${ADMIN_ALERT_ERROR};
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

export const SuccessMessage = styled(ValidateMessage)`
  color: ${ADMIN_ACCENT_PRIMARY};
  justify-content: center;
  circle {
    fill: ${ADMIN_ACCENT_PRIMARY} !important;
  }

`;
export const ReSentMessage = styled(SuccessMessage)`
  justify-content: flex-start;
  ${ADMIN_TEXT_BODY_S_REGULAR}
  & svg {
    transform: scale(1.3);
  }
`;

export const StyledIcon = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
}))`
  margin-right: 6px;
  & svg {
    width: 14px;
    height: 14px;
  }
`;

export const CopiedIcon = styled(StyledIcon).attrs({
  name: 'circledCheckmark',
})`
  circle {
    fill: ${ADMIN_TEXT_100} !important;
  }

  path {
    fill: ${ADMIN_ALERT_CONFIRMATION} !important;
  }
`;

export const AvailableSeatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const AvailableSeatsLabel = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_300};
`;

export const NeedMoreSeatsLabel = styled(AvailableSeatsLabel)`
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  margin-right: 5px;
`;

export const StyledLinkText = styled.span`
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  display: inline-block;
  color: ${ADMIN_ACCENT_PRIMARY};
  text-decoration: underline;
  cursor: pointer;
`;

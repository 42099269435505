import React, { useMemo } from 'react';
import { IInvite } from 'services/invite/models';
import { EditButton, DeleteButton, ResendButton } from '../Button';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  Container,
  UserInfo,
  Tag,
  EmailLabel,
  MarginWrapper,
  ButtonsContainer,
  StyledSpinner,
  DeleteMessage,
  AccountExistContainer,
  AccountExistTitle,
  AccountExistContent,
  ExpiredTag,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

interface AdminCardProps extends React.HTMLAttributes<HTMLDivElement> {
  accountExist: boolean | string,
  fetching?: boolean;
  includeTag?: boolean;
  invite?: IInvite;
  onDelete?: () => void;
  onEdit?: () => void;
  onResend?: () => void;
}

export default function InviteCard({ invite = { expirationTime: 0 }, onDelete, onEdit, onResend, className, includeTag = true, fetching = false, accountExist }: AdminCardProps) {
  const { t } = useAdminTranslation();
  const userTag = useMemo(() => {
    const { targetRoles } = invite;
    const siteAdmin = targetRoles?.some(role => role.scope === '*' && role.write);
    const producer = targetRoles?.some(role => role.scope === 'Producer');
    const otherRole = targetRoles?.some(role => role.scope === 'Chat_Moderator');
    if (siteAdmin) {
      return t('ADMIN_INVITE_TAB_SITE_ADMIN');
    }
    if (producer) {
      return t('ADMIN_LABEL_PRODUCER');
    }
    if (otherRole) {
      return t('ADMIN_LABEL_CHAT_MODERATOR');
    }
    return '';
  }, [invite.targetRoles, t]);
  const expirationTag = useMemo(()=> {
    const { expirationTime } = invite;
    if( new Date().getTime() < new Date(expirationTime).getTime() ) {
      return null;
    }
    const expiration = new Date().getTime() - new Date(expirationTime).getTime();
    const timeInMinutes:number = Math.floor(expiration / 60000);
    const timeInHours:number = Math.floor(expiration / 3600000);
    const timeInDays:number = Math.floor(expiration / 86400000);
    if (timeInDays === 1) {
      return t('EXPIRED_ONE_DAY_AGO');
    }
    if (timeInDays > 1) {
      return t('EXPIRED_X_DAYS_AGO', { days: timeInDays.toString() });
    }
    if (timeInHours === 1) {
      return t('EXPIRED_ONE_HOUR_AGO');
    }
    if (timeInHours > 1) {
      return t('EXPIRED_X_HOURS_AGO', { hours: timeInHours.toString() });
    }
    if (timeInMinutes > 1) {
      return t('EXPIRED_X_MINUTES_AGO', { minutes: timeInMinutes.toString() });
    }
    return t('EXPIRED_WITHIN_LAST_MINUTE');
  }, [invite.expirationTime, t]);
  if (invite.deleted) {
    return (
      <Container className={className}>
        <DeleteMessage>
          {t('ADMIN_INVITE_CARD_INVITATION_DELETED')}
        </DeleteMessage>
      </Container>
    );
  }
  if (invite._id && invite._id === accountExist) {
    return (
      <Container className={className}>
        <AccountExistContainer>
          <TranslatedText component={AccountExistTitle} stringKey="ADMIN_ADDED" />
          <TranslatedText component={AccountExistContent} stringKey="ADMIN_ALREADY_EXIST" />
        </AccountExistContainer>
      </Container>
    );
  }
  return (
    <Container className={className}>
      <UserInfo>
        <EmailLabel label={invite.email} data-testid="invitationCardEmail" />
        {includeTag && <Tag data-testid="invitationCardRole">{userTag}</Tag>}
        <ButtonsContainer>
          {expirationTag && (
          <ExpiredTag>
            {expirationTag}
          </ExpiredTag>
          )}
          { fetching ?
            <StyledSpinner />
              :
            (
            <>
            {onEdit && (
              <MarginWrapper>
                <EditButton onClick={onEdit} data-testid="invitationCardEditBtn" />
              </MarginWrapper>
            ) }
            {onResend && (
              <MarginWrapper>
                <ResendButton onClick={onResend} data-testid="invitationCardResendBtn" />
              </MarginWrapper>
            ) }
            {onDelete && (
              <MarginWrapper>
                <DeleteButton onClick={onDelete} data-testid="invitationCardDeleteBtn" />
              </MarginWrapper>
            ) }
            </>
            )
          }
          </ButtonsContainer>
      </UserInfo>
    </Container>
  );
}

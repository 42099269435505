// TODO flesh out
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import AdminIcon from 'components/admin2/AdminIcon';
import TranslatedText from 'components/i18n/TranslatedText';
import { POLL_SERVICE_BASE_URL } from 'config';
import { ADMIN_TEXT_100, ADMIN_SURFACE_1, SPACING_SMALL } from 'style/constants';
import {
  DIV_BUTTON,
  ADMIN_BACKGROUND_TITLE,
  ACTION_ICON_NORMAL,
  ACTION_ICON_SVG,
} from 'style/mixins';
import AjaxProvider from 'components/core/AjaxProvider';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

const IconButton = styled(Icon)`
  ${ACTION_ICON_NORMAL}
  cursor: pointer;
  margin-left: 1em;
`;

const IconButtonSvg = styled(AdminIcon)`
  ${ACTION_ICON_SVG}
  cursor: pointer;
  margin-left: 1em;
`;

const Container = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING_SMALL};
  border: 2px solid ${ADMIN_SURFACE_1};
  ${DIV_BUTTON};
  margin-bottom: ${props => props.draggable ? '2px' : 0};
  ${props => props.draggable && ADMIN_BACKGROUND_TITLE};
`;

const Text = styled.div``;

const TextContainer = styled.div`

`;

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export default class QuickPickFancyButtonView extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    draggable: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    primaryToken: PropTypes.string.isRequired,
    siteId: PropTypes.string.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
    draggable: false,
    onEdit: undefined,
    onRemove: undefined,
    value: '',
  };

  handleRemove = (e) => {
    e.stopPropagation();
    const { onRemove } = this.props;
    onRemove();
  };

  handleEdit = (e) => {
    e.stopPropagation();
    const { onEdit, value } = this.props;
    onEdit(value);
  };

  render() {
    const {
      className,
      draggable,
      onAdd,
      onEdit,
      onRemove,
      value,
      primaryToken,
      siteId,
    } = this.props;

    return (
      <AjaxProvider
        headers={{
          Authorization: `Bearer ${primaryToken}`,
          'x-maestro-client-id': siteId,
        }}
        url={`${POLL_SERVICE_BASE_URL}/${value}`}
      >
        {
          (data) => {
            const { name } = data || {};
            return (
              <Container className={className} draggable={draggable} onClick={onAdd}>
                <AlignCenter>
                  <TextContainer>
                    {
                      name ?
                        <Text>{ name }</Text> :
                        <TranslatedText stringKey="ADMIN_QUICK_PICK_BUTTON_PLACEHOLDER" />
                    }
                  </TextContainer>
                </AlignCenter>
                {
                  value ? (
                    <span>
                      {onRemove && (
                        <IconButton
                          name="cancel"
                          onClick={this.handleRemove}
                        />
                      )}
                      {onEdit && (
                        <IconButtonSvg
                          name="pencil"
                          onClick={this.handleEdit}
                        />
                      )}
                      { draggable && <IconButton name="menu" /> }
                    </span>
                  ) : <IconButtonSvg name="pointer" />
                }
              </Container>
            );
          }
        }
      </AjaxProvider>
    );
  }
}

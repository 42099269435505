import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_500, ADMIN_TEXT_100, ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_6, ADMIN_SURFACE_5, ADMIN_HIGHLIGHT_PRIMARY } from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_HEADLINE_S } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';

const maxWidth = 300;

export const ProductImage = styled.div<{ isDisabled: boolean; isSelected: boolean; src?: string; }>`
  ${props => !props.isDisabled && css`
    cursor: pointer;
  `}
  width: 100%;
  max-width: ${maxWidth}px;
  aspect-ratio: 1;
  border-radius: 5px;
  ${({ src }) => src && css`
    background-image: url(${src});
    background-repeat: round;
  `};
  ${({ src }) => !src && css`background-color: ${ADMIN_SURFACE_6}`};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.isSelected && !props.isDisabled && '&, '}&:hover {
    ${({ src }) => src && css`
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${src}), #36363C;
      background-repeat: round;
    `};
    ${({ src }) => !src && css`background-color: ${ADMIN_SURFACE_5}`};
    transition: 0.2s ease-in-out;
  }

  ${props => props.isDisabled && props.src && css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${props.src}), #36363C;
    background-repeat: round;
  `};

  ${props => props.isDisabled && !props.src && css`
    background-color: ${ADMIN_SURFACE_5};
  `};

  ${props => props.isSelected && css`
    border: 1px solid ${ADMIN_ACCENT_PRIMARY};
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: ${maxWidth}px;
  position: relative;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Name = styled.h3`
  ${ADMIN_TEXT_BODY_S_BOLD};
  margin: 0;
  color: ${ADMIN_TEXT_100};
`;

export const Price = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_100};
`;

export const SoldOutText = styled.label`
  ${ADMIN_TEXT_HEADLINE_S};
  color: ${ADMIN_TEXT_500};
  transform: rotate(-45deg);
  cursor: pointer;
`;


const ICON_STYLES = css`
  pointer-events: none;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;

  & svg {
    width: 14px;
    height: 14px;
    & path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
  }
`;

export const PlusIcon = styled(Icon).attrs({
  name: 'plus',
})`
  ${ICON_STYLES}
  background: ${ADMIN_SURFACE_6};
`;

export const CheckIcon = styled(Icon).attrs({
  name: 'checked',
})`
  ${ICON_STYLES}
  background: ${ADMIN_ACCENT_PRIMARY};
`;

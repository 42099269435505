import { connect } from 'react-redux';
import { writeToCollectionLegacy, editCollectionItem } from 'services/admin';
import { stripEmptyObjectValues, createSlugFromString } from 'utils';
import NewPersonEditor from './NewPersonEditor';

export { getLogoFromLink, LINK_ICON_KEY, LINK_TEXT_KEY, LINK_URL_KEY } from './NewPersonEditor';

/* eslint-disable sort-keys */
const mapDispatchToProps = dispatch => ({
  handleEdit: (type, id, renderer) => dispatch(editCollectionItem(type, id, renderer)),
  handleSave: ({
    links: personLinks,
    personBio,
    personImageUrl,
    personName,
    personTitle,
  }) => {
    // Legacy fields
    const legacyData = {
      collection: 'people',
      slug: createSlugFromString(personName),
      type: 'person',
    };

    const personRendererRaw = {
      personBio,
      personName,
      personTitle,
      personImageUrl,
      personLinks: personLinks.reduce((acc, {
        linkText, // eslint-disable-line
        linkUrl,
        linkIconName,
      }) => {
        if (linkText || linkIconName || linkUrl) {
          return [
            ...acc,
            {
              linkText,
              linkUrl,
              linkIconName,
            },
          ];
        }
        return acc;
      }, []),
    };
    const renderer = stripEmptyObjectValues(personRendererRaw);
    const doc = {
      ...legacyData,
      renderer,
    };
    dispatch(writeToCollectionLegacy(doc));
  },
});

export default connect(null, mapDispatchToProps)(NewPersonEditor);

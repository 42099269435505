import videoLibraryModalTypes, {
  TVideoLibraryModalTypes,
} from 'components/modals/videoLibraryModalTypes';
import { injectT } from 'hooks/use-translation';
import { PlayerTypes, VideoTypes } from 'models';

export type TModalActionsMode = ReturnType<typeof modalTypeActionsMap>;

export const modalTypeActionsMap = injectT(
  (t) => (
    type: TVideoLibraryModalTypes,
    toast: (text: string) => unknown,
  ) => {
    switch (type) {
      case (videoLibraryModalTypes.ADMIN_LIBRARY):
        return {
          rowActionButtonLabel: t('VIDEO_LIBRARY_LIST_GO_TO_VIDEO_ACTION'),
        } as const;

      case (videoLibraryModalTypes.EDIT_MODE):
        return {
          filters: {
            type: VideoTypes.vod,
          },
          headerTextKey: 'ADMIN_LABEL_VIDEO_AND_PLAYLIST_LIBRARY_HELP',
        } as const;

      case (videoLibraryModalTypes.LIVESTREAM):
        return {
          buttonStringKey: 'ADMIN_LABEL_ADD_VIDEO',
          filters: {
            player: PlayerTypes.twitch,
            type: VideoTypes.livestream,
          },
          headerTextKey: 'ADMIN_LABEL_PAGE_LIVESTREAM_LIBRARY_HELP',
        } as const;

      case (videoLibraryModalTypes.OFFLINE_CONTENT):
        return {
          filters: {
            urlDefined: 'true',
          },
          headerTextKey: 'ADMIN_LABEL_VIDEO_LIBRARY_HELP',
        } as const;

      case (videoLibraryModalTypes.PLAYLIST_EDIT):
        return {
          postSelectItemAction: () => toast(t('ADMIN_MESSAGE_VIDEO_ADDED_TO_PLAYLIST')),
          filters: {
            urlDefined: 'true',
          },
          rowActionButtonLabel: 'Add',
          headerTextKey: 'ADMIN_LABEL_VIDEO_LIBRARY_HELP',
        } as const;

      case (videoLibraryModalTypes.QUEST_EDIT):
        return {
          filters: {
            type: 'all',
          },
          buttonStringKey: 'ADMIN_LABEL_ADD_VIDEO',
          headerTextKey: 'ADMIN_LABEL_QUEST_VIDEO_SELECT_PLACEHOLDER',
        } as const;

      case (videoLibraryModalTypes.SCHEDULE_EDIT):
        return {
          filters: {
            type: VideoTypes.vod,
          },
        } as const;
    }
  },
);

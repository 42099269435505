import React, { useState, useEffect } from 'react';
import { useFocusArea } from 'hooks';
import intersection from 'lodash/intersection';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import SearchInput from 'components/admin2/ui/SearchInput';
import {
  Container,
  DottedDiv,
  ValuesContainer,
  ValueWrapper,
  SelectedValue,
  RemoveIcon,
  OptionContainer,
  SearchWrapper,
  OptionMenu,
  AddMoreButton,
  Option,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

export interface IMultiSelectorProps {
  mapOptionToText: (option: any) => any;
  onChange: (option: any) => void;
  onRemove: (index: number) => void;
  options: any[];
  value: any[];
}

const MultiSelector: React.FC<IMultiSelectorProps> = ({
  value = [],
  options,
  onChange,
  onRemove,
  mapOptionToText = (option) => option,
}) => {
  const { t } = useAdminTranslation();
  const [listOpen, setListOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [validValue, setValidValue] = useState(value);
  // sync local state with props
  useEffect(() => {
    setValidValue(value);
  }, [value]);

  const listRef = useFocusArea<HTMLDivElement>({
    onExit: () => {
      setListOpen(false);
      setSearchText('');
    },
    active: listOpen,
  });

  const remainingOptions = options
    .filter((option) => !value.includes(option))
    .filter((option) => mapOptionToText(option).includes(searchText));

  // clear out selected value if it no longer exists in the options
  useEffect(() => {
    const newValidValueList = intersection(value, options);
    if (newValidValueList.length !== validValue.length) {
      setValidValue(newValidValueList);
    }
  }, [options.length]);

  return (
    <Container>
      {validValue.length === 0 && <DottedDiv>{t('ADMIN_MULTI_SELECTOR_NO_OPTIONS_SELECTED')}</DottedDiv>}
      <ValuesContainer>
        {validValue.length >= 1 && (
          <NonKeyedListMapper list={validValue}>
            {(key, item, i) => (
              <ValueWrapper key={key}>
                <SelectedValue>{mapOptionToText(item)}</SelectedValue>
                {/* tslint:disable-next-line: jsx-no-lambda no-empty */}
                <button onClick={() => onRemove(i)}>
                  <RemoveIcon />
                </button>
              </ValueWrapper>
            )}
          </NonKeyedListMapper>
        )}
      </ValuesContainer>
      {listOpen && (
        <OptionContainer ref={listRef}>
          <SearchWrapper>
            <SearchInput onSearch={setSearchText} />
          </SearchWrapper>
          <OptionMenu>
            <NonKeyedListMapper list={remainingOptions}>
              {(key, option) => (
                <Option
                  key={key}
                  // tslint:disable-next-line: jsx-no-lambda no-empty
                  onClick={() => {
                    onChange(option);
                    setListOpen(false);
                  }}
                >
                  {mapOptionToText(option)}
                </Option>
              )}
            </NonKeyedListMapper>
          </OptionMenu>
        </OptionContainer>
      )}
      {remainingOptions.length > 0 && (
        /* tslint:disable-next-line: jsx-no-lambda no-empty */
        <AddMoreButton onClick={() => setListOpen(true)}>
          Add More
        </AddMoreButton>
      )}
    </Container>
  );
};

export default MultiSelector;

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { NavLinkContainer } from './styles';
import withConfirmation, { ConfirmationProps } from 'components/core/withConfirmation';
import type { TranslationKey } from 'hooks/use-translation';

interface NavLinkProps extends ConfirmationProps {
  stringKey?: TranslationKey;
}

function NavLink({ children, onClick: navigate, stringKey }: NavLinkProps) {
  return (
    <NavLinkContainer onClick={navigate}>
      {stringKey && <TranslatedText stringKey={stringKey} />}
      {children}
    </NavLinkContainer>
  );
}

export default withConfirmation(NavLink);

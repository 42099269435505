import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { IconButton } from 'components/admin2/ui/Button';
import {
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_6,
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
} from 'style/constants';
import { ADMIN_TEXT_BODY_L_REGULAR, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

export const CatalogContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  color: ${ADMIN_TEXT_100};
  margin: 0 20px;
  padding-top: 20px;
  padding-bottom: 50px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  user-select: none;
  padding: 10px 0;
  padding-top: 20px;
  border-bottom: 1px solid ${ADMIN_SURFACE_5};
  &:first-child {
    padding-top: 30px;
  }
`;

export const Header = styled.div`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  & > span {
    color: ${ADMIN_TEXT_100};
  }
`;

export const TitleInfoContainer = styled.div`
  background: ${ADMIN_SURFACE_5};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  flex: 1;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 4.09904px;
  padding: 0 9px;
  color: ${ADMIN_TEXT_200};
  &:hover {
    background: ${ADMIN_SURFACE_6};
  }
`;

export const KindIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_100(props),
}))`
  font-size: 1.2em;
  margin-right: 14px;
  margin-left: 6px;
  color: ${ADMIN_TEXT_100};
  line-height: 0;
  & svg {
    width: 18px;
    height: 18px;
  }
  & i:before {
    margin: 0;
  }
  &:before {
    ${ADMIN_TEXT_BODY_L_REGULAR}
    margin: 0;
  }
`;

export const Info = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  margin-left: 15px;
`;

export const WatchButton = styled(IconButton as any).attrs(() => ({
  name: 'eyeFilled',
}))`
  margin-left: auto;
  margin-right: 10px;
  padding: 0;
  & svg, svg path {
    width: 17px !important;
    height: 17px !important;
  }
`;

import ErrorMessage from 'components/admin2/ui/ErrorMessage';
import Label from 'components/admin2/ui/Label';
import React from 'react';
import { BundleTotalWrapper } from './styles';

interface IBundleTotalProps {
  currency: string;
  hasProduct: boolean;
  hasTicket: boolean;
  itemsHasSameCurrency: boolean;
  submittedOnce: boolean;
  total: number;
}

const BundleTotal: React.FC<IBundleTotalProps> = ({ itemsHasSameCurrency, submittedOnce, hasProduct, hasTicket, currency, total }) => {
  const formattedTotal = React.useMemo(() => {
    const numberFormatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currency.toUpperCase(),
    });
    return `${numberFormatter.format(total)} ${currency.toUpperCase()}`;
  }, [total]);

  const renderTotal = React.useCallback(() => {
    if (!hasProduct && !hasTicket) {
      return <ErrorMessage errorMessageKey="BUNDLE_PRICE_ERROR" />;
    }

    if (!hasProduct) {
      return <ErrorMessage errorMessageKey="BUNDLE_PRICE_ERROR_MISSING_PRODUCT" />;
    }

    if (!hasTicket) {
      return <ErrorMessage errorMessageKey="BUNDLE_PRICE_ERROR_MISSING_TICKET" />;
    }

    if (!itemsHasSameCurrency) {
      return <ErrorMessage errorMessageKey="BUNDLE_PRICE_ERROR_CURRENCY_MISMATCH" />;
    }

    return (
      <>
        <Label labelKey="BUNDLE_SUBTOTAL" />
        <Label label={formattedTotal} />
      </>
    );
  }, [hasProduct, hasTicket, formattedTotal, itemsHasSameCurrency]);

  if (!submittedOnce && !hasProduct && !hasTicket) {
    return null;
  }

  return (
    <BundleTotalWrapper itemsHasSameCurrency={itemsHasSameCurrency} hasProduct={hasProduct} hasTicket={hasTicket}>
      {renderTotal()}
    </BundleTotalWrapper>
  );
};

export default BundleTotal;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import shortid from 'shortid';

import Dragger from 'components/admin2/LegacyDragger';
import PollLibraryModal from 'components/admin2/PollLibraryModal';
import MultipleChoiceLibraryButton from 'components/admin2/RendererEditor/MultipleChoiceLibraryButton';
import { LargeButton } from 'components/admin2/ui/Button';
import withLabel from 'components/core/withLabel';
import { useAdminTranslation } from 'hooks/use-translation';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    type: PropTypes.string,
  }),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      arrayId: PropTypes.string.isRequired,
      pollId: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

const defaultProps = {
  options: {},
  value: [],
};

function MultipleChoiceSelect({ onChange, options: { type = null }, value }) {
  const { t } = useAdminTranslation();
  const [open, setOpen] = useState(false);

  const replaceIndex = (index, pollId) => {
    const newValue = value.map((item, i) => i === index ? { ...item, pollId } : item);
    onChange(newValue);
  };

  const removeIndex = (index) => {
    const newValue = value.filter((item, i) => i !== index);
    onChange(newValue);
  };

  const addId = ({ _id: pollId }) => {
    const arrayId = shortid.generate();
    onChange([
      ...value,
      { arrayId, pollId },
    ]);
  };

  return (
    <div>
      <Dragger
        direction="vertical"
        droppableStyles={{ width: '100%' }}
        innerDivStyles={{ width: '100%' }}
        keyField="arrayId"
        list={value}
        onDragEnd={onChange}
        stretch
      >
        {(item, index) => {
          return (
            <MultipleChoiceLibraryButton
              onChange={_id => replaceIndex(index, _id)}
              onDelete={() => removeIndex(index)}
              options={{
                draggable: true,
                fancyButtonProps: {
                  buttonPlaceholderKey: 'ADMIN_POLL_BUTTON_PLACEHOLDER',
                  docToButtonProps: doc => {
                    return {
                      showImage: false,
                      text: doc?.name,
                      type: doc?.type,
                    }
                  },
                },
                icon: 'check',
                type: type || 'multipleChoice',
                valueField: '_id',
              }}
              value={item.pollId}
            />
          );
        }}
      </Dragger>
      <LargeButton
        fullwidth
        onClick={() => setOpen(true)}
      >
        {t('ADMIN_RENDERER_EDITOR_ADD_MULTIPLE_CHOICE')}
      </LargeButton>
      {open && (
        <PollLibraryModal
          onClose={() => setOpen(false)}
          onSelectItem={addId}
          type={type}
        />
      )}
    </div>
  );
}

MultipleChoiceSelect.propTypes = propTypes;
MultipleChoiceSelect.defaultProps = defaultProps;

export default withLabel(MultipleChoiceSelect);

import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SPACING_SMALL } from 'style/constants';
import KeyedListMapper from 'components/core/KeyedListMapper';
import TranslatedText from 'components/i18n/TranslatedText';
import ActionButton from 'components/admin2/ActionButton';
import Label from 'components/admin2/Label';
import Radio from 'components/admin2/ui/Radio';
import { ADMIN_TEXT_TITLE_FIELD_ERROR } from 'style/mixins';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${SPACING_SMALL};
`;

const RadioWrapper = styled(ActionButton)`
  display: flex;
  align-items: center;
  flex: 1;
`;

const RadioLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLabel = styled(Label)`
  margin-left: ${SPACING_SMALL};
  margin-bottom: 0;
`;

const ErrorText = styled.span`
  margin-left: ${SPACING_SMALL};
  ${ADMIN_TEXT_TITLE_FIELD_ERROR}
`;

const RadioGroup = (props) => {
  const {
    appendComponents,
    className,
    notificationIndex,
    onChange,
    options,
    selection,
  } = props;

  return (
    <Container className={className}>
      <KeyedListMapper keyField="value" list={options}>
        {(key, option, index) => {
          const isChecked = option.target?.value ?
            option.target.value === selection.target.value :
            option.value === selection.value;
          return (
            <RadioContent>
              <RadioWrapper
                key={key}
                data-testid={option.testId}
                onClick={() => onChange(option, index)}
              >
                <Radio
                  checked={isChecked}
                  labelKey={option.stringKey}
                  readOnly
                  value={option.target?.value ? option.target.value : option.value}
                  width="auto"
                />
                <RadioLabelContainer>
                  {notificationIndex === index && (
                  <StyledLabel>
                    <TranslatedText component={ErrorText} stringKey="ADMIN_FOOTER_ERROR_REQUIRED" />
                  </StyledLabel>
                  )}
                </RadioLabelContainer>
              </RadioWrapper>
              {appendComponents &&
                appendComponents.map(({ component, showAtIndex }) => {
                  return showAtIndex === index ? component : null;
                })}
            </RadioContent>
          );
        }}
      </KeyedListMapper>
    </Container>
  );
};

RadioGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appendComponents: PropTypes.any,
  className: PropTypes.string,
  notificationIndex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      stringKey: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object.isRequired,
};

RadioGroup.defaultProps = {
  className: undefined,
};

export default memo(RadioGroup);

import styled from 'styled-components';
import TextInput from 'components/admin2/TextInput';
import Icon from 'components/ui/Icon';
import Label from 'components/admin2/Label';
import ActionButton from 'components/admin2/ActionButton';
import StatusIndicator from 'components/admin2/StatusIndicator';

import {
  SPACING_SMALL,
  SPACING_MEDIUM,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_1,
  ADMIN_TEXT_200,
  ADMIN_SURFACE_2,
} from 'style/constants';

import {
  ACTION_ICON_NORMAL,
  ACTION_NORMAL,
  ADMIN_SCROLL_BAR,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_L_REGULAR, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

export const Container = styled.div`
  background-color: ${ADMIN_SURFACE_4};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  & *:not(:first-child) {
    margin-left: 10px;
  }
`;

export const StyledStatusIndicator = styled(StatusIndicator)`
  display: flex;
  align-items: center;
  float: right;
  position: absolute;
  right: ${SPACING_MEDIUM};
  top: ${SPACING_MEDIUM};
`;

export const Header = styled.div`
  background: ${ADMIN_SURFACE_3};
  display: flex;
  justify-content: space-between;
  padding: ${SPACING_MEDIUM};
  align-items: center;
`;

export const HeaderText = styled.span`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
`;

export const BackButton = styled(Icon).attrs({
  name: 'left-open-big',
})`
  ${ACTION_ICON_NORMAL}
  cursor: pointer;
  display: inline-block;
  ${ADMIN_TEXT_BODY_L_REGULAR}
  margin-right: ${SPACING_MEDIUM};
`;

export const Body = styled.div`
  flex: 1;
  max-height: 250px;
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
`;

export const SaveButton = styled(ActionButton)`
  &:not(:first-child) {
    margin-left: ${SPACING_MEDIUM};
  }
`;

export const SubHeader = styled.div`
  background-color: ${ADMIN_SURFACE_4};
  flex: 0 0 auto;
  padding: ${SPACING_MEDIUM};
`;

export const AddYouTubePlaylistButton = styled.div`
  background: ${ADMIN_SURFACE_2};
  cursor: pointer;
  margin-left: ${SPACING_SMALL};
  & > span {
    padding: 15px;
  }
`;

export const InputContainer = styled.div`
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${SPACING_SMALL};
`;

export const PlaylistVideosContainer = styled.div`
  ${ADMIN_SCROLL_BAR}
  display: flex;
  max-height: 250px;
`;

export const PlaylistInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin-top: ${SPACING_SMALL};
`;

export const NoPlaylistVideosNotice = styled.div`
  align-items: center;
  border: 2px dashed ${ADMIN_SURFACE_1};
  display: flex;
  height: 200px;
  justify-content: center;
  margin: ${SPACING_MEDIUM};
  width: 100%;
`;

export const ThumbnailContainer = styled.div`
  margin-right: ${SPACING_MEDIUM};
`;

export const StyledThumbnail = styled.img`
  width: 220px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoLabel = styled(Label)`

`;

export const InfoValue = styled.span`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  margin-left: ${SPACING_SMALL};
`;

export const VideoType = styled.div``;

export const AddVideoButton = styled.div`
  ${ACTION_NORMAL}
  align-items: center;
  background-color: ${ADMIN_SURFACE_2};
  display: flex;
  flex: 0 0 40px;
  justify-content: center;
  width: 100%;
  z-index: 1;
  margin-top: 3px;
`;

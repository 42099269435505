import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  Button,
} from './styles';
import { useSelector } from 'react-redux';
import { getAdminText300 } from 'services/themes';
import { getAdminSurface5 } from 'services/themes';

export enum SaveTypeEnum {
  SAVE = 'SAVE',
  DONE = 'DONE',
}
interface SaveButtonProps {
  disabled: boolean;
  existing?: boolean;
  onSave?: (e?: React.MouseEvent) => void;
  saved?: boolean;
  type?: SaveTypeEnum,
}

export default function SaveButton({
  disabled,
  existing,
  onSave,
  saved,
  type=SaveTypeEnum.SAVE,
}: SaveButtonProps) {
  const adminText300 = useSelector(getAdminText300);
  const adminThemeSurface5 = useSelector(getAdminSurface5);

  return onSave ? (
    <Button
      disabled={disabled || saved}
      onClick={onSave}
      disabledBackground={adminThemeSurface5}
      disabledColor={adminText300}
    >
      {saved ? (
      <TranslatedText stringKey="ADMIN_LABEL_SAVED" />
      ) : (
      <TranslatedText stringKey={existing ? `ADMIN_ACTION_${type}` : 'ADMIN_ACTION_SAVE_TO_LIBRARY'} />
      )}
    </Button>
  ) : null;
}

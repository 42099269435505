import React from 'react';
import styled from 'styled-components';
import {
  ADMIN_ACCENT_PRIMARY,
} from 'style/constants';

const Dot = styled.div`
  height: 5.8px;
  width: 5.8px;
  margin-left: 4.6px;
  background-color: ${ADMIN_ACCENT_PRIMARY};
  border-radius: 5px;
`;

const Elipse = styled.div`
  display: flex;
  align-items: center;
  margin-left: 7px;
`;

const LoadingEllipse = () => {
  return (
    <Elipse>
      <Dot />
      <Dot style={{ opacity: 0.7 }} />
      <Dot style={{ opacity: 0.4 }} />
    </Elipse>
  );
};

export default LoadingEllipse;

import TranslatedText from 'components/i18n/TranslatedText';
import Link from 'components/ui/Link';
import React, { memo } from 'react';
import { SUPPORT_BASE_URL } from 'shared/constants';
import { HelpSection, HelpIcon } from './styles';

const NeedHelpSection: React.FC = () => {
  return (
    <HelpSection>
      <HelpIcon />
      <Link href={SUPPORT_BASE_URL}>
        <TranslatedText stringKey="QUEST_NEED_HELP" />
      </Link>
    </HelpSection>
  );
};

export default memo(NeedHelpSection);

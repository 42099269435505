// TODO: Enforce "correct answer" index/id is valid
import produce from 'immer';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import shortid from 'shortid';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'hooks';
import { updateRefreshKey } from 'services/admin';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import Label from 'components/admin2/InputLabel';
import TextArea from 'components/admin2/TextArea';
import TextInput from 'components/admin2/TextInput';
import Dropdown from 'components/admin2/ui/Dropdown';
import MultiSelector from 'components/admin2/MultiSelector';
import axios from 'axios';
import JsonDebug from 'components/dev/JsonDebug';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import ActionButton from 'components/admin2/ActionButton';
import TranslatedText from 'components/i18n/TranslatedText';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import { ADMIN_SURFACE_1, SPACING_SMALL } from 'style/constants';
import { ADMIN_BACKGROUND_CONTENT, ADMIN_BACKGROUND_TITLE } from 'style/mixins';
import { POLL_SERVICE_BASE_URL } from 'config';
import { useAdminTranslation, injectT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';
import Answer from './Answer';

const StyledLabel = styled(Label)`
  margin-top: 16px;
`;

const propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  correctAnswers: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  onExit: PropTypes.func,
  onSave: PropTypes.func,
  predictionStatus: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  question: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setCorrectAnswers: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setPredictionStatus: PropTypes.func.isRequired,
  setQuestion: PropTypes.func.isRequired,
  setShowCount: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  showCount: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

const defaultProps = {
  onAdd: null,
  onExit: null,
  onSave: null,
};

const PREDICTION_STATUS_OPTIONS = injectT(
  (t) => lazy(
    () => [
      { label: t('OPEN'), value: 'open' },
      { label: t('CLOSED'), value: 'closed' },
      { label: t('ADMIN_LABEL_COMPLETED'), value: 'results' },
    ],
  ),
);

const loadPredictionStatus = injectT(
  (t) => (open, correctAnswersSet) => {
    if (correctAnswersSet) {
      return PREDICTION_STATUS_OPTIONS(t)[2];
    } if (open) {
      return PREDICTION_STATUS_OPTIONS(t)[0];
    }
    return PREDICTION_STATUS_OPTIONS(t)[1];
  },
);

const StyledActionButton = styled(ActionButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  height: 40px;
  width: 100%;
  outline: 0;
  ${ADMIN_BACKGROUND_TITLE};
`;

const TypeSelector = styled.div`
  border: 2px solid ${ADMIN_SURFACE_1};
  cursor: pointer;
  font-weight: bold;
  padding: 16px;
  text-align: center;
  text-transform: uppercase;
  ${props => props.selected ? ADMIN_BACKGROUND_TITLE : ADMIN_BACKGROUND_CONTENT};
`;

const POLL_KEY = 'poll';
const PREDICTION_KEY = 'prediction';
const TRIVIA_KEY = 'trivia';

const INITIAL_ANSWER_STATE = [{ answer: '' }, { answer: '' }];

function PollEditorView({
  onAdd,
  onExit,
  onSave,
  answers,
  correctAnswers,
  predictionStatus,
  type,
  setAnswers,
  setCorrectAnswers,
  setType,
  save,
  name,
  setName,
  question,
  setShowCount,
  setQuestion,
  setPredictionStatus,
  showCount,
}) {
  const { t } = useAdminTranslation();
  const showTypeSelector = false;

  const addBlankAnswer = () => setAnswers([...answers, { answer: '', arrayId: shortid.generate() }]);
  const deleteLastAnswer = () => setAnswers(answers.slice(0, answers.length - 1));

  const canAddQuestion = type !== 'prediction' ? answers.length < 6 : true;
  const canRemoveQuestion = answers.length > 2;

  const saveAndAdd = () => {
    onSave?.();
    // Await _id
    // onAdd?.(id);
  };

  return (
    <div style={{ width: '600px' }}>
      <div
        style={`
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          padding: 16px;
          ${ADMIN_BACKGROUND_TITLE};
        `}
      >
        <Icon name="left-open" onClick={onExit} style={{ cursor: 'pointer' }} />
        <div style={{ 'flex-grow': 1 }} />
        <Link
          onClick={save}
          plain
          style={{ color: 'inherit', 'font-weight': 'bold', 'text-transform': 'uppercase' }}
        >
          {t('ACTION_SAVE')}
        </Link>
        {onAdd && (
          <Link
            onClick={saveAndAdd}
            plain
            style={`
              color: inherit;
              text-transform: uppercase;
              font-weight: bold;
              margin-left: 16px;
            `}
          >
            {t('ACTION_SAVE_AND_ADD')}
          </Link>
        )}
      </div>
      <div style={{ padding: '16px' }}>
        <div
          style={`
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 2px solid ${ADMIN_SURFACE_1};
            position: relative;
            z-index: 2;
          `}
        >
          {
            (showTypeSelector) && (
              <div
                style={`
                  display: flex;
                  flex-flow: row nowrap;
                  align-items: stretch;
                  & > * {
                    flex: 1 1 0;
                  }
                `}
              >
                <TypeSelector
                  onClick={() => setType(POLL_KEY)}
                  selected={type === POLL_KEY}
                >
                  {t('POLL')}
                </TypeSelector>
                <TypeSelector
                  onClick={() => setType(TRIVIA_KEY)}
                  selected={type === TRIVIA_KEY}
                >
                  {t('TRIVIA')}
                </TypeSelector>
                <TypeSelector
                  onClick={() => setType(PREDICTION_KEY)}
                  selected={type === PREDICTION_STATUS_OPTIONS(t)}
                >
                  {t('PREDICTION')}
                </TypeSelector>
              </div>
            )
          }
          <Label>{t('NAME')}</Label>
          <TextInput
            onChange={newName => setName(newName)}
            value={name}
          />
          <StyledLabel>{t('ADMIN_LABEL_QUESTION')}</StyledLabel>
          <TextArea
            onChange={newQuestion => setQuestion(newQuestion)}
            value={question}
          />
          <div
            style={`
              align-items: center
              display: flex;
              flex-flow: row nowrap;
              margin-top: 16px;
            `}
          />
          <div
            style={`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: ${SPACING_SMALL};
            `}
          >
            <TranslatedText component={StyledLabel} stringKey="ADMIN_LABEL_SHOW_COMPLETED_COUNT" />
            <ToggleSwitch
              checked={showCount}
              onChange={setShowCount}
            />
          </div>
          {type === PREDICTION_KEY && (
            <div>
              <div>
                <StyledLabel>{t('RESULTS')}</StyledLabel>
                <Dropdown
                  getOptionLabel={option => option.label}
                  onChange={option => setPredictionStatus(option)}
                  options={PREDICTION_STATUS_OPTIONS(t)}
                  value={predictionStatus}
                />
              </div>
              {predictionStatus.value === 'results' && (
                <div>
                  <StyledLabel>{t('ADMIN_POLL_EDITOR_CORRECT_OPTION')}</StyledLabel>
                  <MultiSelector
                    mapOptionToText={option => option.answer}
                    onChange={option => setCorrectAnswers([...correctAnswers, option])}
                    onRemove={(index) => {
                      const newCorrectAnswers = produce(correctAnswers, (draft) => {
                        draft.splice(index, 1);
                      });
                      setCorrectAnswers(newCorrectAnswers);
                    }}
                    options={answers}
                    value={correctAnswers}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div
          style={`
            position: relative;
            z-index: 1;
          `}
        >
          {answers.map((answer, i) => {
            return (
              <Answer
                key={answer._id || answer.arrayId}
                imageUrl={answer.imageUrl}
                onChangeImage={(imageUrl) => {
                  const newAnswers = produce(answers, (draft) => {
                    draft[i].imageUrl = imageUrl;
                  });
                  setAnswers(newAnswers);
                }}
                onChangeText={(text) => {
                  const newAnswers = produce(answers, (draft) => {
                    draft[i].answer = text;
                  });
                  setAnswers(newAnswers);
                }}
                text={answer.answer}
                title={answer.answer}
              />
            );
          })}
        </div>
        {canAddQuestion && (
          <StyledActionButton onClick={addBlankAnswer}>{t('ADMIN_QUICKPICK_EDITOR_ADD_OPTION')}</StyledActionButton>
        )}
        {canRemoveQuestion && (
          <StyledActionButton onClick={deleteLastAnswer}>{t('ADMIN_QUICKPICK_EDITOR_REMOVE_OPTION')}</StyledActionButton>
        )}
      </div>
      <JsonDebug value={[name, question, type, answers, correctAnswers, predictionStatus]} />
    </div>
  );
}

const fetchPollData = async ({ id, headers }) => {
  const pollData = await axios({
    headers,
    url: `${POLL_SERVICE_BASE_URL}/${id}`,
  });
  return {
    pollData: pollData.data,
  };
};
export default function QuickPickEditor({ onSave, onExit, editingItemId }) {
  const { t } = useAdminTranslation();
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState(INITIAL_ANSWER_STATE);
  const [type, setType] = useState(PREDICTION_KEY);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [showCount, setShowCount] = useState(false);
  const [predictionStatus, setPredictionStatus] = useState(PREDICTION_STATUS_OPTIONS(t)[0]);

  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);

  const headers = {
    Authorization: `Bearer ${primaryToken}`,
    'x-maestro-client-id': siteId,
  };

  const dispatch = useDispatch();

  const editing = Boolean(editingItemId);

  // set initial data if editing
  useEffect(() => {
    if (editing) {
      fetchPollData({
        headers,
        id: editingItemId,
      })
        .then(({ pollData }) => {
          const {
            name: editingName,
            question: editingQuestion,
            open: editingOpen,
            answers: editingAnswers,
            showCount: editingShowCount,
          } = pollData;
          setName(editingName);
          setQuestion(editingQuestion);
          setAnswers(editingAnswers || INITIAL_ANSWER_STATE);
          const editingCorrectAnswers = editingAnswers.filter(answer => answer.correct);
          setPredictionStatus(
            loadPredictionStatus(t, editingOpen, editingCorrectAnswers.length > 0),
          );
          setCorrectAnswers(editingCorrectAnswers);
          setShowCount(editingShowCount);
        });
    }
  }, []);

  const save = () => {
    const open = predictionStatus.value === 'open';
    const closed = predictionStatus.value === 'closed';
    const formattedAnswers = answers
      .map(({ correct, arrayId, ...rest }) => rest)
      .map((answer) => {
        const isACorrectAnswer = correctAnswers.some((correctAnswer) => {
          const matchingIds = correctAnswer._id && correctAnswer._id === answer._id;
          const matchingText = correctAnswer.answer === answer.answer;
          return matchingIds || matchingText;
        });
        if (isACorrectAnswer && !(open || closed)) {
          return {
            ...answer,
            correct: true,
          };
        }
        return {
          ...answer,
          correct: false,
        };
      });

    const pollBody = {
      answers: formattedAnswers,
      name,
      open,
      question,
      showCount,
      type,
    };

    if (editing) {
      axios({
        data: pollBody,
        headers,
        method: 'PUT',
        url: `${POLL_SERVICE_BASE_URL}/${editingItemId}`,
      }).catch(() => (
        // eslint-disable-next-line no-alert
        alert(`Unable to update Poll: "${pollBody.name}"`)
      ));
    } else {
      axios({
        data: pollBody,
        headers,
        method: 'POST',
        url: `${POLL_SERVICE_BASE_URL}`,
      }).catch(() => (
        // eslint-disable-next-line no-alert
        alert(`Unable to save Poll: "${pollBody.name}"`)
      ));
    }

    onSave?.();
    onExit?.();
    dispatch(updateRefreshKey());
  };
  const state = {
    answers,
    correctAnswers,
    name,
    predictionStatus,
    question,
    showCount,
    type,
  };
  const onChangeMethods = {
    // onChangePredictionStatus,
    setAnswers,
    setCorrectAnswers,
    setName,
    setPredictionStatus,
    setQuestion,
    setShowCount,
    setType,
  };
  return <PollEditorView {...state} {...onChangeMethods} onExit={onExit} save={save} />;
}

QuickPickEditor.propTypes = {
  editingItemId: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

QuickPickEditor.defaultProps = {
  editingItemId: null,
};

PollEditorView.propTypes = propTypes;
PollEditorView.defaultProps = defaultProps;


/* tslint:disable: jsx-boolean-value */
import React, { useMemo } from 'react';
import fontelloIconStrings from 'components/ui/Icon/fontelloIconStrings';
import hash from 'json-stable-stringify';
import { DropdownProps } from 'components/admin2/ui/Dropdown';
import Renderer from './Renderer';
import {
  Dropdown,
  iconWidth,
} from './styles';
import { UpperSnakeCase } from 'shared/type-utils';
import { snakeCase } from 'lodash';
import { withPrefix } from 'shared/string-utils';

const prefixedIcons = fontelloIconStrings.map(name => withPrefix(name, 'icon-'));

const ICON_MAP = fontelloIconStrings.map((iconString, i) => ({
  label: withPrefix(snakeCase(iconString).toUpperCase() as UpperSnakeCase<typeof iconString>, 'ICON_NAME_'),
  name: iconString,
  prefix: prefixedIcons[i],
}));

const defaultIcon = {
  label: 'No Icon',
  name: 'circle-empty',
  prefix: 'icon-circle-empty',
};

type IconData = typeof defaultIcon;

interface IconDropdownProps extends Omit<DropdownProps<IconData>, 'onChange'> {
  onChange(iconName: string): void;
}

const getIconByName = (name: string) => {
  return ICON_MAP.find(icon => icon.name === name) || defaultIcon;
};

export default function IconDropdown({
  onChange,
  value,
  ...props
}: IconDropdownProps) {
  const defaultValue = useMemo(() => {
    if (!value) {
      return defaultIcon;
    }
    if (typeof value === 'string') {
      return getIconByName(value);
    }
    return value as IconData;
  }, [hash(value)]);

  const handleChange = (icon: IconData) => {
    return onChange(icon.label === defaultIcon.label ? '' : icon.name);
  };

  return (
    <Dropdown
      {...props}
      colWidth={iconWidth}
      labelKey="ADMIN_ICON_PICKER_LABEL"
      onChange={handleChange}
      options={ICON_MAP}
      replaceButton
      replaceOption
      replaceSearchInput
      translated
      value={defaultValue}
      valueKey="name"
    >
      <Renderer />
    </Dropdown>
  );
}

import { connect } from 'react-redux';
import { getSiteId } from 'services/app/selectors';
import { loadingCollections, loadingProducts, removeShop } from 'services/shopify';
import { getShopState } from 'services/shopify/selectors';
import ShopifyStoreConnection from './view';

const mapStateToProps = state => ({
  shopInfo: getShopState(state),
  siteId: getSiteId(state),
});

const mapDispatchToProps = dispatch => ({
  loadingCollections: () => dispatch(loadingCollections()),
  loadingProducts: () => dispatch(loadingProducts()),
  removeShopifyStore: (data) => dispatch(removeShop(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyStoreConnection);

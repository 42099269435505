import React, {
  useState,
  useCallback,
  SyntheticEvent,
  useRef,
  ChangeEvent,
  FC,
  Dispatch,
  SetStateAction,
} from 'react';
import { useApis } from 'contexts/ApisContext';
import { useSelector } from 'hooks';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import {
  Dropzone,
  DropzoneContainer,
  DropzoneSeparator,
  AdvancedOptionsButton,
  VideoUrl,
  UseIframeBox,
  ErrorIcon,
} from './styles';
import { IVideoUploaderZoneProps } from 'components/admin-bridge/VideoLibrary/components/VideoUploaderZone';
import { ActionButton } from 'components/admin-bridge/VideoLibrary/components/VideoLibraryList/styles';
import { PlayerTypes, VideoTypes } from 'models';
import { createObjectId } from 'utils';
import { useVideoLibraryContext } from 'contexts/VideoLibraryContext';
import { EditingItem } from './types';
import { useAdminTranslation } from 'hooks/use-translation';

export const AddVideoSection: FC<{
  setItemsToEdit: Dispatch<SetStateAction<EditingItem>>;
}> = ({
  setItemsToEdit,
}) => {
  const { t } = useAdminTranslation();
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);

  const {
    video: {
      fetchMetadata,
    },
  } = useApis();

  const { addFiles } = useVideoLibraryContext();

  const [isShowingAdvancedOptions, setIsShowingAdvancedOptions] = useState(false);

  const toggleAdvancedOptions = useCallback(
    (ev: SyntheticEvent) => {
      ev.preventDefault();
      setIsShowingAdvancedOptions(
        (flag) => !flag,
      );
    },
    [],
  );

  const [metadataFetchingStatus, setMetadataFetchingStatus] = useState<'idle' | 'error' | 'loading'>(
    'idle',
  );

  const debounceUrlChange = useRef(-1 as any as ReturnType<typeof setTimeout>);

  const handleUrlChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debounceUrlChange.current);
      setMetadataFetchingStatus('loading');

      if (!primaryToken)
        return;

      const value = ev.currentTarget.value.trim();

      if (!value) {
        setMetadataFetchingStatus('idle');
        return;
      }

      debounceUrlChange.current = setTimeout(
        async () => {
          try {
            // we get an error if url is invalid
            const url = new URL(value).toString();

            const videoDraft = await fetchMetadata({
              primaryToken,
              siteId,
              url,
            });

            setItemsToEdit([{
              ...videoDraft,
              newId: createObjectId(),
            }]);

            setMetadataFetchingStatus('idle');
          } catch (err) {
            setMetadataFetchingStatus('error');
            // tslint:disable-next-line:no-console
            console.error('Failure when fetching metadata:', err);
          }
        },
        500,
      );
    },
    [fetchMetadata, primaryToken, siteId],
  );

  const onFilesDropped = useCallback<IVideoUploaderZoneProps['onDrop']>(
    (newFiles) => {
      addFiles(
        newFiles.map(
          (blob) => ({ blob }),
        ),
      );
    },
    [addFiles],
  );

  const createIframeVideo = useCallback(
    (ev: SyntheticEvent) => {
      ev.preventDefault();
      setItemsToEdit([{
        title: t('ADMIN_VIDEO_LIBRARY_EMBEDDED_VIDEO'),
        newId: createObjectId(),
        player: PlayerTypes.iframe,
        type: VideoTypes.vod,
      }]);
    },
    [t],
  );

  return (
    <DropzoneContainer>
      <Dropzone onDrop={onFilesDropped} />
      <DropzoneSeparator>
        {t('ADMIN_VIDEO_LIBRARY_OR_USE_VIDEO_URL')}
      </DropzoneSeparator>
      <AdvancedOptionsButton
        onClick={toggleAdvancedOptions}
      >
        {
          isShowingAdvancedOptions
            ? t('ACCESS_CONTROL_HIDE_ADVANCED_OPTIONS')
            : t('ACCESS_CONTROL_SHOW_ADVANCED_OPTIONS')
        }
      </AdvancedOptionsButton>
      <VideoUrl>
        <input
          type="text"
          onChange={handleUrlChange}
          placeholder={t('ADMIN_VIDEO_LIBRARY_URL_PLACEHOLDER')}
        />
        {metadataFetchingStatus === 'loading' && (
          <b>{t('ADMIN_LABEL_FETCHING_METADATA')}</b>
        )}
        {metadataFetchingStatus === 'error' && (
          <b className="error">
            <ErrorIcon />
            {t('ADMIN_VIDEO_LIBRARY_INCORRECT_URL_FORMAT')}
          </b>
        )}
      </VideoUrl>
      {isShowingAdvancedOptions && (
        <UseIframeBox>
          <div>
            <b>{t('ADMIN_VIDEO_LIBRARY_USE_EMBEDDED_VIDEO')}</b>
            {t('ADMIN_VIDEO_LIBRARY_USE_EMBEDDED_VIDEO_DESC')}
          </div>
          <ActionButton
            onClick={createIframeVideo}
            style={{
              fontSize: 12,
              padding: '8px 21px',
            }}
          >
            {t('ADMIN_VIDEO_LIBRARY_ADD_EMBEDDED_VIDEO')}
          </ActionButton>
        </UseIframeBox>
      )}
    </DropzoneContainer>
  );
};

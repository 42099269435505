import TranslatedText from 'components/i18n/TranslatedText';
import React from 'react';
import { useSelector } from 'react-redux';
import { IProduct } from 'services/shopify/models';
import { getShopCurrencyCode } from 'services/shopify/selectors';
import { displayAccounting } from 'shared/string-utils';
import { Name, Price, ProductImage, SoldOutText, ProductInfo, Wrapper, CheckIcon, PlusIcon } from './styles';

interface IProductProps {
  isDisabled: boolean;
  isSelected: boolean;
  onClick: () => void;
  product: IProduct;
}

const Product: React.FC<IProductProps> = ({ product, onClick, isSelected, isDisabled }) => {
  const shopCurrencyCode = useSelector(getShopCurrencyCode);

  const formattedPrice = React.useMemo(() => {
    const price = product.variants?.edges[0]?.node?.price?.amount ? Number(product.variants?.edges[0]?.node?.price?.amount) : 0;
    const numberFormatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: shopCurrencyCode,
    });
    return displayAccounting(price, numberFormatter);
  }, [product.variants?.edges[0]?.node?.price?.amount, shopCurrencyCode]);

  const renderIcon = React.useCallback(() => {
    if (isDisabled) return;
    return (
      isSelected ? <CheckIcon /> : <PlusIcon />
    );
  }, [isSelected, isDisabled]);

  return (
    <Wrapper data-testid={`shopifyProductModalProduct-${product.id}`}>
      <ProductImage
        data-testid={`shopifyProductModalProduct-${product.id}-image`}
        onClick={isDisabled ? undefined : onClick}
        src={product.images?.edges[0]?.node?.url}
        isSelected={isSelected}
        isDisabled={isDisabled}
      >
        {!product.availableForSale && (
          <TranslatedText
            stringKey="SOLD_OUT"
            component={SoldOutText}
            componentProps={{
              'data-testid': `shopifyProductModalProduct-${product.id}-soldOutText`,
            }}
          />
        )}
      </ProductImage>
      {renderIcon()}
      <ProductInfo>
        <Name data-testid={`shopifyProductModalProduct-${product.id}-name`}>{product.title}</Name>
        <Price data-testid={`shopifyProductModalProduct-${product.id}-price`}>{formattedPrice}</Price>
      </ProductInfo>
    </Wrapper>
  );
};

export default Product;

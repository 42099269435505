import styled, { css } from 'styled-components';
import ThrottledInput from 'components/admin2/ThrottledInput';
import Dropdown from 'components/admin2/ui/Dropdown';
import FancyButton from 'components/admin2/FancyButton';
import Label from 'components/admin2/Label';
import DatetimePicker from 'components/admin2/DatetimePicker';
import TabbedView from 'components/admin2/ui/TabbedView';
import ButtonSelect from 'components/admin2/ui/ButtonSelect';
import LibraryButton from 'components/admin2/RendererEditor/LibraryButton';
import VideoSelect from 'components/admin2/VideoSelect';
import QuickPickLibraryButton from 'components/admin2/RendererEditor/QuickPickLibraryButton';
import {
  SPACING_SMALL,
  SPACING_X_SMALL,
  DISABLED_OPACITY,
  NORMAL_OPACITY,
  ADMIN_TEXT_100,
} from 'style/constants';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

const SPACING = css`
  margin: ${SPACING_SMALL} 0;
`;

const CONDITIONAL_USAGE = css`
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  opacity: ${props => props.disabled ? DISABLED_OPACITY : NORMAL_OPACITY};
`;

export const StyledButtonSelect = styled(ButtonSelect)`
  ${SPACING};
`;

export const Container = styled.div`
  padding: ${SPACING_X_SMALL};
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 5px;
`;

export const StyledFancyButton = styled(FancyButton)`
  ${SPACING};
  ${CONDITIONAL_USAGE};
`;

export const StyledSelect = styled(Dropdown)`
  ${SPACING};
`;

export const StyledThrottledInput = styled(ThrottledInput)`
  ${SPACING};
  ${CONDITIONAL_USAGE};
`;

export const StyledDatetimePicker = styled(DatetimePicker)`
`;

export const StyledTabbedView = styled(TabbedView)`
  ${SPACING};
`;

export const StyledLibraryButton = styled(LibraryButton)`
  ${SPACING};
  ${CONDITIONAL_USAGE};
`;

export const StyledQuickPickLibraryButton = styled(QuickPickLibraryButton)`
  ${SPACING};
  ${CONDITIONAL_USAGE};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.spaceBetween && 'justify-content: space-between;'}
  align-items: center;
  margin-bottom: ${SPACING_SMALL};
`;

export const StyledVideoSelect = styled(VideoSelect)`
  ${SPACING};
  ${CONDITIONAL_USAGE};
`;

export const StyledHelperText = styled.div`
  opacity: 0.5;
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  color: ${ADMIN_TEXT_100}
`;

import React, { useCallback, useMemo } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import LegacyCollectionProvider from 'components/admin2/LegacyCollectionProvider';
import { useAdminTranslation } from 'hooks/use-translation';
import { COMPONENT_TYPES } from '../constants';
import {
  StyledLabel,
  StyledSelect,
  StyledThrottledInput,
  StyledDatetimePicker,
  StyledLibraryButton,
  StyledVideoSelect,
  StyledHelperText,
} from './styles';

const DropDown = (props) => {
  const {
    options,
    onChange,
    labelKey,
    value: selection,
  } = props;
  // Find the selected value in the array list
  // we need this because the uncontrolled value coming
  // in only has the value of the selection and not
  // its index

  const selectedOption = useMemo(() => {
    return options.find(option => option._id === selection._id);
  }, [selection._id, options.length]);

  return (
    <StyledSelect
      dropUp
      isSearchable
      labelKey={labelKey}
      onChange={selectedItem => onChange(selectedItem)}
      options={options}
      value={selectedOption}
      valueKey="_id"
      {...props}
    />
  );
};

const MULTI_CHOICE_PLACEHOLDER_KEY_MAP = {
  poll: 'ADMIN_POLL_BUTTON_PLACEHOLDER',
  prediction: 'ADMIN_PREDICTION_BUTTON_PLACEHOLDER',
  trivia: 'ADMIN_TRIVIA_BUTTON_PLACEHOLDER',
};

const ACTION_COMPONENTS = {
  [COMPONENT_TYPES.TEXT_INPUT]: (props) => {
    const { valueKey, actionData, onChange, placeholderKey, type, ...rProps } = props;
    const updateValue = useCallback(
      val => onChange({ [valueKey]: val }),
      [onChange],
    );

    return (
      <StyledThrottledInput
        {...rProps}
        onChange={updateValue}
        placeholderKey={placeholderKey || 'ADMIN_LABEL_QUEST_TEXT_PLACEHOLDER'}
        type={type}
        value={actionData[valueKey] || ''}
      />
    );
  },
  [COMPONENT_TYPES.DATE_TIME_PICKER]: (props) => {
    const { valueKey, actionData, onChange } = props;
    return (
      <StyledDatetimePicker
        {...props}
        onTimeChange={(newTimestamp) => {
          onChange({ [valueKey]: newTimestamp ? new Date(newTimestamp).getTime() : null });
        }}
        showTimeZone={false}
        timestamp={actionData[valueKey]}
      />
    );
  },
  [COMPONENT_TYPES.HELPER_TEXT]: (props) => {
    const { stringKey } = props;
    return <TranslatedText component={StyledHelperText} stringKey={stringKey} />;
  },
  [COMPONENT_TYPES.LABEL]: (props) => {
    const { stringKey } = props;
    return (<TranslatedText component={StyledLabel} stringKey={stringKey} />);
  },
  [COMPONENT_TYPES.PANEL_SELECT]: (props) => {
    const { t } = useAdminTranslation();
    const { onChange, actionData, valueKey, labelKey, componentKey } = props;

    const parsePanelType = (option) => {
      if (option.renderer?.panelType) {
        const wordList = option.renderer.panelType.split('_');
        const panelName = wordList.map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
        return `Untitled ${panelName} Panel`;
      }
      return null;
    };

    return (
      <LegacyCollectionProvider
        collection="panels"
        limit={1000}
      >
        {
          (providerResults, count, error) => {
            if (error) {
              return null;
            }

            const results = providerResults || [];
            const panelId = actionData[valueKey] || {};
            return (
              <DropDown
                componentKey={componentKey}
                getOptionLabel={(option) => {
                  return option?.renderer?.panelName ||
                  parsePanelType(option) ||
                  option.slug;
                }}
                getOptionValue={(option) => option?._id}
                labelKey={labelKey}
                onChange={(arg) => onChange({ [valueKey]: arg._id })}
                options={results}
                placeholder={t('ADMIN_QUEST_EDITOR_SELECT_PANEL')}
                value={{ _id: panelId }}
              />
            );
          }
      }
      </LegacyCollectionProvider>
    );
  },
  [COMPONENT_TYPES.CHANNEL_SELECT]: (props) => {
    const { t } = useAdminTranslation();
    const { onChange, actionData, allowAnyChannel = true, valueKey } = props;
    const channelId = actionData[valueKey];
    return (
      <LegacyCollectionProvider
        collection="pages"
        limit={1000}
      >
        {
          (providerResults, count, error) => {
            if (error) {
              return null;
            }
            const results = providerResults || [];
            const channels = results.filter(page => page.type === 'channel');
            if (allowAnyChannel) {
              channels.unshift({
                data: {
                  name: t('ADMIN_QUEST_EDITOR_ANY_CHANNEL'),
                },
                label: t('ADMIN_QUEST_EDITOR_ANY_CHANNEL'),
              });
            }

            return (
              <>
                <TranslatedText
                  component={StyledLabel}
                  stringKey="ADMIN_LABEL_CHANNEL_SPECIFIC"
                />
                <TranslatedText
                  component={StyledHelperText}
                  stringKey="ADMIN_LABEL_CHANNEL_SPECIFIC_HELPER"
                />
                <DropDown
                  allowAnyChannel={allowAnyChannel}
                  getOptionLabel={option => option.data?.name || ''}
                  getOptionValue={option => option?._id}
                  menuPlacement="top"
                  onChange={selection => onChange({ [valueKey]: selection?._id || null })}
                  options={channels}
                  placeholder={t('ADMIN_QUEST_EDITOR_SELECT_CHANNEL')}
                  value={{ _id: channelId }}
                />
              </>
            );
          }
        }
      </LegacyCollectionProvider>
    );
  },
  [COMPONENT_TYPES.MULTI_CHOICE_SELECT]: (props) => {
    const { onChange, actionData, typeKey } = props;

    return (
      <StyledLibraryButton
        onChange={_id => onChange({ validationValue: _id })}
        options={{
          fancyButtonProps: {
            buttonPlaceholderKey: MULTI_CHOICE_PLACEHOLDER_KEY_MAP[typeKey],
            docToButtonProps: doc => ({
              showImage: false,
              text: doc?.name,
            }),
          },
          type: typeKey,
          valueField: '_id',
        }}
        value={actionData.validationValue}
      />
    );
  },
  [COMPONENT_TYPES.LINK_TEXT]: (props) => {
    const { onChange, actionData } = props;
    const updateValue = useCallback(
      val => onChange({ callToAction: val }),
      [onChange],
    );

    return (
      <StyledThrottledInput
        labelKey="ADMIN_LABEL_QUEST_LINK_TEXT"
        onChange={updateValue}
        placeholderKey="ADMIN_QUEST_ACTION_INPUT_PLACEHOLDER_LINK_TEXT"
        value={actionData.callToAction || ''}
      />
    );
  },
  [COMPONENT_TYPES.VIDEO_BUTTON]: (props) => {
    const { onChange, actionData } = props;

    const docToButtonProps = (doc) => {
      const { data, renderer } = doc || {};
      const { name: rendererName, thumbnail: rendererThumbnail } = renderer || {};
      return {
        imageSrc: rendererThumbnail || data?.metadata?.snippet?.thumbnails?.high?.url,
        showImage: true,
        text: rendererName || data?.name,
      };
    };
    return (
      <StyledVideoSelect
        buttonPlaceholderKey="ADMIN_LABEL_QUEST_VIDEO_SELECT_PLACEHOLDER"
        docToButtonProps={docToButtonProps}
        onChange={video => onChange({
          validationValue: video ? video._id : undefined,
        })}
        value={actionData.validationValue}
      />
    );
  },
};

export default ACTION_COMPONENTS;

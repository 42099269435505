import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaestroStudioHost from 'components/MaestroStudio/Host';
import { isStudioStreamMode, ModeTypes, setAdminMode } from 'services/admin';
import { isMobileLayout } from 'services/device';
import { setNavigationBlocked, clearPendingNavigationActions } from 'services/navigation';

/**
 * This prevents studio from being unloaded when an admin resizes the
 * window past the `mobile` breakpoint and also allows an admin to open
 * panels, channel settings, and other configs without leaving Studio.
 * This is a temporary solution until we're able to tackle Mobile Admin/
 * Navigation overhaul and avoids adding more logic to the already
 * convoluted Redux-based nav system
 */
export const StudioGuardian: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const isMobile = useSelector(isMobileLayout);
  const isStudioPageActive = useSelector(isStudioStreamMode);

  const [shouldDisplayStudio, setShouldDisplayStudio] = useState(false);

  // prevent studio from being killed when shrinking down the browser
  const forceRender = useRef(false);

  const deferred = useRef<NodeJS.Timeout>();

  useEffect(
    () => {
      // since we're indirectly reacting to window resize events,
      // state updates need be debounced due to the high frequency
      // of global state changes caused by side effects out of our
      // control
      const deferUpdates = (
        callback: () => void,
      ) => {
        // this is different from wrapping the whole
        // effect in a setTimeout since we only want
        // to cancel pending updates when firing a new
        // change, not whenever the effect runs
        if (deferred.current) {
          clearTimeout(deferred.current);
        }

        deferred.current = setTimeout(callback, 100);
      };

      if (isMobile && shouldDisplayStudio) {
        // when the screen is resized, `isMobile` flips to `true`
        // and we lock studio in place, since the app will try
        // to kill all admin views

        deferUpdates(
          () => {
            dispatch(setNavigationBlocked(false));
            forceRender.current = true;
          },
        );
      } else if (!isStudioPageActive && !isMobile && forceRender.current) {
        // when screen is resized back to desktop size, we remove the lock

        deferUpdates(
          () => {
            dispatch(setAdminMode(ModeTypes.STUDIO_STREAM));
            dispatch(clearPendingNavigationActions());
            dispatch(setNavigationBlocked(true));
            forceRender.current = false;
          },
        );
      } else if (!isStudioPageActive && shouldDisplayStudio && !isMobile && !forceRender.current) {
        // normal behavior: unmount studio when exiting stream mode

        deferUpdates(
          () => {
            setShouldDisplayStudio(false);
          },
        );
      } else if (isStudioPageActive && !shouldDisplayStudio) {
        // normal behavior: don't lock studio in place
        // when admin mode === STUDIO_STREAM

        deferUpdates(
          () => {
            forceRender.current = false;
            setShouldDisplayStudio(true);
          },
        );
      }
    },
    [isStudioPageActive, shouldDisplayStudio, isMobile],
  );

  if (shouldDisplayStudio) {
    return <MaestroStudioHost />;
  }

  return (
    <>{children}</>
  );
};

import React, { PropsWithChildren, useMemo } from 'react';
import hash from 'json-stable-stringify';
import withLabel from 'components/core/withLabel';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import Tab from './Tab';
import {
  Body,
  ButtonSelectContainer,
  ButtonSelectStyleProps,
  TabWrapper,
} from './styles';

interface ButtonSelectProps extends ButtonSelectStyleProps {
  className?: string;
  options?: Option[];
  value?: string | boolean;
  onChange?(data: any): void;
}

interface Option {
  disabled?: boolean;
  icon?: string;
  label?: string;
  testId?: string;
  value?: string | boolean;
}

function ButtonSelect({
  children,
  className,
  onChange,
  options = [],
  value,
  ...props
}: PropsWithChildren<ButtonSelectProps>) {
  const selectedIndex = useMemo(() => {
    return options.findIndex(({ value: itemValue }) => itemValue === value) + 1;
  }, [hash(options), value]);

  return (
    <ButtonSelectContainer className={className} selectedIndex={selectedIndex} {...props}>
      <TabWrapper {...props} selectedIndex={selectedIndex}>
        <NonKeyedListMapper list={options}>
          {(key: string, { disabled, icon, label, testId, value: itemValue }: Option) => {
            return (
              <Tab
                key={key}
                data-testid={testId}
                disabled={disabled}
                icon={icon}
                itemValue={itemValue}
                labelKey={label}
                onChange={onChange}
                selected={value === itemValue}
                {...props}
              />
            );
          }}
        </NonKeyedListMapper>
      </TabWrapper>
      {children && <Body {...props}>{children}</Body>}
    </ButtonSelectContainer>
  );
}

export default withLabel(ButtonSelect);

/* tslint:disable: jsx-boolean-value */
import React, { useMemo, useState } from 'react';
import useSorting from 'hooks/use-sorting';
import hash from 'json-stable-stringify';
import Row, { RowItem, ClickHandlers } from './Row';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import TranslatedText from 'components/i18n/TranslatedText';
import SearchInput from 'components/admin2/ui/SearchInput';
import {
  Container,
  Table,
  Head,
  NameHeader,
  EditHeader,
  Body,
  SortArrowIcon,
  SearchContainer,
  SearchHelperText,
} from './styles';
import type { TranslationKey } from 'hooks/use-translation';

export type { ClickHandler } from './Row';

type ItemPropGetter<T, R = string> = (item: LibraryItem<T>) => R;

interface LibraryProps<T> extends ClickHandlers<T>, ItemPropGetters<T>, SearchTextProps {
  actionsWidth?: number;
  items: LibraryItem<T>[];
  testIdAdd?: string;
  testIdBroadcast?: string;
  testIdDelete?: string;
  testIdEdit?: string;
  testIdItem?: string;
  testIdItemTitle?: string;
  testIdItemType?: string;
  testIdPreview?: string;
  testIdRemove?: string;
  testIdSearchInput?: string;
  verticalActions?: boolean;
}

export interface ItemPropGetters<T> {
  getItemIcon: ItemPropGetter<T>;
  getItemLastModified: ItemPropGetter<T, number | Date>;
  getItemName: ItemPropGetter<T>;
  getItemTypeKey: ItemPropGetter<T>;
}

export interface SearchTextProps {
  disableInternalSearch?: boolean;
  onSearch?: (value: string) => void;
  searchHelperKey?: TranslationKey;
  searchPlaceholderKey?: TranslationKey;
}

export interface LibraryItem<T> {
  renderer: T;
}

const sortOptions = {
  updated: { sortKey: 'lastModified', reversedSort: true },
  title: { sortKey: 'name' },
};

export default function Library<T>({
  actionsWidth,
  disableInternalSearch,
  items,
  getItemIcon,
  getItemLastModified,
  getItemName,
  getItemTypeKey,
  onAdd,
  onBroadcast,
  onDelete,
  onSelect,
  onEdit,
  onSearch,
  searchHelperKey,
  searchPlaceholderKey,
  testIdAdd,
  testIdBroadcast,
  testIdDelete,
  testIdEdit,
  testIdItem,
  testIdItemTitle,
  testIdPreview,
  testIdRemove,
  testIdSearchInput,
  testIdItemType,
  verticalActions,
}: LibraryProps<T>) {
  const [searchText, setSearchText] = useState('');

  const validatedItems = useMemo(() => {
    return items.map<RowItem<T>>(item => ({
      icon: getItemIcon(item),
      lastModified: getItemLastModified(item),
      name: getItemName(item),
      renderer: item.renderer,
      typeKey: getItemTypeKey(item) as TranslationKey,
    }));
  }, [hash(items)]);

  const [sortedItems, sortKey, isDescendingSort, sortByDate, sortByTitle] = useSorting(validatedItems, 'updated', sortOptions);

  const filteredItems = useMemo(() => {
    if (!searchText) {
      return sortedItems;
    }
    const pattern = new RegExp(searchText, 'i');
    return sortedItems.filter(({ name }) => name?.match(pattern));
  }, [hash(sortedItems), searchText]);

  const handleSearch = (value: string) => {
    onSearch?.(value);
    if (!disableInternalSearch) {
      setSearchText(value);
    }
  };

  return (
    <Container>
      <SearchContainer>
        {searchHelperKey && (
          <TranslatedText component={SearchHelperText} stringKey={searchHelperKey} />
        )}
        <SearchInput
          testIdSearchInput={testIdSearchInput}
          onSearch={handleSearch}
          searchPlaceholderKey={searchPlaceholderKey}
          short
        />
      </SearchContainer>
      <Table>
        <Head>
          <NameHeader onClick={sortByTitle}>
            <TranslatedText stringKey="ADMIN_LABEL_NAME" />
            {sortKey === 'title' && <SortArrowIcon descending={isDescendingSort} />}
          </NameHeader>
          <EditHeader minWidth={actionsWidth} onClick={sortByDate}>
            <TranslatedText stringKey="ADMIN_LABEL_UPDATED" />
            {sortKey === 'updated' && <SortArrowIcon descending={isDescendingSort} />}
          </EditHeader>
        </Head>
        <Body>
          <NonKeyedListMapper list={filteredItems}>
            {(key: string, item: RowItem<T>, index: number) => {
              return (
                <Row
                  data-testid={`${testIdItem}-${index}`}
                  key={key}
                  actionsWidth={actionsWidth}
                  onAdd={onAdd}
                  onBroadcast={onBroadcast}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onSelect={onSelect}
                  testIdAdd={`${testIdAdd}-${index}`}
                  testIdBroadcast={`${testIdBroadcast}-${index}`}
                  testIdDelete={`${testIdDelete}-${index}`}
                  testIdEdit={`${testIdEdit}-${index}`}
                  testIdPreview={`${testIdPreview}-${index}`}
                  testIdRemove={`${testIdRemove}-${index}`}
                  typeNameTestId={testIdItemType}
                  nameTestId={`${testIdItemTitle}-${index}`}
                  verticalPositionActions={verticalActions}
                  {...item}
                />
              );
            }}
          </NonKeyedListMapper>
        </Body>
      </Table>
    </Container>
  );
}

import styled from 'styled-components';
import {
  SPACING_MEDIUM,
  ADMIN_SURFACE_1,
  ADMIN_TEXT_100,
} from 'style/constants';
import Icon from 'components/ui/Icon';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import Dropdown from 'components/admin2/ui/Dropdown';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';

export const StyledSelect = styled(Dropdown)`
  display: flex;
  margin-bottom: ${SPACING_MEDIUM};
`;

export const ChannelListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 200px;
  color: ${ADMIN_TEXT_100};
`;
export const ChannelList = styled.ul`
  ${ADMIN_SCROLL_BAR}
  overflow: auto;
  padding: 0;
  flex:1;
`;

export const Divider = styled.div`

`;

export const StyledIcon = styled(Icon)`
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  margin-right: 10px;
`;

export const RowContainer = styled.li`
  padding: 16px;
  background-color: ${ADMIN_SURFACE_1};
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 2px;
`;

export const ChannelTitle = styled.span`
  ${ADMIN_TEXT_LABEL_L_MEDIUM}
`;

export const Description = styled.span`
  color: ${ADMIN_TEXT_100};
`;

export const ManualSection = styled.div`
  margin-top: ${SPACING_MEDIUM};
`;

export const StyledLabelToggle = styled(LabelToggle).attrs({
  compact: true,
})`
  width: 100%;
`;

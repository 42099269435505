import React, { useRef, useEffect, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import KeyedListMapper from 'components/core/KeyedListMapper';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import range from 'lodash/range';
import {
  MEDIA_ASSETS_BASE_URL,
} from 'config';
import TranslatedText from 'components/i18n/TranslatedText';
import AdminSpinner from 'components/admin2/AdminSpinner';
import {
  Container,
  CardGroup,
  ImageCard,
  Image,
  ImageCover,
  ImageName,
  UploadTime,
  StyledActionContainer,
  ImageItem,
  ImageInfo,
  EmptyItem,
  IMAGE_ITEM_SPACE,
} from './styles';

const ImageGrid = (props) => {
  const {
    loadNextPage,
    images,
    isLoading,
    onDelete,
    onSelect,
    siteId,
    totalImageCount,
  } = props;
  const scrollContainer = useRef(null);
  const imageCollectionRef = useRef(null);

  const [fillerItems, setFillerItems] = useState(0);

  const shouldLoadMoreItems = () => {
    const { bottom: containerBottom } = scrollContainer.current.getBoundingClientRect();
    const { bottom: collectionBottom } = imageCollectionRef.current.getBoundingClientRect();
    const isWithinThreshold = collectionBottom <= containerBottom;
    const hasMoreItems = totalImageCount - images.length > 0;
    return !isLoading && images.length > 0 && isWithinThreshold && hasMoreItems;
  };

  const updateLayout = useCallback(() => {
    const node = imageCollectionRef.current;
    if (!node) {
      return;
    }
    const { width } = node.getBoundingClientRect();
    const columns = Math.floor(width / IMAGE_ITEM_SPACE) || 1;
    const rest = images.length % columns;
    if (!rest) {
      return;
    }
    setFillerItems(columns - rest);
  }, [images.length, imageCollectionRef.current]);

  const updateLayoutAfterAnimation = useCallback(debounce(
    updateLayout,
    250,
  ), [updateLayout]);

  const getImageCollectionRef = (node) => {
    if (!node) {
      return;
    }
    imageCollectionRef.current = node;
    updateLayoutAfterAnimation();
  };

  const handleScroll = throttle(
    () => {
      if (shouldLoadMoreItems()) {
        loadNextPage();
      }
    }, 250,
  );

  useEffect(() => {
    scrollContainer.current.addEventListener('scroll', handleScroll, false);
    window.addEventListener('resize', updateLayout);
    return () => {
      scrollContainer.current.removeEventListener('scroll', handleScroll, false);
      window.removeEventListener('resize', updateLayout);
    };
  });

  return (
    <Container ref={scrollContainer}>
      <CardGroup ref={getImageCollectionRef}>
        <KeyedListMapper keyField="_id" list={images}>
          { (key, image, index) => {
            const { name, created, temporaryImageUrl, ...imageData } = image;
            return (
              <ImageItem key={key} data-testid={`imageLibraryItem-${index}`}>
                <ImageCard>
                  <Image
                    src={temporaryImageUrl ||
                      `${MEDIA_ASSETS_BASE_URL}/${siteId}/${imageData._id}.${imageData.ext}`}
                  />
                  <ImageCover
                    isLoading={Boolean(temporaryImageUrl)}
                  >
                    {
                    temporaryImageUrl ?
                      <AdminSpinner /> : (
                        <StyledActionContainer
                          onAdd={() => onSelect(image)}
                          onDelete={() => onDelete(image._id)}
                          testIdAdd={`imageLibraryUseItem-${index}`}
                          testIdDelete={`imageLibraryDeleteItem-${index}`}
                        />
                      )
                  }
                  </ImageCover>
                </ImageCard>
                <ImageInfo>
                  <ImageName>
                    {temporaryImageUrl ? (
                      <TranslatedText stringKey="ADMIN_IMAGE_LIBRARY_LOADING" />
                    ) : name}
                  </ImageName>
                  <UploadTime>
                    {created && moment(created).from(Date.now())}
                  </UploadTime>
                </ImageInfo>
              </ImageItem>
            );
          }}
        </KeyedListMapper>
        <NonKeyedListMapper list={range(0, fillerItems)}>
          {/* eslint-disable-next-line no-unused-vars */}
          {(key, _) => (
            <EmptyItem key={key} />
          )}
        </NonKeyedListMapper>
      </CardGroup>
    </Container>
  );
};

ImageGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  totalImageCount: PropTypes.number.isRequired,
};

export default ImageGrid;

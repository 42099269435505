import styled from 'styled-components';
import TextInput from 'components/admin2/TextInput';
import TextArea from 'components/admin2/TextArea';
import UploadZone from 'components/admin2/UploadZone';
import IconDropdown from 'components/admin2/ui/IconDropdown';
import {
  SPACING_SMALL,
  SPACING_MEDIUM,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_1,
} from 'style/constants';
import { DIV_BUTTON } from 'style/mixins';
import { ADMIN_TEXT_LABEL_L_BOLD } from 'style/design-system/textStyles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-color: ${ADMIN_SURFACE_3};
`;

export const EditorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING_MEDIUM};
  ${ADMIN_TEXT_LABEL_L_BOLD}
  text-transform: uppercase;
  background-color: ${ADMIN_SURFACE_1};
`;

export const PersonName = styled.div`padding: 10px;`;

export const Body = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: ${SPACING_MEDIUM};
`;

export const BackButton = styled.div`
  cursor: pointer;
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
`;

export const SaveButton = styled.div`
  ${ADMIN_TEXT_LABEL_L_BOLD}
  text-transform: uppercase;
  ${DIV_BUTTON};
  &:not(:first-child) {
    margin-left: ${SPACING_MEDIUM};
  }
`;

export const InputContainer = styled.div`
  flex: 1;
`;

export const Halves = styled.div`
  display: flex;
  margin-bottom: ${SPACING_SMALL};
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${SPACING_SMALL};

`;

export const Gutter = styled.div`
  width: 16px;
`;

export const StyledTextArea = styled(TextArea)`
  height: 164px;
`;

export const StyledUploadZone = styled(UploadZone).attrs({
  height: 139,
})``;

export const ListLink = styled.div`
  padding: ${SPACING_SMALL};
`;

export const LinkFlex = styled.div`
  display: flex;
`;

export const ListLinkTextInput = styled(TextInput)`
  flex: 1;
`;

export const StyledIconPicker = styled(IconDropdown)``;

// tslint:disable:jsx-boolean-value
import React from 'react';
import AdminModal from 'components/admin2/ui/AdminModal';
import type { IVideo } from 'models';
import { VideoMetadataModalV2 } from 'components/modals/VideoMetadataModal';

interface ActiveVideoMetadataModalProps {
  onClose: () => void;
  video: IVideo;
}

export default function ActiveVideoMetadataModal({
  onClose,
  video,
}: ActiveVideoMetadataModalProps) {
  return (
    <AdminModal allowOverflow fixedDimensions onClose={onClose} overlapAdminbar>
      <VideoMetadataModalV2 data={{ videos: [video] }} onDismiss={onClose} />
    </AdminModal>
  );
}

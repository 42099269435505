/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import AjaxFetch from 'components/core/AjaxFetch';
import TranslatedText from 'components/i18n/TranslatedText';
import { ADMIN_SERVICE_BASE_URL } from 'config';
import SearchInput from 'components/admin2/ui/SearchInput';
import { useSelector } from 'react-redux';
import {
  getAdminBarWidth,
} from 'services/admin/selectors';
import store from './store';
import ImageGrid from './ImageGrid';
import {
  Container,
  AddButton,
  AddIcon,
  Header,
  UpperFlex,
  Title,
  ButtonWrapper,
  SearchContainer,
  NoResults,
  UploadInput,
  StyledAdminModal,
  Footer,
  UpperRowContainer,
  ErrorContainer,
} from './styles';
import { ErrorIcon, ErrorText } from '../UploadZone/styles';
import OptionalTranslated from '../ui/OptionalTranslated';

const ImageLibraryModal = (props) => {
  const {
    onClose,
    primaryToken,
    refreshKey,
    siteId,
  } = props;
  const [state, actions] = store(props);
  const {
    handleAdd,
    handleDelete,
    handleAjaxResponse,
    handleAjaxRequest,
    handleSearch,
    handleSelectImage,
  } = actions;
  const { images, loading, totalImageCount, params, errorMessageStringKey } = state;
  const uploader = React.createRef();

  const isAdding = Boolean(images[0] && images[0].temporaryImageUrl);
  const canAdd = !loading || !isAdding;
  const adminbarWidth = useSelector(getAdminBarWidth);

  const handleUpload = (e) => {
    const image = e.target.files;
    if (image && image.length > 0) {
      handleAdd(image);
    }
  };

  const renderContent = () => {
    const { loadingNewImage } = state;
    const { getNextPage } = actions;
    if (!loading && images.length === 0) {
      return (
        <TranslatedText component={NoResults} isLoading={loading} stringKey="ADMIN_IMAGE_LIBRARY_UPLOAD_TITLE" />
      );
    }

    return (
      <ImageGrid
        images={images}
        isAddingImage={loadingNewImage}
        isLoading={loading}
        loadNextPage={getNextPage}
        onDelete={handleDelete}
        onSelect={handleSelectImage}
        siteId={siteId}
        totalImageCount={totalImageCount}
      />
    );
  };

  return (
    <StyledAdminModal
      adminbarWidth={adminbarWidth}
      id="image-library-modal"
      onClose={onClose}
      overlapAdminbar
    >
      <AjaxFetch
        headers={{
          Authorization: `Bearer ${primaryToken}`,
        }}
        method="get"
        onFetch={handleAjaxRequest}
        onValue={handleAjaxResponse}
        params={params}
        refreshKey={refreshKey}
        url={`${ADMIN_SERVICE_BASE_URL}/legacy/media`}
      />
      <Container>
        <Header>
          <UpperFlex>
            <TranslatedText
              component={Title}
              stringKey="ADMIN_IMAGE_LIBRARY_TITLE"
            />
            <UpperRowContainer>
              {errorMessageStringKey && (
              <ErrorContainer>
                <ErrorIcon />
                <OptionalTranslated component={ErrorText} stringKey={errorMessageStringKey} />
              </ErrorContainer>
              )}
              <ButtonWrapper>
                <AddButton
                  disabled={!canAdd}
                  onClick={() => uploader && uploader.current.click()}
                >
                  <AddIcon />
                  <TranslatedText
                    stringKey="ADMIN_IMAGE_LIBRARY_BUTTON_ADD"
                  />
                </AddButton>
                <UploadInput ref={uploader} onChange={handleUpload} />
              </ButtonWrapper>
            </UpperRowContainer>
          </UpperFlex>
          {/*
          TODO: put back in when drag and drop issue w iframe fixed
          <HelperText>
            <TranslatedText stringKey="ADMIN_IMAGE_LIBRARY_DESCRIPTION" />
          </HelperText> */}
        </Header>
        <SearchContainer>
          <SearchInput
            onSearch={handleSearch}
            searchPlaceholderKey="ADMIN_IMAGE_LIBRARY_SEARCH"
            testIdSearchInput="searchImageLibraryInput"
          />
        </SearchContainer>
        {
          renderContent()
        }
        <Footer />
      </Container>
    </StyledAdminModal>
  );
};

ImageLibraryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onSelectAsUrl: PropTypes.func,
  primaryToken: PropTypes.string.isRequired,
  refreshKey: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
};

ImageLibraryModal.defaultProps = {

};

export default ImageLibraryModal;

import clone from 'lodash/clone';
import lodashGet from 'lodash/get';
import setWith from 'lodash/setWith';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';

export const get = (target, path) => {
  return lodashGet(target, path);
};

export const withChange = (target, path, value) => {
  // remove the key if value is null or undefined
  if (isNil(value)) {
    return omit(clone(target), path);
  }
  // set the key to value
  return setWith(clone(target), path, value, clone);
};

export const normalizeTwitterHandle = (twitterHandle = '') => {
  if (twitterHandle.charAt(0) === '@') {
    return twitterHandle;
  }

  return `@${twitterHandle}`;
};

export const sanitizeTwitterSource = (sourceText = '') => {
  if (!sourceText?.length) { return ''; }
  if (typeof sourceText.charAt !== 'function') { return ''; }

  if (sourceText.charAt(0) === '#') { return sourceText; }

  return normalizeTwitterHandle(sourceText);
};

// formatting helpers input text, handled by label name
export const inputHelpers = {
  ADMIN_LABEL_TWITTER: sanitizeTwitterSource,
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SPACING_X_SMALL_PX } from 'style/constants';
import warning from 'warning';

import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import KeyedListMapper from 'components/core/KeyedListMapper';

const StyledDiv = styled.div`
  ${(props) => props.css};
  ${(props) => props.draggableCss};
`;

const InnerDiv = styled.div`
  ${({ innerDivCss }) => innerDivCss}
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  // TODO: This swap syntax should work? Test!
  // [result[startIndex], result[endIndex]] = [result[endIndex], result[startIndex]];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/*
  Legacy Dragger component. you should use the component that uses react-nestable
*/

export default class Dragger extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    draggableCss: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    draggableStyles: PropTypes.shape({}),
    droppableStyles: PropTypes.shape({}),
    innerDivCss: PropTypes.string,
    innerDivStyles: PropTypes.shape({}),
    isDragDisabled: PropTypes.bool,
    keyField: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    list: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    noPadding: PropTypes.bool,
    onDragEnd: PropTypes.func.isRequired,
    renderPlaceholder: PropTypes.func,
    stretch: PropTypes.bool,
  };

  static defaultProps = {
    direction: 'horizontal',
    draggableCss: '',
    draggableStyles: {},
    droppableStyles: {},
    innerDivCss: '',
    innerDivStyles: {},
    isDragDisabled: false,
    keyField: 'arrayId',
    noPadding: false,
    renderPlaceholder: () => {},
    stretch: false,
  };

  handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { list: previousItems } = this.props;
    const items = reorder(
      previousItems,
      result.source.index,
      result.destination.index,
    );
    const { onDragEnd } = this.props;
    warning(
      onDragEnd,
      'Dragger missing onDragEnd',
    );
    onDragEnd?.(items);
  };

  render() {
    // TODO make sure children is a func with invariant maybe
    const {
      draggableCss,
      draggableStyles,
      droppableStyles,
      direction,
      innerDivCss,
      renderPlaceholder,
      isDragDisabled,
      children,
      list: items,
      innerDivStyles,
      noPadding,
      droppableProps, // eslint-disable-line react/prop-types
      stretch,
      keyField,
    } = this.props;

    const flexDirection = direction === 'horizontal' ? 'row' : 'column';
    const alignItems = stretch ? 'stretch' : undefined;
    const ListMapper = keyField ? KeyedListMapper : NonKeyedListMapper;

    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <Droppable direction={direction} droppableId="droppable">
          { provided => (
            <div
              {...droppableProps}
              // NOTE: react-beautiful-dnd uses innerRef for passing refs
              ref={provided.innerRef}
              style={{
                overflow: 'auto hidden',
                padding: noPadding ? '0' : `${SPACING_X_SMALL_PX}px 0`,
                scrollbarWidth: 'thin',
                ...droppableStyles,
              }}
              {...provided.droppableProps}
            >
              <InnerDiv
                innerDivCss={innerDivCss}
                style={{ alignItems, display: 'flex', flexDirection, ...innerDivStyles }}
              >
                <ListMapper keyField={keyField} list={items}>
                  {
                    (key, item, index) => (
                      <Draggable
                        key={key}
                        draggableId={key}
                        index={index}
                        isDragDisabled={item.placeholder || isDragDisabled}
                      >
                        { _provided => (
                          <StyledDiv
                            // NOTE: react-beautiful-dnd uses innerRef for passing refs
                            ref={_provided.innerRef}
                            {..._provided.draggableProps}
                            {..._provided.dragHandleProps}
                            draggableCss={draggableCss}
                            style={{
                              ..._provided.draggableProps.style,
                              ...draggableStyles,
                            }}
                          >
                            { children(item, index) }
                          </StyledDiv>
                        )}
                      </Draggable>
                    )
                  }
                </ListMapper>
                { renderPlaceholder() }
                { provided.placeholder }
              </InnerDiv>
            </div>
          ) }
        </Droppable>
      </DragDropContext>
    );
  }
}

import { connect } from 'react-redux';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import { getRefreshKey } from 'services/admin';
import LibraryModalQuickPick from './LibraryModalQuickPick';

const mapStateToProps = state => ({
  primaryToken: getPrimaryToken(state),
  refreshKey: getRefreshKey(state),
  siteId: getSiteId(state),
});

export default connect(mapStateToProps)(LibraryModalQuickPick);

export const RENDERER_KEYS = {
  BEHAVIOR: 'behavior',
  ICON: 'iconName',
  KIND: 'kind',
  NAME: 'panelName',
  PAGES: 'pages',
  VIEW: 'view',
};

export const KINDS = {
  AUTOMATIC: 'automatic',
  MANUAL: 'manual',
};

export const BEHAVIOR_TABS = {
  [KINDS.AUTOMATIC]: {
    stringKey: 'ADMIN_PANEL_CHANNEL_SELECT_SECTION_BUTTON_AUTOMATIC',
    value: KINDS.AUTOMATIC,
  },
  [KINDS.MANUAL]: {
    stringKey: 'ADMIN_PANEL_CHANNEL_SELECT_SECTION_BUTTON_MANUAL',
    value: KINDS.MANUAL,
  },
};

/* eslint-disable react/prop-types */
// TODO: Fix prop types
import React from 'react';
import PropTypes from 'prop-types';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import templates from './templates';
import ACTION_COMPONENTS from './components';
import { Container } from './styles';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actionData: PropTypes.object.isRequired,
  kind: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ActionComponent = (props) => {
  const { kind, actionData, onChange, panels } = props;
  const template = templates[kind];
  if (!template) {
    return null;
  }
  return (
    <Container>
      <NonKeyedListMapper list={template || []}>
        {(listKey, data) => {
          const { componentKey } = data;
          const Component = ACTION_COMPONENTS[componentKey];
          return (
            <Component
              {...data}
              key={listKey}
              actionData={actionData}
              onChange={onChange}
              panels={panels}
            />
          );
        }}
      </NonKeyedListMapper>
    </Container>
  );
};

ActionComponent.propTypes = propTypes;

export default ActionComponent;

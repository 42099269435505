import styled, { css } from 'styled-components';
import {
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_6,
  ADMIN_TEXT_200,
} from 'style/constants';
import {
  BACKGROUND_IMAGE,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

const PRODUCT_IMAGE_SIZE = 94;
const CROSS_SIZE = Math.floor(Math.hypot(PRODUCT_IMAGE_SIZE, PRODUCT_IMAGE_SIZE)) - 6;
const LEFT_MARGIN = -((CROSS_SIZE - PRODUCT_IMAGE_SIZE) / 2);

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  background: ${ADMIN_SURFACE_5};
  color: ${ADMIN_TEXT_200};
  border-radius: 3.5px;
  img {
    width: 50%;
    height: 80%;
    margin-right: 0.9em;
  }
`;

export const Image = styled.div`
  ${BACKGROUND_IMAGE}
  width: ${PRODUCT_IMAGE_SIZE}px;
  height: ${PRODUCT_IMAGE_SIZE}px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 13.5px;
  border-radius: 3.5px;
  position: relative;
  ${({ src }) => !src && css`
    background-color: ${ADMIN_SURFACE_4};
    border: 0.7px dotted ${ADMIN_SURFACE_6};
    &:before, &:after {
      content: "";
      width: ${CROSS_SIZE}px;
      border-top: 0.7px dotted ${ADMIN_SURFACE_6};
      display: block;
      position: absolute;
      top: 50%;
      left: ${LEFT_MARGIN}px;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(315deg);
    }
  `}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_LABEL_M_BOLD}
`;

export const Content = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
`;

import styled from 'styled-components';
import { Button as BaseButton } from 'components/admin2/ui/Button';

export const Button = styled(BaseButton as any)`
  height: 24px;
  width: 73px;
  white-space: nowrap;
  line-height: 16.34px;
  font-weight: 700;
`;

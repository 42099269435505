import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import PropTypes from 'prop-types';
import produce from 'immer';
import Dragger from 'components/admin2/LegacyDragger';
import Row from 'components/admin2/ui/Library/Row';
import ButtonSelect from 'components/admin2/ui/ButtonSelect';
import {
  ChannelListContainer,
  Description,
  ChannelList,
  StyledSelect,
} from './styles';
import { KINDS } from './constants';

const VIEW_MODE_OPTIONS = [
  { label: 'Grid', value: 'grid' },
  { label: 'List', value: 'list' },
];

const ChannelSelection = (props) => {
  const {
    channels,
    kind,
    onChannelChange,
    onKindChange,
    onViewChange,
    view,
  } = props;

  const selectedOption = VIEW_MODE_OPTIONS.find(option => option.value === view);
  const descriptionKey = kind === KINDS.AUTOMATIC ?
    'ADMIN_PANEL_CHANNEL_SELECT_SECTION_DESCRIPTION_AUTOMATIC' :
    'ADMIN_PANEL_CHANNEL_SELECT_SECTION_DESCRIPTION_MANUAL';

  return (
    <>
      <StyledSelect
        labelKey="ADMIN_LABEL_CHANNEL_SELECT_DISPLAY"
        onChange={option => onViewChange(option.value)}
        options={VIEW_MODE_OPTIONS}
        placeholder={selectedOption?.label}
        value={selectedOption}
      />
      <ButtonSelect
        onChange={onKindChange}
        options={[{
          icon: 'adminbarChannels',
          label: 'ADMIN_PANEL_CHANNEL_SELECT_SECTION_BUTTON_AUTOMATIC',
          value: KINDS.AUTOMATIC,
        }, {
          icon: 'adminbarEditor',
          label: 'ADMIN_PANEL_CHANNEL_SELECT_SECTION_BUTTON_MANUAL',
          value: KINDS.MANUAL,
        }]}
        value={kind}
      >
        <TranslatedText
          component={Description}
          stringKey={descriptionKey}
        />
        {kind === KINDS.MANUAL && (
          <ChannelListContainer>
            <ChannelList>
              <Dragger
                direction="vertical"
                droppableStyles={{ padding: 0, width: '100%' }}
                innerDivStyles={{ width: '100%' }}
                keyField="page._id"
                list={channels}
                onDragEnd={onChannelChange}
                stretch
              >
                {
                  (item, index) => (
                    <Row
                      draggable
                      icon="adminbarChannels"
                      name={item.page?.title}
                      onToggleChange={(isActive) => {
                        const mutatedChannels = produce(channels, (draft) => {
                          draft[index].active = isActive;
                        });
                        onChannelChange(mutatedChannels);
                      }}
                      padding={index === 0 ? '0 0 5px 0' : undefined}
                      secondaryRowColor
                      toggleChecked={item.active}
                      typeKey="ADMIN_LABEL_CHANNEL"
                    />
                  )
                }
              </Dragger>
            </ChannelList>
          </ChannelListContainer>
        )}
      </ButtonSelect>
    </>
  );
};

ChannelSelection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  channels: PropTypes.array.isRequired,
  kind: PropTypes.oneOf(Object.values(KINDS)).isRequired,
  onChannelChange: PropTypes.func.isRequired,
  onKindChange: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};

export default ChannelSelection;

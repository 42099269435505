import SearchInput from 'components/admin2/ui/SearchInput';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import { ADMIN_SURFACE_2, ADMIN_SURFACE_3, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100, ADMIN_SURFACE_4, ADMIN_HIGHLIGHT_PRIMARY } from 'style/constants';
import styled from 'styled-components';
import Icon from 'components/admin2/Icon';
import { Button as AdminButton } from 'components/admin2/ui/Button';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

export const ShoppingBadIcon = styled(Icon).attrs({
  name: 'shoppingBag',
})`
  display: flex;
  height: 24px;
`;

export const PlusIcon = styled(Icon).attrs({
  name: 'plus',
})`
  margin-right: 8px;
  display: flex;
  width: fit-content;
  height: fit-content;

  & svg {
    width: 16px;
    height: 16px;
    & path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
  }
`;

export const ConnectStoreButton = styled(AdminButton)`
  width: fit-content;
`;

export const SubmitButton = styled(AdminButton)`
  width: fit-content;
  align-self: flex-end;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
`;

export const Title = styled.h2`
  margin: 0;
  ${ADMIN_TEXT_TITLE_M};
  color: ${ADMIN_TEXT_100};
`;

export const NoContent = styled.div`
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

export const SearchWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 2fr;
  align-items: center;
`;

export const StyledSearchInput = styled(SearchInput)`
  width: auto;
`;

export const HelpText = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_200};
  & a {
    color: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const ProductListWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content;
  gap: 20px;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  ${ADMIN_SCROLL_BAR};
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 30px 30px 40px 30px;
  flex-direction: column;
  gap: 30px;
  border-radius: 10px;
  background-color: ${ADMIN_SURFACE_4};
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

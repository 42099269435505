// tslint:disable: jsx-boolean-value
import React, { ComponentProps } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { NavButton } from 'components/admin2/ui/Button';
import {
  LTIcon,
  Label,
} from './styles';

export default function BackButton(props: ComponentProps<typeof NavButton>) {
  return (
    <NavButton {...props} left>
      <LTIcon disabled={props.disabled} />
      <TranslatedText
        component={Label}
        componentProps={{
          large: props.large,
        }}
        stringKey={props.large ? 'ADMIN_ACTION_BACK_TO_LIBRARY' : 'BACK'}
      />
    </NavButton>
  );
}

import React, { useCallback, useState } from 'react';
import Dropdown from 'components/admin2/ui/Dropdown';
import locales from 'assets/locales.json';
import { useDispatch, useSelector } from 'react-redux';
import { updatePage } from 'services/page/actions';
import { getPageBaseLanguage, getPageId } from 'services/app/selectors';
import { getLocaleLabel } from 'components/admin2/LocalizationTab/utils';

function getDefaultLocale(locale = 'en-US') {
  const defaultLocale = locales.find(item => item.locale === locale);
  if (defaultLocale) return defaultLocale;

  return {
    locale: 'en-US',
    language: 'English (United States)',
  };
}

export default function LocaleDropdown() {
  const dispatch = useDispatch();
  const pageId = useSelector(getPageId);
  const pageLocale = useSelector(getPageBaseLanguage);
  const defaultLocale = getDefaultLocale(pageLocale);
  const [selectedLocale, setSelectedLocale] = useState<Locale | null>(defaultLocale);

  const updateBaseLanguage = useCallback((locale: Locale) => {
    dispatch(updatePage(pageId, { baseLanguage: locale.locale }));
    setSelectedLocale(locale);
  }, [pageId]);

  return selectedLocale ? (
    <Dropdown
      descriptionKey="ADMIN_LABEL_LOCALE_DROPDOWN_DESCRIPTION"
      labelKey="ADMIN_LABEL_LOCALE_DROPDOWN_LABEL"
      options={locales}
      valueKey="locale"
      getOptionLabel={getLocaleLabel}
      value={selectedLocale}
      onChange={updateBaseLanguage}
      padding="0"
      isLocalization={true}
    />
  ) : null;
}

type Locale = {
  language: string;
  locale: string;
};
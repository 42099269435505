import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectionsCatalog, getIsShopifyConnected, getShopState } from 'services/shopify/selectors';
import AdminModal from '../ui/AdminModal';
import { getCollection } from 'services/shopify/api';
import { ICollection, IProduct, IShopifyState } from 'services/shopify/models';
import ShopifyProductList from './ShopifyProductList';
import { navigateToAdminBarAction } from 'services/admin/actions';
import { MAESTRO_SHOPIFY_APP_URL } from 'shared/constants';
import { ActionKey } from 'services/admin/constants';

interface IShopifyProductListModalProps {
  isMultipleSelection: boolean;
  onClose: () => void;
  onSelectMultipleProducts?: (products: IProduct[]) => void;
  onSelectProduct?: (product: IProduct) => void;
  selectedProduct?: IProduct | null;
  selectedProducts?: IProduct[];
}

const loadShopifyCollection = async ({ collectionHandle, shopInfo }: { collectionHandle: string; shopInfo: IShopifyState; }) => {
  try {
    const { shop, shopAccessToken } = shopInfo;
    const data = await getCollection(shop, shopAccessToken, collectionHandle);
    const { collectionByHandle: returnedCollection } = data;
    return { err: null, returnedCollection };
  } catch (err) {
    return { err, returnedCollection: null };
  }
};

const ShopifyProductListModal: React.FC<IShopifyProductListModalProps> = ({
  selectedProducts,
  onClose,
  isMultipleSelection,
  onSelectMultipleProducts,
  onSelectProduct,
  selectedProduct,
}) => {
  const dispatch = useDispatch();
  const isShopifyConnected = useSelector(getIsShopifyConnected);
  const collections = useSelector(getCollectionsCatalog);
  const shopify = useSelector(getShopState);

  const [collection, setCollection] = React.useState(collections[0]);
  const [products, setProducts] = React.useState<IProduct[]>([]);

  React.useEffect(() => {
    if (!isShopifyConnected || !collection) {
      return;
    }
    loadShopifyCollection({
      collectionHandle: collection.handle,
      shopInfo: shopify,
    }).then(({ err, returnedCollection }) => {
      if (err) {
        return;
      }
      const newCollection = returnedCollection as Omit<ICollection, 'handle'>;
      setProducts(newCollection.products.edges.map((edge) => edge.node));
    });
  }, [shopify, isShopifyConnected, collection]);

  const handleOpenThirdPartyTab = React.useCallback(() => {
    window.open(MAESTRO_SHOPIFY_APP_URL, '_blank');
    dispatch(navigateToAdminBarAction({ actionKey: ActionKey.thirdParty }));
  }, [dispatch]);

  return (
    <AdminModal
      allowOverflow={true}
      maxWidth="1000px"
      fixedHeight={true}
      onClose={onClose}
    >
      <ShopifyProductList
        collection={collection}
        collections={collections}
        onCollectionChange={setCollection}
        products={products}
        isShopifyConnected={isShopifyConnected}
        onClickConnectStore={handleOpenThirdPartyTab}
        isMultipleSelection={isMultipleSelection}
        onSelectMultipleProducts={onSelectMultipleProducts}
        onSelectSingleProduct={onSelectProduct}
        selectedProducts={selectedProducts}
        selectedProduct={selectedProduct}
      />
    </AdminModal>
  );
};

export default ShopifyProductListModal;

import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Dropdown from 'components/admin2/ui/Dropdown';

const propTypes = {
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      data: PropTypes.shape({}),
      renderer: PropTypes.shape({}),
    }).isRequired,
  ).isRequired,
  inputTestId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rendererNameField: PropTypes.string,
  sort: PropTypes.bool,
  value: PropTypes.string,
  valueField: PropTypes.string,
};

const defaultProps = {
  rendererNameField: null,
  sort: true,
  value: null,
  valueField: '_id',
};

const DEFAULT_LABEL = 'Untitled';

export default function ObjectSelectView({
  docs,
  onChange: onSelectId,
  rendererNameField: nameField,
  value: selectedId,
  valueField,
  sort,
  inputTestId,
}) {
  const options = useMemo(
    () => {
      const unsorted = docs
        .filter(doc => doc)
        .map((doc) => {
          const value = doc[valueField];
          const label = (nameField && doc.renderer?.[nameField]) ||
            doc.name || // TODO: Semi-hack for videos
            doc.data?.title ||
            doc.data?.name ||
            DEFAULT_LABEL;
          return { label, value };
        });
      if (sort) {
        return sortBy(unsorted, option => option.label);
      }
      return unsorted;
    },
    [docs],
  );

  const value = useMemo(
    () => options.find(option => option.value === selectedId) || null,
    [options, selectedId],
  );

  const onChange = option => onSelectId(option.value);

  return (
    <Dropdown
      dataTestId={inputTestId}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
}

ObjectSelectView.propTypes = propTypes;
ObjectSelectView.defaultProps = defaultProps;

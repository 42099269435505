import React, { useMemo, CSSProperties } from 'react';
import { withTheme } from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  PageChangeFunction,
  PageSizeChangeFunction,
  ComponentPropsGetterR,
  SortedChangeFunction,
  Column as ReactTableColum,
  RowInfo,
  ReactTableFunction,
} from 'react-table-6';
import 'react-table-6/react-table.css';
import produce from 'immer';
import Button, {
  AccessCodeButton,
  EditButton,
  DeleteButton,
  IconButton,
  LockButton,
} from 'components/admin2/ui/Button';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import { ASC_KEY, DESC_KEY } from 'components/admin2/MediaLibrary/constants';
import { isUploadedVod, IVideo } from 'models/Video';
import Pagination from './Pagination';
import {
  StyledTrGroup,
  StyledThead,
  VisibilityWrapper,
  Arrow,
  Spacer,
  Cell,
  ImageNameWrapper,
  Image,
  StyledPlaceholder,
  PlaceholderIcon,
  CustomHeader,
  Title,
  UseButton,
  headerStyle,
  StyledReactTable,
} from './styles';
import { MEDIA_LIBRARY_TABLE_THEAD, MEDIA_LIBRARY_TABLE_TBODY } from 'css-classes';

const isPending = (video: IVideo) => {
  return video.uploadStatus && video.uploadStatus !== 'complete';
};

export const canDownload = ({ collection }) => (
  ['quests'].includes(collection)
);

const renderArrow = (sort: string) => {
  return sort === ASC_KEY || sort === DESC_KEY ? (
    <Arrow up={sort === DESC_KEY} />
  ) : (
    <Spacer />
  );
};

const renderArrowWithoutSorting = (
  colData: CustomColumn,
  currentlySorted: any | null,
) => {
  if (currentlySorted?.sortBy === colData.accessor) {
    if (currentlySorted?.sortDirection === 1) {
      return <Arrow up={true} />;
    }
    return <Arrow />;
  }
  return <Spacer />;
};

export interface IColumn extends Pick<
  ReactTableColum,
  | 'accessor'
  | 'Cell'
  | 'Header'
  | 'id'
  | 'maxWidth'
  | 'width'
> {
  columnData?: any;
  defaultThumbnail?: string;
  headerKey?: string;
  headerStyle?: { [K in keyof CSSProperties]: any };
  isRoundedThumbnail?: boolean;
  isTitle?: boolean;
  preventSort?: boolean;
  sort?: string;
  testId?: string;
  thumbnailAccessor?: any;
  title?: string;
  transformValue?: (value: any) => string;
}

export enum MEDIA_LIBRARY_ACTION_MAP_ENUM {
  onDeleteClick = 'onDeleteClick',
  onDownloadClick = 'onDownloadClick',
  onEditClick = 'onEditClick',
  onKeyClick = 'onKeyClick',
  onRestoreClick = 'onRestoreClick',
  onSelectItem = 'onSelectItem',
  onVisibilityClick = 'onVisibilityClick',
}

interface ActionColumn {
  Cell: (cellData: any) => React.ReactNode;
  getProps?: (state: any, rowInfo: RowInfo) => any;
  width?: number;
}

type CustomAction = MEDIA_LIBRARY_ACTION_MAP_ENUM | ActionColumn;
type CustomColumn = ReactTableColum & IColumn;

export interface IProps {
  columns?: Array<CustomColumn>;
  currentlySorted?: object;
  customActions?: CustomAction[];
  data?: any[];
  defaultPageSize?: number;
  disableEditing?: boolean;
  disableKeyClick?: boolean;
  disableSelecting?: boolean;
  headBackground?: string;
  itemBackground?: string;
  loading?: boolean;
  manual?: boolean;
  onDeleteClick?: (...args: any[]) => any;
  onDownloadClick?: (...args: any[]) => any;
  onEditClick?: (...args: any[]) => any;
  onFetchData?: (...args: any[]) => any;
  onHeaderClick?: (...args: any[]) => any;
  onKeyClick?: (...args: any[]) => any;
  onPageChange?: PageChangeFunction;
  onPageSizeChange?: PageSizeChangeFunction;
  onRestoreClick?: (...args: any[]) => any;
  onRowClick?: (...args: any[]) => any;
  onSelectItem?: (...args: any[]) => any;
  onSortedChange?: SortedChangeFunction;
  onVisibilityClick?: (...args: any[]) => any;
  page?: number;
  pages?: number;
  pageSize?: number;
  showPagination?: boolean;
  sorted?: any[];
  testIdAddButton?: string;
  theme?: any;
  useSelectorSorting?: boolean;
}

const MediaLibraryTable: React.FC<IProps> = ({
  columns = [],
  currentlySorted = null,
  customActions = [],
  data = [],
  defaultPageSize = 1000,
  disableEditing = false,
  disableKeyClick = true,
  disableSelecting = false,
  headBackground = null,
  itemBackground,
  loading = false,
  manual = false,
  onDeleteClick = null,
  onDownloadClick = null,
  onEditClick = null,
  onFetchData = () => null,
  onHeaderClick = () => null,
  onKeyClick = null,
  onPageChange,
  onPageSizeChange,
  onRestoreClick = null,
  onRowClick = () => null,
  onSelectItem = null,
  onSortedChange,
  onVisibilityClick = null,
  page = 0,
  pages = -1,
  pageSize = 250,
  showPagination = false,
  sorted = [],
  testIdAddButton = 'media-library-select-item',
  useSelectorSorting = false,
}) => {
  const reactTableCols = useMemo(
    () =>
      columns.map((colData, i): CustomColumn => {
        const {
          thumbnailAccessor,
          defaultThumbnail,
          transformValue,
          isRoundedThumbnail,
        } = colData;
        return {
          // accessor: d => d[colData.title],
          accessor: colData.title,
          Cell: (cellData: any) => {
            const value = transformValue
              ? transformValue(cellData.value)
              : cellData.value;
            const thumbnailResponse =
              typeof thumbnailAccessor === 'function'
                ? thumbnailAccessor(cellData.original)
                : cellData.original?.[thumbnailAccessor];

            const {
              icon: thumbnailIcon,
              url: thumbnail,
              rounded,
            } = typeof thumbnailResponse === 'object'
              ? thumbnailResponse
              : {
                  icon: defaultThumbnail,
                  rounded: isRoundedThumbnail,
                  url: thumbnailResponse,
                };

            // want first column to be indented but only 'name' columns should have lighter text
            return (
              <Cell disabled={cellData.original?.disabled} isFirst={i === 0}>
                {thumbnailAccessor && thumbnailIcon && (
                  <ImageNameWrapper>
                    {thumbnail ? (
                      <Image rounded={rounded} src={thumbnail} />
                    ) : (
                      <StyledPlaceholder rounded={rounded}>
                        <PlaceholderIcon name={thumbnailIcon} />
                      </StyledPlaceholder>
                    )}
                  </ImageNameWrapper>
                )}
                <span
                  data-testid={`${cellData.column.testId}-${cellData.index}`}
                >
                  {value}
                </span>
              </Cell>
            );
          },
          // kind of hacky. this enables us to read colData in the Header render callback.
          columnData: {
            index: i,
            ...colData,
          },
          getHeaderProps: () =>
            onSortedChange ? {} : { onClick: () => onHeaderClick(i) },
          getProps: ((_: any, rowInfo: RowInfo) => {
            return {
              onClick: () => onRowClick(rowInfo.index),
            };
          }) as unknown as ReactTableFunction,
          Header: (row) => {
            const { columnData } = row.column as CustomColumn;
            const { title, sort, preventSort, headerKey, testId } = columnData;
            return (
              <CustomHeader
                data-testid={
                  testId ? `${testId}TableHeader` : 'header-library-table'
                }
                preventSort={preventSort}
              >
                <Title>
                  {headerKey ? <TranslatedText stringKey={headerKey} /> : title}
                </Title>
                {(onSortedChange && !currentlySorted) || !onSortedChange
                  ? renderArrow(sort)
                  : null}
                {onSortedChange && useSelectorSorting
                  ? renderArrowWithoutSorting(colData, currentlySorted)
                  : null}
              </CustomHeader>
            );
          },
          headerStyle: {
            ...headerStyle,
          },
          id: colData.title,
          style: {
            justifyContent: 'flex-start',
            position: 'relative',
          },
          ...colData,
        };
      }),
    [columns],
  );

  const selectButtonColumn: ReactTableColum = {
    Cell: (cellData) => {
      const { original, index } = cellData;
      const shouldHideAdd = isPending(original);
      if (shouldHideAdd) {
        return (
          <LockButton
            data-testid={`media-library-select-item-locked-${index}`}
          />
        );
      }
      const { activeOnGate = false } = original;
      return (
        <UseButton key={index} data-testid={`${testIdAddButton}-${index}`} disabled={activeOnGate}>
          <TranslatedText stringKey="ADMIN_LABEL_USE_ENTITLEMENT" />
        </UseButton>
      );
    },
    getProps: ((_: unknown, rowInfo: RowInfo) => {
      const { original } = rowInfo;
      const { activeOnGate = false } = original;
      const shouldHideAdd = isPending(original) || activeOnGate;
      return {
        onClick: () => !shouldHideAdd && onSelectItem?.(rowInfo.index),
      };
    }) as unknown as ReactTableFunction,
    style: {
      justifyContent: 'flex-start',
    },
    width: 70,
  };

  const editColumn: ReactTableColum = {
    Cell: (cellData) => {
      const { original } = cellData;
      const { activeOnGate = false } = original;
      return <EditButton disabled={activeOnGate} data-testid={`media-library-edit-item-${cellData.index}`} />;
    },
    getProps: ((_: unknown, rowInfo: RowInfo) => {
      const { original } = rowInfo;
      const { activeOnGate = false } = original;
      return {
        onClick: () => !activeOnGate && onEditClick?.(rowInfo.index),
      };
    }) as unknown as ReactTableFunction,
    width: 34,
  };

  const changeVisibility: ReactTableColum = {
    Cell: (cellData) => {
      const rowIndex = cellData.index;
      const checked = data[rowIndex].visible;

      return (
        <VisibilityWrapper>
          <TranslatedText stringKey="VISIBLE" />
          <ToggleSwitch checked={checked} />
        </VisibilityWrapper>
      );
    },
    getProps: ((_: any, rowInfo: RowInfo) => {
      return {
        onClick: () => onVisibilityClick?.(rowInfo.index),
      };
    })  as unknown as ReactTableFunction,
    width: 110,
  };

  const selectKeyColumn: ReactTableColum = {
    Cell: (cellData) => (
      <AccessCodeButton
        data-testid={`media-library-access-code-item-${cellData.index}`}
        name="key"
      />
    ),
    getProps: ((_: any, rowInfo: RowInfo) => {
      return {
        onClick: () => onKeyClick?.(rowInfo.index),
      };
    }) as unknown as ReactTableFunction,
    width: 40,
  };

  const downloadColumn: ReactTableColum = {
    accessor: (d) => canDownload(d),
    Cell: (cellData) => {
      return cellData.value ? <IconButton name="download" /> : null;
    },
    getProps: ((_: any, { index, row }: RowInfo) => {
      const { download } = row;
      return {
        onClick: () => download && onDownloadClick?.(index),
      };
    }) as unknown as ReactTableFunction,
    id: 'download',
    width: 40,
  };

  const deleteColumn: ReactTableColum = {
    Cell: () => <DeleteButton />,
    getProps: ((_: any, rowInfo: RowInfo) => {
      return {
        onClick: () => onDeleteClick?.(rowInfo.index),
        'data-testid': `media-library-delete-code-item-${rowInfo.index}`,
      };
    }) as unknown as ReactTableFunction,
    width: 34,
  };

  const restoreColumn: ReactTableColum = {
    Cell: () => (
      <Button>
        <TranslatedText stringKey="ADMIN_LABEL_RESTORE" />
      </Button>
    ),
    getProps: ((_: any, rowInfo: RowInfo) => {
      return {
        onClick: () => onRestoreClick?.(rowInfo.index),
      };
    }) as unknown as ReactTableFunction,
    width: 100,
  };

  const ACTION_MAP = {
    [MEDIA_LIBRARY_ACTION_MAP_ENUM.onDeleteClick]: deleteColumn,
    [MEDIA_LIBRARY_ACTION_MAP_ENUM.onDownloadClick]: downloadColumn,
    [MEDIA_LIBRARY_ACTION_MAP_ENUM.onEditClick]: editColumn,
    [MEDIA_LIBRARY_ACTION_MAP_ENUM.onKeyClick]: selectKeyColumn,
    [MEDIA_LIBRARY_ACTION_MAP_ENUM.onRestoreClick]: restoreColumn,
    [MEDIA_LIBRARY_ACTION_MAP_ENUM.onSelectItem]: selectButtonColumn,
    [MEDIA_LIBRARY_ACTION_MAP_ENUM.onVisibilityClick]: changeVisibility,
  };

  const setActionsHeader = (
    actionColumn: CustomColumn,
    columnsLenght: number,
    lastIndex: number,
  ) => {
    if (columnsLenght <= lastIndex) return;

    actionColumn.Header = () => (
      <CustomHeader preventSort={true}>
        <Title>
          <TranslatedText stringKey="ADMIN_LABEL_QUEST_ACTIONS" />
        </Title>
      </CustomHeader>
    );
    actionColumn.headerStyle = {
      ...actionColumn.headerStyle,
      display: 'flex',
      justifyContent: `flex-${
        columnsLenght - 1 === lastIndex ? 'end' : 'start'
      }`,
      overflow: 'visible',
      zIndex: '1',
    };
  };

  const colsWithSelect: CustomColumn[] = customActions.length !== 0
    ? produce(reactTableCols, (draft) => {
        const lastIndex = draft.length;
        customActions.forEach((action) => {
          if (ACTION_MAP.hasOwnProperty(action as string)) {
            draft.push(
              ACTION_MAP[action as MEDIA_LIBRARY_ACTION_MAP_ENUM],
            );
          } else {
            draft.push(action as CustomAction & { getProps: ReactTableFunction });
          }

          setActionsHeader(draft[lastIndex], draft.length, lastIndex);
        });
      })
    : produce(reactTableCols, (draft) => {
        const lastIndex = draft.length;
        if (onDownloadClick) {
          draft.push(downloadColumn);
        }
        if (onVisibilityClick) {
          draft.push(changeVisibility);
        }
        if (onDeleteClick) {
          draft.push(deleteColumn);
        }
        if (onKeyClick && !disableKeyClick) {
          draft.push(selectKeyColumn);
        }
        if (onRestoreClick) {
          draft.push(restoreColumn);
        }
        if (onEditClick && !disableEditing) {
          draft.push(editColumn);
        }
        if (onSelectItem && !disableSelecting) {
          draft.push(selectButtonColumn);
        }
        setActionsHeader(draft[lastIndex], draft.length, lastIndex);
      });

  const getTBodyProps = () => ({
    className: MEDIA_LIBRARY_TABLE_TBODY,
    style: {
      marginTop: 5,
      maxWidth: '100%',
      minWidth: 0,
      overflow: 'hidden auto',
    },
  });

  const getTHeadProps = () => ({
    className: MEDIA_LIBRARY_TABLE_THEAD,
    headBackground,
  });

  const getTGroupProps: ComponentPropsGetterR = (_, rowInfo) => ({
    isSelected: rowInfo?.original.isSelected,
    itemBackground,
    style: {
      margin: '0 10px',
    },
  });

  return (
    <StyledReactTable
      className="admin-dark"
      collapseOnDataChange={false}
      columns={colsWithSelect}
      data={data}
      defaultPageSize={defaultPageSize}
      getTbodyProps={getTBodyProps}
      getTheadProps={getTHeadProps}
      getTrGroupProps={getTGroupProps}
      loading={loading}
      manual={manual} // true for server-side pagination
      minRows={0}
      nextText="&#8250;"
      onFetchData={onFetchData}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortedChange={onSortedChange}
      page={page}
      pages={pages}
      pageSize={pageSize}
      pageText=""
      PaginationComponent={Pagination}
      previousText="&#8249;"
      resizable={false}
      showPageJump={false}
      showPagination={showPagination}
      sorted={sorted}
      TheadComponent={StyledThead}
      TrGroupComponent={StyledTrGroup}
    />
  );
};

export default withTheme(MediaLibraryTable);

import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { DROPDOWN_TITLE } from 'css-classes';
import {
  Title,
} from './styles';
import {
  ButtonViewProps,
} from './interfaces';

export default function DefaultButtonView<T>({
  shouldHideTitle = true,
  shouldTranslate,
  placeholderText,
  isAdmin,
  isOpen,
  isSelected,
  }: ButtonViewProps<T>) {
  if (shouldHideTitle && isOpen) {
    return null;
  }

  return shouldTranslate ? (
    <TranslatedText component={Title} componentProps={{ isAdmin, isSelected }} stringKey={placeholderText} />
  ) : <Title isAdmin={isAdmin} isSelected={isSelected} className={DROPDOWN_TITLE}>{placeholderText}</Title>;
}

import { connect } from 'react-redux';
import { writeToCollectionLegacy, editCollectionItem } from 'services/admin';
import { stripEmptyObjectValues, createSlugFromString } from 'utils';
import NewGroupEditor from './NewGroupEditor';

const mapDispatchToProps = dispatch => ({
  handleEdit: (type, id, renderer) => dispatch(editCollectionItem(type, id, renderer)),
  handleSave: (data) => {
    // Legacy fields
    const legacyData = {
      collection: 'groups',
      slug: createSlugFromString(data.groupName),
      type: 'group',
    };
    const groupRendererRaw = data;
    const renderer = stripEmptyObjectValues(groupRendererRaw);
    const doc = {
      ...legacyData,
      renderer,
    };
    dispatch(writeToCollectionLegacy(doc));
  },
});

export default connect(null, mapDispatchToProps)(NewGroupEditor);

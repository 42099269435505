import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SuperAdminModal from './SuperAdminModal';

export default function InternalFeatureGateControlButton({ children, onClose }) {
  const [showModal, setShowModal] = useState(false);
  const showFeatureModal = () => setShowModal(true);
  const hideFeatureModal = () => setShowModal(false);

  const handleClose = () => {
    onClose?.();
    setShowModal(false);
  };

  return (
    <React.Fragment>
      {children(showFeatureModal, hideFeatureModal)}
      {
        showModal && (
          <SuperAdminModal
            onExit={handleClose}
          />
        )
      }
    </React.Fragment>
  );
}

InternalFeatureGateControlButton.propTypes = {
  children: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

/* eslint-disable no-console */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFeatureGate } from 'hooks';
import styled from 'styled-components';
import every from 'lodash/every';
import isEqual from 'lodash/isEqual';
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import AdminModal from 'components/admin2/ui/AdminModal';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import Button, { CloseButton } from 'components/admin2/ui/Button';
import {
  Feature,
  Overlay,
  Panel,
  QuestActions,
  isEnabled,
} from 'services/feature-gate';
import { getStateModals } from 'services/modals/selectors';
import { checkApplePayVerify } from 'services/billing';
import { ADMIN_SURFACE_6 } from 'style/constants';
import { ADMIN_TEXT_BODY_L_REGULAR, ADMIN_TEXT_TITLE_L } from 'style/design-system/textStyles';

const buildMap = object => Object.entries(object)
  .reduce((acc, [name, value]) => {
    acc[value] = upperFirst(lowerCase(name));
    return acc;
  }, {});

const GATED_FEATURES = buildMap(Feature);
const GATED_OVERLAYS = buildMap(Overlay);
const GATED_PANELS = buildMap(Panel);
const GATED_QUEST_ACTIONS = buildMap(QuestActions);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${ADMIN_TEXT_TITLE_L}
  height: 100px;
  justify-content: center;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
`;

const SubHeader = styled.div`
  border-top: 2px solid ${ADMIN_SURFACE_6};
  align-items: center;
  display: flex;
  flex-direction: column;
  ${ADMIN_TEXT_BODY_L_REGULAR}
  padding: 12px 0;
  justify-content: center;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 12px;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-transform: uppercase;
`;

const PanelSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  height: 100%;
`;

const PanelRow = styled.div`
  width: 50%;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 8px;
  margin-bottom: 12px;
  & > div {
    margin: 0 16px;
  }
  & button {
    display: inline-block !important;
  }
`;

const StyledField = styled(LabelToggle).attrs({
  width: '100%',
})`
  width: 100%;
  padding: 0 20px;
`;

const ExitButton = styled(CloseButton).attrs({
  containerCss: `
    position: absolute;
    top: 10px;
    right: 10px;
  `,
})``;

const GateSection = memo(({
  activeFeatures,
  featureNames,
  fullWidth,
  onChange,
}) => {
  const RowElement = fullWidth ? Row : PanelRow;
  return (
    <NonKeyedListMapper list={Object.keys(featureNames)}>
      {
        (key, feature) => {
          const isActive = isEnabled(activeFeatures, feature);
          return (
            <RowElement key={key}>
              <StyledField
                checked={isActive}
                data-testid={`gate-${feature}`}
                label={featureNames[feature]}
                onChange={value => onChange(feature, value)}
              />
            </RowElement>
          );
        }
      }
    </NonKeyedListMapper>
  );
}, (prev, next) => (
  isEqual(prev.activeFeatures, next.activeFeatures) &&
  isEqual(prev.featureNames, next.featureNames)
));

GateSection.propTypes = {
  activeFeatures: PropTypes.shape({}).isRequired,
  featureNames: PropTypes.shape({}).isRequired,
  fullWidth: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SuperAdminModal = ({ onExit }) => {
  const dispatch = useDispatch();
  const [{
    featureMap,
    overlayMap,
    panelMap,
    questActionMap,
  }, setFeatures] = useFeatureGate();

  const errorStateModals = useSelector(getStateModals);
  const errorModal = errorStateModals[0];

  if (
    featureMap.applePay &&
    errorModal &&
    errorModal.data?.message === 'Error: Domain failed the apple pay verification'
  ) {
    setFeatures('feature', ['applePay'], false);
  }

  const allEnabled = every(Object.keys(GATED_FEATURES), feature => featureMap[feature]);
  const allDisabled = every(Object.keys(GATED_FEATURES), feature => !featureMap[feature]);

  return (
    <AdminModal maxWidth="800px" onClose={onExit}>
      <Container>
        <ExitButton onClick={onExit} />
        <Header>
          Choose which features should be enabled
        </Header>
        <GateSection
          activeFeatures={featureMap}
          featureNames={GATED_FEATURES}
          fullWidth
          onChange={(feature, value) => {
            setFeatures('feature', [feature], value);
            if (feature === 'applePay' && value) {
              dispatch(checkApplePayVerify());
            }
          }}
        />
        <ActionRow>
          <div>
            <Button
              disabled={allDisabled}
              onClick={() => {
                setFeatures('feature', Object.keys(GATED_FEATURES), false);
              }}
            >
              Disable all features
            </Button>
          </div>
          <div>
            <Button
              disabled={allEnabled}
              onClick={() => {
                setFeatures('feature', Object.keys(GATED_FEATURES), true);
              }}
            >
              Enable all features
            </Button>
          </div>
        </ActionRow>
        <SubHeader>
          Panels
        </SubHeader>
        <PanelSection>
          <GateSection
            activeFeatures={panelMap}
            featureNames={GATED_PANELS}
            fullWidth={false}
            onChange={(panelType, value) => {
              setFeatures('panel', [panelType], value);
            }}
          />
        </PanelSection>
        <SubHeader>
          Overlays
        </SubHeader>
        <GateSection
          activeFeatures={overlayMap}
          featureNames={GATED_OVERLAYS}
          fullWidth
          onChange={(overlayType, value) => {
            setFeatures('overlay', [overlayType], value);
          }}
        />
        <SubHeader>
          Quest Actions
        </SubHeader>
        <GateSection
          activeFeatures={questActionMap}
          featureNames={GATED_QUEST_ACTIONS}
          fullWidth
          onChange={(questAction, value) => {
            setFeatures('questAction', [questAction], value);
          }}
        />
      </Container>
    </AdminModal>
  );
};

SuperAdminModal.propTypes = {
  onExit: PropTypes.func.isRequired,
};

export default memo(SuperAdminModal);

import React, { useCallback } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  ButtonSelectStyleProps,
  StyledIcon,
  TabButton,
} from './styles';

interface TabProps extends ButtonSelectStyleProps {
  icon?: string;
  itemValue?: string | boolean;
  labelKey?: string;
  onChange?(value: string | boolean): void;
}

export default function Tab({
  disabled,
  icon,
  itemValue: value = '',
  labelKey,
  onChange,
  selected,
  ...props
}: TabProps) {
  const handleChange = useCallback(() => {
    onChange!(value);
  }, [value, onChange]);

  return (
    <TabButton
      disabled={disabled}
      onClick={onChange && handleChange}
      selected={selected}
      type="button"
      {...props}
    >
      {icon && <StyledIcon name={icon} />}
      {labelKey && <TranslatedText stringKey={labelKey} />}
    </TabButton>
  );
}

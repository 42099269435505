import React, { HTMLAttributes } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  TabContainer,
  TabHeader,
  HeaderText,
  StyledSearchInput,
} from './styles';
import { TranslationKey } from 'hooks/use-translation';

export interface TabProps extends Omit<HTMLAttributes<HTMLDivElement>, 'id'> {
  active?: boolean;
  'data-testid'?: string;
  headerHelperKey?: TranslationKey;
  iconName?: string;
  id?: number | string;
  onSearch?: (searchText: string) => void;
  searchPlaceholderKey?: TranslationKey;
  titleKey: string;
}

export default function Tab({
  active = false,
  children,
  className,
  headerHelperKey,
  onSearch,
  searchPlaceholderKey,
  ...props
}: TabProps) {
  return (
    <TabContainer
      className={className}
      data-testid={props['data-testid']}
      hidden={!active}
    >
      {
        searchPlaceholderKey && (
          <TabHeader center={!!headerHelperKey}>
            {headerHelperKey && (
              <TranslatedText component={HeaderText} stringKey={headerHelperKey} />
            )}
            <StyledSearchInput
              onSearch={onSearch}
              searchPlaceholderKey={searchPlaceholderKey}
            />
          </TabHeader>
        )
      }
      {children}
    </TabContainer>
  );
}

import React, { useMemo } from 'react';
import { IAccountRole } from 'services/auth/models';
import defaultPersonImage from 'assets/default-person.svg';
import { EditButton } from '../Button';
import {
  Container,
  ProfPic,
  UserInfo,
  Name,
  Tag,
  EmailContainer,
  EmailLabel,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

interface AdminCardProps extends React.HTMLAttributes<HTMLDivElement> {
  includeTag?: boolean;
  onEdit?: () => void;
  user?: User;
}

interface User {
  email?: string;
  icon?: string;
  name?: string;
  roles?: IAccountRole[];
}

export default function AdminCard({ user = {}, onEdit, className, includeTag = true }: AdminCardProps) {
  const { t } = useAdminTranslation();
  const userTag = useMemo(() => {
    const { roles } = user;
    const siteAdmin = roles?.some(role => role.scope === '*' && role.write);
    const producer = roles?.some(role => role.scope === 'Producer');
    const otherRole = roles?.some(role => role.scope === 'Chat_Moderator');
    if (siteAdmin) {
      return t('ADMIN_INVITE_TAB_SITE_ADMIN');
    }
    if (producer) {
      return t('ADMIN_LABEL_PRODUCER');
    }
    if (otherRole) {
      return t('ADMIN_LABEL_CHAT_MODERATOR');
    }
    return '';
  }, [user.roles]);

  return (
    <Container className={className}>
      <ProfPic src={user.icon || defaultPersonImage} />
      <UserInfo>
        <Name>{user.name}</Name>
        {includeTag && <Tag>{userTag}</Tag>}
        <EmailContainer>
          <EmailLabel label={user.email} />
          {onEdit && <EditButton onClick={onEdit} />}
        </EmailContainer>
      </UserInfo>
    </Container>
  );
}

import styled, { css } from 'styled-components';
import { ADMIN_FIELD_TEXT_INPUT, ADMIN_QUERY_TEXT_INPUT } from 'style/mixins';
import { ADMIN_INPUT_BORDER_COLOR, SPACING_X_SMALL, ADMIN_ALERT_ERROR, ADMIN_SURFACE_5, ADMIN_TEXT_200, ADMIN_SURFACE_3 } from 'style/constants';
import Icon from 'components/admin2/Icon';
import withTooltip from 'components/core/withTooltip';
import { TYPES } from './constants';
import { InputType } from 'zlib';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div<{padding?: string}>`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  padding: ${({ padding }) => padding || '10px 0'};
  /* Hides number input arrows */
  & input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const PrefixInput = styled.div`
  width: 42px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${ADMIN_SURFACE_5};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;


export const HTTPSPrefixInput = styled.span`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: left;
  display: block;
  height: 40px;
  background-color: ${ADMIN_SURFACE_3};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 12px 0px 12px 16px;
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
`;

export const FormContainer = styled.div<{inputBorder: string; inputType: InputType; stretch: boolean}>`
  display: flex;
  flex-flow: row nowrap;
  border-bottom: ${(props) => props.inputType === TYPES.SEARCH ? `1px solid ${ADMIN_INPUT_BORDER_COLOR(props)}` : 'none'};
  position: relative;
  ${(stretch) => stretch ? `align-self: stretch`: ''};
  &:after {
    content: "";
    pointer-events: none;
    border: ${({ inputBorder }) => inputBorder};
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;

export const SearchIcon = styled(Icon)`
  margin-right: ${SPACING_X_SMALL};
`;

export const BasicInput = styled.input<{inputType: InputType; prefixInputIcon?: string; showDangerErrorMessage: boolean}>`
  ${({ inputType }) => (inputType === TYPES.SEARCH ? ADMIN_QUERY_TEXT_INPUT : ADMIN_FIELD_TEXT_INPUT)} min-width: 0;
  ${({ showDangerErrorMessage }) => showDangerErrorMessage && css`border: 1px solid ${ADMIN_ALERT_ERROR};`};
  ${({ prefixInputIcon }) => (prefixInputIcon && css`border-top-left-radius: 0; border-bottom-left-radius: 0;`)};
  ${({ prefixInputIcon }) => (prefixInputIcon === 'linkOutline' && css`padding: 12px 10px 13px 1px;`)};
`;

export const Input = withTooltip(BasicInput, { containerCss: 'width: 100%;' });

export const PrefixIcon = styled(Icon)`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: default;

  & svg {
    width: 18px;
    height: 18px;
  }

  & path {
    fill: ${ADMIN_TEXT_200};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0px;
  align-items: center;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  height: 100%;
`;

import RadioGroup from 'components/admin2/RadioGroup';
import TextInput from 'components/admin2/TextInput';
import AdminModal from 'components/admin2/ui/AdminModal';
import Dropdown from 'components/admin2/ui/Dropdown';
import withLabel from 'components/core/withLabel';
import { ADMIN_SURFACE_2, ADMIN_TEXT_100, ADMIN_TEXT_200, ADMIN_SURFACE_1, ADMIN_SURFACE_4 } from 'style/constants';
import styled from 'styled-components';
import Button from 'components/admin2/ui/Button';
import { ErrorLabel } from 'components/ui/Label';
import { ADMIN_TEXT_BODY_M_REGULAR, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_HEADLINE_S } from 'style/design-system/textStyles';

export const StyledAdminModal = styled(AdminModal)`
  background: ${ADMIN_SURFACE_4};
`;

export const Container = styled.div`
  background-color: ${ADMIN_SURFACE_4};
  padding: 30px;
`;

export const Title = styled.h1`
  ${ADMIN_TEXT_HEADLINE_S}
  color: ${ADMIN_TEXT_100};
  margin: 0 auto;
`;

export const Subtitle = styled.p`
  ${ADMIN_TEXT_BODY_M_REGULAR}
  color: ${ADMIN_TEXT_200};
`;

export const StyledTextInput = styled(TextInput)`
  width: 50%;
  padding-bottom: 0px;
`;

export const MenuContainer = styled.div`
  & label {
    ${ADMIN_TEXT_BODY_S_MEDIUM}
    color: ${ADMIN_TEXT_200};
    padding-bottom: 5px;
  }
`;

export const StyledDropDown = styled(Dropdown)`
  width: 50%;
  padding-bottom: 0px;
`;

export const RadioGroupWithLabel = withLabel(RadioGroup);

export const StyledErrorLabel = styled(ErrorLabel)`
  padding-top: 2px !important;
`;

export const StyledRadioGroup = styled(RadioGroupWithLabel)`
  display: flex;
  flex-direction: column;
  & > div {
    ${ADMIN_TEXT_BODY_S_MEDIUM}
    color: ${ADMIN_TEXT_200};
    padding: 0px;
    margin-bottom: 0px;
  }
`;

export const ButtonsContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BackButton = styled(Button)`
  width: 165px;
  height: 40px;
  padding: 13px 20px;
  color: ${ADMIN_TEXT_100};
  background: ${ADMIN_SURFACE_1};
`;

export const SaveButton = styled(Button)`
  width: 165px;
  height: 40px;
  padding: 13px 20px;
`;

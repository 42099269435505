import Icon from 'components/admin2/Icon';
import { ADMIN_TEXT_100 } from 'style/constants';
import styled from 'styled-components';

export const ColorsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColorPickerIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_100(props),
}))`
  background: transparent;
  position: absolute;
  width: 14.46px;
  height: 13.79px;
  & svg {
    display: block;
    opacity: 1;
    width: 14.46px;
    height: 13.79px;
  }
`;

export const TransparentIcon = styled(Icon).attrs((props) => ({
}))`
  width: 100%;
  height: 100%;
  line-height: 0;
  & svg {
    width: 25.44px;
    height: 25.44px;
  }
`;

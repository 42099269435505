import React, { createContext, useCallback, useEffect, useState, useRef } from 'react';
import Papa from 'papaparse';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import useEffectAfterMount from 'hooks/use-effect-after-mount';
import { getPrimaryToken } from 'services/auth/selectors';
import { dismissModal } from 'services/modals';
import { showAdminErrorModal } from 'services/modals/actions';
import { useToast } from 'components/Toast';
import { ModalKinds } from 'services/modals/types';
import { getSiteId, getDefaultLanguage, getShowUserLanguagePicker } from 'services/app/selectors';
import locales from 'assets/locales.json';
import { useAdminTranslation } from 'hooks/use-translation';
import { getLocalCodes, downloadTemplateCSV, uploadLocaleJSON, deleteLocale, updateDefaultLanguage, updateUserLanguageSelection } from './api';
import { downloadCsvFile } from './utils';

const CSV_ID_HEADER = 'ID (do not edit)';
const CSV_TRANSLATION_HEADER = 'TRANSLATION';

export type ILocale = {
  language: string;
  locale: string;
};

export type ISupportedLanguages = ILocale & {
  isValid?: boolean;
};

interface ILocalizationTabContext {
  addNewLanguage: (newLanguage: ILocale) => void;
  defaultLanguage: ILocale;
  downloadCSV: (locale: ILocale['locale']) => void;
  emptyDefaultLanguage: ILocale;
  isUserLanguageSelectionEnabled: boolean;
  locales: ILocale[];
  removeLanguage: (locale: ILocale['locale'], isValid: boolean | undefined) => void;
  setDefaultLanguage: React.Dispatch<React.SetStateAction<ILocale>>;
  setIsUserLanguageSelectionEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  supportedLanguages: ISupportedLanguages[];
  uploadCSV: (locale: ILocale['locale'], isValid: boolean | undefined) => void;
  validLanguages: ILocale[];
}

export const LocalizationTabContext = createContext({} as ILocalizationTabContext);

export const LocalizationTabProvider: React.FC<{ children: any }> = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();
  const { showToast, renderToasts } = useToast();
  const emptyDefaultLanguage: ILocale = { locale: t('ADMIN_LOCALIZATION_DEFAULT_DROPDOWN_OPTION'), language: '' };

  const primaryToken = useSelector(getPrimaryToken)!;
  const siteId = useSelector(getSiteId);
  const initialUserLanguageSelection = useSelector(getShowUserLanguagePicker) ?? false;
  const defaultLanguageSelector = useSelector(getDefaultLanguage);
  const initialDefaultLanguage = locales.find(item => item.locale === defaultLanguageSelector) || emptyDefaultLanguage;

  const [defaultLanguage, setDefaultLanguage] = useState<ILocale>(initialDefaultLanguage);
  const [isUserLanguageSelectionEnabled, setIsUserLanguageSelectionEnabled] = useState<boolean>(initialUserLanguageSelection);
  const [supportedLanguages, setSupportedLanguages] = useState<ISupportedLanguages[]>([]);
  const [validLanguages, setValidLanguages] = useState<ILocale[]>([]);

  // used to keep track of when to toggle user language selection on i.e. when second language is added
  const prevCountValidLanguages = useRef(validLanguages.length);

  useEffect(() => {
    getLocalCodes({ siteId, primaryToken }).then((localCodes) => {
      const addedLocales = localCodes.map(localeCode => {
        const language = locales.find(item => item.locale === localeCode)?.language || '';
        return {
          locale: localeCode,
          language,
          isValid: true,
        };
      });
      setSupportedLanguages(addedLocales);
    }).catch(() => {
      dispatch(showAdminErrorModal('ADMIN_ERROR_GENERIC'));
    });
}, [siteId, primaryToken]);

  // since initial values for userLanguageSelection and defaultLanguage are coming from redux/BE, don't need to make a call to update BE on mount
  useEffectAfterMount(() => {
    try {
      updateUserLanguageSelection({ siteId, primaryToken, userSelectionEnabled: isUserLanguageSelectionEnabled });
    } catch (e) {
      dispatch(showAdminErrorModal('ADMIN_ERROR_GENERIC'));
      // reset to original toggle state
      setIsUserLanguageSelectionEnabled(!isUserLanguageSelectionEnabled);
    }
  }, [isUserLanguageSelectionEnabled, siteId, primaryToken]);

  useEffectAfterMount(() => {
    try {
      if (defaultLanguage?.locale && defaultLanguage.locale !== emptyDefaultLanguage.locale) {
        updateDefaultLanguage({ siteId, primaryToken, defaultLanguage: defaultLanguage.locale });
      } else {
        updateDefaultLanguage({ siteId, primaryToken, defaultLanguage: null });
      }
    } catch (e) {
      dispatch(showAdminErrorModal('ADMIN_ERROR_GENERIC'));
      // reset dropdown state to default None
      setDefaultLanguage(emptyDefaultLanguage);
    }
  }, [defaultLanguage, siteId, primaryToken]);

  const addNewLanguage = useCallback(
    (newLanguage: ILocale) => {
      if (supportedLanguages?.find((item) => item.locale === newLanguage.locale)) return;
      setSupportedLanguages((old) => [
        ...old,
        { ...newLanguage, isValid: false },
      ]);
    },
    [supportedLanguages],
  );

  const removeLanguage = useCallback((localeCode, isValid) => {
    if(!isValid) {
      setSupportedLanguages((languages) => languages?.filter((item) => item.locale !== localeCode ));
      dispatch(dismissModal(ModalKinds.adminConfirmation));
      return;
    }

    try {
      deleteLocale({ siteId, primaryToken, localeCode });
      if (defaultLanguage?.locale === localeCode) setDefaultLanguage(emptyDefaultLanguage);
      setSupportedLanguages((languages) => languages?.filter((item) => item.locale !== localeCode ));
      dispatch(dismissModal(ModalKinds.adminConfirmation));
    } catch (e) {
      dispatch(dismissModal(ModalKinds.adminConfirmation));
      dispatch(showAdminErrorModal('ADMIN_ERROR_GENERIC'));
      // if error, add back lanuage to list if already removed
      setSupportedLanguages((languages) => {
        if (languages?.find((item) => item.locale === localeCode)) return languages;
        return [...languages, { ...locales.find((item) => item.locale === localeCode)!, isValid }];
      });
    }
  }, [siteId, primaryToken, defaultLanguage]);

  const uploadCSV = useCallback<ILocalizationTabContext['uploadCSV']>(
    (localeCode, isValid) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.csv';

      input.onchange = () => {
        const file = input.files?.[0];
        if (!file) return;

        Papa.parse<{ [CSV_ID_HEADER]: string, [CSV_TRANSLATION_HEADER]: string }>(
          file,
          {
            header: true,
            skipEmptyLines: true,
            complete: (parsedCsv) => {
              try {
                const csvRows = parsedCsv.data; // data is array of objects with key:header and value:field
                const csvHeaders = parsedCsv?.meta?.fields;
                const csvErrors = parsedCsv?.errors;

                // tslint:disable-next-line no-console
                if (!isEmpty(csvErrors)) console.error('encountered error/s parsing some rows', csvErrors);

                // validate headers we need for parsing are present (order and other headers don't matter)
                if (!csvHeaders?.includes(CSV_ID_HEADER) || !csvHeaders.includes(CSV_TRANSLATION_HEADER)) {
                  // TODO: add specific error message modal
                  dispatch(showAdminErrorModal('ADMIN_ERROR_CSV_FILE_UPLOAD'));
                  return;
                }

                const jsonToUpload = csvRows.reduce(
                  (res, row) => {
                    const id = row?.[CSV_ID_HEADER];
                    const translation = row?.[CSV_TRANSLATION_HEADER];

                    if (id && translation) {
                      res[id] = translation;
                    }
                    return res;
                  },
                  {} as Record<string, string>,
                );

                uploadLocaleJSON({ siteId, primaryToken, localeCode, file: jsonToUpload });

                if (!isValid) {
                  setSupportedLanguages(
                    (languages) => languages.map(
                      (item) => (
                        item.locale === localeCode
                          ? { ...item, isValid: true }
                          : item
                      ),
                    ),
                  );
                }

                showToast({ content: t('ADMIN_LOCALIZATION_FILE_UPLOAD_SUCCESS') });
              } catch (e) {
                dispatch(showAdminErrorModal('ADMIN_ERROR_GENERIC'));
                // return state to have missing strings as upload failed i.e. if not valid language
                if (!isValid) {
                  setSupportedLanguages(
                    (languages) => languages.map(
                      (item) => (
                        item.locale === localeCode
                          ? { ...item, isValid: false }
                          : item
                      ),
                    ),
                  );
                }
              } finally {
                input.remove();
              }
            },
          },
        );
      };

      input.click();
    },
    [siteId, primaryToken],
  );

  const downloadCSV = useCallback(async (localeCode: ILocale['locale']) => {
    try {
      const csvString = await downloadTemplateCSV({ siteId, primaryToken, localeCode });
      if (csvString) downloadCsvFile(csvString, `${localeCode}.csv`);
    } catch (e) {
      dispatch(showAdminErrorModal('ADMIN_ERROR_GENERIC'));
    }
  }, [siteId, primaryToken]);

  useEffect(() => {
    const filteredValidLanguages = supportedLanguages?.reduce((result: ILocale[], lang: ISupportedLanguages) => {
      if (lang?.isValid) {
        result.push({ locale: lang?.locale, language: lang?.language });
      }
      return result;
    }, []);
    setValidLanguages(filteredValidLanguages);
  }, [supportedLanguages]);

  useEffect(() => {
    const currentCountValidlanguages = validLanguages.length;
    if (currentCountValidlanguages === 1) setIsUserLanguageSelectionEnabled(false);
    if (prevCountValidLanguages.current === 1 && currentCountValidlanguages === 2) {
      setIsUserLanguageSelectionEnabled(true);
    }
    prevCountValidLanguages.current = currentCountValidlanguages;
  }, [validLanguages.length]);

  return (
    <LocalizationTabContext.Provider
      value={{
        addNewLanguage,
        defaultLanguage,
        downloadCSV,
        emptyDefaultLanguage,
        isUserLanguageSelectionEnabled,
        locales,
        removeLanguage,
        setDefaultLanguage,
        setIsUserLanguageSelectionEnabled,
        supportedLanguages,
        validLanguages,
        uploadCSV,
      }}
    >
      {children}
      {renderToasts()}
    </LocalizationTabContext.Provider>
  );
};

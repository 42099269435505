import styled from 'styled-components';
import DefaultIcon from  'components/ui/Icon';
import DefaultDropdown from 'components/admin2/ui/Dropdown';
import { SearchInput } from 'components/admin2/ui/Dropdown/styles';
import { OptionContainer } from 'components/admin2/ui/Dropdown/styles';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
} from 'style/constants';
import {
  ADMIN_ICON_STANDARD,
  TEXT_ELLIPSIS,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const iconWidth = 36;

export const Dropdown: typeof DefaultDropdown = styled(DefaultDropdown)`
  & ${OptionContainer} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:after {
      content: "";
      flex: 1;
    }
  }
`;

export const Result = styled.div<{isActive?: boolean}>`
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 0;
`;

export const Icon = styled(DefaultIcon).attrs({
  admin: true,
})<{isActive?: boolean}>`
  ${ADMIN_ICON_STANDARD}
  width: ${iconWidth}px;
  height: ${iconWidth}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: ${({ isActive }) => isActive ? 2 : 0}px solid ${ADMIN_ACCENT_PRIMARY};
  color: ${ADMIN_TEXT_100};
  flex-shrink: 0;
  flex-grow: 0;
`;

export const IconName = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  ${TEXT_ELLIPSIS}
  padding-right: 30px;
  display: block;
`;

export const InnerSearchInput = styled(SearchInput as any)`
  padding-left: 25px;
` as typeof SearchInput;

import React, { MouseEventHandler, PropsWithChildren } from 'react';
import withLabel from 'components/core/withLabel';
import withPadding from 'components/core/withPadding';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import TranslatedText from 'components/i18n/TranslatedText';
import Image from 'components/ui/Image';
import { Actions } from 'components/admin2/ActionContainer';
import { Button } from 'components/admin2/ui/Button';
import {
  Container,
  ActionLabelContainer,
  InnerLabel,
  StyledDescription,
  StyledActionContainer,
  StyleProps,
} from './styles';
import type { TranslationKey } from 'hooks/use-translation';

interface ActionLabelProps extends Actions, StyleProps {
  bottomDescription?: string;
  bottomDescriptionKey?: TranslationKey;
  className?: string;
  image?: string;
  innerIcon?: string;
  innerLabel?: string;
  innerLabelColor?: string;
  innerLabelKey?: string;
  innerLabelTextSize?: string;
  onSelect?: MouseEventHandler;
  onUse?: MouseEventHandler;
  testIdEdit?: string;
}

function ActionLabel({
  bottomDescription,
  bottomDescriptionKey,
  children,
  className,
  image,
  innerIcon,
  innerLabel,
  innerLabelColor,
  innerLabelKey,
  innerLabelTextSize,
  isActive,
  isDisabled,
  onAdd,
  onBroadcast,
  onDelete,
  onEdit,
  onPreview,
  onRemove,
  onSelect,
  onUse,
  testIdEdit,
  ...props
}: PropsWithChildren<ActionLabelProps>) {
  const { isLarge = !!image } = props;
  return (
    <Container
      className={className}
      hasDescription={!!(bottomDescription || bottomDescriptionKey)}
      isActive={isActive}
      onClick={onSelect}
    >
      <ActionLabelContainer
        isActive={isActive}
        isDisabled={isDisabled}
        isLarge={isLarge}
      >
      {image && <Image spacing="10px 10px 10px 0" src={image} variant="circle" />}
        <InnerLabel
          color={innerLabelColor}
          icon={image ? undefined : innerIcon}
          label={innerLabel}
          labelKey={innerLabelKey}
          textSize={innerLabelTextSize}
        />
        {children}
        <StyledActionContainer
          onAdd={onAdd}
          onBroadcast={onBroadcast}
          onDelete={onDelete}
          onEdit={onEdit}
          onPreview={onPreview}
          onRemove={onRemove}
          testIdEdit={testIdEdit}
        >
          {onUse && (
            <Button onClick={onUse}>
              <TranslatedText stringKey="ADMIN_LABEL_USE" />
            </Button>
          )}
        </StyledActionContainer>
      </ActionLabelContainer>
      {(bottomDescription || bottomDescriptionKey) && (
        <OptionalTranslated component={StyledDescription} stringKey={bottomDescriptionKey}>
          {bottomDescription}
        </OptionalTranslated>
      )}
    </Container>
  );
}

export default withPadding(withLabel(ActionLabel), '5px 0');

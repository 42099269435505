import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_SURFACE_2, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_1, ADMIN_TEXT_100 } from 'style/constants';
import withTooltip from 'components/core/withTooltip';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  column-gap: 5px;
  padding: 4px;
`;

export const FilledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${ADMIN_SURFACE_2};
  border-radius: 5px;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Header = styled.div<{ withPadding?: boolean }>`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_200};
  margin-bottom: 5px;
  ${({ withPadding }) =>
    withPadding &&
    css`
      padding-right: 42px;
    `}
`;

export const Footer = styled.div<{ shouldPaint?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px 8px;
  border-radius: 5px;
  background-color: transparent;
  ${({ shouldPaint }) =>
    shouldPaint &&
    css`
      background-color: ${ADMIN_SURFACE_1};
      padding: 6px;
    `}
`;

export const ThemeName = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_200};
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 30px);
  max-width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ThreeDotsIcon = styled(Icon).attrs({
  name: 'threeDots',
})``;

export const CloseIcon = styled(Icon).attrs({
  name: 'delete',
})`
  padding: 3px;
  & > svg {
    width: 8px;
    height: 8px;
    & > path {
      fill: ${ADMIN_TEXT_100};
    }
  }
`;

export const CustomizeButton = styled.button`
  background: ${ADMIN_ACCENT_PRIMARY};
  width: 20px;
  height: 20px;
  &:hover {
    background: ${ADMIN_SURFACE_1};
  }
  border-radius: 4.17px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionsButton = styled.button<{ settingsOpen: boolean }>`
  ${({ settingsOpen }) => settingsOpen && css`background: ${ADMIN_SURFACE_1};`};
  &:hover {
    background: ${ADMIN_SURFACE_1};
  }
  width: 30px;
  cursor: pointer;
  border: none;
  border-radius: 6.21px;
`;

export const CustomizeIcon = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'adminbarEditor',
  }))`
  width: 13.33px;
  height: 13.33px;
    & > svg {
      width: 13.33px;
      height: 13.33px;
      padding-bottom: 1px;
      padding-left: 1px;
      & > path {
        fill: ${ADMIN_TEXT_100};
      }
    }
  `,
  {
    admin: true,
  },
);

import React from 'react';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import { getActiveSiteQuestActions } from 'services/app/selectors';
import { useSelector } from 'react-redux';
import { QuestActions } from 'services/feature-gate/constants';
import camelCase from 'lodash/camelCase';
import styled from 'styled-components';
import AdminModal from 'components/admin2/ui/AdminModal';
import TranslatedText from 'components/i18n/TranslatedText';
import { DIV_BUTTON } from 'style/mixins';
import { ADMIN_SURFACE_4 } from 'style/constants';
import {
  ACTIONS,
} from './constants';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Container = styled.div`
`;

const Grid = styled.div`
  display: flex;
`;

const Col = styled.div`
  flex: 1;
`;

const Action = styled.div`
  height: 87px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${DIV_BUTTON};
  &:hover {
    background-color: ${ADMIN_SURFACE_4};
  }
`;

const Text = styled.span`
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`;

const gatedActions = Object.values(QuestActions);

const getChunkedActions = (activeGatedActions) => {
  const actions = ACTIONS.filter((action) => {
    const kind = camelCase(action.kind);
    if (gatedActions.includes(kind)) {
      return activeGatedActions[kind];
    }
    return true;
  });
  return chunk(actions, 5);
};

const ActionPicker = ({
  onClose,
  onClick,
}) => {
  const activeGatedQuestActions = useSelector(getActiveSiteQuestActions);
  const chunkedActions = getChunkedActions(activeGatedQuestActions);
  return (
    <AdminModal fixedDimensions onClose={onClose}>
      <Container>
        <Grid>
          {
            chunkedActions.map(actionArray => (
              <Col key={actionArray[0].stringKey}>
                {
                  actionArray.map(({ stringKey, kind }) => (
                    <Action key={stringKey} onClick={() => onClick(kind)}>
                      <TranslatedText component={Text} stringKey={stringKey} />
                    </Action>
                  ))
                }
              </Col>
            ))
          }
        </Grid>
      </Container>
    </AdminModal>
  );
};

ActionPicker.propTypes = propTypes;

export default ActionPicker;

import React from 'react';
import moment from 'moment-timezone';
import startCase from 'lodash/startCase';
import TranslatedText from 'components/i18n/TranslatedText';
import { DESC_KEY } from 'components/admin2/MediaLibrary/constants';
import LibraryModal from 'components/admin2/LibraryModal';
import AdminModal from 'components/admin2/ui/AdminModal';
import PollEditor from 'components/admin2/PollEditor';
import { camelify } from 'shared/string-utils';
import { injectT, withT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';
import { POLL_SERVICE_BASE_URL } from 'config';

const pollLibraryColumns = injectT(
  (t) => lazy(
    () => [
      {
        accessor: 'name',
        isTitle: true,
        minWidth: 120,
        title: t('NAME'),
        transformValue: value => value || (<TranslatedText stringKey="ADMIN_LABEL_UNTITLED_POLL" />),
      },
      {
        accessor: 'question',
        title: t('ADMIN_LABEL_QUESTION'),
        width: 290,
      },
      {
        accessor: 'open',
        title: t('ADMIN_CHANNEL_LIBRARY_IS_OPEN'),
        transformValue: value => value.toString(),
        width: 80,
      },
      {
        accessor: 'modified',
        sort: DESC_KEY,
        title: t('ADMIN_CHANNEL_LIBRARY_LAST_MODIFIED'),
        transformValue: value => moment(value).fromNow(),
        width: 140,
      },
      {
        accessor: 'type',
        title: 'type',
        transformValue: value => startCase(value),
      },
    ],
  ),
);

export const DEFAULT_POLL_DOC = {
  collection: 'polls',
  data: {
    kind: 'two_option_poll',
    showNumbers: false,
  },
  type: 'poll',
};

const FRESH_DOC = {
  collection: 'polls',
  data: {},
  fromOverlay: true,
};

const setEditingType = injectT(
  (t) => (type) => {
    const pollDoc = { ...FRESH_DOC };
    switch (type) {
      case 'poll':
        pollDoc.data = {
          kind: 'two_option_poll',
          name: t('LABEL_NEW_POLL'),
          title: t('LABEL_NEW_POLL'),
        };
        break;
      case 'prediction':
        pollDoc.data = {
          kind: 'two_option_prediction',
          name: t('LABEL_NEW_PREDICTION'),
          status: {
            kind: 'open',
          },
          title: t('LABEL_NEW_PREDICTION'),
        };
        break;
      case 'trivia':
        pollDoc.data = {
          kind: 'two_option_trivia',
          name: t('LABEL_NEW_TRIVIA'),
          title: t('LABEL_NEW_TRIVIA'),
        };
        break;
      default:
        return DEFAULT_POLL_DOC;
    }
    pollDoc.type = type;
    return pollDoc;
  },
);

export default withT(
  class PollLibraryModal extends React.Component {
    state = {
      editingPoll: null,
    };

    static defaultProps = {
      onClose: () => null,
      type: 'poll',
    };

    selectItem = (value) => {
      const { onClose, onSelectItem } = this.props;
      onSelectItem(value);
      onClose();
    };

    handleEditItem = (editingPoll) => {
      this.setState({ editingPoll: camelify(editingPoll) });
    };

    render() {
      const { onClose, type, t } = this.props;
      const { editingPoll } = this.state;
      const buttonProps = {
        labelKey: 'ADMIN_LABEL_NEW_QUESTION',
        onClick: () => this.setState({
          editingPoll: setEditingType(t, type),
        }),
      };

      return (
        <>
          <LibraryModal
            buttonProps={buttonProps}
            columns={pollLibraryColumns(t)}
            fetchObject={{
              service: POLL_SERVICE_BASE_URL,
            }}
            helperText="ADMIN_LABEL_MULTIPLE_CHOICE_DESCRIPTION"
            onClose={onClose}
            onEditItem={this.handleEditItem}
            onSelectItem={this.selectItem}
            params={{ type }}
            searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH_FOR_A_QUESTION"
            titleKey="ADMIN_PANEL_MULTIPLE_CHOICE_NAME"
          />
          {editingPoll && (
            <AdminModal fixedHeight fixedWidth maxHeight="90%" maxWidth="500px" onClose={() => this.setState({ editingPoll: null })}>
              <PollEditor
                initialDoc={editingPoll}
                onClose={() => this.setState({ editingPoll: null })}
                onSave={() => this.setState({ editingPoll: null })}
                type={type}
              />
            </AdminModal>
          )}
        </>
      );
    }
  },
);

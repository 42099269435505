import { ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_M_BOLD, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';
import { Radio } from 'components/admin2/ui/Radio';
import { LabelText } from 'components/admin2/ui/Checkbox/styles';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
`;

export const Title = styled.h1`
  all: unset;
  ${ADMIN_TEXT_LABEL_M_BOLD}
  color: ${ADMIN_TEXT_100};
`;

export const Description = styled.h1`
  all: unset;
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  color: ${ADMIN_TEXT_200};
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const StyledRadio = styled(Radio)`
  & ${LabelText} {
    ${ADMIN_TEXT_LABEL_M_MEDIUM};
  }
`;

import styled, { css } from 'styled-components';
import Button from 'components/admin2/ui/Button';
import { ProfilePanelButton } from 'components/ui/Button/styles';
import { ADMIN_SURFACE_1, ADMIN_TEXT_300, ADMIN_TEXT_200, TEXT_100, ADMIN_TEXT_100, ADMIN_SURFACE_5 } from 'style/constants';
import { BACKGROUND_DEFAULT, BACKGROUND_DEFAULT_LIGHTEN } from 'style/mixins';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export interface ExpandableCardStyleProps {
  collapsedCardHeight?: string;
  disableButtonVariantChange?: boolean;
}

export const ChildrenContainer = styled.div`
  ${({ hidden }) => hidden && 'display: none;'}
`;

export const DefaultButton = styled(ProfilePanelButton)`
  min-width: 70px;
`;

export const AdminButton = styled(Button as any)`
  min-width: 70px;
  height: 28px;
`;

export const HoverButton = styled(DefaultButton as any).attrs({
  variant: 'border-only',
})`` as typeof Button;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  min-width: 0;
`;

export const Title = styled.div<{ showForAdmin?: boolean }>`
  ${ADMIN_TEXT_BODY_S_BOLD}
  color: ${TEXT_100};
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  white-space: nowrap;
  max-width: 100%;
  ${({ showForAdmin }) =>
    showForAdmin
      ? css`
          color: ${ADMIN_TEXT_200};
        `
      : ''}
`;

export const Subtitle = styled.div<{ isPrimary?: boolean }>`
  display: flex;
  &,
  & * {
    ${ADMIN_TEXT_BODY_XS_MEDIUM}
  }
  color: ${ADMIN_TEXT_300};
  align-items: center;
`;

export const SubtitleText = styled.pre<{ showForAdmin?: boolean }>`
  margin: 0;
  padding: 0;
  max-width: 98px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ showForAdmin }) =>
    showForAdmin
      ? css`
          color: ${ADMIN_TEXT_300};
        `
      : ''}
`;

export const CardWrapper = styled.div<
  { height: number; isExpanded?: boolean; showForAdmin?: boolean; variant?: string } & ExpandableCardStyleProps
>`
  ${({ showForAdmin }) =>
    showForAdmin
      ? css`
          background-color: ${ADMIN_SURFACE_5};
          color: ${ADMIN_TEXT_100};
        `
      : css`
          ${BACKGROUND_DEFAULT}
        `}
  border-radius: 5px;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: height 0.5s;
  height: ${({ collapsedCardHeight = '70px' }) => collapsedCardHeight};
  ${({ height, isExpanded }) =>
    isExpanded &&
    `
    height: ${height}px;
  `}
  ${({ variant, showForAdmin }) =>
    variant === 'secondary' && showForAdmin
      ? css`
          background-color: ${ADMIN_SURFACE_1};
          color: white;
        `
      : variant === 'secondary' &&
        css`
          ${BACKGROUND_DEFAULT_LIGHTEN}
        `}
  & ${Subtitle} {
    ${({ showForAdmin }) =>
      !showForAdmin
        ? css`
            color: ${TEXT_100};
            opacity: 0.6;
          `
        : css`
            color: ${ADMIN_TEXT_200};
          `}
  }
`;

export const CardContainer = styled.div<{ isExpanded?: boolean } & ExpandableCardStyleProps>`
  width: 100%;
  display: flex;
  ${({ isExpanded }) =>
    isExpanded
      ? `
    flex-direction: column;
    padding: 15px;
  `
      : `
    align-items: center;
    padding: 0 15px;
  `}
  min-height: ${({ collapsedCardHeight = '70px' }) => collapsedCardHeight};
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
`;

import styled from 'styled-components';
import AdminBarTitle from 'components/admin2/AdminBarTitle';
import Link from 'components/ui/Link';
import {
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
  SPACING_MEDIUM,
} from 'style/constants';
import { ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';

export const StyledAdminBarTitle = styled(AdminBarTitle)`
  margin-bottom: 8px;
`;

export const Label = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  margin-bottom: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 5px;
  margin-bottom: ${SPACING_MEDIUM};
`;

export const HelperText = styled.div`
  line-height: 24px;
  color: ${ADMIN_TEXT_200};
`;

export const UploadBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UploadItem = styled.div`

  margin: 0 5px 10px 5px;
`;

export const StyledLink = styled(Link)`
  margin-bottom: ${SPACING_MEDIUM};
`;

export const MoreInfoLinkText = styled.span`
   &&{
    ${ADMIN_TEXT_LABEL_L_MEDIUM}
    margin-bottom: ${SPACING_MEDIUM};
    opacity: 0.5;
    text-decoration: underline !important;
    color: ${ADMIN_TEXT_100};
  }
`;

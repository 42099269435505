// tslint:disable:member-ordering
import React, { useEffect, useMemo, useState } from 'react';
import withLabel from 'components/core/withLabel';
import withPadding from 'components/core/withPadding';
import hash from 'json-stable-stringify';
import { padZero } from 'shared/string-utils';
import {
  to12Hours,
  to24Hours,
  HOUR_OPTIONS_12,
  MINUTE_OPTIONS,
  PERIOD_OPTIONS,
  getMinuteOptionsFractionated,
} from 'shared/datetime-utils';
import { TimeContainer, TimeDropdown, PeriodDropdown } from './styles';
import { TIME_INPUT, TIME_INPUT_ITEM } from 'css-classes';

interface  TimeInputComponentProps {
  disabled?: boolean;
  fullwidth?: boolean;
  minutesFraction?: number;
  onChange?(time: Time): void;
  showSeconds?: boolean;
  time?: Time;
}

export interface Time {
  hour: number;
  minute: number;
  second: number;
}

function TimeInputComponent({
  disabled,
  onChange,
  showSeconds,
  time = {} as Time,
  minutesFraction = 1,
}: TimeInputComponentProps) {
  const { hour, minute, second } = useMemo(() => ({
    hour: time.hour || 0,
    minute: time.minute || 0,
    second: time.second || 0,
  }), [hash(time)]);

  const minuteOptions = useMemo(() => getMinuteOptionsFractionated(minutesFraction), [minutesFraction]);

  const [period, setPeriod] = useState({ label: 'AM', value: 'am' });

  const changeHour = (option: Record<string, string | number>) => {
    onChange?.({
      hour: to24Hours(Number(option.value) || 0, period.value),
      minute,
      second,
    });
  };

  const changeTime = (timeUnit: string) => (option: Record<string, string | number>) => {
    onChange?.({
      hour,
      minute,
      second,
      [timeUnit]: Number(option.value) || 0,
    });
  };

  const changePeriod = (option: Record<string, string | number>) => {
    setPeriod(option as any);
  };

  const hourValue = useMemo(() => {
    const twelve = to12Hours(hour);
    if (twelve.period !== period.value) {
      setPeriod({ label: twelve.period.toUpperCase(), value: twelve.period });
    }
    return {
      label: padZero(twelve.hour),
      value: twelve.hour,
    };
  }, [hour]);

  const minuteValue = useMemo(() => ({
    label: padZero(minute),
    value: minute,
  }), [minute]);

  const secondValue = useMemo(() => ({
    label: padZero(second),
    value: second,
  }), [second]);

  useEffect(() => {
    changeHour(hourValue);
  }, [period.value]);

  return (
    <TimeContainer data-testid="timeDropdownMenus" className={TIME_INPUT}>
      <TimeDropdown
        isDisabled={disabled}
        onChange={changeHour}
        options={HOUR_OPTIONS_12}
        value={hourValue}
        className={TIME_INPUT_ITEM}
      />:
      <TimeDropdown
        isDisabled={disabled}
        onChange={changeTime('minute')}
        options={minuteOptions}
        value={minuteValue}
        className={TIME_INPUT_ITEM}
      />
      {showSeconds && (
      <>
        :<TimeDropdown
          isDisabled={disabled}
          onChange={changeTime('second')}
          options={MINUTE_OPTIONS}
          value={secondValue}
          className={TIME_INPUT_ITEM}
        />
      </>
      )}
      <PeriodDropdown
        isDisabled={disabled}
        onChange={changePeriod}
        options={PERIOD_OPTIONS}
        value={period}
      />
    </TimeContainer>
  );
}

export default withPadding(withLabel(TimeInputComponent), '6px 0');

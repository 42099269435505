import React,{ memo } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { ErrorContainer, WarningIcon } from './styles';

interface IProps{
  errorMessageKey: string
}

// TODO:make more flexible this component;
const ErrorMessage = ({ errorMessageKey }:IProps)=>{
  return(
    <ErrorContainer>
       <WarningIcon />
          <TranslatedText stringKey={errorMessageKey} />
    </ErrorContainer>
  );
};

export default memo(ErrorMessage);

import React from 'react';
import styled from 'styled-components';
import Label from 'components/admin2/ui/Label';
import TextInput from 'components/admin2/TextInput';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import {
  UploadZoneWithoutPadding,
  IMAGE_ACCEPTED_TYPES,
  UploadZoneProps,
} from 'components/admin2/UploadZone';
import { useAdminTranslation } from 'hooks/use-translation';

interface ISEOFieldsProps extends Pick<UploadZoneProps, 'imagePreview' | 'onClearImage' | 'onFileSubmit'> {
  description: string;
  disableSameAsCardToggle: boolean;
  isImageSameAsCard: boolean;
  isLandingPage: boolean;
  keywords: string;
  onDescriptionChange: (text: string) => void;
  onKeywordsChange: (text: string) => void;
  onSameAsCardChange: (checked: boolean) => void;
  onTitleChange: (text: string) => void;
  title: string;
}

export default function({
  description,
  disableSameAsCardToggle,
  imagePreview,
  isImageSameAsCard,
  isLandingPage,
  keywords,
  title,
  onClearImage,
  onDescriptionChange,
  onFileSubmit,
  onKeywordsChange,
  onSameAsCardChange,
  onTitleChange,
}: ISEOFieldsProps) {
  const { t } = useAdminTranslation();

  return (
    <SEOFields>
      <Label
        descriptionKey="ADMIN_INFO_PAGE_SEO"
        labelKey="ADMIN_LABEL_SEARCH_ENGINE_OPTIMIZATION_SEO"
      />
      <TextInput
        descriptionKey={`ADMIN_SEO_TITLE_SUBTEXT`}
        labelKey="ADMIN_LABEL_TITLE"
        onChange={onTitleChange}
        padding="0"
        inputTestId="channelTitle"
        placeholderText={t('ADMIN_SEO_CHANNEL_TITLE')}
        value={title}
      />
      <TextInput
        descriptionKey={`${isLandingPage ? 'PAGE' : 'CHANNEL'}_DESCRIPTION_FIELD_DESCRIPTION`}
        labelKey="ADMIN_LABEL_DESCRIPTION"
        onChange={onDescriptionChange}
        padding="0"
        inputTestId="channelDescription"
        placeholderText={t('ADMIN_SEO_CHANNEL_DESC')}
        value={description}
      />
      <TextInput
        descriptionKey="ADMIN_LABEL_KEYWORDS_FIELD_DESCRIPTION"
        labelKey="ADMIN_LABEL_KEYWORDS"
        onChange={onKeywordsChange}
        padding="0"
        inputTestId="channelKeywords"
        placeholderText={t('ADMIN_SEO_CHANNEL_KEYWORDS_PLACEHOLDER')}
        value={keywords}
      />
      <ImageUploadZoneDescription>
        <LabelToggle
          checked={!disableSameAsCardToggle && isImageSameAsCard}
          data-testid="channelImageRequiredToggle"
          disabled={disableSameAsCardToggle}
          labelKey="ADMIN_LABEL_IMAGE"
          onChange={onSameAsCardChange}
          padding="0"
        />
        <Label descriptionKey={`ADMIN_LABEL_${isLandingPage ? 'PAGE' : 'CHANNEL'}_IMAGE_UPLOAD_DESCRIPTION`} />
        <Label descriptionKey="ADMIN_LABEL_PAGE_SETTINGS_SAME_IMAGE_TOGGLE" />
      </ImageUploadZoneDescription>
      {(disableSameAsCardToggle || !isImageSameAsCard) && (
        <ImageUploadZone
          imagePreview={imagePreview}
          onClearImage={onClearImage}
          onFileSubmit={onFileSubmit}
          testIdThumbnail="channelImage"
        />
      )}
    </SEOFields>
  );
}

const SEOFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ImageUploadZoneDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageUploadZone = styled(UploadZoneWithoutPadding).attrs(() => ({
  acceptedTypes: IMAGE_ACCEPTED_TYPES,
  canDrop: true,
  dimensionRequirements: {
    exactDimensions: true,
    pixelHeight: 640,
    pixelWidth: 360,
  },
  height: 172,
  withWrapper: true,
}))``;

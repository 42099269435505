import React, { useEffect } from 'react';
import JsonDebug from 'components/dev/JsonDebug';
import {
  TIPPING_MESSAGE_MAX_LENGTH,
  TIP_THANK_YOU_MESSAGE,
  THANK_YOU_MESSAGE,
} from './constants';
import {
  Active,
  CharCounter,
  Container,
  MessageContainer,
  MessageLabel,
  StyledTextArea,
  TipLabel,
} from './styles';
import { ITippingPanelProps } from 'components/panels/TippingPanel/view';

interface ITippingPanelEditorProps {
  onChange: (obj: ITippingPanelProps['renderer']) => void;
  renderer: ITippingPanelProps['renderer'];
}

const TippingPanelEditor = ({ renderer, onChange }: ITippingPanelEditorProps) => {
  const {
    [TIP_THANK_YOU_MESSAGE]: tipThankYouMessage,
  } = renderer;

  useEffect(() => {
    // When creating a new tipping panel, the message is undefined, so we set it to the default value.
    if (typeof renderer.tipThankYouMessage === 'undefined') {
      onChange({
        ...renderer,
        [TIP_THANK_YOU_MESSAGE]: THANK_YOU_MESSAGE,
        panelName: 'Tips',
      });
    }
  }, [renderer]);

  const handleChangeMessage = (value: string) => {
    const message = value.slice(0, TIPPING_MESSAGE_MAX_LENGTH);
    onChange({
      ...renderer,
      [TIP_THANK_YOU_MESSAGE]: message,
    });
  };

  return (
    <Container data-testid="tippingPanelEditorWrapper">
      <MessageLabel data-testid="tippingPanelEditorThankYouLabel" labelKey="ADMIN_LABEL_THANK_YOU" descriptionKey="ADMIN_LABEL_THANK_YOU_HINT" />
      <MessageContainer>
        <StyledTextArea
          data-testid="tippingPanelEditorTextarea"
          onChange={handleChangeMessage}
          value={tipThankYouMessage}
        />
        <CharCounter><Active>{String(tipThankYouMessage).length}/</Active>{TIPPING_MESSAGE_MAX_LENGTH}</CharCounter>
      </MessageContainer>
      <TipLabel data-testid="tippingPanelEditorNoRefundsLabel" descriptionKey="ADMIN_LABEL_TIP_NO_REFUNDS" icon="info" />
      <JsonDebug value={renderer} />
    </Container>
  );
};

export default TippingPanelEditor;

/* tslint:disable: jsx-boolean-value */
import React, { ButtonHTMLAttributes, MouseEventHandler, ComponentProps, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import AdminButton, { ButtonProps } from 'components/admin2/ui/Button';
import { BUTTON_MAP, StyledIcon, ButtonLabel } from './styles';
import { ADMIN_ACCENT_PRIMARY } from 'style/constants';
import type { TranslationKey } from 'hooks/use-translation';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonProps {
  alwaysRenderText?: boolean;
  button?: typeof AdminButton;
  children?: string;
  'data-testid'?: string;
  icon?: string;
  iconSize?: number;
  iconSpacing?: number;
  kind?: string;
  labelKey?: TranslationKey;
}

export default function IconButton({
  alwaysRenderText,
  button,
  children,
  color,
  'data-testid': testId,
  iconSize,
  iconSpacing,
  labelKey,
  icon,
  kind = 'default',
  ...props
}: IconButtonProps & ComponentProps<typeof AdminButton>) {
  const Button = useMemo(() => button || BUTTON_MAP[kind] || BUTTON_MAP.default, [kind, button]);

  const [isTextOverflowing, setTextOverflowing] = useState(false);

  const checkTextOverflow = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setTextOverflowing(node.clientWidth < node.scrollWidth);
    }
  }, []);

  const hideText = !alwaysRenderText && isTextOverflowing;
  return (
    <Button data-testid={testId} {...props}>
      {icon && (
        <StyledIcon
          iconSize={iconSize}
          isTextOverflowing={hideText}
          name={icon}
          spacing={iconSpacing}
        />
      )}
      <OptionalTranslated
        ref={checkTextOverflow}
        color={color}
        component={ButtonLabel}
        isTextOverflowing={hideText}
        stringKey={labelKey}
      >
        {children}
      </OptionalTranslated>
    </Button>
  );
}

export const BorderButton = styled(IconButton).attrs(props => ({
  icon: 'plusCircle',
  iconSpacing: 12,
  kind: 'border',
  hoverBackground: ADMIN_ACCENT_PRIMARY(props),
  ...props,
}))``;

export function AddButton({ children, onMouseEnter, onMouseLeave, ...props }: IconButtonProps) {
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    setHovering(true);
    onMouseEnter?.(e);
  }, [onMouseEnter]);

  const handleMouseLeave: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    setHovering(false);
    onMouseLeave?.(e);
  }, [onMouseLeave]);

  return (
    <BorderButton
      {...props}
      fullwidth
      kind={hovering ? 'large' : 'border'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </BorderButton>
  );
}

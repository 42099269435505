import TranslatedText from 'components/i18n/TranslatedText';
import React, { PropsWithChildren } from 'react';
import parse from 'html-react-parser';
import { IProduct } from 'services/shopify/models';
import { Dropdown } from 'components/admin2/ui/Dropdown';
import {
  ConnectStoreButton,
  Content,
  HelpText,
  PlusIcon,
  ProductListWrapper,
  SearchWrapper,
  ShoppingBadIcon,
  StyledSearchInput,
  Title,
  TitleWrapper,
  Wrapper,
  NoContent,
  ContentHeader,
  SubmitButton,
} from './styles';
import Product from './Product';
import { useAdminTranslation } from 'hooks/use-translation';

interface ICollection {
  handle: string;
  label: string;
  value: string;
}

interface IShopifyProductListProps {
  collection: ICollection;
  collections: ICollection[];
  isMultipleSelection: boolean;
  isShopifyConnected: boolean;
  onClickConnectStore: () => void;
  onCollectionChange: (collection: ICollection) => void;
  onSelectMultipleProducts?: (product: IProduct[]) => void;
  onSelectSingleProduct?: (product: IProduct) => void;
  products: IProduct[];
  selectedProduct?: IProduct | null;
  selectedProducts?: IProduct[];
}

const ContentWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper data-testid="shopifyProductModal">
      <TitleWrapper data-testid="shopifyProductModalHeader">
        <ShoppingBadIcon />
        <TranslatedText component={Title} stringKey="ADMIN_LABEL_PRODUCTS" />
      </TitleWrapper>
      {children}
    </Wrapper>
  );
};

const ShopifyProductList: React.FC<IShopifyProductListProps> = ({
  isShopifyConnected,
  products,
  collection,
  collections,
  onCollectionChange,
  onClickConnectStore,
  isMultipleSelection,
  onSelectMultipleProducts,
  onSelectSingleProduct,
  selectedProduct,
  selectedProducts,
}) => {
  const [search, setSearch] = React.useState('');
  const initalProductsSelectedState = selectedProduct ? [selectedProduct] : selectedProducts;
  const [productsSelected, setProductsSelected] = React.useState<IProduct[]>(initalProductsSelectedState || []);
  const { t } = useAdminTranslation();
  const [productToReplaceWith, setProductToReplaceWith] = React.useState<IProduct | null>(null);
  const isReplacingProduct = React.useMemo(() => {
    return selectedProduct && !isMultipleSelection;
  },[selectedProduct, isMultipleSelection]);
  const filteredProducts = React.useMemo(() => {
    if (!search) {
      return products;
    }
    return products.filter((product) => {
      return product.title.toLowerCase().includes(search.toLowerCase());
    });
  }, [products, search]);

  const handleSelectProduct = React.useCallback((product: IProduct) => () => {
    if (!isMultipleSelection && !isReplacingProduct) {
      onSelectSingleProduct?.(product);
      return;
    }

    if (isReplacingProduct) {
      setProductsSelected([product]);
      setProductToReplaceWith(product);
      return;
    }

    setProductsSelected((prev) => {
      if (prev.find((p) => p.id === product.id)) {
        return prev.filter((p) => p.id !== product.id);
      }
      return [...prev, product];
    });
  }, [isMultipleSelection, onSelectSingleProduct, isReplacingProduct]);

  const renderProduct = React.useCallback((product: IProduct) => {
    const isSelected = productsSelected.some((p) => p.id === product.id);
    let isDisabled: boolean = false;
    if (isReplacingProduct && selectedProduct && selectedProducts) {
      isDisabled = selectedProducts.some((p) => p.id === product.id) && product.id !== selectedProduct.id;
    }

    return (
      <Product
        isSelected={isSelected}
        key={product.id}
        product={product}
        onClick={handleSelectProduct(product)}
        isDisabled={isDisabled}
      />
    );
  }, [productsSelected, handleSelectProduct, isReplacingProduct, products, selectedProducts, selectedProduct]);

  const handleSubmit = React.useCallback(() => {
    if (isReplacingProduct && productToReplaceWith) {
      onSelectSingleProduct?.(productToReplaceWith);
      return;
    }
    onSelectMultipleProducts?.(productsSelected);
  }, [onSelectMultipleProducts, productsSelected, onSelectSingleProduct, productToReplaceWith, isReplacingProduct]);

  if (!isShopifyConnected) {
    return (
      <ContentWrapper>
        <NoContent>
          <TranslatedText
            component={HelpText}
            stringKey="SHOPIFY_PRODUCTS_LIST_SHOPIFY_NOT_CONNECTED"
            componentProps={{
              'data-testid': 'shopifyProductModalNoStoreConnectedHelpText',
            }}
          />
          <ConnectStoreButton data-testid="shopifyProductModalConnectStoreButton" onClick={onClickConnectStore}>
            <PlusIcon />
            <TranslatedText stringKey="CONNECT_NEW_STORE" />
          </ConnectStoreButton>
        </NoContent>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Content data-testid="shopifyProductModalContent">
        <ContentHeader>
          <HelpText data-testid="shopifyProductModalDescription">{parse(t('SHOPIFY_PRODUCTS_LIBRARY_DESCRIPTION') || '')}</HelpText>
          <SearchWrapper>
            <Dropdown
              isAdmin={true}
              onChange={onCollectionChange}
              options={collections}
              value={collection}
              dataTestId="shopifyProductModalCollectionDropdown"
            />
            <StyledSearchInput
              data-testid="shopifyProductsLibrarySearchInput"
              onSearch={setSearch}
              padding="12px 13px"
              searchPlaceholderKey="SEARCH_SOMETHING"
            />
          </SearchWrapper>
        </ContentHeader>
        <ProductListWrapper data-testid="shopifyProductModalProductList">
          {filteredProducts.map(renderProduct)}
        </ProductListWrapper>
        {
          (isMultipleSelection || isReplacingProduct) && (
          <SubmitButton data-testid="shopifyProductModalSubmitButton" onClick={handleSubmit}>
            {isReplacingProduct ? t('ADMIN_ACTION_REPLACE') : t('ADMIN_ACTION_DONE')}
          </SubmitButton>
        )}
      </Content>
    </ContentWrapper>
  );
};

export default ShopifyProductList;

import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  ADMIN_ACCENT_PRIMARY,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
`;

export const ArrowIcon = styled(Icon).attrs(props => ({
  // @ts-ignore
  color: props.titleColor ?? ADMIN_ACCENT_PRIMARY(props),
  name: 'designUpArrow',
}))<{ collapsed?: boolean; titleColor?: string; }>`
  & svg {
    transition: transform 0.2s;
    transform: rotate(${({ collapsed }) => collapsed ? 180 : 360}deg);
  }
`;

export const TitleText = styled.div<{ titleColor?: string; }>`
  ${ADMIN_TEXT_LABEL_M_BOLD}
  color: ${props => props.titleColor ?? ADMIN_ACCENT_PRIMARY(props)};
  text-transform: uppercase;
`;

export const Content = styled.div<{ collapsed?: boolean; contentFullyOpen: boolean; maxHeight?: number; }>`
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: height 0.5s;
  height: 0px;
  ${({ maxHeight, collapsed }) => !collapsed && `
    height: ${maxHeight}px;
  `};
  ${({ contentFullyOpen }) => contentFullyOpen && `overflow: visible;`};
`;

export const ContentWrapper = styled.div``;

import { connect } from 'react-redux';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import QuickPickFancyButton from './QuickPickFancyButton';

const mapStateToProps = state => ({
  primaryToken: getPrimaryToken(state),
  siteId: getSiteId(state),
});

export default connect(mapStateToProps)(QuickPickFancyButton);

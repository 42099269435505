import React, { HTMLAttributes } from 'react';
import {
  CircleContainer,
  DragButtContainer,
  DragCircle,
} from './styles';

type DragButtProps = HTMLAttributes<HTMLDivElement> & { maxWidth?: string };

export default function DragButt(props: DragButtProps) {
  return (
    <DragButtContainer {...props}>
      <CircleContainer>
        <DragCircle />
        <DragCircle />
        <DragCircle />
      </CircleContainer>
    </DragButtContainer>
  );
}

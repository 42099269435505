import styled from 'styled-components';
import Label from 'components/admin2/Label';
import ThrottledInput from 'components/admin2/ThrottledInput';
import Link from 'components/ui/Link';
import { LargeButton } from 'components/admin2/ui/Button';
import { Column, Container, ZoneSelect } from 'components/admin2/DatetimePicker/styles';

import {
  ADMIN_SURFACE_1,
  SPACING_SMALL,
  SPACING_MEDIUM,
  ADMIN_SURFACE_4,
  ADMIN_TEXT_200,
  ADMIN_ALERT_ERROR,
} from 'style/constants';
import {
  ADMIN_LINK,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_S_BOLD, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

export const StyledColumn = styled(Column)``;

export const OuterContainer = styled.div`
  background-color: ${ADMIN_SURFACE_4};
  padding: ${SPACING_MEDIUM};
`;

export const DateContainer = styled(Container)`
  margin-bottom: ${SPACING_SMALL};
`;

export const ExportLabel = styled(Label)`
  text-transform: uppercase;
${ADMIN_TEXT_TITLE_S}
`;

export const ExportHelper = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  margin-bottom: ${SPACING_SMALL};
`;

export const ExportStatus = styled.div`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  padding-top: 6px;
  text-transform: uppercase;
`;

export const ExportError = styled(ExportStatus)`
  color: ${ADMIN_ALERT_ERROR};
`;

export const ExportTitle = styled.div`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  padding-top: 6px;
`;

export const DownloadLink = styled(Link)`
  ${ADMIN_LINK};
  ${ADMIN_TEXT_LABEL_S_BOLD}
  padding: 2px 18px;
  text-decoration: none  !important;
  background-color: ${ADMIN_SURFACE_1};
  text-transform: uppercase;
`;

export const ExportButton = styled(LargeButton).attrs({
  disabledBackground: '#585C68',
  disabledColor: '#dbdbdb',
  fullwidth: true,
})`
  display: flex;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  opacity: ${props => props.disabled ? '0.3' : '1'};
  ${ADMIN_TEXT_TITLE_S};
  margin-bottom: ${SPACING_SMALL};
`;

export const LinkSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${SPACING_MEDIUM};
`;

export const ExportColumn = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: start;
  flex-direction: column;
`;

export const StatusColumn = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: center;
  flex-direction: column;
  min-width: 135px;
`;

export const StyledZoneSelect = styled(ZoneSelect)`
  margin-bottom: ${SPACING_SMALL};
`;

export const StyledTextArea = styled(ThrottledInput).attrs(() => ({
  type: 'textArea',
}))`
  margin-bottom: ${SPACING_MEDIUM};
`;

import { useFocusArea } from 'hooks';
import { useAdminTranslation } from 'hooks/use-translation';
import { ITheme } from 'models';
import React from 'react';
import DeleteThemeModal from '../DeleteThemeModal';
import ThemeCard from '../ThemeCard';
import {
  ActionButton,
  CloseIcon,
  Container,
  CustomizeIcon,
  FavoriteIcon,
  Footer,
  OptionsButton,
  Row,
  SetFavoriteIcon,
  ThemeName,
  ThemeOptionRow,
  ThreeDotsIcon,
  TrashIcon,
} from './styles';

const NAME_TRANSLATION_KEYS = {
  'Dark Mode': 'ADMIN_LABEL_DARK_MODE',
  'Light Mode': 'ADMIN_LABEL_LIGHT_MODE',
} as const;

interface ISelectThemeButtonProps {
  isActive?: boolean;
  isFavorite?: boolean;
  isLoading?: boolean;
  name?: string;
  onCustomize?: () => void;
  onDelete?: () => void;
  onFavorite?: () => void;
  onSelect?: () => void;
  theme: ITheme;
}

interface IThemeOptionsMap {
  Icon: React.FC<any>;
  onClick: () => void;
  tooltip: string;
}

const SelectThemeButton = ({
  isActive,
  isFavorite,
  isLoading = false,
  name = 'Theme',
  onCustomize,
  onDelete,
  onFavorite,
  onSelect,
  theme,
}: ISelectThemeButtonProps) => {
  const [settingsOpen, setSettingsOpen] = React.useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState<boolean>(false);
  const footerRef = useFocusArea({
    onExit: () => setSettingsOpen(false),
    active: settingsOpen,
  });
  const { t } = useAdminTranslation();

  const onSettingsClick = () => {
    setSettingsOpen(oldState => !oldState);
  };

  const onDeleteConfirm = () => {
    setIsDeleteOpen(false);
    onDelete?.();
  };

  const onFavoriteClick = () => {
    setSettingsOpen(false);
    onFavorite?.();
  };

  const onDeleteCancel = () => {
    setIsDeleteOpen(false);
  };

  const THEME_OPTIONS_MAP = React.useMemo(() => {
    const options: IThemeOptionsMap[] = [
      {
        Icon: CustomizeIcon,
        tooltip: 'ADMIN_THEMES_CUSTOMIZE_THEME',
        onClick: () => onCustomize?.(),
      },
      {
        Icon: SetFavoriteIcon,
        tooltip: 'ADMIN_THEMES_SET_DEFAULT',
        onClick: onFavoriteClick,
      },
    ];

    if (onDelete && !isFavorite && !isActive) {
      options.push({
        Icon: TrashIcon,
        tooltip: 'ADMIN_THEMES_DELETE_THEME',
        onClick: () => setIsDeleteOpen(true),
      });
    }

    return options;
  }, [onDelete, isFavorite, isActive]);

  const renderedFooter = React.useMemo(() => {
    if (settingsOpen) {
      return (
        // @ts-ignore
        <Footer ref={footerRef}>
          <ThemeOptionRow>
            {THEME_OPTIONS_MAP.map(({ Icon, tooltip, onClick }, key) => (
              <ActionButton onClick={onClick} key={`ActionButton-${name}-${key}`}>
                <Icon tooltipKey={tooltip} />
              </ActionButton>
            ))}
          </ThemeOptionRow>
          <OptionsButton settingsOpen={settingsOpen} onClick={onSettingsClick}>
            <CloseIcon />
          </OptionsButton>
        </Footer>
      );
    }
    const nameTranslationKey = NAME_TRANSLATION_KEYS[name as keyof typeof NAME_TRANSLATION_KEYS];
    return (
      <Footer>
        <Row>
          {isFavorite && <FavoriteIcon />}
          <ThemeName>{nameTranslationKey ? t(nameTranslationKey) : name}</ThemeName>
        </Row>
        {!isLoading && (
          <OptionsButton settingsOpen={settingsOpen} onClick={onSettingsClick}>
            <ThreeDotsIcon />
          </OptionsButton>
        )}
      </Footer>
    );
  }, [settingsOpen, name, isFavorite]);

  return (
    <Container isActive={isActive} shouldFill={settingsOpen} isLoading={isLoading}>
      {
        isDeleteOpen && (
        <DeleteThemeModal
          textKey="ADMIN_DESIGN_TAB_DELETE_THEME_CONFIRMATION_TITLE"
          descriptionTextKey="ADMIN_DESIGN_TAB_DELETE_THEME_CONFIRMATION_DESCRIPTION"
          onBack={onDeleteCancel}
          onConfirm={onDeleteConfirm}
        />
        )
      }
      <Row>
        <ThemeCard
          height={96}
          onClick={onSelect}
          themeColors={theme.colors}
          type={theme.type}
          width={148}
        />
      </Row>
      {renderedFooter}
    </Container>
  );
};

export default SelectThemeButton;

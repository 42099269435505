import React from 'react';
import {
  Container,
  Image,
  InfoContainer,
  Title,
  Content,
} from './styles';

interface ProductCardProps {
  image?: string;
  price?: string;
  title?: string;
  variant?: string;
}

export default function ProductCard({ image, title, price, variant, ...props }: ProductCardProps) {
  return (
    <Container {...props}>
      <Image src={image} />
      <InfoContainer>
        <Title>{title}</Title>
        <Content>{variant}</Content>
        <Title>${price}</Title>
      </InfoContainer>
    </Container>
  );
}

import { ADMIN_SURFACE_4, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_REGULAR } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const BundleItemWrapper = styled.div`
  max-width: 100%;
  min-height: 123px;
  padding: 32px;
  align-items: center;
  background: ${ADMIN_SURFACE_4};
  border-radius: 10px;
  ${ADMIN_TEXT_BODY_S_REGULAR}
  color: ${ADMIN_TEXT_100};
  display: grid;
  grid-template-columns: 55% 1fr auto;
`;

export const BundleItemName = styled.span`
  ${ADMIN_TEXT_BODY_S_BOLD}
`;

export const BundleItemPrice = styled.span``;

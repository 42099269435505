import React, { FC, FormEventHandler, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAdminTranslation } from 'hooks/use-translation';
import { Container, Description, RadioGroup, Title, StyledRadio as Radio, Header } from './styles';
import { PAGE_SETTINGS_VISIBILITY_ID } from 'global-ids';
import { getEditingPageDoc } from 'services/admin/selectors';

export type PageVisibilityTabProps = {
  updatePageVisibility: (isPrivate: boolean) => void;
};

const PageVisibilityTab: FC<PageVisibilityTabProps> = ({ updatePageVisibility }) => {
  const { t } = useAdminTranslation();
  const page = useSelector(getEditingPageDoc);

  const handleSelectOption = useCallback<FormEventHandler<HTMLInputElement>>(
    (ev) => {
      if (!ev.currentTarget.checked) {
        return;
      }

      switch (ev.currentTarget.value) {
        case 'public':
          return updatePageVisibility(false);
        case 'private':
          return updatePageVisibility(true);
        default:
          break;
      }
    },
    [updatePageVisibility],
  );

  return (
    <Container id={PAGE_SETTINGS_VISIBILITY_ID} data-testid={PAGE_SETTINGS_VISIBILITY_ID}>
      <Header>
        <Title>{t('PAGE_VISIBILITY_TITLE')}</Title>
        <Description>{t('PAGE_VISIBILITY_DESCRIPTION')}</Description>
      </Header>
      <RadioGroup>
        <Radio
          checked={!page.data.private}
          labelKey="PUBLIC"
          descriptionKey="PAGE_VISIBILITY_PUBLIC_DESCRIPTION"
          onChange={handleSelectOption}
          value={'public'}
        />
        <Radio
          checked={page.data.private}
          labelKey="PRIVATE"
          descriptionKey="PAGE_VISIBILITY_PRIVATE_DESCRIPTION"
          onChange={handleSelectOption}
          value={'private'}
        />
        </RadioGroup>
    </Container>
  );
};

export default PageVisibilityTab;

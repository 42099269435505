import React, { useMemo } from 'react';
import { useElapsedTime } from 'hooks';

export const StreamElapsedTime = ({ startTime }: { startTime: number }) => {
  const elapsedS = useElapsedTime(startTime) / 1000;
  const hours = Math.floor(elapsedS / 3600);
  const minutes = Math.floor((elapsedS - hours * 3600) / 60);
  const seconds = Math.floor(elapsedS - hours * 3600 - minutes * 60);
  const formatted = useMemo(
    () => [hours, minutes, seconds].map(
      (t) => t.toString().padStart(2, '0'),
    ).join(':'),
    [hours, minutes, seconds],
  );
  return (
    <>{formatted}</>
  );
};

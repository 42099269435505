/* tslint:disable:jsx-boolean-value */
import TranslatedText from 'components/i18n/TranslatedText';
import React, { MouseEventHandler, useMemo } from 'react';
import { LeftArrow, RightArrow } from 'components/admin2/ui/Button';
import {
  PaginationContainer,
  PaginationTitle,
  PageSelectorContainer,
  PageButtonContainer,
  PageButton,
} from './styles';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';

export interface PaginationProps<T> {
  canNextPage: boolean;
  canPreviousPage: boolean;
  page?: T[];
  pageOptions: number[];
  state: {
    pageIndex: number;
    pageSize: number;
  };
  gotoPage(pageIndex: number): void;
  nextPage(): void;
  previousPage(): void;
  setPageSize(pageSize: number): void;
}

export function getPaginationList(pageIndex: number, totalPages: number) {
  const firstPageToRender = Math.max(pageIndex - 1 < 1 ? 1 : Math.min(pageIndex - 1, totalPages - 5), 1);
  const list: number[] = [];

  for (let index = firstPageToRender; index < firstPageToRender + 5 && index < totalPages; index++) {
    list.push(index);
  }
  if (list[0] - 1 > 0) {
    list.unshift(-1);
    list.unshift(1);
  }
  if (list[list.length - 1] + 1 < totalPages) {
    list.push(-1);
  }
  if (totalPages > 1) {
    list.push(totalPages);
  }

  return list;
}

export default function NumberPagination<T>({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageOptions,
  previousPage,
  state: { pageIndex },
}: PaginationProps<T>) {
  const totalPages = useMemo(() => (
    pageOptions.length
  ), [pageOptions.length]);

  const paginationList = useMemo(() => {
    return getPaginationList(pageIndex, totalPages);
  }, [pageIndex, totalPages]);

  const handlePageChange: MouseEventHandler<HTMLDivElement> = (e) => {
    const page = parseInt(e.currentTarget.getAttribute('data-page') || '1', 10);
    if (page < 0 || page >= totalPages) {
      return;
    }
    gotoPage(page);
  };

  return totalPages > 1 ? (
    <PaginationContainer>
      <TranslatedText component={PaginationTitle} stringKey="ADMIN_LABEL_PAGE" />
      <PageSelectorContainer>
        <LeftArrow disabled={!canPreviousPage} onClick={previousPage} spacing="-22px" />
        <PageButtonContainer>
          <NonKeyedListMapper list={paginationList}>
            {(key: string, child: number) => {
              if (child === -1) {
                return <PageButton ellipsis key={key} />;
              }
              return (
                <PageButton active={child - 1 === pageIndex} key={key} data-page={child - 1} onClick={handlePageChange}>
                  {child}
                </PageButton>
              );
            }}
          </NonKeyedListMapper>
        </PageButtonContainer>
        <RightArrow disabled={!canNextPage} onClick={nextPage} spacing="-22px" />
      </PageSelectorContainer>
    </PaginationContainer>
  ) : null;
}

import styled from 'styled-components';
import {
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
} from 'style/constants';
import {
  CIRCLE,
} from 'style/mixins';

export const DragButtContainer = styled.div<{ maxWidth?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${ADMIN_SURFACE_4};
  height: 100%;
  max-width: 19px;
  min-width: ${({ maxWidth }) => maxWidth || '19px'};
  border-radius: 4px 0 0 4px;
`;

export const CircleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16px;
  width: 8px;
`;

export const DragCircle = styled.div.attrs({
  radius: '3px',
})`
  display: flex;
  justify-content: space-between;
  &:after, &:before {
    content: "";
    ${CIRCLE}
    background: ${ADMIN_SURFACE_5};
  }
`;

export const ICONS_TYPES = [
  {
    id: 'favicon',
    length: 16,
    width: 16,
  },
  {
    id: 'apple167X167',
    length: 167,
    width: 167,
  },
  {
    id: 'apple180X180',
    length: 180,
    width: 180,
  },
  {
    id: 'favicon128X128',
    length: 128,
    width: 128,
  },
  {
    id: 'favicon196X196',
    length: 196,
    width: 196,
  },
  {
    id: 'favicon228X228',
    length: 228,
    width: 228,
  },
];

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ADMIN_ERROR_MODAL_ROOT } from 'global-ids';
import AdminModal from 'components/admin2/ui/AdminModal';
import MediaLibrary from 'components/admin2/MediaLibrary';
import AjaxFetch from 'components/core/AjaxFetch';
import { ASC_KEY, DESC_KEY, NONE_KEY } from 'components/admin2/MediaLibrary/constants';
import { camelify } from 'shared/string-utils';
import hash from 'json-stable-stringify';

const SORT_MAP = {
  [ASC_KEY]: 1,
  [DESC_KEY]: -1,
  [NONE_KEY]: null,
};

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableEditing: PropTypes.bool,
  disableSelecting: PropTypes.bool,
  fetchObject: PropTypes.shape({
    defaultParams: PropTypes.any,
    endpoint: PropTypes.string,
    headers: PropTypes.object,
    method: PropTypes.oneOf(['GET']),
  }).isRequired,
  helperText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  searchPlaceholderKey: PropTypes.string,
  titleKey: PropTypes.string.isRequired,
};
const defaultProps = {
  disableEditing: false,
  disableSelecting: false,
  helperText: '',
  searchPlaceholderKey: '',
};

const LibraryModalChannel = (props) => {
  const {
    columns,
    fetchObject,
    helperText,
    onClose,
    onSelectItem,
    searchPlaceholderKey,
    disableEditing,
    disableSelecting,
    titleKey,
  } = props;

  const {
    defaultParams,
    endpoint,
    headers,
    method = 'GET',
  } = fetchObject;

  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [params, setParams] = useState(defaultParams);

  const handleResponse = useCallback((response) => {
    setList(camelify(response));
    setLoaded(true);
  }, [hash(list)]);

  const setQueryParams = (newParams) => {
    setParams(newParams);
  };

  const handleSort = (sortObject = {}) => {
    const { sort: sortKey } = sortObject;
    const sortValue = SORT_MAP[sortKey];
    const newParams = {
      ...params,
      options: {
        limit: 100,
        projection: {
          _id: 1,
          'data.channelSelectActiveImageUrl': 1,
          'data.name': 1,
          'seo.title': 1,
          slug: 1,
        },
        sort: {
          _id: sortValue,
        },
      },
    };
    setQueryParams(newParams);
  };

  const handleSearch = (searchText) => {
    const channelTitle = searchText.trim().length;
    const newCriteria = channelTitle ?
      {
        ...params.criteria,
        'data.name': searchText,
      } :
      {
        ...params.criteria,
        'data.name': { $exists: 1 },
      };
    const newParams = { ...params, criteria: newCriteria };
    setQueryParams(newParams);
    setLoaded(false);
  };

  const findInitialSort = newColumns => newColumns.find(col => Boolean(col.sort));

  useEffect(() => {
    handleSort(findInitialSort(columns));
  }, []);

  return (
    <AdminModal
      fixedHeight
      id={ADMIN_ERROR_MODAL_ROOT}
      maxWidth="900px"
      onClose={onClose}
    >
      <AjaxFetch
        headers={headers}
        method={method}
        onValue={handleResponse}
        params={params}
        url={endpoint}
      />
      <MediaLibrary
        columns={columns}
        disableEditing={disableEditing}
        disableSelecting={disableSelecting}
        helperText={helperText}
        list={list}
        loaded={loaded}
        onSearch={handleSearch}
        onSelectItem={onSelectItem}
        onSort={handleSort}
        searchPlaceholderKey={searchPlaceholderKey}
        setLoading={() => setLoaded(false)}
        titleKey={titleKey}
      />
    </AdminModal>
  );
};

LibraryModalChannel.propTypes = propTypes;
LibraryModalChannel.defaultProps = defaultProps;
export default LibraryModalChannel;

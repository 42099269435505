import styled from 'styled-components';
import Label from '../Label';
import {
  ADMIN_SURFACE_5,
  ADMIN_TEXT_300,
  ADMIN_TEXT_200,
  MAESTRO_WHITE,
} from 'style/constants';
import {
  CIRCLE_IMAGE,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${ADMIN_SURFACE_5};
  border-radius: 10px;
  height: 102px;
  padding: 16px;
`;

export const ProfPic = styled.div`
  ${CIRCLE_IMAGE}
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;

`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 13px;
  min-width: 0;
`;

export const Name = styled.div`
  ${ADMIN_TEXT_TITLE_S}
  color: ${ADMIN_TEXT_200};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Tag = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${MAESTRO_WHITE};
`;

export const EmailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const EmailLabel = styled(Label).attrs((props) => ({
  color: ADMIN_TEXT_300(props),
  compact: true,
  icon: 'mail',
  iconSize: 17,
  iconSpacing: 5,
  maxWidth: '85%',
  textSize: 'p2',
}))`
  flex: 1;
  padding-right: 16px;
  color: ${ADMIN_TEXT_300};
  overflow: hidden;
`;

export const MarginWrapper = styled.div`
  margin: 4px 3px 0 3px;
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import set from 'lodash/set';
import PropTypes from 'prop-types';
import {
  SPACING_MEDIUM,
} from 'style/constants';
import LegacyCollectionProvider from 'components/admin2/LegacyCollectionProvider';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import PanelEditorHeader from '../PanelEditorHeader';
import ChannelSelection from './ChannelSelection';
import { KINDS, RENDERER_KEYS } from './constants';

const {
  BEHAVIOR,
  ICON,
  KIND,
  NAME,
  PAGES,
  VIEW,
} = RENDERER_KEYS;
const QUERY = [['type', 'channel']];

export const Container = styled.div`
  padding: ${SPACING_MEDIUM};
  height: 100%;
  min-height: 520px;
  display: flex;
  flex-direction: column;
`;

export const ItemSpinner = styled(LoadingSpinner)`
  && {
    display: block;
    text-align: center;
    width: 100%;
  }
`;

const ChannelSelectEditor = (props) => {
  const { onChange, renderer } = props;
  const { behavior } = renderer;
  const { kind = KINDS.AUTOMATIC, pages: activePages = [], view = 'list' } = behavior || {};
  const [channels, setChannels] = useState(activePages);
  const [availablePages, setAvailablePages] = useState([]);
  const [panelKind, setPanelKind] = useState(kind);
  const {
    [ICON]: iconName = '',
    [NAME]: panelName = '',
  } = renderer;

  // add available/inactive channel objects to panel state for admin to toggle/add
  useEffect(
    () => {
      const renderizedChannels = availablePages.reduce((output, currentPage) => {
        const channelSelectIndex = activePages.findIndex(
          activePage => activePage.page._id === currentPage._id,
        );
        if (channelSelectIndex !== -1) {
          output[channelSelectIndex] = {
            ...output[channelSelectIndex],
            active: output[channelSelectIndex]?.active || true,
          };
        } else if (currentPage.type) {
          const { collection, _id, siteId, data, type } = currentPage;
          output.push({
            active: kind === KINDS.AUTOMATIC,
            collection,
            page: {
              _id,
              title: data?.name || 'Untitled Channel',
            },
            siteId,
            type,
          });
        }
        return output;
      }, [...activePages]);

      setChannels(renderizedChannels);
    }, [availablePages],
  );

  const onPanelKindChange = (value) => {
    const updatedRenderer = { ...renderer, behavior: { ...renderer.behavior } };
    setPanelKind(value);
    set(updatedRenderer, [BEHAVIOR, KIND], value);

    if (value === KINDS.AUTOMATIC) {
      const allActiveChannels = channels.map(
        channel => ({ ...channel, active: true }),
      );
      set(updatedRenderer, [BEHAVIOR, PAGES], allActiveChannels);
      setChannels(allActiveChannels);
    }

    if (value === KINDS.MANUAL) {
      const allInActiveChannels = channels.map(
        channel => ({ ...channel, active: false }),
      );
      set(updatedRenderer, [BEHAVIOR, PAGES], []);
      setChannels(allInActiveChannels);
    }
    return onChange(updatedRenderer);
  };

  const onPagesChange = (value) => {
    const activeChannels = value.filter(channel => channel.active === true);
    const updatedRenderer = {
      ...renderer,
      behavior: {
        ...renderer.behavior,
        pages: activeChannels,
      },
    };
    setChannels(value);
    return onChange(updatedRenderer);
  };

  const onViewChange = (value) => {
    const updatedRenderer = { ...renderer, behavior: { ...renderer.behavior } };
    set(updatedRenderer, [BEHAVIOR, VIEW], value);
    return onChange(updatedRenderer);
  };

  const setRendererField = (rendererKey, value) => {
    const updatedRenderer = {
      ...renderer,
      [rendererKey]: value,
    };
    return onChange(updatedRenderer);
  };

  return (
    <Container>
      <PanelEditorHeader
        iconValue={iconName}
        nameValue={panelName}
        onIconChange={value => setRendererField(ICON, value)}
        onNameChange={value => setRendererField(NAME, value)}
      />
      <LegacyCollectionProvider
        collection="pages"
        limit={1000}
        queries={QUERY}
      >
        { (pages, count, error, listLoaded) => {
          if (!listLoaded) {
            return (<ItemSpinner />);
          }
          if (error) {
            console.error(`Warning: ${error}`); // eslint-disable-line no-console
            return null;
          }
          if (pages && availablePages.length !== pages.length) {
            setAvailablePages(pages);
          }

          return (
            <ChannelSelection
              channels={channels}
              kind={panelKind}
              onChannelChange={onPagesChange}
              onKindChange={onPanelKindChange}
              onViewChange={onViewChange}
              view={view}
            />
          );
        } }
      </LegacyCollectionProvider>
    </Container>
  );
};

ChannelSelectEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  renderer: PropTypes.shape({
    // kind = KINDS.AUTOMATIC, pages: activePages = [], view = 'list'
    behavior: PropTypes.shape({

    }),
  }).isRequired,
};

ChannelSelectEditor.defaultProps = {

};

export default ChannelSelectEditor;

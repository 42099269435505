import styled from 'styled-components';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import { ADMIN_SURFACE_3, ADMIN_TEXT_100 } from 'style/constants';
import { WORD_BREAK } from 'style/mixins';

export const FullURLPreview = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  margin-top: -10px;
`;

export const FullURLV3Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const FullURLV3Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 4px 0px 4px 16px;
  border-radius: 5px;
  background: ${ADMIN_SURFACE_3};
  gap: 0px;
`;

export const CopyButtonV3Wrapper = styled.div`
  display: flex;
  align-center: center;
  justify-content: center;
  padding: 4px;
  min-width: 48px;
  min-height: 48px;
`;

export const FullURLV3 = styled.span`
  color: ${ADMIN_TEXT_100};
  ${WORD_BREAK}
`;

export const StyledTranslateContainer = styled.div`
  span {
    ${ADMIN_TEXT_BODY_S_MEDIUM}
  }
`;

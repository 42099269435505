import React from 'react';
import PropTypes from 'prop-types';
import AdminModal from 'components/admin2/ui/AdminModal';
import PlaylistEditor from './PlaylistEditor';

export default function PlaylistEditorModal(props) {
  return ( // eslint-disable-next-line
    <AdminModal fixedHeight maxWidth="664px" onClose={props.onClose} overlapAdminbar>
      <PlaylistEditor {...props} />
    </AdminModal>
  );
}

PlaylistEditorModal.propTypes = {
  id: PropTypes.string,
  onAfterSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  skipRefresh: PropTypes.bool,
};

PlaylistEditorModal.defaultProps = {
  id: null,
  onAfterSave: () => {},
  skipRefresh: false,
};

export const VALIDATION_TYPES = {
  URL: 'URL',
};
/* eslint-disable */
export const REGEX = {
  URL: RegExp( /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/),
};
/* eslint-enable */

export const TYPES = {
  SEARCH: 'SEARCH',
  TEXT: 'TEXT',
};

export type InputType = keyof typeof TYPES;

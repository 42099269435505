import styled, { css } from 'styled-components';
import Text from 'components/admin2/Label';
import UnstyledButton from 'components/admin2/ui/Button';
import Icon from 'components/ui/Icon';
import EllipsisLoader from 'components/admin2/ui/EllipsisLoader';
import {
  ADMIN_FIELD_TEXT_INPUT,
  CIRCLE,
  FLEX_CENTER,
} from 'style/mixins';
import TextLabel from 'components/admin2/ui/Label';
import {
  ADMIN_SURFACE_4,
  ADMIN_TEXT_200,
  ADMIN_TEXT_100,
  ADMIN_TEXT_300,
  ADMIN_SURFACE_2,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_ALERT_CONFIRMATION,
  ADMIN_ALERT_ERROR,
  ADMIN_TEXT_500,
  ADMIN_SURFACE_5,
} from 'style/constants';
import { ADMIN_TEXT_BODY_M_REGULAR, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_L_BOLD, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

export const Label = styled(TextLabel)`
  padding-bottom: 10px;

  & label {
    padding: 0;
  }
`;

export const ExitButtonContainer = styled.div`
  position: relative;
  top: -26px;
  left: 20px;
`;

export const Container = styled.div`
  background-color: ${ADMIN_SURFACE_4};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 329px;
`;

export const AmountGenerateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  padding: 10px;
  background-color: ${ADMIN_SURFACE_5};
  margin: 0 30px;
  border-radius: 5px;

  div:first-child {
    grid-column: 1 / span 2;
    margin-bottom: 10px;
  }

  div:last-child {
    grid-column: 1 / span 2;
  }

  ${({ isError }) => isError && css`
    box-shadow: 0px 0px 1px 1px ${ADMIN_ALERT_ERROR};
  `};
`;

export const CodeGenerationInstructions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px 30px;
  ${ADMIN_TEXT_BODY_M_REGULAR}
  color: ${ADMIN_TEXT_200};
`;

export const AmountInput = styled.input`
  padding: 8px; 
  ${ADMIN_FIELD_TEXT_INPUT}
  height: 40px;
  width: 170px;
  flex-grow: 0;
  background-color: ${ADMIN_SURFACE_2};

  &::placeholder {
    color: ${ADMIN_TEXT_300};
    opacity: 1;
  }
`;

export const CenteredLabel = styled(Text)`
  align-self: center;
  flex-basis: 62%;
  ${ADMIN_TEXT_BODY_M_REGULAR};
  color: ${ADMIN_TEXT_100};
`;

export const Header = styled.div`
  justify-content: space-between;
  padding: 30px 30px 20px;
  ${ADMIN_TEXT_LABEL_L_BOLD}
`;

export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled(UnstyledButton)`
  height: 40px;
  width: 173px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorMessage = styled.div`
  ${ADMIN_TEXT_LABEL_XS_MEDIUM}
  color: ${ADMIN_ALERT_ERROR};
  padding-left: 5px;
`;

export const ErrorIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_ALERT_ERROR(props),
  name: 'warning',
}))`
  & svg {
    width: 10px;
    height: 8px;
  }
`;

export const StyledLoader = styled(EllipsisLoader).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
}))`
  margin-left: 20px;
  position: relative;
  top: 5px;
`;

export const RefreshIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_100(props),
  name: 'refresh',
}))`
  margin-right: 7px;
`;

export const CheckMark = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_500(props),
  name: 'successCheckmark',
  radius: '16px',
}))`
  ${CIRCLE}
  ${FLEX_CENTER}
  background: ${ADMIN_ALERT_CONFIRMATION};
  color: ${ADMIN_TEXT_500};
  padding: 3px;
  margin-right: 7px;
  & svg {
    width: 7px;
    height: 7px;
  }
`;

export const HelpTextContainer = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  display: relative;
  margin-top: 10px;
  line-height: 20.43px;
  color: ${ADMIN_TEXT_200};
`;

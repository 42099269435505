export const CURRENCY_COUNTRYFLAG_MAP: Record<string,string> =
  {
    'usd': 'US',
    'aed': 'AE',
    'afn': 'AF',
    'all': 'AL',
    'amd': 'AM',
    'ang': 'AN',
    'aoa': 'AO',
    'ars': 'AR',
    'aud': 'AU',
    'awg': 'AW',
    'azn': 'AZ',
    'bam': 'BA',
    'bbd': 'BB',
    'bdt': 'BD',
    'bgn': 'BG',
    'bif': 'BI',
    'bmd': 'BM',
    'bnd': 'BN',
    'bob': 'BO',
    'brl': 'BR',
    'bsd': 'BS',
    'bwp': 'BW',
    'byn': 'BY',
    'bzd': 'BZ',
    'cad': 'CA',
    'cdf': 'CD',
    'chf': 'CH',
    'clp': 'CL',
    'cny': 'CN',
    'cop': 'CO',
    'crc': 'CR',
    'cve': 'CV',
    'czk': 'CZ',
    'djf': 'DJ',
    'dkk': 'DK',
    'dop': 'DO',
    'dzd': 'DZ',
    'egp': 'EG',
    'etb': 'ET',
    'eur': 'EU',
    'fjd': 'FJ',
    'fkp': 'FK',
    'gbp': 'GB',
    'gel': 'GE',
    'gip': 'GI',
    'gmd': 'GM',
    'gnf': 'GN',
    'gtq': 'GT',
    'gyd': 'GY',
    'hkd': 'HK',
    'hnl': 'HN',
    'hrk': 'HR',
    'htg': 'HT',
    'huf': 'HU',
    'idr': 'ID',
    'ils': 'IL',
    'inr': 'IN',
    'isk': 'IS',
    'jmd': 'JM',
    'jpy': 'JP',
    'kes': 'KE',
    'kgs': 'KG',
    'khr': 'KH',
    'kmf': 'KM',
    'krw': 'KR',
    'kyd': 'KY',
    'kzt': 'KZ',
    'lak': 'LA',
    'lbp': 'LB',
    'lkr': 'LK',
    'lrd': 'LR',
    'lsl': 'LS',
    'mad': 'MA',
    'mdl': 'MD',
    'mga': 'MG',
    'mkd': 'MK',
    'mmk': 'MM',
    'mnt': 'MN',
    'mop': 'MO',
    'mro': 'MR',
    'mur': 'MU',
    'mvr': 'MV',
    'mwk': 'MW',
    'mxn': 'MX',
    'myr': 'MY',
    'mzn': 'MZ',
    'nad': 'NA',
    'ngn': 'NG',
    'nio': 'NI',
    'nok': 'NO',
    'npr': 'NP',
    'nzd': 'NZ',
    'pab': 'PA',
    'pen': 'PE',
    'pgk': 'PG',
    'php': 'PH',
    'pkr': 'PK',
    'pln': 'PL',
    'pyg': 'PY',
    'qar': 'QA',
    'ron': 'RO',
    'rsd': 'RS',
    'rub': 'RU',
    'rwf': 'RW',
    'sar': 'SA',
    'sbd': 'SB',
    'scr': 'SC',
    'sek': 'SE',
    'sgd': 'SG',
    'shp': 'SH',
    'sll': 'SL',
    'sos': 'SO',
    'srd': 'SR',
    'std': 'ST',
    'szl': 'SZ',
    'thb': 'TH',
    'tjs': 'TJ',
    'top': 'TO',
    'try': 'TR',
    'ttd': 'TT',
    'twd': 'TW',
    'tzs': 'TZ',
    'uah': 'UA',
    'ugx': 'UG',
    'uyu': 'UY',
    'uzs': 'UZ',
    'vnd': 'VN',
    'vuv': 'VU',
    'wst': 'WS',
    'xaf': 'CM',
    'xcd': 'KN',
    'xof': 'SN',
    'xpf': 'NC',
    'yer': 'YE',
    'zar': 'ZA',
    'zmw': 'ZM',
  };

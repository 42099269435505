import React, { useCallback, useState, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { ACHIEVEMENT_SERVICE_BASE_URL } from 'config';
import { useDispatch, useRealtimeDocument, useSelector } from 'hooks';
import Label from 'components/admin2/Label';
import Calendar from 'components/admin2/ui/Calendar';
import { parseRegion, TIMEZONE_OPTIONS } from 'components/admin2/DatetimePicker/utils';
import TranslatedText from 'components/i18n/TranslatedText';
import { getSiteId } from 'services/app/selectors';
import { showAdminErrorKey } from 'services/modals';
import JsonDebug from 'components/dev/JsonDebug';

import PanelEditorHeader from '../PanelEditorHeader';
import {
  ExportButton,
  ExportColumn,
  ExportHelper,
  ExportLabel,
  ExportTitle,
  ExportStatus,
  ExportError,
  DownloadLink,
  DateContainer,
  LinkSection,
  OuterContainer,
  StatusColumn,
  StyledColumn,
  StyledTextArea,
  StyledZoneSelect,
} from './styles';

const fileProps = {
  file: PropTypes.shape({
    endDate: PropTypes.number,
    link: PropTypes.string,
    startDate: PropTypes.number,
    status: PropTypes.string,
  }),
};
const defaultFileProps = { file: null };

function Filename({ file }) {
  let title = 'None';
  if (file) {
    const { startDate, endDate } = file;
    const formatDate = timestamp => moment(timestamp).format('YYYY-MM-DD');
    title = `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }
  return <ExportTitle>{ title }</ExportTitle>;
}
Filename.propTypes = fileProps;
Filename.defaultProps = defaultFileProps;

function DownloadButton({ file }) {
  if (file?.link) {
    return (
      <TranslatedText
        component={DownloadLink}
        componentProps={{ href: file.link }}
        stringKey="ADMIN_LABEL_DOWNLOAD"
      />
    );
  }
  if (file?.status === 'pending') {
    return <TranslatedText component={ExportStatus} stringKey="ADMIN_LABEL_GENERATING" />;
  }
  if (file?.status === 'error') {
    return <TranslatedText component={ExportError} stringKey="ADMIN_LABEL_ERROR" />;
  }
  return <ExportStatus>-</ExportStatus>;
}
DownloadButton.propTypes = fileProps;
DownloadButton.defaultProps = defaultFileProps;

export default function LeaderboardPanelEditor(props) {
  const { renderer, onChange } = props;
  const { description, iconName, panelName } = renderer;
  const siteId = useSelector(getSiteId);
  const dispatch = useDispatch();
  const [startFocused, setStartFocus] = useState(false);
  const [endFocused, setEndFocus] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [latestExport, loaded] = useRealtimeDocument('achievements_export', siteId);

  const updatePanel = useCallback(key => val => onChange({
    ...renderer,
    [key]: val,
  }), [renderer, onChange]);

  const zoneValue = useMemo(() => {
    const { region } = parseRegion(timezone);
    const { options: zones } = TIMEZONE_OPTIONS[region] || {};
    return zones.find(item => item.timezone === timezone);
  }, [timezone]);

  const upperLimit = useMemo(
    () => moment().tz(timezone).subtract(1, 'days').endOf('day'),
    [timezone],
  );

  const isStartOutsideRange = useCallback(
    start => start > upperLimit || start > endDate,
    [endDate, upperLimit],
  );

  const isEndOutsideRange = useCallback(
    end => end > upperLimit || end < startDate,
    [startDate, upperLimit],
  );

  const handleStartDateChange = useCallback((momentObj) => {
    const dayMoment = moment(momentObj).tz(timezone).startOf('day');
    setStartDate(dayMoment);
  }, [timezone]);

  const handleEndDateChange = useCallback((momentObj) => {
    const dayMoment = moment(momentObj).tz(timezone).endOf('day');
    setEndDate(dayMoment);
  }, [timezone]);

  const onExportClick = useCallback(async () => {
    setExporting(true);
    if (startDate && endDate) {
      try {
        await axios.post(`${ACHIEVEMENT_SERVICE_BASE_URL}/leaderboard/export/${siteId}`, {
          endDate: moment(endDate.format('YYYY-MM-DD')).tz(timezone).endOf('day').valueOf(),
          startDate: moment(startDate.format('YYYY-MM-DD')).tz(timezone).startOf('day').valueOf(),
        });
        setExporting(false);
      } catch (error) {
        setExporting(false);
        dispatch(showAdminErrorKey('ADMIN_ERROR_EXPORT_FAILED'));
      }
    }
  }, [startDate, endDate, timezone]);

  const disableExport = useMemo(() => (
    !startDate ||
    !endDate ||
    !loaded ||
    exporting ||
    latestExport?.status === 'pending'
  ), [startDate, endDate, exporting, loaded, latestExport]);

  return (
    <OuterContainer>
      <PanelEditorHeader
        iconValue={iconName}
        nameValue={panelName}
        onIconChange={updatePanel('iconName')}
        onNameChange={updatePanel('panelName')}
      />
      <TranslatedText component={ExportLabel} stringKey="ADMIN_LABEL_EARN_POINTS" />
      <TranslatedText component={ExportHelper} stringKey="ADMIN_HELPER_EARN_POINTS" />
      <StyledTextArea
        labelKey="ADMIN_LABEL_DESCRIPTION"
        onChange={updatePanel('description')}
        value={description || ''}
      />
      <TranslatedText component={ExportLabel} stringKey="ADMIN_LABEL_EXPORT" />
      <TranslatedText component={ExportHelper} stringKey="ADMIN_HELPER_EXPORT_ACHIEVEMENTS" />
      <DateContainer>
        <StyledColumn>
          <Calendar
            date={startDate}
            focused={startFocused}
            isDisabled={false}
            isOutsideRange={isStartOutsideRange}
            labelKey="ADMIN_LABEL_START_DATE"
            numberOfMonths={1}
            onDateChange={handleStartDateChange}
            onFocusChange={({ focused: newFocus }) => setStartFocus(newFocus)}
            placeholder="Set a date"
          />
        </StyledColumn>
        <StyledColumn>
          <Calendar
            anchorDirection="right"
            calendarMargin="-165px"
            date={endDate}
            focused={endFocused}
            isDisabled={false}
            isOutsideRange={isEndOutsideRange}
            labelKey="ADMIN_LABEL_END_DATE"
            numberOfMonths={1}
            onDateChange={handleEndDateChange}
            onFocusChange={({ focused: newFocus }) => setEndFocus(newFocus)} // required
            placeholder="Set a date"
          />
        </StyledColumn>
      </DateContainer>
      <StyledZoneSelect
        isDisabled={false}
        isSearchable
        name="TimeZone"
        onChange={({ timezone: newZone }) => setTimezone(newZone)}
        options={Object.values(TIMEZONE_OPTIONS)}
        value={zoneValue}
        valueKey="timezone"
      />
      <ExportButton bigSize disabled={disableExport} onClick={onExportClick}>
        <TranslatedText stringKey="ADMIN_LABEL_GENERATE_CSV" />
      </ExportButton>
      <TranslatedText component={ExportHelper} stringKey="ADMIN_HELPER_CSV_GENERATION" />
      <LinkSection>
        <ExportColumn>
          <TranslatedText component={Label} stringKey="ADMIN_LABEL_LAST_EXPORT" />
          <Filename file={latestExport} />
        </ExportColumn>
        <StatusColumn>
          <TranslatedText component={Label} stringKey="ADMIN_LABEL_STATUS" />
          <DownloadButton file={latestExport} />
        </StatusColumn>
      </LinkSection>
      <JsonDebug value={renderer} />
    </OuterContainer>
  );
}

LeaderboardPanelEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  renderer: PropTypes.shape({
    description: PropTypes.string,
    iconName: PropTypes.string,
    panelName: PropTypes.string,
  }).isRequired,
};

import produce from 'immer';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ErrorLabel } from 'components/ui/Label';
import {
  createPoll,
  updatePoll,
} from 'services/polls/actions';
import usePollEditor, {
  PREDICTION_STATUS_OPTIONS,
  PredictionStatus,
  getLabelFromStatus,
} from 'hooks/use-poll-editor';
import { PollTypes } from 'services/polls/models';
import Label from 'components/admin2/InputLabel';
import TextInput from 'components/admin2/TextInput';
import Dropdown from 'components/admin2/ui/Dropdown';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import BackButton from 'components/admin2/NavControls/BackButton';
import Button from 'components/admin2/ui/Button';
import ButtonSelect from 'components/admin2/ui/ButtonSelect';
import { useDispatch } from 'hooks';

import { useSelector } from 'react-redux';
import { getAdminSurface3 } from 'services/themes';
import { useAdminTranslation } from 'hooks/use-translation';
import Answer from './Answer';
import {
  ContentContainer,
  LabelToggleWrapper,
  PredictionContainer,
  ResultsWrapper,
  CorrectOptionContainer,
  ActionButton,
  PollEditorContainer,
  Controls,
  Spacer,
  SelectContainer,
} from './style';

const propTypes = {
  initialDoc: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

const defaultProps = {
  onClose: null,
  onSave: null,
};

const valueKey = 'answer';
export default function PollEditor({ initialDoc, onClose, onSave }) {
  const [error, setError] = useState(null);
  const { t } = useAdminTranslation();
  const adminSurface3 = useSelector(getAdminSurface3);
  const showTabs = !initialDoc.fromOverlay;
  const dispatch = useDispatch();
  const [state, dispatchPollEditor] = usePollEditor({
    initialDoc,
  });

  const changeType = (newType) => {
    switch (newType) {
      case 'poll':
        dispatchPollEditor({
          payload: PollTypes.Poll,
          type: 'SET_TYPE',
        });
        break;
      case 'prediction':
        dispatchPollEditor({
          payload: PollTypes.Prediction,
          type: 'SET_TYPE',
        });
        break;
      case 'trivia':
        dispatchPollEditor({
          payload: PollTypes.Trivia,
          type: 'SET_TYPE',
        })
        break;
      default:
        break;
    }
  };

  const increaseCount = () => dispatchPollEditor({ type: 'ADD_BLANK_ANSWER' });

  const decreaseCount = () => dispatchPollEditor({ type: 'DELETE_LAST_ANSWER' });

  const canAddQuestion = state.poll.type !== PollTypes ? state.poll.answers.length < 6 : true;
  const canRemoveQuestion = state.poll.answers.length > 2;

  useEffect(() => {
    const { poll } = state;
    const emptyAnswers = (poll.answers || []).filter(i => !i.answer);
    if (poll.name && poll.question && !emptyAnswers.length) {
      setError(null);
    }
  }, [state.poll]);

  const save = () => {
    const { poll } = state;
    if (!poll.name) {
      setError('ADMIN_LABEL_ERROR_NAME');
      return;
    }

    if (!poll.question) {
      setError('ADMIN_LABEL_ERROR_QUESTION');
      return;
    }

    const emptyAnswers = (poll.answers || []).filter(i => !i.answer);
    if (emptyAnswers.length) {
      setError('ADMIN_LABEL_ERROR_ANSWER');
      return;
    }
    if (state.poll._id) {
      dispatch(updatePoll(state.poll));
    } else {
      dispatch(createPoll(state.poll));
    }
    onSave?.();
  };

  const correctAnswer = state.correctAnswers.filter(item => item.correct)[0];
  const renderContent = () => (
    <>
      <ContentContainer>
        <TextInput
          labelKey="NAME"
          onChange={(newName) => {
            dispatchPollEditor({ payload: newName, type: 'SET_NAME' });
          }}
          placeholderKey={`LABEL_NEW_${(state.poll?.type || '').toUpperCase()}`}
          value={state.poll.name}
        />
        <TextInput
          labelKey="ADMIN_LABEL_QUESTION"
          onChange={(newQuestion) => {
            dispatchPollEditor({ payload: newQuestion, type: 'SET_QUESTION' });
          }}
          placeholderKey="ADMIN_LABEL_POLL_EDITOR_QUESTION_PLACEHOLDER"
          value={state.poll.question}
        />
        <LabelToggleWrapper>
          <LabelToggle
            checked={state.poll.showCount}
            label="Show numbers"
            noSpaceBetween
            onChange={(show) => {
              dispatchPollEditor({ payload: show, type: 'SET_SHOW_COUNT' });
            }}
          />
        </LabelToggleWrapper>
        {state.poll.type === PollTypes.Trivia && (
          <>
            <Label>{t('ADMIN_POLL_EDITOR_CORRECT_OPTION')}</Label>
            <Dropdown
              getOptionLabel={option => option.answer}
              getOptionValue={option => option.answer}
              onChange={(option) => {
                dispatchPollEditor({
                  payload: [{ answer: option.answer, correct: true }],
                  type: 'SET_CORRECT_ANSWERS',
                });
              }}
              options={state.poll.answers}
              placeholder={t('ADMIN_SELECT_AN_ANSWER')}
              value={correctAnswer}
              valueKey={valueKey}
            />
          </>
        )}
        {state.poll.type === PollTypes.Prediction && (
          <PredictionContainer>
            <ResultsWrapper>
              <Label>{t('RESULTS')}</Label>
              <Dropdown
                onChange={(option) => {
                  dispatchPollEditor({
                    payload: option.value,
                    type: 'SET_PREDICTION_STATUS',
                  });
                }}
                options={PREDICTION_STATUS_OPTIONS}
                value={getLabelFromStatus(state.predictionStatus)}
              />
            </ResultsWrapper>
            {state.predictionStatus === PredictionStatus.COMPLETED && (
              <CorrectOptionContainer>
                <Label>{t('ANSWER')}</Label>
                <Dropdown
                  getOptionLabel={option => option.answer}
                  getOptionValue={option => option.answer}
                  onChange={(option) => {
                    dispatchPollEditor({
                      payload: [{ answer: option.answer, correct: true }],
                      type: 'SET_CORRECT_ANSWERS',
                    });
                  }}
                  options={state.poll.answers}
                  placeholder={t('ADMIN_SELECT_AN_ANSWER')}
                  value={correctAnswer}
                  valueKey={valueKey}
                />
              </CorrectOptionContainer>
            )}
          </PredictionContainer>
        )}
      </ContentContainer>
      {state.poll.answers.map((answer, i) => {
        const title = `Option ${i + 1}`;
        return (
          <Answer
            key={answer._id}
            imageUrl={answer.imageUrl}
            onChangeImage={(imageUrl) => {
              const newAnswers = produce(state.poll.answers, (draft) => {
                draft[i].imageUrl = imageUrl;
              });
              dispatchPollEditor({ payload: newAnswers, type: 'SET_ANSWERS' });
            }}
            onChangeText={(text) => {
              const newAnswers = produce(state.poll.answers, (draft) => {
                draft[i].answer = text;
              });
              dispatchPollEditor({ payload: newAnswers, type: 'SET_ANSWERS' });
            }}
            text={answer.answer}
            title={title}
          />
        );
      })}
      {canAddQuestion && (
        <ActionButton onClick={increaseCount}>
          {t('ADMIN_POLL_EDITOR_ADD_OPTION')}
        </ActionButton>
      )}
      {canRemoveQuestion && (
        <ActionButton onClick={decreaseCount}>
          {t('ADMIN_POLL_EDITOR_REMOVE_OPTION')}
        </ActionButton>
      )}
    </>
  );

  return (
    <PollEditorContainer>
      <Controls>
        <BackButton onClick={onClose} />
        <Spacer />
        <Button onClick={save}>
          {t('ACTION_SAVE')}
        </Button>
      </Controls>
      <SelectContainer>
        {error && (
          <ErrorLabel
            admin
            labelKey={error}
          />
        )}
        {
          showTabs ? (
            <ButtonSelect
              activeBackground={adminSurface3}
              onChange={changeType}
              options={[{
                label: 'POLL',
                value: 'poll',
              }, {
                label: 'TRIVIA',
                value: 'trivia',
              }, {
                label: 'ADMIN_LABEL_QUEST_PREDICTION',
                value: 'prediction',
              }]}
              value={state.poll.type}
            >
              {renderContent()}
            </ButtonSelect>
          ) : renderContent()
          }
      </SelectContainer>
    </PollEditorContainer>
  );
}

PollEditor.propTypes = propTypes;
PollEditor.defaultProps = defaultProps;

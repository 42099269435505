import React, { useState, useContext, useCallback } from 'react';

import { AddLanguageButton } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { ILocale, LocalizationTabContext } from './LocalizationTabContext';
import Dropdown from '../ui/Dropdown';
import { getLocaleLabel } from './utils';

const AddLanguage = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { t } = useAdminTranslation();
  const { locales, addNewLanguage } = useContext(LocalizationTabContext);

  const handleClick = useCallback(() => {
    setShowDropdown(true);
  }, []);

  const handleSelectLocale = useCallback((value: ILocale) => {
    addNewLanguage(value);
    setShowDropdown(false);
  }, [addNewLanguage]);

  if (!showDropdown) {
    return (
      <AddLanguageButton onClick={handleClick}>
        {t('ADMIN_LOCALIZATION_ADD_LANGUAGE')}
      </AddLanguageButton>
    );
  }

  return (
    <Dropdown
      placeholder={t('ADMIN_LOCALIZATION_DROPDOWN_PLACEHOLDER')}
      options={locales}
      valueKey="locale"
      getOptionLabel={getLocaleLabel}
      value={null}
      padding="0"
      startsOpen={true}
      onChange={handleSelectLocale}
      isLocalization={true}
      isAdmin={true}
    />
  );
};

  export default AddLanguage;
import { useAdminTranslation } from 'hooks/use-translation';
import React, { useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { LocalizationTabContext, ISupportedLanguages } from './LocalizationTabContext';
import {
  CardContainer,
  CardInfo,
  LanguageCardTitle,
  LanguageCardSubtitle,
  CardActions,
  DownloadButton,
  UploadButton,
  RemoveButton,
  LanguageCardDescription,
  LanguageCardError,
} from './styles';

const LanguageCard: React.FC<ISupportedLanguages> = ({ locale, language, isValid }) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const {
    removeLanguage,
    uploadCSV,
    downloadCSV,
  } = useContext(LocalizationTabContext);

  const handleDownload = useCallback(() => {
    downloadCSV(locale);
  }, [locale, downloadCSV]);

  const handleUpload = useCallback(() => {
    uploadCSV(locale, isValid);
  }, [locale, isValid, uploadCSV]);

  const handleRemove = useCallback(() => dispatch(
    showModal({
      kind: ModalKinds.adminConfirmation,
      data: {
        onConfirmClick: () => removeLanguage(locale, isValid),
        titleKey: 'ADMIN_DELETE_LANGUAGE_MODAL_TITLE',
        subtitleKey: 'ADMIN_DELETE_LANGUAGE_MODAL_SUBTITLE',
      },
    }),
  ), [locale, isValid, removeLanguage]);

  return (
    <>
      <CardContainer isValid={Boolean(isValid)}>
          <CardInfo>
            <LanguageCardTitle>
              {locale}
            </LanguageCardTitle>
            <LanguageCardSubtitle>
              <LanguageCardDescription>
                {language}
              </LanguageCardDescription>
              {!isValid && (
                <LanguageCardError>
                  {t('ADMIN_LOCALIZATION_MISSING_STRINGS')}
                </LanguageCardError>
              )}
            </LanguageCardSubtitle>
          </CardInfo>
          <CardActions>
            <DownloadButton onClick={handleDownload} />
            <UploadButton onClick={handleUpload} />
            <RemoveButton onClick={handleRemove} />
          </CardActions>
        </CardContainer>
    </>
  );
};

export default LanguageCard;

export const getErrorKey = (error: string) => {
  switch (error.toLowerCase()) {
    case 'missing file':
      return 'UPLOAD_ZONE_ERROR_MISSING_FILE';
    case 'file must be an image or mp4 video':
      return 'UPLOAD_ZONE_ERROR_UNSUPPORTED_FILE_TYPE';
    case 'invalid file type':
      return 'UPLOAD_ZONE_ERROR_UNSUPPORTED_FILE_EXTENSION';
    case 'invalid file name':
      return 'UPLOAD_ZONE_ERROR_INVALID_FILE_NAME';
    case 'upload failed':
    default:
      return 'UPLOAD_ZONE_ERROR_DEFAULT';
  }
};

import { ComponentType, HTMLAttributes } from 'react';
import styled from 'styled-components';
import withPadding from 'components/core/withPadding';
import {
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
  ADMIN_TEXT_300,
  ADMIN_ACCENT_PRIMARY,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_LABEL_M_BOLD, ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';

type TextComponent = ComponentType<HTMLAttributes<HTMLDivElement>>;

export const TextBlock: TextComponent = withPadding(styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_200};
`);

export const Description: TextComponent = withPadding(styled.div<{center?: boolean}>`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_200};
  ${({ center }) => center && 'text-align: center;'}
`, '5px 0');

export const TitleWithoutPadding: TextComponent = styled.div`
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${ADMIN_TEXT_100};
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500 !important;
`;

export const Title: TextComponent = withPadding(TitleWithoutPadding, '7px 0');

export const Header: TextComponent = styled.div`
  ${ADMIN_TEXT_LABEL_M_BOLD}
  text-transform: uppercase;
  color: ${ADMIN_TEXT_300};
  padding: 10px 0;
`;

export const AdminTabTextBlock: TextComponent = withPadding(styled(TextBlock)`
  color: ${ADMIN_TEXT_100};
`, '10px 0');

export const DescriptionWithoutPadding: TextComponent = styled.div<{center?: boolean}>`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_300};
  ${({ center }) => center && 'text-align: center;'}
`;

import React from 'react';
import { LocalizationCheckIcon, LocalizationOption, LocalizationOptionSubtitle, LocalizationOptionTitle, LocalizationTextContainer } from './styles';
import { OptionViewProps } from '../../interfaces';

function LocalizationOptionView<T>({ children, isActive, isAdmin, ...props }: OptionViewProps<T>) {
  const { locale, language } = props.data as any;
  return (
    <LocalizationOption isActive={Boolean(isActive)} isAdmin={Boolean(isAdmin)}>
      {isActive && <LocalizationCheckIcon/>}
      <LocalizationTextContainer>
        <LocalizationOptionTitle>
          {locale}
        </LocalizationOptionTitle>
        {language ? (
          <LocalizationOptionSubtitle>
            {language}
          </LocalizationOptionSubtitle>
          ) : null
        }
      </LocalizationTextContainer>
    </LocalizationOption>
  );
}

export default LocalizationOptionView;

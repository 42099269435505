import React from 'react';
import PropTypes from 'prop-types';
import TranslatedText from 'components/i18n/TranslatedText';
import CollapsibleList from 'components/admin2/CollapsibleList';
import LibraryModal from 'components/admin2/LibraryModal';
import NewPersonEditor, { getLogoFromLink, LINK_ICON_KEY, LINK_TEXT_KEY, LINK_URL_KEY } from 'components/admin2/NewPersonEditor';
import RealtimeDocument from 'components/core/RealtimeDocument';
import shortid from 'shortid';
import produce from 'immer';
import BackButton from 'components/admin2/NavControls/BackButton';
import Button from 'components/admin2/ui/Button';
import { peopleColumns } from 'components/admin2/MediaLibrary/constants';
import {
  Container,
  EditorContainer,
  Header,
  Body,
  Halves,
  InputContainer,
  StyledTextInput,
  Gutter,
  StyledTextArea,
  StyledUploadZone,
  ListLink,
  LinkFlex,
  ListLinkTextInput,
  PersonName,
  StyledIconPicker,
} from './styles';

const GROUP_NAME_KEY = 'groupName';
const BANNER_URL_KEY = 'groupBannerUrl';
const GROUP_BIO_KEY = 'groupBio';
const LINKS_ARRAY_KEY = 'groupLinks';
const PEOPLE_ARRAY_KEY = 'groupPeople';
const MODAL_KEY = 'peopleModalOpen';

const createNewLinkObject = () => ({
  generatedKey: shortid.generate(),
  [LINK_ICON_KEY]: '',
  [LINK_TEXT_KEY]: '',
  [LINK_URL_KEY]: '',
});

export default class NewGroupEditor extends React.Component {
  static propTypes = {
    editingItemId: PropTypes.string,
    handleEdit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
  };

  static defaultProps = {
    editingItemId: null,
    onClose: () => {},
    onCreate: () => {},
  };

  state = {
    [BANNER_URL_KEY]: '',
    [GROUP_BIO_KEY]: '',
    [GROUP_NAME_KEY]: '',
    [LINKS_ARRAY_KEY]: [],
    [MODAL_KEY]: false,
    [PEOPLE_ARRAY_KEY]: [],
  };

  showPersonLibModal = () => {
    this.setState({
      [MODAL_KEY]: true,
    });
  };

  handleChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };

  handleLinkChange = (value, key, index) => {
    const newState = produce(this.state, (draft) => {
      draft[LINKS_ARRAY_KEY][index][key] = value;
    });
    this.setState(newState);
  };

  handleAddItem = (createItemFn, listKey, closeModal) => {
    const { [listKey]: list } = this.state;
    const item = createItemFn();
    this.setState({
      [listKey]: [
        ...list,
        item,
      ],
    });
    if (closeModal) {
      this.handleModalClose();
    }
  };

  handleModalClose = () => {
    this.setState({
      [MODAL_KEY]: false,
    });
  };

  handleDeleteItem = (listKey, index) => {
    const { [listKey]: oldList } = this.state;
    const list = [...oldList];
    list.splice(index, 1);
    this.setState({
      [listKey]: list,
    });
  };

  renderPeopleModal() {
    return (
      <LibraryModal
        columns={peopleColumns}
        fetchObject={{
          endpoint: '/legacy/people',
          method: 'GET',
        }}
        NewItemComponent={NewPersonEditor}
        newItemLabel="ADMIN_LABEL_NEW_PERSON"
        onClose={() => this.handleChange(false, MODAL_KEY)}
        onSelectItem={item => this.handleAddItem(() => item, PEOPLE_ARRAY_KEY, true)}
        titleKey="ADMIN_LABEL_PEOPLE"
        transformRequestWithRenderer
      />
    );
  }

  handleSave = () => {
    const { handleSave, onCreate, onClose, handleEdit, editingItemId } = this.props;
    const {
      [MODAL_KEY]: _,
      ...rest
    } = this.state;
    const editing = Boolean(editingItemId);
    if (editing) {
      handleEdit('group', editingItemId, rest);
    } else {
      handleSave(rest);
    }
    onCreate();
    onClose();
  };

  handleValue = (doc) => {
    if (!doc) {
      return;
    }
    const { renderer } = doc;
    const { id } = this.state;
    const previouslyLoaded = Boolean(id);
    if (renderer && !previouslyLoaded) {
      this.setState(renderer);
    }
  };

  getNewLinkHeaderText = item => item[LINK_TEXT_KEY] || <TranslatedText stringKey="ACTION_NEW_LINK" />

  getLinkLabel = item => item[LINK_URL_KEY] || <TranslatedText stringKey="ADMIN_LOGO_LINK_LABEL" />

  getLabelNewPerson = person => person.personName || <TranslatedText stringKey="ADMIN_LABEL_NEW_PERSON" />

  getLabelPerson = person => person.personTitle || <TranslatedText stringKey="ADMIN_LABEL_PERSON" />

  render() {
    const {
      [BANNER_URL_KEY]: groupBannerUrl,
      [GROUP_BIO_KEY]: groupBio,
      [GROUP_NAME_KEY]: groupName,
      [LINKS_ARRAY_KEY]: links,
      [PEOPLE_ARRAY_KEY]: people,
      [MODAL_KEY]: peopleModalOpen,
    } = this.state;
    const { handleChange, handleLinkChange } = this;
    const { editingItemId, onClose } = this.props;
    return (
      <Container>
        { peopleModalOpen && this.renderPeopleModal() }
        <RealtimeDocument
          collection="objects"
          id={editingItemId}
          onValue={this.handleValue}
        />
        <EditorContainer>
          <Header>
            <BackButton onClick={onClose} />
            <Button onClick={this.handleSave}>
              <TranslatedText stringKey="ADMIN_ACTION_SAVE" />
            </Button>
          </Header>
          <Body>
            <StyledTextInput
              labelKey="ADMIN_LABEL_NAME"
              onChange={value => handleChange(value, GROUP_NAME_KEY)}
              placeholderKey="ADMIN_PLACEHOLDER_ENTER_NAME"
              value={groupName}
            />
            <Halves>
              <InputContainer>
                <StyledUploadZone
                  canDrop={false}
                  dimensionRequirements={{
                    exactDimensions: true,
                    pixelHeight: 320,
                    pixelWidth: 300,
                    verify: false,
                  }}
                  imagePreview={groupBannerUrl}
                  labelKey="ADMIN_LABEL_BANNER"
                  onClearImage={() => handleChange('', BANNER_URL_KEY)}
                  onFileSubmit={url => handleChange(url, BANNER_URL_KEY)}
                />
              </InputContainer>
              <Gutter />
              <InputContainer>
                <StyledTextArea
                  descriptionKey="ADMIN_LABEL_GROUP_DESCRIPTION"
                  labelKey="ADMIN_LABEL_BIO"
                  onChange={value => handleChange(value, GROUP_BIO_KEY)}
                  placeholderKey="ADMIN_PLACEHOLDER_BIO"
                  value={groupBio}
                />
              </InputContainer>
            </Halves>
            <CollapsibleList
              addItemKey="ACTION_ADD_LINK"
              draggable
              emptyListKey="NO_LINKS"
              fallbackImageIcon={item => item[LINK_ICON_KEY] || 'link'}
              headerText={this.getNewLinkHeaderText}
              imageSrc={getLogoFromLink}
              keyField="generatedKey"
              labelKey="ADMIN_LABEL_LINKS"
              list={links}
              nameText={this.getLinkLabel}
              onAddItem={() => this.handleAddItem(createNewLinkObject, LINKS_ARRAY_KEY)}
              onDelete={i => this.handleDeleteItem(LINKS_ARRAY_KEY, i)}
              onDragEnd={items => handleChange(items, LINKS_ARRAY_KEY)}
              secondaryColor
            >
              {
                (item, i) => (
                  <ListLink>
                    <LinkFlex>
                      <ListLinkTextInput
                        labelKey="TEXT"
                        onChange={value => handleLinkChange(value, LINK_TEXT_KEY, i)}
                        placeholderKey="ADMIN_PLACEHOLDER_ENTER_TEXT"
                        value={links[i][LINK_TEXT_KEY]}
                      />
                      <Gutter />
                      <ListLinkTextInput
                        labelHintKey="URL_MUST_BEGIN_WITH_HTTP_HTTPS"
                        labelKey="URL"
                        onChange={value => handleLinkChange(value, LINK_URL_KEY, i)}
                        placeholderKey="ADMIN_PLACEHOLDER_ENTER_URL"
                        value={links[i][LINK_URL_KEY]}
                      />
                    </LinkFlex>
                    <StyledIconPicker
                      onChange={value => handleLinkChange(value, LINK_ICON_KEY, i)}
                      value={links[i][LINK_ICON_KEY]}
                    />
                  </ListLink>
                )
              }
            </CollapsibleList>
            <CollapsibleList
              addItemKey="ADMIN_ACTION_ADD_PEOPLE"
              draggable
              emptyListKey="ADMIN_LABEL_NO_PEOPLE"
              fallbackImageIcon="gamer"
              headerText={this.getLabelNewPerson}
              imageSrc={person => person.personImageUrl}
              keyField={person => person._id}
              labelKey="ADMIN_LABEL_PEOPLE"
              list={people}
              nameText={this.getLabelPerson}
              onAddItem={this.showPersonLibModal}
              onDelete={i => this.handleDeleteItem(PEOPLE_ARRAY_KEY, i)}
              onDragEnd={items => handleChange(items, PEOPLE_ARRAY_KEY)}
              secondaryColor
            >
              {
                person => <PersonName>{ person.personName }</PersonName>
              }
            </CollapsibleList>
          </Body>
        </EditorContainer>
      </Container>
    );
  }
}

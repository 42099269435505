import { RemoveButton } from 'components/admin2/ui/Button';
import React from 'react';
import { IItem } from '../..';
import { BundleItemName, BundleItemPrice, BundleItemWrapper } from './styles';

interface IBundleItemProps {
  item: IItem;
  onRemove: () => void;
}

const BundleItem: React.FC<IBundleItemProps> = ({ item, onRemove }) => {
  const formattedTotal = React.useMemo(() => {
    const numberFormatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: item.currency.toUpperCase(),
    });
    return `${numberFormatter.format(item.price)} ${item.currency.toUpperCase()}`;
  }, [item.currency, item.price]);

  return (
    <BundleItemWrapper>
      <BundleItemName data-testid={`newBundleItemName-${item.id}`}>{item.name}</BundleItemName>
      <BundleItemPrice data-testid={`newBundleItemPrice-${item.id}`}>{formattedTotal}</BundleItemPrice>
      <RemoveButton data-testid={`newBundleItemRemoveButton-${item.id}`} onClick={onRemove} />
    </BundleItemWrapper>
  );
};

export default BundleItem;

import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { TextBlock } from 'components/admin2/ui/Text';
import {
  ADMIN_HIGHLIGHT_PRIMARY,
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_6,
  ADMIN_TEXT_200,
  ADMIN_TEXT_100,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_4,
} from 'style/constants';
import {
  ADMIN_CHECKBOX,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const Box = styled.label<{ checked?: boolean; type?: string }>`
  ${ADMIN_CHECKBOX}
  background: transparent;
  border-radius: 4px;
  ${({ checked, type }) => type === 'radio' && css`
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: ${checked ? ADMIN_SURFACE_5 : ADMIN_SURFACE_1};
    border: 1px solid ${ADMIN_SURFACE_4};
    padding: 4px;
  `}
`;

export const Label = styled.label<{ type?: string }>`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  display: flex;
  align-items: center;
  color: ${ADMIN_TEXT_100};
  ${({ type }) => type === 'radio' && css`
    & ${LabelText} {
      ${ADMIN_TEXT_BODY_XS_MEDIUM}
      color: ${ADMIN_TEXT_200};
    }
  `}
`;

export const LabelText = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  margin-left: 10px;
  color: ${ADMIN_TEXT_200};
`;

export const Input = styled.input<{ type: string }>`
  width: 0;
  height: 0;
  ${({ type }) => type === 'radio' ? css`display: none;` : null}
  opacity: 0;
  &:checked + span {
    background: ${ADMIN_ACCENT_PRIMARY};
    border-color: ${ADMIN_ACCENT_PRIMARY};
    opacity: 1;

    & svg {
      opacity: 1;
      & path {
        fill: ${ADMIN_TEXT_100};
        stroke: ${ADMIN_TEXT_100};
      }
    }
  }
  &:checked:disabled + span {
    opacity: 0.5;
    cursor: default;
    background: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const CheckIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_HIGHLIGHT_PRIMARY(props),
  name: 'checkmark',
}))`
  ${ADMIN_CHECKBOX}
  background: transparent;
  border: 1px solid ${ADMIN_SURFACE_6};
  border-radius: 4px;
  padding: 2px;
  & svg {
    display: block;
    opacity: 0;
    width: 10px;
    height: 10px;
  }
`;

export const Radio = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: ${ADMIN_TEXT_100};
  opacity: 0;
`;

export const StyledDescription = styled(TextBlock)`
  margin-left: 22px;
  padding-bottom: 0;
`;

import styled, { css } from 'styled-components';
import AdminModal from 'components/admin2/ui/AdminModal';
import Dropdown from 'components/admin2/ui/Dropdown';
import { LargeButton } from 'components/admin2/ui/Button';
import { ADMIN_ALERT_ERROR, ADMIN_SURFACE_2, ADMIN_SURFACE_4, ADMIN_SURFACE_1, ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_6, ADMIN_TEXT_100, ADMIN_TEXT_300 } from 'style/constants';
import { Radio as RadioComponent } from 'components/admin2/ui/Radio';
import { ACTION_DISABLED } from 'style/mixins';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_TITLE_L } from 'style/design-system/textStyles';

export const StyledModal = styled(AdminModal)`
  min-width: 550px;
  max-width: 550px;
  border-radius: 10px;
  background: ${ADMIN_SURFACE_4};
`;

export const Header = styled.div`
  padding: 30px 30px 20px;
  border-bottom: 1px solid ${ADMIN_SURFACE_6};
`;

export const Footer = styled.div`
  padding: 30px 45px;
  border-top: 1px solid ${ADMIN_SURFACE_6};
`;

export const Content = styled.div`
  padding: 20px 45px 20px;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_TITLE_L}
  margin-bottom: 5px;
`;

export const Label = styled.label`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  min-width: 50px;
  margin-top: 10px;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  & > label {
    margin-right: 20px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  & > button:nth-child(1) {
    margin-right: 15px;
  }
`;

export const Select = styled(Dropdown)`
  background: ${ADMIN_SURFACE_1};
`;

export const CancelButton = styled(LargeButton)`
  background: ${ADMIN_SURFACE_6};
`;

export const Radio = styled(RadioComponent)`
  & > label > label {
    background: ${ADMIN_SURFACE_2};
  }
  & > label > label > input:checked + span {
    background: ${ADMIN_ACCENT_PRIMARY};
    background-color: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const RadioLabel = styled.div<{ disabled?: boolean }>`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_100};
  ${({ disabled }) => disabled ? css`
      color: ${ADMIN_TEXT_300};
      ` : null}
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  & > div:nth-child(1) {
    margin-right: 12px;
  }
`;

export const ErrorBox = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_ALERT_ERROR};
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled(LargeButton as any) <{ isLoading?: boolean }>`
  height: 40px;
  padding: 0 10px;
  ${({ isLoading }) => isLoading ? css`
    ${ACTION_DISABLED}
    background: ${ADMIN_SURFACE_1};
    color: white;
    display: flex;
    column-gap: 10px;
    ` : null}
`;

export const Spinner = styled(LoadingSpinner).attrs(props => ({
  name: 'three-bounce',
  colorOverride: ADMIN_ACCENT_PRIMARY(props),
})) <{ size?: number }>`
  & > div > div {
    ${({ size }) => size ? css`
    width: ${size}px;
    height: ${size}px;
    ` : null}
  }
  .sk-three-bounce {
    height: auto;
  }
`;

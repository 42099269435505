import React, { useMemo } from 'react';
import ExpandableCard from 'components/admin2/ui/ExpandableCard';
import TranslatedText from 'components/i18n/TranslatedText';
import moment from 'moment';
import { formatPrice, titlelize } from 'shared/string-utils';
import { FREQ_MAP, DATE_FORMAT } from './constants';
import { DefaultButton, SubInfo, Title, Prop, Key, Value, CancelsDateTextContainer, Loading } from './styles';
import {
  AccountEntitlementStatus,
  GrantedMethod,
  IAccountEntitlement,
} from 'services/gate/models';
import { SubscriptionRecurrence } from 'models/ISubscription';
import { getSubscriptionPrice } from 'utils';
import type { TranslationKey } from 'hooks/use-translation';

interface IProps {
  accountEntitlement: IAccountEntitlement;
  loading?: boolean;
  onCancelSubscription?: (subscription: IAccountEntitlement) => void;
}

interface PropertyProps {
  active?: boolean;
  nameKey?: TranslationKey;
  value?: string;
  valueKey?: TranslationKey;
}
const STATUS_MAP: { [name in AccountEntitlementStatus]: TranslationKey } = {
  active: 'ADMIN_LABEL_ACTIVE',
  expired: 'ADMIN_LABEL_EXPIRED',
  canceled: 'ADMIN_LABEL_EXPIRED',
  cancelScheduled: 'ADMIN_LABEL_CANCEL_SCHEDULE',
};

const Property = ({ nameKey: key, value, valueKey, active }: PropertyProps) => (
  <Prop active={active}>
    {key && (
      <TranslatedText stringKey={key}>
        {(text) => <Key>{titlelize(text)}</Key>}
      </TranslatedText>
    )}
    {!!valueKey && <TranslatedText component={Value} stringKey={valueKey} />}
    {!!value && <Value>{value}</Value>}
  </Prop>
);

const CancelsDateText = ({ date }: { date: number }) => (
  <CancelsDateTextContainer>
    <TranslatedText
      component={Value}
      stringKey={STATUS_MAP[AccountEntitlementStatus.cancelScheduled]}
    />
    <Value>{` ${moment.unix(date).format(DATE_FORMAT)}`}</Value>
  </CancelsDateTextContainer>
);

const EntitlementCard = ({
  accountEntitlement,
  onCancelSubscription,
  loading,
}: IProps) => {
  const {
    entitlement,
    dateGranted,
    dateCanceled,
    dateRefunded,
    currentPeriodEnd,
    grantedMethod,
    presentmentCurrency,
  } = accountEntitlement;
  const startDate = dateGranted ? new Date(dateGranted) : undefined;
  const endDateNumber = dateCanceled || dateRefunded || currentPeriodEnd;
  const endDate = endDateNumber ? new Date(endDateNumber) : undefined;
  const isPurchased = grantedMethod === GrantedMethod.purchased;
  const price = getSubscriptionPrice(entitlement, presentmentCurrency || 'usd');

  const recurringSubscription = (
    entitlement.recurrence &&
    entitlement.recurrence !== SubscriptionRecurrence.once);

  if (!entitlement) {
    return null;
  }

  const formattedPrice = useMemo(() => {
    if (!price.value || !presentmentCurrency) {
      return '';
    }
    return formatPrice(presentmentCurrency, isPurchased ? price.value : 0);
  }, [price.value, presentmentCurrency, isPurchased]);

  const recurrenceKey = useMemo(() => {
      return entitlement.recurrence && FREQ_MAP[entitlement.recurrence];
    }, [entitlement.recurrence]) || '' as TranslationKey;

  const [purchaseDate, isActive] = useMemo(() => {
    return startDate
      ? [
          startDateFormatted(),
          accountEntitlement.status === AccountEntitlementStatus.active,
        ]
      : ['', false];

    function startDateFormatted() {
      return moment(startDate).format(DATE_FORMAT);
    }
  }, [startDate?.getTime(), endDate?.getTime(), accountEntitlement.status]);

  return (
    <ExpandableCard
      data-testid="ticketUserProfileCard"
      expandButtonTestId="manageEntitlementCard"
      expandTextKey="ADMIN_LABEL_MANAGE"
      subtitleKey={recurringSubscription ? recurrenceKey : undefined}
      title={entitlement.name}
      disabled={
        !recurringSubscription ||
        accountEntitlement.status === AccountEntitlementStatus.cancelScheduled
      }
      subtitleChildren={
        !recurringSubscription ? (
          <Title>
            <Property nameKey="ADMIN_LABEL_PURCHASED" value={purchaseDate} />
            <Property nameKey="ADMIN_LABEL_PRICE" value={formattedPrice} />
          </Title>
        ) : recurringSubscription &&
          accountEntitlement.status ===
            AccountEntitlementStatus.cancelScheduled &&
          endDateNumber ? (
          <CancelsDateText date={endDateNumber} />
        ) : undefined
      }
    >
      <Title>{entitlement.name}</Title>
      <SubInfo>
        <Property nameKey="ADMIN_LABEL_PURCHASED" value={purchaseDate} />
        <Property nameKey="ADMIN_LABEL_FREQUENCY" valueKey={recurrenceKey} />
        <Property nameKey="ADMIN_LABEL_PRICE" value={formattedPrice} />
        <Property
          nameKey="ADMIN_LABEL_STATUS"
          valueKey={STATUS_MAP[accountEntitlement.status]}
          active={isActive}
        />
      </SubInfo>
      {onCancelSubscription && isActive && !loading && (
        <TranslatedText
          component={DefaultButton}
          componentProps={{
            onClick: () => onCancelSubscription(accountEntitlement),
          }}
          stringKey="ADMIN_LABEL_CANCEL_SUBSCRIPTION"
        />
      )}
      <Loading loading={loading}/>
    </ExpandableCard>
  );
};

export default EntitlementCard;

import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import useFocusArea from 'hooks/use-focus-area';
import withPadding from 'components/core/withPadding';
import withLabel from 'components/core/withLabel';

import {
  CardWrapper,
  CardContainer,
  CardContent,
  DefaultButton,
  Info,
  Title,
  Subtitle,
  SubtitleText,
  ExpandableCardStyleProps,
  AdminButton,
  ChildrenContainer,
} from './styles';
import { ADMIN_BAR_FOOTER_ID } from 'components/admin-bridge/AdminBar/constants';
import type { TranslationKey } from 'hooks/use-translation';

export type ExpandableCardProps = PropsWithChildren<{
  'data-testid'?: string;
  disabled?: boolean;
  expandButtonTestId?: string;
  expandText?: string;
  expandTextKey?: TranslationKey;
  forceToggle?: boolean;
  onClose?: () => void;
  showForAdmin?: boolean;
  subtitle?: string;
  subtitleChildren?: ReactNode;
  subtitleKey?: TranslationKey;
  successful?: boolean;
  successLabel?: string;
  successLabelKey?: string;
  title?: string;
  titleKey?: TranslationKey;
  variant?: string;
}> & ExpandableCardStyleProps;

function ExpandableCard({
  children,
  disabled,
  disableButtonVariantChange,
  expandButtonTestId,
  expandText,
  expandTextKey,
  onClose,
  subtitle,
  subtitleChildren,
  subtitleKey,
  successful,
  successLabel,
  successLabelKey,
  title,
  titleKey,
  forceToggle,
  showForAdmin,
  ...props
}: ExpandableCardProps) {
  const [isExpanded, setExpanded] = useState(false);
  const [localToggle, setLocalToggle] = useState(false);
  const [containerHeight, setContainerHeight] = useState(70);
  const containerRef = useFocusArea<HTMLDivElement>({
    onExit: () => setLocalToggle(!!forceToggle),
    ignoreIDs: [ADMIN_BAR_FOOTER_ID],
    active: true,
  });

  const handleClickChangeLocalToggle = () => setLocalToggle(!localToggle);

  useEffect(() => {
    const toggle = localToggle !== !!forceToggle;

    if (onClose && isExpanded) {
      onClose();
    }
    setExpanded(toggle);
  }, [localToggle, forceToggle]);

  const getCardHeight = (node: HTMLDivElement | null) => {
    const height = node?.scrollHeight;
    if (!height || height === containerHeight) {
      return;
    }
    setContainerHeight(height);
  };

  return (
    <>
      <CardWrapper showForAdmin={showForAdmin} ref={getCardHeight} height={containerHeight} isExpanded={isExpanded} {...props}>
        <CardContainer
          ref={containerRef}
          collapsedCardHeight={props.collapsedCardHeight}
          data-testid={props['data-testid']}
          disableButtonVariantChange={disableButtonVariantChange}
          isExpanded={isExpanded}
        >
          <ChildrenContainer hidden={!isExpanded}>
            {children}
          </ChildrenContainer>
          <CardContent hidden={isExpanded}>
            <Info>
              <OptionalTranslated component={Title} stringKey={titleKey} showForAdmin={showForAdmin}>
                {title}
              </OptionalTranslated>
              <Subtitle>
                <OptionalTranslated component={SubtitleText} stringKey={subtitleKey} showForAdmin={showForAdmin}>
                  {subtitle}
                </OptionalTranslated>
                {subtitleChildren}
              </Subtitle>
            </Info>
            {disabled || (
              <>
                <OptionalTranslated
                  data-testid={expandButtonTestId}
                  component={showForAdmin ? AdminButton : DefaultButton}
                  onClick={handleClickChangeLocalToggle}
                  stringKey={expandTextKey}
                  showForAdmin={showForAdmin}
                >
                  {expandText}
                </OptionalTranslated>
              </>
            )}
          </CardContent>
        </CardContainer>
      </CardWrapper>
    </>
  );
}

export default withPadding(withLabel(ExpandableCard), '0 0 9px 0');

import { connect } from 'react-redux';
import { fetchSilencedUsers, toggleUserSilence } from 'services/chat/actions';
import { getSilencedUsers } from 'services/chat/selectors';
import { getObjectId } from 'services/app/selectors';
import ChatPanelEditor from './ChatPanelEditor';

const mapStateToProps = state => ({
  channelId: getObjectId(state),
  silencedUsers: getSilencedUsers(state),
});

const mapDispatchToProps = dispatch => ({
  syncSilencedUsers: payload => dispatch(fetchSilencedUsers(payload)),
  unsilenceUser: ({ channel, userId, userType }) => dispatch(toggleUserSilence({
    channel,
    isSilenced: false,
    userId,
    userType,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatPanelEditor);

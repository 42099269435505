import React from 'react';
import { useAdminTranslation } from 'hooks/use-translation';
import { Container, Dot } from './styles';

const UnpublishedChangesBadge: React.FC = () => {
  const { t } = useAdminTranslation();
  return (
    <Container>
      <Dot />
      {t('UNPUBLISHED_CHANGES')}
    </Container>
  );
};

export default UnpublishedChangesBadge;

import React, { useMemo, HTMLAttributes } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  Container,
  StyledIcon,
  Butt,
} from './styles';

interface TabButtonProps extends Omit<HTMLAttributes<HTMLDivElement>, 'id'>, TabButtonVariant {
  active: boolean;
  'data-testid'?: string;
  iconName?: string;
  id: number;
  onTabClick?: (index: number) => void;
  stringKey: string;
}

export interface TabButtonVariant {
  variant?: 'tail' | 'highlighted';
}

export default function TabButton({
  active,
  className,
  iconName,
  id,
  onTabClick,
  stringKey,
  variant = 'tail',
  ...props
}: TabButtonProps) {
  const onClick = useMemo(() => onTabClick && (() => {
    onTabClick(id);
  }), [onTabClick]);

  const showTail = useMemo(() => variant === 'tail', [variant]);

  return (
    <Container
      className={`${variant} ${className} ${active ? 'active' : ''}`}
      data-testid={props['data-testid']}
      onClick={onClick}
      key={stringKey}
    >
      {iconName && <StyledIcon name={iconName} />}
      <TranslatedText
        stringKey={stringKey}
      />
      {
        active && showTail && (
          <Butt />
        )
      }
    </Container>
  );
}

import styled, { css } from 'styled-components';
import TabHeader from 'components/admin2/ui/TabHeader';
import { SubHeader } from 'components/admin2/ui/TabHeader/styles';
import {
  TAB_WIDTH_MD, TAB_PADDING, ADMIN_SURFACE_4, ADMIN_TEXT_100, ADMIN_SURFACE_6,
} from 'style/constants';
import {
  ADMIN_BAR_TAB,
  ADMIN_SCROLL_BAR,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

const AdminBarTabHeader = styled(TabHeader).attrs(({ adminbarTab = true }) => ({
  adminbarTab,
  saveExisting: true,
}))<{noRightBorder?: boolean}>`
  min-height: 52px;
  max-height: 52px;
  border-bottom: 1px solid ${ADMIN_SURFACE_6};
  ${({ noRightBorder }) => !noRightBorder && css`border-right: 1px solid ${ADMIN_SURFACE_6};`}
  & + ${SubHeader} {
    border-top: none;
    min-height: 40px;
    height: 40px;
  }
`;

export const AdminBarTab = styled.section`
  position: relative;
  flex-shrink: 0;
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
  ${ADMIN_BAR_TAB}
  ${ADMIN_SCROLL_BAR}
  display: flex;
  flex-flow: column nowrap;
  min-width: ${TAB_WIDTH_MD};
  background-color: ${ADMIN_SURFACE_4};
  border-right: 1px solid ${ADMIN_SURFACE_6};
  & > *:first-child {
    margin-inline: -${TAB_PADDING};
    border-right: 0;
    margin-bottom: 20px;
  }
`;

export default AdminBarTabHeader;

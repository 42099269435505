import styled from 'styled-components';
import Label from 'components/admin2/ui/Label';

export interface LabelToggleStyleProps {
  noSpaceBetween?: boolean;
  verticalCenter?: boolean;
}

export const Container = styled.div<LabelToggleStyleProps>`
  display: flex;
  ${({ verticalCenter, noSpaceBetween }) => `
    ${verticalCenter && 'align-items: center;'}
    ${!noSpaceBetween && 'justify-content: space-between;'}
  `}
`;

export const StyledLabel = styled(Label)`
  min-width: 0;
`;

import styled, { css } from 'styled-components';
import { ADMIN_TEXT_100, ADMIN_TEXT_300, SPACING_SMALL } from 'style/constants';
import { StyleProps, activeColor } from '../../styles';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';
import Icon from 'components/ui/Icon';

export const LocalizationOption = styled.div<{isActive: boolean, isAdmin: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;
  height: 52px;
  padding-left: ${SPACING_SMALL};
  ${({ isActive, ...props }) => isActive && css`
    background-color: ${activeColor(props)};
    color: ${ADMIN_TEXT_100};
  `}
`;

export const LocalizationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LocalizationOptionTitle = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD};
  ${ADMIN_TEXT_100};
`;

export const LocalizationOptionSubtitle = styled.div`
  ${ADMIN_TEXT_LABEL_XS_MEDIUM};
  ${ADMIN_TEXT_300};
`;

export const LocalizationCheckIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_100(props),
  name: 'checkmark',
})) <StyleProps>`
  margin-right: ${SPACING_SMALL};
  & svg {
    display: block;
    width: 11px;
    height: 11px;
  }
`;

import React from 'react';
import { DropdownWithoutPadding } from 'components/admin2/ui/Dropdown';
import { DropdownProps } from 'components/admin2/ui/Dropdown/interfaces';
import Currency from './CurrencyOption';

const CurrencyDropDown = (props: DropdownProps<any>) => {
  const getLabel = (option: any) => option as string;
  return (
    <DropdownWithoutPadding
      {...props}
      getOptionLabel={getLabel}
      isSearchable={true}
      replaceOption={true}
      replaceButton={true}
    >
      <Currency/>
    </DropdownWithoutPadding>
  );
};

export default CurrencyDropDown;

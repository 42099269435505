import styled from 'styled-components';

export const BundleTotalWrapper = styled.div<{ hasProduct: boolean; hasTicket: boolean; itemsHasSameCurrency: boolean; }>`
  display: flex;
  align-items: center;
  margin: 20px 0px;
  justify-content: ${({ hasProduct, hasTicket, itemsHasSameCurrency }) => hasProduct && hasTicket && itemsHasSameCurrency ? 'space-between' : 'center'};
  & > div {
    margin-top: 0;
  }
`;

import React from 'react';
import ThemeCard from '../ThemeCard';
import {
  ThreeDotsIcon,
  ThemeName,
  StyledHeader,
  Row,
  OptionsButton,
  Header,
  Footer,
  FilledContainer,
  CustomizeIcon,
  CustomizeButton,
  Container,
  CloseIcon,
} from './styles';
import { useFocusArea } from 'hooks';
import { IThemeColors } from 'models';
import { THEME_TYPES, ThemeTypes } from 'models/ITheme';
import { useAdminTranslation } from 'hooks/use-translation';

interface IActiveThemeCardProps {
  hideFooter?: boolean;
  name?: string;
  onCustomize?: () => void;
  theme: IThemeColors;
  type: ThemeTypes;
}

const ActiveThemeCard = ({ name = '', theme, hideFooter = false, onCustomize, type = THEME_TYPES.CLASSIC }: IActiveThemeCardProps) => {
  const [settingsOpen, setSettingsOpen] = React.useState<boolean>(false);
  const footerRef = useFocusArea({
    onExit: () => setSettingsOpen(false),
    active: settingsOpen,
  });
  const { t } = useAdminTranslation();

  const onSettingsClick = () => {
    setSettingsOpen(oldState => !oldState);
  };

  const onCustomizeClick = () => {
    return onCustomize?.();
  };

  const renderedFooter = React.useMemo(() => {
    if (hideFooter) {
      return null;
    }

    if (settingsOpen) {
      return (
        // @ts-ignore
        <Footer ref={footerRef} shouldPaint={true}>
          <CustomizeButton data-testid="editActiveTheme" onClick={onCustomizeClick}>
            <CustomizeIcon tooltipKey="ADMIN_THEMES_CUSTOMIZE_THEME" />
          </CustomizeButton>
          <OptionsButton settingsOpen={settingsOpen} onClick={onSettingsClick}>
            <CloseIcon />
          </OptionsButton>
        </Footer>
      );
    }
    return (
      <Footer shouldPaint={false}>
        <ThemeName>{name}</ThemeName>
        <OptionsButton settingsOpen={settingsOpen} onClick={onSettingsClick}>
          <ThreeDotsIcon />
        </OptionsButton>
      </Footer>
    );
  }, [settingsOpen, name, hideFooter]);

  return (
    <Container>
      <StyledHeader>
        <Header>{t('ADMIN_SETTINGS_DESIGN_SECTION_LOGO_WEB')}</Header>
        <Header withPadding={true}>{t('ADMIN_SETTINGS_DESIGN_SECTION_LOGO_MOBILE')}</Header>
      </StyledHeader>
      <FilledContainer>
        <Row>
          <ThemeCard themeColors={theme} type={type} />
          <ThemeCard themeColors={theme} mobile={true} type={type} />
        </Row>
        {renderedFooter}
      </FilledContainer>
    </Container>
  );
};

export default ActiveThemeCard;

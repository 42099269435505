import { ADMIN_SURFACE_1, ADMIN_SURFACE_4, ADMIN_TEXT_100, ADMIN_ACCENT_PRIMARY } from 'style/constants';
import styled, { css } from 'styled-components';
import AdminModal from 'components/admin2/ui/AdminModal';
import { CloseButton, LargeButton } from 'components/admin2/ui/Button';
import { ADMIN_TEXT_BODY_M_REGULAR, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const DeleteThemeBaseModal = styled(AdminModal)`
  position: relative;
  padding: 30px;
  height: 210px;
  width: 300px;
  background-color: ${ADMIN_SURFACE_4};
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledCloseButton = styled(CloseButton).attrs({
  containerCss: `
    position: absolute;
    top: 8px;
    right: 9px;
    & span {
      background-color: rgba(0, 0, 0, 0.3);
    }
  `,
})``;

const BUTTON_CSS = css`
  width: 83px;
  height: 40px;
  padding: 13px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ADMIN_TEXT_BODY_S_BOLD}
`;

export const NoButton = styled(LargeButton)`
  ${BUTTON_CSS};
  background-color: ${ADMIN_SURFACE_1};
`;

export const YesButton = styled(LargeButton)`
  ${BUTTON_CSS};
  margin-left: 9px;
  background-color: ${ADMIN_ACCENT_PRIMARY};
`;

export const HeaderText = styled.span`
  ${ADMIN_TEXT_BODY_M_REGULAR}
  color: ${ADMIN_TEXT_100};
`;

export const DescriptionText = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_100};
  margin: 10px 0px 20px;
  padding: 0px 35px;
`;

export const ActionWrapper = styled.div`
  display: flex;
`;

/* tslint:disable: jsx-boolean-value */
import React, { PropsWithChildren } from 'react';
import { DownArrow } from 'components/admin2/ui/Button';
import {
  CollapsibleItemContainer,
  ExpandableItemContent,
  ItemHeader,
  ItemHeaderProps,
} from './styles';
import withPadding from 'components/core/withPadding';

interface ItemProps extends ItemHeaderProps {
  open?: boolean;
  showArrow?: boolean;
}

function Item({
  children,
  open,
  showArrow,
  ...props
}: PropsWithChildren<ItemProps>) {
  return (
    <CollapsibleItemContainer>
      <ItemHeader
        extraActions={showArrow ? <DownArrow spacing="0 0 0 5px" up={open} /> : undefined}
        isCatalogItem
        isExpanded={open}
        verticalCenterActions
        verticalPositionActions
        {...props}
      />
      <ExpandableItemContent isExpanded={open}>
        {children}
      </ExpandableItemContent>
    </CollapsibleItemContainer>
  );
}

export default withPadding(Item, '5px 0');

import styled from 'styled-components';
import PageNavigation from 'components/ui/PageNavigation';
import {
  ADMIN_SURFACE_5,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  ADMIN_TEXT_300,
  SPACING_X_SMALL,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_6,
  ADMIN_TEXT_500,
  ADMIN_SURFACE_1,
  ADMIN_TEXT_200,
} from 'style/constants';
import Icon from 'components/ui/Icon';
import { ThumbnailPlaceholder } from 'components/admin-bridge/PageManager/PageEditorVideoItem/styles';
import { ADMIN_ACTION_NORMAL_SMALL, ADMIN_SCROLL_BAR } from 'style/mixins';
import Button, { DownArrow } from '../ui/Button';
import { ADMIN_TEXT_BODY_L_MEDIUM, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import ReactTable from 'react-table-6';

const imageHeightPx = '44px';
const imageWidthPx = '77px';

export interface TableStyleProps {
  headBackground?: string;
  isSelected?: boolean;
  itemBackground?: string;
}


export const StyledReactTable = styled(ReactTable)`
  ${ADMIN_SCROLL_BAR};
  border: none;
  color: ${ADMIN_TEXT_100};
  flex: 0 1 100%;
  overflow: hidden;
  max-height: 100%;

  & .rt-thead .rt-tr .rt-th {
    border-right: none;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    font-family: "proxima-nova-regular,sans-serif";
    height: 36px;
    letter-spacing: 1px;
    text-transform: uppercase;
    justify-content: center;
    box-shadow: none;
    transition: none;
  }

  & .rt-thead.-header {
    box-shadow: none;
  }

  & .rt-td {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    cursor: pointer;
    font-size: 12px;
  }

  & .rt-td.disabled {
    opacity: 0.5;
  }

  & .rt-thead {
    box-shadow: none;
    padding-bottom: 12px;
  }

  & .rt-thead .rt-tr .rt-th:focus {
    outline: none;
  }

  & .rt-noData {
    display: none;
  }

  & .rt-table {
    overflow-y: auto;
    overflow-x: hidden;
  }

  & .rt-tr-group {
    flex: none;
  }

  & .rt-tbody .rt-tr {
    outline: none;
    border-radius: 10px;
    margin-bottom: 5px;

    &:hover {
      outline: none;
      background-color: ${ADMIN_SURFACE_6};
    }

    &:not(:hover) .enable-on-row-hover {
      background-color: ${ADMIN_SURFACE_6};
      color: #858996;
    }

    &:not(:hover) .show-on-row-hover {
      display: none;
    }
  }

  & .-pagination {
    align-items: center;
    background: transparent;
    flex: 0;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    height: 50px;
    text-transform: uppercase;
    word-spacing: 3px;

    & .-btn {
      background: transparent;
      border: none;
      color: ${ADMIN_TEXT_500};
      font-size: 48px;
      padding: 0;
      margin-top: -5px;
      width: 20px;
    }

    & .-center .select-wrap.-pageSizeOptions{
    position: absolute;
    right: 20px;
    }

    & .-btn:not([disabled]):hover {
      background: transparent;
    }

    & .-next {
      flex: 0;
    }

    & .-previous {
      flex: 0;
    }

    & .-center {
      flex: 0;
      justify-content: center;
      min-width: 120px;
    }
  }

  & .-loading {
    background: ${ADMIN_SURFACE_1};

    & > div {
      color: ${ADMIN_TEXT_200};
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    & .-active > div {
      -webkit-transform: translateY(0);
      transform: translateY(0)
    }
  }

`;

export const PaginationContainer = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  &:before {
    content: "";
    flex: 1;
  }
`;

export const StyledNavigation = styled(PageNavigation)`
  position: absolute;
  width: 33%;
  transform: translateX(-50%);
  left: 50%;
`;

export const StyledThead = styled.div<TableStyleProps>`
  &, & *, & * * {
    background: ${({ headBackground = 'transparent' }) => headBackground};
  }
  & > div {
    width: 100%;
    border-bottom: 1px solid ${ADMIN_SURFACE_5};
  }
  flex: 0;
  padding: 0px 20px;
`;

export const StyledTrGroup = styled.div<TableStyleProps>`
  & > div {
    display: flex;
    background: ${({ itemBackground = ADMIN_SURFACE_5 }) => itemBackground};
    border-radius: 10px;
    color: ${ADMIN_TEXT_100};
    height: 75px;
    width: 100%;
    margin: 5px 0;
    padding: 0 15px;
    &:hover {
      background: ${ADMIN_SURFACE_6};
    }
    border: ${({ isSelected = false, ...props }) =>
      isSelected ? `1px solid ${ADMIN_ACCENT_PRIMARY(props)}` : 'none'
    }
  }
`;

export const VisibilityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-right: 7px;
  }
`;

export const CustomHeader = styled.div<{ preventSort: boolean }>`
 &:hover > div, &:hover > span {
    color: ${ADMIN_TEXT_100}
 }
  align-items: center;
  cursor: ${({ preventSort }) => preventSort ? 'default !important' : 'pointer'};
  display: flex;
  pointer-events: ${({ preventSort }) => preventSort ? 'none !important' : 'auto'};
`;

export const ImageNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-right: 10px;
`;

export const Image = styled.img<{ rounded: boolean }>`
  border-radius: ${props => props.rounded ? '50%' : 0};
  height: ${imageHeightPx};
  width: ${props => props.rounded ? imageHeightPx : imageWidthPx};
  object-fit: ${props => props.rounded ? 'cover' : 'contain'};
`;

export const PlaceholderIcon = styled(Icon).attrs(props => ({
  color: ADMIN_TEXT_100(props),
}))`
  ${ADMIN_TEXT_BODY_L_MEDIUM}
  opacity: 0.3;
  color: ${ADMIN_TEXT_100};
`;

export const StyledPlaceholder = styled(ThumbnailPlaceholder)<{ rounded: boolean }>`
  ${props => props.rounded ? 'border-radius:50%;' : null}
  height: ${imageHeightPx};
  width: ${props => props.rounded ? imageHeightPx : '45px'};
  ${props => props.rounded ? 'object-fit: cover;' : null}
`;

export const Title = styled.div`
  ${ADMIN_ACTION_NORMAL_SMALL};
  ${ADMIN_TEXT_BODY_S_BOLD}
  color: ${ADMIN_TEXT_300};
  text-transform: capitalize;
  margin-right: 3px;
  text-transform: capitalize;
  color: ${ADMIN_TEXT_300};
`;

export const Cell = styled.div<{ disabled: boolean, isFirst: boolean }>`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  padding-left: ${p => p.isFirst ? SPACING_X_SMALL : '0px'};
  color: ${props => !props.disabled ? ADMIN_TEXT_100 : ADMIN_TEXT_300} !important;
  display: flex;
  align-items: center;
`;

export const Spacer = styled.div`
  width: 20px;
  height: 20px;
`;

export const headerStyle = {
  cursor: 'default',
  justifyContent: 'flex-start',
  pointerEvents: 'none',
};

export const Arrow = styled(DownArrow).attrs({
  size: 10,
  spacing: '0 0 0 10px',
})``;

export const UseButton = styled(Button)`
  width: 56px;
  height: 24px;
`;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Label from 'components/admin2/Label';
import ThrottledInput from 'components/admin2/ThrottledInput';
import TranslatedText from 'components/i18n/TranslatedText';
import InputLabel from 'components/admin2/InputLabel';
import UploadZone from 'components/admin2/UploadZone';
import { ADMIN_ALERT_WARNING, SPACING_SMALL } from 'style/constants';
import { useAdminTranslation } from 'hooks/use-translation';

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  & > :not(:first-child) {
    margin-left: 16px;
  }
`;

const InfoColumn = styled.div`
  flex: 1 1 auto;
`;

const ImageColumn = styled.div`
  flex: 0 0 200px;
`;
const LabelContainer = styled.div`
  display: flex;
`;

const WarningLabel = styled(Label)`
  color: ${ADMIN_ALERT_WARNING};
  margin-left: ${SPACING_SMALL};
`;

const propTypes = {
  rewardDescription: PropTypes.string,
  rewardImageUrl: PropTypes.string,
  setRewardDescription: PropTypes.func.isRequired,
  setRewardImageUrl: PropTypes.func.isRequired,
  setWebhookType: PropTypes.func,
  webhookType: PropTypes.string,
};

const defaultProps = {
  rewardDescription: '',
  rewardImageUrl: null,
  setWebhookType: null,
  webhookType: undefined,
};

export default function RewardDetails({
  rewardImageUrl,
  rewardDescription,
  webhookType,
  setRewardImageUrl,
  setRewardDescription,
  setWebhookType,
}) {
  const { t } = useAdminTranslation();

  return (
    <Container>
      <InfoColumn>
        <LabelContainer>
          <InputLabel>{t('ADMIN_LABEL_QUEST_REWARD')}</InputLabel>
          <TranslatedText component={WarningLabel} stringKey="ADMIN_LABEL_REQUIRED" />
        </LabelContainer>
        <ThrottledInput
          onChange={setRewardDescription}
          placeholderText={t('ADMIN_QUEST_EDITOR_REWARD_EXPLANATION')}
          value={rewardDescription}
        />
        {(typeof setWebhookType === 'function') && (
          <>
            <InputLabel style={{ 'margin-top': '16px' }}>{t('ADMIN_QUEST_EDITOR_REWARD_TYPE')}</InputLabel>
            <ThrottledInput
              onChange={setWebhookType}
              value={webhookType}
            />
          </>
        )}
      </InfoColumn>
      <ImageColumn>
        <InputLabel>{t('ADMIN_QUEST_EDITOR_REWARD_IMAGE')}</InputLabel>
        <UploadZone
          imagePreview={rewardImageUrl || undefined}
          onClearImage={setRewardImageUrl}
          onFileSubmit={setRewardImageUrl}
        />
      </ImageColumn>
    </Container>
  );
}

RewardDetails.propTypes = propTypes;
RewardDetails.defaultProps = defaultProps;

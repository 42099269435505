import styled, { css } from 'styled-components';
import { ADMIN_SURFACE_6 } from 'style/constants';

interface DividerProps {
  color?: string;
  height?: string;
  margin?: string;
  orientation?: 'horizontal' | 'vertical';
  width?: string;
}

const Divider = styled.div<DividerProps>`
  width: ${({ width = '100%' }) => width};
  ${({ height }) => height && `height: ${height};`}
  margin: ${({ margin }) => margin || '20px 0'} !important;
  border-${({ orientation = 'horizontal' }) => (
    orientation === 'horizontal' ? 'bottom' : 'right'
  )}: 1px solid ${({ color = ADMIN_SURFACE_6 }) => css`${color}`};
`;

export default Divider;

/* tslint:disable: jsx-boolean-value */
import React, { useCallback, useState, useMemo, useEffect, useRef, UIEventHandler } from 'react';
import withLabel from 'components/core/withLabel';
import withPadding from 'components/core/withPadding';
import useFocusArea from 'hooks/use-focus-area';
import useScrollToView from 'hooks/use-scroll-to-view';
import hash from 'json-stable-stringify';
import startCase from 'lodash/startCase';
import { DROPDOWN_CONTAINER, DROPDOWN_MENU } from 'css-classes';
import ButtonRenderer from './ButtonRenderer';
import OptionRenderer from './OptionRenderer';
import { useSelector } from 'react-redux';
import {
  Container,
  DropDownMenu,
  KeyListener,
  LabelWithoutPadding,
} from './styles';
import {
  DropdownProps,
} from './interfaces';
import { debounce } from 'lodash';
import { getAdminSurface5 } from 'services/themes';
import { useAdminTranslation } from 'hooks/use-translation';

export type {
  BaseProps,
  SelectHandler,
  OptionGetter,
  HOCProps,
  DropdownProps,
  OptionProps,
  OptionRendererProps,
  OptionViewProps,
  ButtonRendererProps,
  ButtonViewProps,
  RendererProps,
} from './interfaces';

export function Dropdown<T extends Record<string, any>>({
  children,
  colWidth,
  compactDropdown,
  isAdmin = true,
  keyPrevRow = 'ArrowUp',
  keyNextRow = 'ArrowDown',
  keyPrev,
  keyNext,
  dataTestId,
  disableSelection,
  getOptionLabel = (option) => option.label as string,
  onChange,
  onInputChange,
  onMenuScrollToBottom,
  options,
  hideSearchIcon,
  isDisabled,
  isSearchable = true,
  dropUp: defaultDropUp,
  placeholder = '',
  placeholderKey = '',
  value,
  valueKey = 'value',
  translated = false,
  replaceSearchInput,
  replaceButton,
  replaceOption,
  searchPlaceholderKey,
  shouldCheckDropDownOverflow = true,
  shouldHideTitle,
  testIdSearchInput,
  startsOpen,
  dropdownMaxHeight,
  isLocalization,
  isSiteNavigation,
  isCustomNavigation,
  isCtaBehavior,
  ...props
}: DropdownProps<T>) {
  const keyListenerRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const colCountRef = useRef(0);
  const navigationRef = useRef({
    navNext: keyNext,
    navNextRow: keyNextRow,
    navPrev: keyPrev,
    navPrevRow: keyPrevRow,
  });
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const adminThemeSurface5 = useSelector(getAdminSurface5);

  const getDropdownRef = (node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }
    const { width: dropdownWidth } = node.getBoundingClientRect();
    colCountRef.current = !colWidth || !dropdownWidth ? 0 : Math.floor(dropdownWidth / colWidth);

    if (colCountRef.current) {
      navigationRef.current.navNext = keyNext ? keyNext : 'ArrowRight';
      navigationRef.current.navPrev = keyPrev ? keyPrev : 'ArrowLeft';
      return;
    }
    navigationRef.current.navNext = keyNext ? keyNext : 'ArrowDown';
    navigationRef.current.navPrev = keyPrev ? keyPrev : 'ArrowUp';
  };

  const hasSections = useMemo(() => {
    return options?.[0]?.options instanceof Array;
  }, [hash(options)]);

  const [filteredOptions, setFilteredOptions] = useState<T[]>(options);
  const [isOpen, setOpen] = useState(Boolean(startsOpen));
  const [selectedValue, setSelectedValue] = useState(value);
  const [suggestion, setSuggestion] = useState('');
  const [dropUp, setDropUp] = useState(defaultDropUp);

  const { t } = useAdminTranslation();

  const updatePlaceholder = (fallback?: string) => {
    const selectedOption = items[listPointer.current];
    const searchInput = searchRef.current;
    if (!searchInput) {
      return;
    }
    if (selectedOption) {
      searchInput.placeholder = getLabel(selectedOption, placeholder);
      return;
    }
    if (fallback) {
      searchInput.placeholder = fallback;
    }
  };

  const getLabel = useCallback((option: T, fallback: string = '') => {
    const labelKey = getOptionLabel(option)?.toString() || fallback;
    const label = t(labelKey as any) || fallback;
    return translated ? label : labelKey;
  }, [getOptionLabel, translated]);

  const selectedLabel = useMemo(() => {
    if (!selectedValue) {
      return placeholder;
    }
    return startCase(getLabel(selectedValue));
  }, [hash(selectedValue), getLabel]);

  // TODO: clean the shouldTranslate logic up
  const [shouldTranslate, placeholderText] = useMemo(() => {
    const text = (disableSelection || !selectedValue) ? placeholder : getOptionLabel(selectedValue || {} as T) || placeholder;
    return text ? [translated, text] : [!!placeholderKey, placeholderKey];
  }, [hash(selectedValue), placeholder, placeholderKey, translated]);

  const MenuRef = useFocusArea<HTMLDivElement>({
    onExit: () => {
      setOpen(false);
    },
    active: isOpen,
  });

  const items = useMemo(() => {
    if (hasSections) {
      const filtered: T[] = [];
      for (const section of filteredOptions) {
        const sectionOptions = section.options;
        filtered.push(...sectionOptions);
      }
      return filtered as typeof filteredOptions;
    }
    return filteredOptions;
  }, [hasSections, hash(filteredOptions)]);

  const [ItemRefs, activeIndex] = useScrollToView<HTMLDivElement, T>(selectedValue, valueKey, items, isOpen);
  const listPointer = useRef(activeIndex);

  const navigateList = (e: React.KeyboardEvent) => {
    const { key } = e;
    const { navNext, navPrev, navPrevRow, navNextRow } = navigationRef.current;
    if (!isOpen || !(
      key === 'Enter' ||
      key === navNext ||
      key === navPrev ||
      key === navPrevRow ||
      key === navNextRow
    )) {
      return;
    }
    const currentItem = ItemRefs[listPointer.current]?.current;
    if (currentItem) {
      currentItem.style.background = '';
    }
    const currentIndex = currentItem ? listPointer.current : 0;
    if (key === 'Enter') {
      onOptionSelect(items[currentIndex]);
      return;
    }
    const last = ItemRefs.length - 1;
    if (key === navPrev) {
      e.preventDefault();
      const prev = currentIndex - 1;
      listPointer.current = prev >= 0 ? prev : last;
    }
    if (key === navNext) {
      e.preventDefault();
      const next = currentIndex + 1;
      listPointer.current = next <= last ? next : 0;
    }
    const colCount = colCountRef.current;
    if (colCount) {
      if (key === navPrevRow) {
        e.preventDefault();
        const prevRow = currentIndex - colCount;
        listPointer.current = prevRow >= 0 ? prevRow : last;
      }
      if (key === navNextRow) {
        e.preventDefault();
        const nextRow = currentIndex + colCount;
        listPointer.current = nextRow <= last ? nextRow : 0;
      }
    }
    selectItem();
  };

  const selectItem = (scroll = true) => {
    const selectedItem = ItemRefs[listPointer.current]?.current;
    if (selectedItem) {
      selectedItem.style.background = adminThemeSurface5;
      updatePlaceholder();
      if (scroll) {
        selectedItem.scrollIntoView({
          block: 'nearest',
        });
      }
    }
  };

  const checkOverflow = () => {
    if (!shouldCheckDropDownOverflow) {
      return;
    }

    const dropdown = dropdownMenuRef.current;
    if (!dropdown) {
      return;
    }
    const rect = dropdown.getBoundingClientRect();
    const isOverflowing = (
      rect.y < 0 || (rect.y + rect.height) > window.innerHeight
    );
    setDropUp((currentDrop) => isOverflowing ? !currentDrop : currentDrop);
  };

  const queryOptions = useCallback((inputValue: string) => {
    onInputChange?.(inputValue);
    if (inputValue && !isOpen) {
      setOpen(true);
    }
    const filter = (option: T) => {
      const label = getLabel(option);
      return label.toString().toLowerCase().includes(inputValue.toLowerCase());
    };
    const filtered = hasSections ?
      options.map(section => ({
        ...section,
        options: section.options.filter(filter),
      })).filter(section => section.options.length) : options.filter(filter);
    setFilteredOptions(inputValue ? filtered : options);
  }, [hash(options)]);

  const toggleDropdown = () => {
    setOpen(!isOpen);
    setFilteredOptions(options);
  };

  const onOptionSelect = (rowData: T) => {
    setOpen(false);
    setSelectedValue(rowData);
    onChange(rowData);
    setFilteredOptions(options);
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(debounce((e) => {
    const menu = dropdownMenuRef.current;
    if (!menu) {
      return;
    }
    const { clientHeight, scrollHeight, scrollTop } = menu;
    if (scrollHeight === clientHeight + scrollTop) {
      onMenuScrollToBottom?.(e);
    }
  }, 500), [dropdownMenuRef, onMenuScrollToBottom]);

  useEffect(() => {
    const searchInput = searchRef.current;
    queryOptions(searchInput?.value || '');
    setSelectedValue(value);
  }, [hash(options)]);

  useEffect(() => {
    checkOverflow();
    const option = items[0];
    const search = searchRef.current?.value;
    if (!search || !option) {
      return setSuggestion('');
    }
    if (isOpen) {
      setSuggestion(getLabel(option) || '');
    }
  }, [hash(filteredOptions), isOpen]);

  useEffect(() => {
    const keyListener = keyListenerRef.current;
    const searchInput = searchRef.current;
    if (!isOpen && searchInput) {
      searchInput.value = '';
      setSuggestion('');
    }
    if (isOpen) {
      selectItem(false);
      keyListener?.focus();
      searchInput?.focus();
    }
    listPointer.current = activeIndex;
    updatePlaceholder(placeholder);
  }, [isOpen, activeIndex]);

  useEffect(() => {
    setSelectedValue(value);
  }, [hash(value)]);

  const formatTitle = (text: string): string => {
    if(!text) {
      return '';
    }
    if(text.length > 60) {
      return text.substring(0, 60) + ' ...';
    } else {
      return text;
    }
  };

  return (
    <Container
      ref={MenuRef}
      isActive={isOpen}
      isAdmin={isAdmin}
      isDisabled={isDisabled}
      className={DROPDOWN_CONTAINER}
      {...props}
    >
      {isSearchable || <KeyListener ref={keyListenerRef} onKeyDown={navigateList} />}
      <ButtonRenderer
        compact={compactDropdown}
        compactDropdown={compactDropdown}
        data={selectedValue}
        displaySearchInput={isSearchable && isOpen && !replaceSearchInput}
        hideSearchIcon={hideSearchIcon}
        isAdmin={isAdmin}
        isSelected={!!selectedValue}
        isOpen={isOpen}
        onClick={isDisabled ? undefined : toggleDropdown}
        placeholderText={formatTitle(placeholderText)}
        replace={replaceButton}
        data-testid={dataTestId}
        searchInputProps={{
          compact: true,
          disabled: !isSearchable,
          delay: 100,
          hideIcon: true,
          inputRef: searchRef,
          onClick: isDisabled ? undefined : toggleDropdown,
          onKeyDown: navigateList,
          onSearch: queryOptions,
          placeholder: selectedLabel,
          searchPlaceholderKey: selectedLabel ? undefined : searchPlaceholderKey,
          short: true,
          suggestion,
          testIdSearchInput,
        }}
        shouldHideTitle={shouldHideTitle}
        shouldTranslate={shouldTranslate}
      >
        {children}
      </ButtonRenderer>
      {
        isOpen && (
          <DropDownMenu
            ref={dropdownMenuRef}
            dropdownMaxHeight={dropdownMaxHeight}
            dropUp={dropUp}
            isAdmin={isAdmin}
            onScroll={handleScroll}
            className={DROPDOWN_MENU}
          >
            <OptionRenderer
              activeIndex={disableSelection ? -1 : activeIndex}
              contentRef={getDropdownRef}
              getOptionLabel={getOptionLabel}
              hasSections={hasSections}
              isAdmin={isAdmin}
              itemRefs={ItemRefs}
              onOptionSelect={onOptionSelect}
              options={filteredOptions}
              replace={replaceOption}
              translated={translated}
              data-testid={dataTestId ? `${dataTestId}Item` : undefined}
              isLocalization={isLocalization}
              isSiteNavigation={isSiteNavigation}
              isCustomNavigation={isCustomNavigation}
              isCtaBehavior={isCtaBehavior}
            >
              {children}
            </OptionRenderer>
          </DropDownMenu>
        )
      }
    </Container>
  );
}

export default withPadding(withLabel(Dropdown)) as typeof Dropdown;

export const DropdownWithoutPadding = withLabel(Dropdown, { styledLabel: LabelWithoutPadding }) as typeof Dropdown;
export const DropdownNoPaddingNoLabel = Dropdown;

import moment from 'moment-timezone';
import { findDefaultPrice } from 'shared/string-utils';
import capitalize from 'lodash/capitalize';
import { injectT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';

const FREQUENCY = injectT(
  (t) => lazy(
    () => ({
      month: t('ADMIN_LABEL_FREQUENCY_MONTHLY'),
      once: t('ADMIN_LABEL_FREQUENCY_ONE_TIME'),
      undefined: t('ADMIN_LABEL_FREQUENCY_ONE_TIME'),
      year: t('ADMIN_LABEL_FREQUENCY_YEARLY'),
    }),
  ),
);

const TYPE_TRANSLATIONS = {
  bundle: 'ADMIN_COMMUNITY_TYPE_BUNDLE',
  subscription: 'ADMIN_BILLING_ITEM_SUBSCRIPTION',
  ticket: 'ADMIN_COMMUNITY_TYPE_TICKET',
};

export default injectT(
  (t) => lazy(
    () => ({
      SUBSCRIPTIONS: [
        {
          accessor: (row) => {
            return t(TYPE_TRANSLATIONS[row.type]);
          },
          isTitle: true,
          testId: 'type',
          title: t('ADMIN_LABEL_TYPE'),
          transformValue: frequency => capitalize(frequency),
        },
        {
          accessor: 'name',
          isTitle: true,
          testId: 'name',
          title: t('NAME'),
        },
        {
          accessor: 'sku',
          isTitle: true,
          testId: 'sku',
          title: 'Sku',
        },
        {
          accessor: 'prices',
          testId: 'price',
          title: t('ADMIN_LABEL_PRICE'),
          transformValue: prices => findDefaultPrice(prices),
        },
        {
          accessor: 'recurrence',
          testId: 'frequency',
          title: t('ADMIN_SETTINGS_BILLING_PLAN_FREQUENCY'),
          transformValue: value => FREQUENCY(t)[value],
        },
        {
          accessor: 'created',
          testId: 'added',
          title: t('ADMIN_CHANNEL_LIBRARY_ADDED'),
          transformValue: value => moment(value).fromNow(),
        },
      ],
    }),
  ),
);

import styled from 'styled-components';
import Label from 'components/admin2/ui/Label';
import TextArea from 'components/admin2/TextArea';
import { ADMIN_PANEL_EDITOR_BODY } from 'style/mixins';
import { ADMIN_TEXT_300, ADMIN_SURFACE_3, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

export const Container = styled.div`
  ${ADMIN_PANEL_EDITOR_BODY}
  padding-top: 15px;
`;

export const MessageLabel = styled(Label)`
  * {
    ${ADMIN_TEXT_BODY_S_MEDIUM}
    color: ${ADMIN_TEXT_100};
  }

  & > div div {
    line-height: 20px;
    color: ${ADMIN_TEXT_300};
  }
  & > div {
    padding: 10px 0;
  }
`;

export const MessageContainer = styled.div`
  background: ${ADMIN_SURFACE_3};
  padding: 11.5px;
  border-radius: 5px;

  & > div {
    padding: 0;
  }
`;

export const StyledTextArea = styled(TextArea)`
  padding: 0px;
  height: 100%;
  & > textarea {
      padding: 0px;
      min-height: 40px;
      ${ADMIN_TEXT_BODY_S_MEDIUM}
      color: ${ADMIN_TEXT_300};
    }
`;

export const CharCounter = styled.span`
  position: relative;
  right: -1.5px;
  display: flex;
  width: 100%;
  justify-content: right;
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  color: ${ADMIN_TEXT_300};
`;

export const TipLabel = styled(Label)`
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  & > div {
    padding-top: 0px;
  }
  & > div div {
    ${ADMIN_TEXT_BODY_XS_REGULAR}
    display: flex;
    align-items: center;
  }
  & > label {
    display: flex;
    align-items: start;
    padding-top: 2.36px;
    ${ADMIN_TEXT_BODY_XS_REGULAR}
  }
  & > label span svg {
    width: 12px;
    height: 12px;
  }
`;

export const Active = styled.div `
  color: ${ADMIN_TEXT_100};
  ${ADMIN_TEXT_BODY_XS_REGULAR}
`;

import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import Dragger from 'components/admin2/LegacyDragger';
import ButtonSelect from 'components/admin2/ui/ButtonSelect';
import { AddButton } from 'components/admin2/ui/IconButton';
import {
  peopleColumns as peopleLibraryColumns,
  groupColumns as groupLibraryColumns,
} from 'components/admin2/MediaLibrary/constants';
import LibraryModal from 'components/admin2/LibraryModal';
import NewPersonEditor from 'components/admin2/NewPersonEditor';
import NewGroupEditor from 'components/admin2/NewGroupEditor';
import withLabel from 'components/core/withLabel';
import { useAdminTranslation } from 'hooks/use-translation';
import LibraryButton from './LibraryButton';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    limit: PropTypes.number,
  }).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

const defaultProps = {
  value: [],
};

const PERSON = 'person';
const GROUP = 'group';

const editorConfigs = {
  group: {
    endpoint: '/legacy/groups',
    helperTextKey: 'ADMIN_LABEL_GROUPS_HELPER_TEXT',
    modalColumns: groupLibraryColumns,
    newItemCloseLabel: 'CLOSE',
    newItemEditor: NewGroupEditor,
    newItemLabelKey: 'ADMIN_LABEL_NEW_GROUP',
    titleKey: 'ADMIN_GROUP_BUTTON_PLACEHOLDER',
  },
  person: {
    endpoint: '/legacy/people',
    helperTextKey: 'ADMIN_LABEL_PEOPLE_HELPER_TEXT',
    modalColumns: peopleLibraryColumns,
    newItemCloseLabel: 'CLOSE',
    newItemEditor: NewPersonEditor,
    newItemLabelKey: 'ADMIN_LABEL_NEW_PERSON',
    titleKey: 'ADMIN_PERSON_BUTTON_PLACEHOLDER',
  },
};

const rendererNameFields = {
  group: 'groupName',
  person: 'personName',
};

const rendererTitleFields = {
  person: 'personTitle',
};

const rendererImgFields = {
  group: 'groupBannerUrl',
  person: 'personImageUrl',
};

const getInitialTab = (value) => {
  return value[0] ? value[0].type : PERSON;
};

function MultiPartySelect({ onChange, options, value = [] }) {
  const { t } = useAdminTranslation();
  const [partyType, setPartyType] = useState(getInitialTab(value));
  const [open, setOpen] = useState(false);
  const partyIcon = useMemo(() => (
    partyType === 'person' ? 'gamer' : 'user'
  ), [partyType]);
  const canAddMore = value.length < options.limit;
  const editorConfig = editorConfigs[partyType];
  const {
    helperTextKey,
    modalColumns,
    titleKey,
    newItemEditor,
    newItemLabelKey,
    newItemCloseLabel,
    endpoint,
  } = editorConfig;

  const changePartyType = (type) => {
    setPartyType(type);
    onChange([]);
  };

  const replaceIndex = (index, id) => {
    const newValue = value.map((item, i) => i === index ? { ...item, id } : item);
    onChange(newValue);
  };

  const removeIndex = (index) => {
    const newValue = value.filter((item, i) => i !== index);
    onChange(newValue);
  };

  const addParty = (party) => {
    onChange([
      ...value,
      {
        id: party.id,
        type: partyType,
      },
    ]);
    setOpen(false);
  };

  const renderTab = () => {
    return (
      <div>
        <Dragger
          direction="vertical"
          droppableStyles={{ padding: 0, width: '100%' }}
          innerDivStyles={{ width: '100%' }}
          keyField="id"
          list={value}
          onDragEnd={onChange}
          stretch
        >
          {(item, index) => {
            return (
              <LibraryButton
                onChange={id => replaceIndex(index, id)}
                onDelete={() => removeIndex(index)}
                options={{
                  draggable: true,
                  fancyButtonProps: {
                    buttonPlaceholderKey: titleKey,
                    docToButtonProps: (doc) => {
                      if (!doc) { return {}; }
                      return {
                        fallbackImageIcon: partyIcon,
                        imageSrc: get(doc, `renderer[${rendererImgFields[partyType]}]`),
                        isCatalogItem: true,
                        text: get(doc, `renderer[${rendererNameFields[partyType]}]`),
                        type: get(doc, `renderer[${rendererTitleFields[partyType]}]`) || partyType,
                      };
                    },
                    icon: partyIcon,
                  },
                  newItemBackLabel: newItemCloseLabel,
                  type: partyType,
                }}
                value={item.id}
              />
            );
          }}
        </Dragger>
        {canAddMore && (
          <AddButton
            onClick={() => setOpen(true)}
            spacing="10px 0 0 0"
          >
            {t('ADMIN_RENDERER_EDITOR_ADD_REFERENCE')}
          </AddButton>
        )}
      </div>
    );
  };

  const renderModal = () => {
    if (!open) {
      return null;
    }
    return (
      <LibraryModal
        columns={modalColumns}
        fetchObject={{
          endpoint,
          method: 'GET',
        }}
        helperText={helperTextKey}
        NewItemComponent={newItemEditor}
        newItemLabel={newItemLabelKey}
        onClose={() => setOpen(false)}
        onSelectItem={addParty}
        searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH"
        titleKey={titleKey}
        transformRequestWithRenderer
      />
    );
  };

  return (
    <div>
      <ButtonSelect
        bodyPadding="0 !important"
        onChange={changePartyType}
        options={[{
          label: 'ADMIN_LABEL_PERSON',
          value: PERSON,
        }, {
          label: 'ADMIN_LABEL_GROUP',
          value: GROUP,
        }]}
        value={partyType}
        variant="slide"
      >
        { renderTab() }
      </ButtonSelect>
      { renderModal() }
    </div>
  );
}

MultiPartySelect.propTypes = propTypes;
MultiPartySelect.defaultProps = defaultProps;

export default withLabel(MultiPartySelect);

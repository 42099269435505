import React from 'react';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import {
  ErrorLabel,
  ErrorIcon,
} from './styles';
import type { TranslationKey } from 'hooks/use-translation';

interface ErrorLabelProps {
  errorKey?: TranslationKey;
  errorMsg?: string;
  hidden?: boolean;
}

export default function ErrorLabelComponent({
  errorKey,
  errorMsg,
  hidden,
  ...props
}: ErrorLabelProps) {
  return hidden ? null : (
    <ErrorLabel {...props}>
      <ErrorIcon />
      <OptionalTranslated stringKey={errorKey}>
        {errorMsg}
      </OptionalTranslated>
    </ErrorLabel>
  );
}

import { RefObject } from 'react';
import styled, { css } from 'styled-components';
import AdminModal from 'components/admin2/ui/AdminModal';
import TextArea from 'components/admin2/TextArea';
import Dropdown from 'components/admin2/ui/Dropdown';
import { LargeButton } from 'components/admin2/ui/Button';
import { ADMIN_ALERT_ERROR, ADMIN_SURFACE_4, ADMIN_TEXT_400, ADMIN_TEXT_200, ADMIN_SURFACE_1, ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_6, ADMIN_TEXT_100, ADMIN_SURFACE_3 } from 'style/constants';
import { ACTION_DISABLED } from 'style/mixins';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_TITLE_L, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

export const StyledModal = styled(AdminModal)`
  min-width: 500px;
  max-width: 500px;
  border-radius: 10px;
  background: ${ADMIN_SURFACE_4};
`;

export const Header = styled.div`
  padding: 30px 30px 20px;
  border-bottom: 1px solid ${ADMIN_SURFACE_6};
`;

export const Footer = styled.div`
  padding: 0px 28px 35px;
`;

export const Content = styled.div`
  padding: 20px 45px 30px;
`;

export const ContentainerInfo = styled.div`
  display: flex;
  width: 85%;
  & > *:nth-child(1) {
    margin-right: 10px;
  }
  &.footer {
    width: auto;
    margin-bottom: 10px;
    &:nth-child(1) {
      align-items: center;
    }
  }
`;

export const Title = styled.div`
  ${ADMIN_TEXT_TITLE_L}
  margin-bottom: 5px;
`;

export const LabelCheck = styled.div`
  ${ADMIN_TEXT_TITLE_M}
`;

export const InfoText = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_200};
`;

export const InputArea = styled(TextArea)``;

export const Label = styled.label`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  min-width: 50px;
  margin-right: 10px;
  &.top {
    margin-top: 18px;
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  &.center {
    align-items: center;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  & > button:nth-child(1) {
    margin-right: 15px;
  }
`;

export const Select = styled(Dropdown)`
  background: ${ADMIN_SURFACE_3};
  .icon-down-open {
    color: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const CancelButton = styled(LargeButton)`
  background: ${ADMIN_SURFACE_6};
`;

export const ContainerRefund = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 12px;
  background: ${ADMIN_SURFACE_3};
  border-radius: 5px;
  width: 100%;
  margin: 10px 0px;
  span {
    color: ${ADMIN_TEXT_400};
  }
`;

export const ErrorBox = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_ALERT_ERROR};
  display: flex;
  justify-content: flex-end;
`;

export const Input = styled.input.attrs({
  type: 'text',
}) <{ ref?: RefObject<HTMLInputElement>, width: number }>`
  background: transparent;
  color: ${ADMIN_TEXT_100};
  width: ${({ width }) => `${width}px`};
  border: none;
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  padding: 0;
`;

export const ConfirmButton = styled(LargeButton as any) <{ isLoading?: boolean }>`
  ${({ isLoading }) => isLoading ? css`
    ${ACTION_DISABLED}
    min-width: 125px;
    background: ${ADMIN_SURFACE_1};
    color: white;
    display: flex;
    column-gap: 10px;
    ` : null}
`;

export const Spinner = styled(LoadingSpinner).attrs(props => ({
  name: 'three-bounce',
  colorOverride: ADMIN_ACCENT_PRIMARY(props),
})) <{ size?: number }>`
  & > div > div {
    ${({ size }) => size ? css`
    width: ${size}px;
    height: ${size}px;
    ` : null}
  }
  .sk-three-bounce {
    height: auto;
  }
`;

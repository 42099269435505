/* tslint:disable: jsx-boolean-value */
import React, { ComponentProps, useEffect, useState } from 'react';
import withLabel from 'components/core/withLabel';
import DatetimePicker, { TimezoneSelect, DatetimePickerProps, LOCAL_TIMEZONE } from 'components/admin2/DatetimePicker';
import {
  ScheduleContainer,
  DatetimeContainer,
} from './styles';

type OptionalDatetimePickerProps = (
  Omit<DatetimePickerProps, 'onTimeChange'> &
  Pick<Partial<DatetimePickerProps>, 'onTimeChange'>
);

interface Schedule {
  endTime: number | null;
  startTime: number | null;
  timezone?: string;
}

interface ScheduleProps extends Omit<DatetimePickerProps, 'onTimeChange'> {
  endDateProps: OptionalDatetimePickerProps;
  schedule: Partial<Schedule>;
  startDateProps: OptionalDatetimePickerProps;
  timezoneProps?: Omit<ComponentProps<typeof TimezoneSelect>, 'onChange'> & { onChange?(tz: string): void };
  onChange?(schedule: Schedule): void;
}

function Schedule({
  endDateProps: {
    dateSelectKey: endDateSelectKey = 'ADMIN_LABEL_END_CALENDAR',
    onTimeChange: onEndTimeChange,
    timeSelectKey: endTimeKey = 'ADMIN_LABEL_END_TIME',
    ...endDateProps
  } = {},
  hideTimezone,
  startDateProps: {
    dateSelectKey: startDateSelectKey = 'ADMIN_LABEL_START_CALENDAR',
    onTimeChange: onStartTimeChange,
    timeSelectKey: startTimeKey = 'ADMIN_LABEL_START_TIME',
    ...startDateProps
  } = {},
  onChange,
  schedule: {
    endTime = Date.now(),
    startTime = Date.now(),
    timezone = LOCAL_TIMEZONE,
  } = {},
  timezoneProps: {
    onChange: onTimezoneChange,
    ...timezoneProps
  } = {},
}: ScheduleProps) {
  const [innerEndTime, setInnerEndTime] = useState(endTime);
  const [innerStartTime, setInnerStartTime] = useState(startTime);
  const [innerTimezone, setInnerTimezone] = useState(timezone);

  const handleTimezoneChange = ({ timezone: newTimezone }: { timezone: string }) => {
    setInnerTimezone(newTimezone);
    onTimezoneChange?.(newTimezone);
  };

  const handleStartTimeChange = (time: number | null) => {
    setInnerStartTime(time);
    onStartTimeChange?.(time);
  };

  const handleEndTimeChange = (time: number | null) => {
    setInnerEndTime(time);
    onEndTimeChange?.(time);
  };

  useEffect(() => {
    onChange?.({ endTime: innerEndTime, startTime: innerStartTime, timezone: innerTimezone });
  }, [innerEndTime, innerStartTime, innerTimezone]);

  return (
    <ScheduleContainer>
      {!hideTimezone && <TimezoneSelect {...timezoneProps} onChange={handleTimezoneChange} timezone={timezone} />}
      <DatetimeContainer>
        <DatetimePicker
          {...startDateProps}
          dateSelectKey={startDateSelectKey}
          hideTimezone
          onTimeChange={handleStartTimeChange}
          required
          rowAlign
          timeSelectKey={startTimeKey}
          timestamp={startTime}
          timezone={timezone}
          verticalTime
        />
        <DatetimePicker
          {...endDateProps}
          dateSelectKey={endDateSelectKey}
          hideTimezone
          onTimeChange={handleEndTimeChange}
          required
          rowAlign
          timeSelectKey={endTimeKey}
          timestamp={endTime}
          timezone={timezone}
          verticalTime
        />
      </DatetimeContainer>
    </ScheduleContainer>
  );
}

export default withLabel(Schedule);

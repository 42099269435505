import React, {
  useCallback,
  useMemo,
  useState,
  Ref,
  RefAttributes,
  HTMLAttributes,
} from 'react';
import { SUGGESTION_CONTAINER } from 'css-classes';
import debounce from 'lodash/debounce';
import withPadding from 'components/core/withPadding';
import withLabel from 'components/core/withLabel';
import {
  SearchContainer,
  StyledLoader,
  Input,
  Suggestion,
  SuggestionContainer,
  IconContainer,
  SearchIcon,
} from './styles';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';

export interface SearchInputProps
  extends RefAttributes<HTMLInputElement>,
    HTMLAttributes<HTMLInputElement> {
  autoFocus?: boolean;
  compact?: boolean;
  delay?: number;
  disabled?: boolean;
  height?: number;
  hideIcon?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  loading?: boolean;
  maxLength?: number;
  onInputChange?: (text: string) => void;
  onSearch?: (text: string) => void;
  searchPlaceholderKey?: TranslationKey;
  short?: boolean;
  suggestion?: string;
  testIdSearchInput?: string;
  value?: string;
}

function SearchInput({
  className,
  delay = 1000,
  inputRef,
  height,
  hideIcon,
  onClick,
  loading,
  maxLength,
  onInputChange,
  onSearch,
  searchPlaceholderKey,
  autoFocus,
  short,
  suggestion,
  testIdSearchInput,
  value,
  ...props
}: SearchInputProps) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState(value || '');
  const { t } = useAdminTranslation();

  const isLoading = loading === undefined ? isSearching : isSearching || loading;

  const handleSearch = useCallback(
    debounce((text: string) => {
      onSearch?.(text);
      setIsSearching(false);
    }, delay),
    [onSearch, setIsSearching, delay],
  );

  const onChange = useCallback(
    ({ target: { value: targetValue } }: React.ChangeEvent<HTMLInputElement>) => {
      const text = targetValue.trim();
      setSearchText(text);
      onInputChange?.(text);
      setIsSearching(true);
      handleSearch(text);
    },
    [handleSearch, setIsSearching],
  );

  const placeholder = searchPlaceholderKey ? t(searchPlaceholderKey) : props.placeholder;

  return (
    <SearchContainer
      className={className}
      height={height}
      onClick={onClick}
      short={short}
      hasSuggestion={!!suggestion}
    >
      {!hideIcon && (
        <IconContainer>
          <SearchIcon />
        </IconContainer>
      )}
      <Input
        data-testid={testIdSearchInput}
        ref={inputRef}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        autoFocus={autoFocus}
        {...props}
      />
      {suggestion && <Autocomplete input={searchText} word={suggestion} />}
      <StyledLoader data-testid="search-input-loading-spinner" dotSize={short ? 4 : 6} loading={isLoading} />
    </SearchContainer>
  );
}

export default withPadding(withLabel(SearchInput, { padding: '5px 0' }), '15px');

interface AutocompleteProps {
  input?: string;
  word?: string;
}

function Autocomplete({ input, word }: AutocompleteProps) {
  const autocompleted = useMemo(() => {
    if (word && input && word.toLowerCase().startsWith(input.toLowerCase())) {
      return word.replace(new RegExp(input, 'i'), '');
    }
    return '';
  }, [word, input]);

  return (
    <SuggestionContainer className={SUGGESTION_CONTAINER}>
      {input}
      <Suggestion>{autocompleted}</Suggestion>
    </SuggestionContainer>
  );
}

import React from 'react';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import { LocalizationTabProvider } from './LocalizationTabContext';
import LocalizationTabBody from './LocalizationTabBody';

const LocalizationTab = () => {
  return (
    <AdminBarTab style={{ height: '100%' }}>
      <AdminBarTabHeader
        headerKey="ADMIN_LABEL_SETTINGS"
        subtitleKey="ADMIN_LABEL_LOCALIZATION"
      />
      <LocalizationTabProvider>
        <LocalizationTabBody />
      </LocalizationTabProvider>
    </AdminBarTab>
  );
};

export default LocalizationTab;

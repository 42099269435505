import styled, { css } from 'styled-components';
import Label from 'components/admin2/ui/Label';
import ActionContainer from 'components/admin2/ActionContainer';
import { Description } from 'components/admin2/ui/Text';
import {
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
  ADMIN_ACCENT_PRIMARY,
} from 'style/constants';
import {
  TEXT_ELLIPSIS,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

export interface StyleProps {
  hasDescription?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isLarge?: boolean;
}

export const Container = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  ${({ hasDescription }) => hasDescription && css`
    padding: 10px 10px 0 10px;
  `}
  ${({ onClick }) => onClick && css`
    &, & *, & * * {
      cursor: pointer;
    }
  `}
  ${({ isActive }) => isActive && css`
    &:after {
      content: "";
      position: absolute;
      width: calc(100% - 2px);
      height: 100%;
      border: 1px solid ${ADMIN_ACCENT_PRIMARY};
      border-radius: 4px;
    }
  `}
  ${({ onClick }) => !!onClick && 'cursor: pointer;'}
`;

export const ActionLabelContainer = styled.div<StyleProps>`
  height: ${({ isLarge }) => isLarge ? 60 : 40}px;
  padding: ${({ isLarge }) => isLarge ? '0 17px 0 20px' : '0px 10px 0px 15px'} ;
  border-radius: ${({ isLarge }) => isLarge ? 10 : 4}px;
  display: flex;
  justify-content: space-between;
  user-select: none;
  overflow: visible;
  align-items: center;
  background: ${ADMIN_SURFACE_2};
  color: ${ADMIN_TEXT_100};
  ${({ isActive, isDisabled }) => css`
    background: ${isActive ? ADMIN_SURFACE_4 : ADMIN_SURFACE_2};
    &:hover {
      background: ${ADMIN_SURFACE_5};
    }
    ${isDisabled && css`
      background: ${ADMIN_SURFACE_5};
      pointer-events: none;
    `}
  `}
`;

export const StyledActionContainer = styled(ActionContainer).attrs({
  reverse: true,
})`
  margin-left: 10px;
`;

export const InnerLabel = styled(Label).attrs(({ color = ADMIN_TEXT_100, textSize = 'h6' }) => ({
  color,
  textSize,
}))`
  ${TEXT_ELLIPSIS}
  flex: 1;
`;

export const StyledDescription = styled(Description)`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  margin-left: 2px;
`;

import React from 'react';
import styled from 'styled-components';
import TextInput from 'components/admin2/TextInput';
import TranslatedText from 'components/i18n/TranslatedText';
import { ADMIN_SURFACE_3, SPACING_MEDIUM } from 'style/constants';
import shortid from 'shortid';
import CollapsibleList from 'components/admin2/CollapsibleList';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import PanelEditorHeader from '../PanelEditorHeader';

const Container = styled.div`
  background-color: ${ADMIN_SURFACE_3};
  padding: ${SPACING_MEDIUM};
`;

const TwitterHeader = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const ListItem = styled.div`
  padding: ${SPACING_MEDIUM};
`;

const ICON_KEY = 'iconString';
const PANEL_NAME_KEY = 'panelName';
export default class SocialPanelEditor extends React.Component {
  static propTypes = {

  };

  static defaultProps = {

  };

  state = {
    [ICON_KEY]: '',
    [PANEL_NAME_KEY]: '',
    sources: [],
  };

  handleDelete = (index) => {
    const { sources } = this.state;
    sources.splice(index, 1);
    this.setState({ sources });
  };

  handleChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };

  handleSourceTextChange = (newValue, index) => {
    const { sources: oldSources } = this.state;
    const sources = oldSources.map((sourceObj, i) => (
      i === index ? { ...sourceObj, sourceText: newValue } : sourceObj
    ));
    this.setState({
      sources,
    });
  };

  handleAddSource = () => {
    const { sources: oldSources } = this.state;
    this.setState({
      sources: [
        ...oldSources, {
          generatedKey: shortid.generate(),
          sourceText: '',
        },
      ],
    });
  };

  render() {
    const {
      sources,
      [ICON_KEY]: iconName,
      [PANEL_NAME_KEY]: panelName,
    } = this.state;
    const {
      handleSourceTextChange,
      handleAddSource,
      handleDelete,
      handleChange,
    } = this;

    const getHeaderText = item => item.sourceText || <TranslatedText stringKey="ADMIN_LABEL_NEW_SOURCE" />;

    return (
      <Container>
        <PanelEditorHeader
          iconValue={iconName}
          nameValue={panelName}
          onIconChange={value => handleChange(value, ICON_KEY)}
          onNameChange={value => handleChange(value, PANEL_NAME_KEY)}
        />
        <TwitterHeader>
          <TranslatedText stringKey="ADMIN_LABEL_TWITTER" />
        </TwitterHeader>
        <CollapsibleList
          addItemKey="ADMIN_ACTION_ADD_SOURCE"
          emptyListKey="ADMIN_LABEL_NO_SOURCES"
          headerText={getHeaderText}
          list={sources}
          onAddItem={handleAddSource}
          onDelete={handleDelete}
        >
          {
            (item, i) => (
              <ListItem key={i}>
                <TextInput
                  labelKey="ADMIN_LABEL_SOURCE"
                  onChange={value => handleSourceTextChange(value, i)}
                  placeholderKey="ADMIN_PLACEHOLDER_ADD_SOURCE"
                  value={sources[i].sourceText}
                />
              </ListItem>
            )
          }
        </CollapsibleList>
      </Container>
    );
  }
}

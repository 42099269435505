import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { css } from 'styled-components';
import type { TranslationKey } from 'hooks/use-translation';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import { ActionButton, BlockTitle, EditorActions, EditorHeader, Wrapper } from './styles';
import OptionalTranslated from '../OptionalTranslated';

export interface EditorAction {
  confirmationMessageKey?: TranslationKey;
  descriptionTextKey?: TranslationKey;
  icon: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  tooltipKey?: TranslationKey;
  useAbsolutePosition?: boolean;
}
export interface IEditableAreaProps extends PropsWithChildren {
  blockTitle?: string;
  blockTitleKey?: TranslationKey;
  borderStyle?: string;
  className?: string;
  editorActions?: EditorAction[];
  editorActionsCss?: ReturnType<typeof css> | string;
  editorWrapperRef?: React.RefObject<HTMLDivElement>;
  isEditing: boolean;
  keepBorder?: boolean;
  onBlur?: () => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  testId?: string;
}

const EditableArea: React.FC<IEditableAreaProps> = ({
  blockTitleKey,
  blockTitle,
  editorActions = [],
  editorActionsCss,
  borderStyle,
  keepBorder,
  children,
  testId,
  onClick,
  onBlur,
  editorWrapperRef,
  className,
  isEditing,
}) => {
  return (
    <Wrapper
      ref={editorWrapperRef}
      borderStyle={borderStyle}
      className={className}
      isEditing={isEditing}
      onClick={onClick}
      onBlur={onBlur}
      data-testid={testId}
      keepBorder={keepBorder}
    >
      {isEditing && (
        <EditorHeader>
          <OptionalTranslated component={BlockTitle} stringKey={blockTitleKey}>
            {blockTitle}
          </OptionalTranslated>
          <EditorActions editorActionsCss={editorActionsCss}>
            <NonKeyedListMapper list={editorActions}>
              {(key: string, action: EditorAction) => (
                <ActionButton
                  key={key}
                  action={action.onClick}
                  descriptionTextKey={action.descriptionTextKey}
                  messageKey={action.confirmationMessageKey}
                  name={action.icon}
                  shouldAskConfirmation={!!action.confirmationMessageKey}
                  tooltipKey={action.tooltipKey}
                  useAbsolutePosition={action.useAbsolutePosition}
                />
              )}
            </NonKeyedListMapper>
          </EditorActions>
        </EditorHeader>
      )}
      {children}
    </Wrapper>
  );
};

export default EditableArea;

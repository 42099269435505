/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, useAjax } from 'hooks';
import { Transition } from 'react-transition-group';
import { ACCOUNTS_SERVICE_BASE_URL } from 'config';
import { getPrimaryToken, getUserAccount } from 'services/auth';
import { getSiteId } from 'services/app';
import {
  getSiteRolesAction,
  getSiteRolesSelector,
} from 'services/user-roles';
import {
  getPendingInvitesSelector,
  deleteInvite,
  clearInvite,
  resendInvite,
  accountExistsSelector,
  resentSelector,
  getLoadingInviteSelector,
} from 'services/invite';
import { showAdminErrorKey, showModal } from 'services/modals';
import KeyedListMapper from 'components/core/KeyedListMapper';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import SearchInput from 'components/admin2/ui/SearchInput';
import Divider from 'components/admin2/ui/Divider';
import AdminCard from 'components/admin2/ui/AdminCard';
import InviteCard from 'components/admin2/ui/InviteCard';
import ConfigureInviteTab from 'components/admin2/ConfigureInviteTab';
import ConfigureUserTab from 'components/admin2/ConfigureUserTab';
import Label from 'components/admin2/ui/Label';
import hash from 'json-stable-stringify';

import { SPACING_SMALL } from 'style/constants';
import { getAdminText100 } from 'services/themes';
import { getIsLegacyPlan, getIsUnlimitedPlan, getPlanFeatures } from 'services/billing';
import { useAdminTranslation } from 'hooks/use-translation';
import { ModalKinds } from 'services/modals/types';
import InviteTab from './InviteTab';
import {
  InviteAdminButton,
  UsersContainer,
  ExtendAnimation,
  ReSentMessage,
  StyledIcon,
  AvailableSeatsWrapper,
  AvailableSeatsLabel,
  StyledLinkText,
  NeedMoreSeatsLabel,
} from './styles';

const MAESTRO_DOMAIN = '@maestro.io';

const UsersTab = () => {
  const { t } = useAdminTranslation();
  const { email: userAccountEmail } = useSelector(getUserAccount) || {};
  const dispatch = useDispatch();
  const siteId = useSelector(getSiteId);
  const token = useSelector(getPrimaryToken);
  const permissionedUsers = useSelector(getSiteRolesSelector);
  const fetching = useSelector(getLoadingInviteSelector);
  const pendingInvitations = useSelector(getPendingInvitesSelector);
  const accountExist = useSelector(accountExistsSelector);
  const resent = useSelector(resentSelector);
  const [filteredUsers, setFilteredUsers] = useState(permissionedUsers);
  const [searchText, setSearchValue] = useState('');
  const [rightTab, setRightTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rightPosition, leftPosition] = useState(0);
  const [keyEditing, setKeyEditing] = useState(null);
  const [inviteTabAdmin, setInviteTabAdmin] = useState(null);
  const [userTabAdmin, setUserTabAdmin] = useState(null);
  const adminText100 = useSelector(getAdminText100);
  const planMaxAmountOfAdmins = useSelector(getPlanFeatures)?.maxAmountOfAdmins;
  const isLegacyPlan = useSelector(getIsLegacyPlan);
  const isUnlimitedPlan = useSelector(getIsUnlimitedPlan);
  const planHasAdminLimit = !isUnlimitedPlan && !isLegacyPlan;

  React.useEffect(() => {
    dispatch(getSiteRolesAction());
  }, []);

  const filterAdminList = (adminUsers, userEmail) => {
    return adminUsers.filter(user => {
      const isAdminSeeingTheTabFromMaestro = userEmail && userEmail.includes(MAESTRO_DOMAIN);
      if (isAdminSeeingTheTabFromMaestro) {
        return true; // show every admin
      }

      const isAdminInTheAdminsListFromMaestro = user.email && user.email.includes(MAESTRO_DOMAIN);
      return !isAdminInTheAdminsListFromMaestro; // show every admin that is not from maestro
    });
  }

  const filterNonMaestroAdminList = (adminUsers) => {
    return adminUsers.filter(user => {
      const isAdminInTheAdminsListFromMaestro = user.email && user.email.includes(MAESTRO_DOMAIN);
      return !isAdminInTheAdminsListFromMaestro; // only count admins that are not from maestro
    });
  }

  const filteredAdminList = useMemo(() => {
    return filterAdminList(filteredUsers, userAccountEmail);
  }, [filteredUsers, userAccountEmail]);

  const invitesAndAdminsTotalCount = useMemo(() => {
    const admins = filterNonMaestroAdminList([...permissionedUsers, ...pendingInvitations]);
    return admins.length;
  }, [permissionedUsers, pendingInvitations]);
  const isMaxAdminsReached = invitesAndAdminsTotalCount >= planMaxAmountOfAdmins;
  const remainingAdminCount = Math.max(planMaxAmountOfAdmins - invitesAndAdminsTotalCount, 0);

  const headers = {
    Authorization: `Bearer ${token}`,
    'x-maestro-client-id': siteId,
  };
  const params = searchText.trim().length ? `&search=${searchText.trim()}&searchFields=name,email` : '';
  const { data, error } = useAjax({
    headers,
    method: 'GET',
    url: `${ACCOUNTS_SERVICE_BASE_URL}/roles/query?admin=true${params}`,
  });

  useEffect(() => {
    if (!searchText.length) {
      setFilteredUsers(permissionedUsers);
      return;
    }
    if (error) {
      dispatch(showAdminErrorKey('ADMIN_ERROR_GENERIC'));
      setFilteredUsers(permissionedUsers);
      return;
    }

    if (!data?.accounts && searchText.length) {
      setLoading(false);
      return;
    }

    if (data?.accounts && searchText.length) {
      const accountsWithIcon = data?.accounts.map((account) => {
        return {
          ...account,
          icon: data.userProfiles?.[account?._id]?.icon,
        };
      });
      setFilteredUsers(accountsWithIcon);
      setLoading(false);
    }
  }, [hash(data), hash(error)]);

  useEffect(() => {
    setLoading(true);
    if (!searchText.length) {
      setLoading(false);
      setFilteredUsers(permissionedUsers);
      return;
    }

    if (!data?.accounts) {
      setLoading(false);
      return;
    }

    const accountsWithIcon = data?.accounts.map((account) => {
      return {
        ...account,
        icon: data.userProfiles?.[account?._id]?.icon,
      };
    });
    setFilteredUsers(accountsWithIcon);
    setLoading(false);
  }, [hash(data), hash(error), permissionedUsers, searchText]);

  const doOpenAddNewAdmin = () => {
    if (planHasAdminLimit && isMaxAdminsReached) {
      dispatch(
        showModal({
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_EXCEEDED_MAX_AMOUNT_OF_ADMINS',
          },
          kind: ModalKinds.upgradePlan,
        }),
      );
      return;
    }
    setRightTab(false);
    dispatch(clearInvite());
    setRightTab(true);
  };

  const doDeleteInvite = (invite) => {
    setKeyEditing(invite._id);
    setRightTab(false);
    dispatch(deleteInvite(invite._id));
  };

  const doResendInvite = (invite) => {
    setKeyEditing(invite._id);
    setRightTab(false);
    dispatch(resendInvite(invite._id));
  };

  const onUpgradePlan = () => {
    dispatch(showModal({ kind: ModalKinds.upgradePlan }));
  };

  useEffect(() => {
    return () => {
      setRightTab(false);
    }
  }, []);

  if (inviteTabAdmin) {
    return (
      <ConfigureInviteTab
        handleTabBack={() => setInviteTabAdmin(null)}
        user={inviteTabAdmin}
      />
    );
  }

  if (userTabAdmin) {
    return <ConfigureUserTab handleTabBack={() => setUserTabAdmin(null)} user={userTabAdmin} />;
  }

  return (
    <div
      ref={(wrapp) => {
        if (!wrapp) {
          return;
        }
        leftPosition(wrapp.getBoundingClientRect().right);
      }}
    >
      <AdminBarTab>
        <AdminBarTabHeader
          headerKey="ADMIN_LABEL_MANAGE"
          subHeaderKey="ADMIN_LABEL_ADMINS"
          subtitleKey="ADMIN_LABEL_ADMINS"

        />
          <SearchInput
            loading={loading}
            onSearch={setSearchValue}
            padding="0 15px 15px"
            searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH_USER"
            short
            testIdSearchInput="searchUserInput"
          />
          {
            planHasAdminLimit && (
              <AvailableSeatsWrapper>
                <AvailableSeatsLabel>
                  {t('ADMIN_INVITE_AVAILABLE_SEATS')}
                  <span>{remainingAdminCount}</span>
                </AvailableSeatsLabel>
                <span>
                  {!isMaxAdminsReached && (
                    <NeedMoreSeatsLabel>
                      {t('ADMIN_INVITE_NEED_MORE_SEATS')}
                    </NeedMoreSeatsLabel>
                  )}
                  <StyledLinkText onClick={onUpgradePlan}>
                    {t('ADMIN_INVITE_UPGRADE_PLAN')}
                  </StyledLinkText>
                </span>
              </AvailableSeatsWrapper>
            )
          }
          <InviteAdminButton
            data-testid="inviteNewAdminBtn"
            icon="plus-circle"
            labelKey="ADMIN_INVITE_NEW_ADMIN"
            onClick={doOpenAddNewAdmin}
          />
          <Label
            color={adminText100}
            compact
            labelKey="ADMIN_INVITATIONS"
            spacing={SPACING_SMALL}
          />
          <KeyedListMapper
            data-testid="invitationCardItem"
            keyField="_id"
            list={pendingInvitations}
          >
            {
              (key, invite) => (
                <UsersContainer key={key}>
                  <InviteCard
                    accountExist={accountExist}
                    fetching={keyEditing === invite._id ? fetching : false}
                    invite={invite}
                    onDelete={() => { doDeleteInvite(invite); }}
                    onEdit={() => setInviteTabAdmin(invite)}
                    onResend={() => { doResendInvite(invite); }}
                  />
                </UsersContainer>
              )
            }
          </KeyedListMapper>
          {resent && (
            <ReSentMessage>
              <StyledIcon name="circledCheckmark" />
              Invite Re-sent!
            </ReSentMessage>
          )}
          <Divider />

          <Label
            color={adminText100}
            compact
            labelKey="ADMIN_LABEL_ADMINS"
            spacing={SPACING_SMALL}
          />
          <KeyedListMapper keyField="_id" list={filteredAdminList}>
            {
              (key, user) => (
                <UsersContainer key={key}>
                  <AdminCard
                    onEdit={() => setUserTabAdmin(user)}
                    user={user}
                  />
                </UsersContainer>
              )
            }
          </KeyedListMapper>
      </AdminBarTab>
      {
        rightTab && (
          <Transition in={rightTab}>
            {(state) => (
              <ExtendAnimation state={state} style={{ left: `${rightPosition}px` }}>
                {rightTab && (
                  <InviteTab
                    closeModal={() => setRightTab(false)}
                    filteredUsers={filteredUsers}
                    pendingInvitations={pendingInvitations}
                  />
                )}
              </ExtendAnimation>
            )}
          </Transition>
        )
      }
    </div>
  );
};

export default UsersTab;

/* tslint:disable: jsx-boolean-value */
import React, { HTMLAttributes, MouseEvent, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import Row, { ClickHandler } from 'components/admin2/ui/Library/Row';
import type { TranslationKey } from 'hooks/use-translation';

export interface ClickHandlers {
  onConfirmClick?: (id: string) => void;
  onPreviewClick?: (id: string, highlighted: boolean) => void;
}

export interface Kind {
  'data-testid'?: string;
  iconName: string;
  id: string;
  infoKey: TranslationKey;
  nameKey: TranslationKey;
  testIdAdd?: string;
  testIdBroadcast?: string;
  testIdDelete?: string;
  testIdEdit?: string;
  testIdPreview?: string;
  testIdRemove?: string;
  typeNameTestId?: string;
}

interface ItemProps extends Omit<HTMLAttributes<HTMLDivElement>, 'id'>, ClickHandlers, Kind {
  extraActions?: ReactNode;
  name?: string;
  onRemove?: MouseEventHandler;
  onSelect?: ClickHandler<any>;
  reversePositionActions?: boolean;
  tabIndex: number;
  type?: string;
  verticalCenterActions?: boolean;
  verticalPositionActions?: boolean;
}

export default function Item({
  className,
  'data-testid': testId,
  extraActions,
  iconName,
  id,
  infoKey,
  name,
  nameKey,
  onConfirmClick,
  onPreviewClick,
  onRemove,
  onSelect,
  reversePositionActions = true,
  type,
  typeNameTestId,
  testIdAdd,
  testIdBroadcast,
  testIdDelete,
  testIdEdit,
  testIdPreview,
  testIdRemove,
  verticalCenterActions,
  verticalPositionActions = true,
}: ItemProps) {
  const [highlighted, setHighlighted] = useState(false);
  const [rendered, setRendered] = useState(false);

  const handleConfirmClick = (_: any, e: MouseEvent) => {
    onConfirmClick!(id);
    e.stopPropagation();
  };

  const handlePreviewClick = () => {
    setHighlighted(!highlighted);
  };

  useEffect(() => {
    if (rendered) {
      onPreviewClick?.(id, highlighted);
      return;
    }

    setRendered(true);
  }, [id, highlighted, onPreviewClick]);

  return (
    <Row
      data-testid={testId}
      nameKey={infoKey}
      className={className}
      extraActions={extraActions}
      icon={iconName}
      isCatalogItem
      name={name}
      onAdd={onConfirmClick && handleConfirmClick}
      onPreview={onPreviewClick && handlePreviewClick}
      onRemove={onRemove}
      onSelect={onSelect}
      reversePositionActions={reversePositionActions}
      testIdAdd={testIdAdd}
      testIdBroadcast={testIdBroadcast}
      testIdDelete={testIdDelete}
      testIdEdit={testIdEdit}
      testIdPreview={testIdPreview}
      testIdRemove={testIdRemove}
      type={type}
      typeNameTestId={typeNameTestId}
      typeKey={nameKey}
      verticalCenterActions={verticalCenterActions}
      verticalPositionActions={verticalPositionActions}
    />
  );
}

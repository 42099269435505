/* tslint:disable: jsx-boolean-value */
import React from 'react';
import { CloseButton } from 'components/admin2/ui/Button';
import {
  NavContainer,
  ConfirmNavButton,
} from './styles';
import { TranslationKey } from 'hooks/use-translation';

interface NavControlsProps {
  confirmTextKey?: TranslationKey;
  disabled?: boolean;
  onBack?: (e?: React.MouseEvent) => void;
  onClose?: (e?: React.MouseEvent) => void;
  shouldAskConfirmation?: boolean;
}

export default function NavControls({
  disabled = false,
  onBack,
  onClose,
  confirmTextKey = 'ADMIN_UNSAVED_CHANGES_WARNING',
  shouldAskConfirmation = false,
}: NavControlsProps) {
  return (
    <NavContainer>
      {onBack && (
        <ConfirmNavButton
          action={onBack}
          messageKey={confirmTextKey}
          shouldAskConfirmation={shouldAskConfirmation}
          small
          disabled={disabled}
        />
      )}
      {onClose && (
        <CloseButton
          onClick={onClose}
          tooltipPosition="left"
        />
      )}
    </NavContainer>
  );
}

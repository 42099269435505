import React, { useState, useRef, useEffect, useMemo } from 'react';
import { ADMIN_ERROR_MODAL_ROOT } from 'global-ids';
import Icon from 'components/ui/Icon';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  CancelButton,
  ContainerButtons,
  ContainerInputs,
  Content,
  ContentainerInfo,
  Footer,
  Header,
  InfoText,
  InputArea,
  Label,
  LabelCheck,
  Select,
  StyledModal,
  Title,
  ContainerRefund,
  ErrorBox,
  Input,
  ConfirmButton,
  Spinner,
} from './styles';
import { Checkbox } from '../ui/Checkbox';

const options = [
  { label: 'ADMIN_LABEL_OPTION_REFUND_DUPLICATE_MODAL', value: 'duplicate', index: 0 },
  { label: 'ADMIN_LABEL_OPTION_REFUND_FRAUDULENT_MODAL', value: 'fraudulent', index: 1 },
  { label: 'ADMIN_LABEL_OPTION_REFUND_REQUEST_CUSTOMER_MODAL', value: 'requested_by_customer', index: 2 },
];

interface LibraryModalRefundProps {
  currency: string;
  onClose: () => void;
  onConfirm: (reason: string, details: string, keepEntitlement: boolean, refundAmount: number) => void;
  refund: number;
}

const LibraryModalRefund = (props: LibraryModalRefundProps) => {
  const { currency, refund, onClose, onConfirm } = props;
  const [errorRequired, setErrorRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorRefund, setErrorRefund] = useState(false);
  const [errorRefundNan, setErrorRefundNan] = useState(false);
  const [reason, setReason] = useState<{ label?: string, value?: string }>({});
  const [details, setDetails] = useState('');
  const [refundValue, setRefundValue] = useState(refund);
  const [keepEntitlement, setKeepEntitlement] = useState(false);
  const [cursorPosition, setCursorPosition] = useState([0, 0]);
  const onChangeDetails = (value: string) => {
    setDetails(value);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const formattedCurrency = useMemo(() => currency?.toUpperCase(), [currency]);

  useEffect(() => {
    const [start, end] = cursorPosition;
    inputRef.current?.setSelectionRange(start, end);
  }, [refundValue, cursorPosition]);

  const onChangeRefund = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = new RegExp('^[0-9]*(\.[0-9]{2})?$');
    const cursorPositionStart = Number(inputRef.current?.selectionStart);
    const cursorPositionEnd = Number(inputRef.current?.selectionEnd);
    setCursorPosition([cursorPositionStart, cursorPositionEnd]);
    if (regex.test(value)) {
      setRefundValue(parseInt(value.startsWith('.') ? `0${value}` : value, 10));
    }
  };

  const validateGrantRefund = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = false;
    if (e.target.checked) {
      value = true;
    }
    setKeepEntitlement(value);
  };

  const confirmRefund = () => {
    const validateError = refundValue > refund || !reason?.value || Number.isNaN(refundValue);
    if (Number.isNaN(refundValue)) {
      setErrorRefundNan(true);
    }
    if (refundValue > refund) {
      setErrorRefund(true);
    }
    if (!reason?.value) {
      setErrorRequired(true);
    }
    if (!validateError) {
      setLoading(true);
      onConfirm(reason?.value || '', details, keepEntitlement, refundValue);
    }
  };

  return (
    <StyledModal
      id={ADMIN_ERROR_MODAL_ROOT}
      onClose={onClose}
      fixedDimensions={true}
    >
      <Header>
        <Title><TranslatedText stringKey="ADMIN_TITLE_REFUND_ENTITLEMENT_MODAL" /></Title>
        <ContentainerInfo>
          <Icon name="info" />
          <InfoText><TranslatedText stringKey="ADMIN_INFO_TITLE_REFUND_ENTITLEMENT_MODAL" /></InfoText>
        </ContentainerInfo>
      </Header>
      <Content>
        {errorRequired && (
          <ErrorBox>
            <TranslatedText stringKey="ADMIN_LABEL_ERROR_REFUND_SELECT_ENTITLEMENT_MODAL" />
          </ErrorBox>
        )}
        {errorRefund && (
          <ErrorBox>
            <TranslatedText stringKey="ADMIN_LABEL_ERROR_REFUND_VALUE_ENTITLEMENT_MODAL" />
          </ErrorBox>
        )}
        {errorRefundNan && (
          <ErrorBox>
            <TranslatedText stringKey="ADMIN_LABEL_ERROR_REFUND_INVALID_VALUE_ENTITLEMENT_MODAL" />
          </ErrorBox>
        )}
        <ContainerInputs className="center" >
          <Label><TranslatedText stringKey="REFUND" /></Label>
          <ContainerRefund>
            <Input
              ref={inputRef}
              onChange={onChangeRefund}
              width={refundValue.toFixed(2).toString().length * 7}
              value={refundValue.toFixed(2)}
            />
            <span>{formattedCurrency}</span>
          </ContainerRefund>
        </ContainerInputs>
        <ContainerInputs className="center">
          <Label><TranslatedText stringKey="REASON" /></Label>
          <Select
            placeholderKey={'SELECT'}
            onChange={setReason}
            translated={true}
            options={options}
            value={null}
          />
        </ContainerInputs>
        <ContainerInputs>
          <Label className="top"><TranslatedText stringKey="DETAILS" /></Label>
          <InputArea
            placeholderKey="ADMIN_LABEL_DETAILS_REFUND_ENTITLEMENT_MODAL"
            value={details}
            onChange={onChangeDetails}
          />
        </ContainerInputs>
        <ContainerButtons>
          <CancelButton onClick={onClose}>
            <TranslatedText stringKey="CANCEL" />
          </CancelButton>
          <ConfirmButton onClick={confirmRefund} isLoading={loading} >
            <TranslatedText stringKey="REFUND" />
            {loading && <Spinner fade={false} size={9} />}
          </ConfirmButton>
        </ContainerButtons>
      </Content>
      <Footer>
        <ContentainerInfo className="footer">
          <Checkbox size={19} checked={keepEntitlement} onChange={validateGrantRefund} />
          <LabelCheck><TranslatedText stringKey="ADMIN_TITLE_BOTTOM_REFUND_ENTITLEMENT_MODAL" /></LabelCheck>
        </ContentainerInfo>
        <ContentainerInfo className="footer">
          <Icon name="info" />
          <InfoText className="footer-info-text"><TranslatedText stringKey="ADMIN_INFO_BOTTOM_REFUND_ENTITLEMENT_MODAL" /></InfoText>
        </ContentainerInfo>
      </Footer>
    </StyledModal>
  );
};

export default LibraryModalRefund;

import styled, { css } from 'styled-components';
import { StyleProps } from '../../styles';
import { ADMIN_HIGHLIGHT_SECONDARY, ADMIN_TEXT_100, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_4 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import Icon from 'components/ui/Icon';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';

export const Text = styled.span`
  ${SINGLE_LINE_ELLIPSIS}
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
`;

export const CtaBehaviorOption = styled.div<StyleProps>`
  color: ${({ isActive }) => isActive ? ADMIN_TEXT_100 : ADMIN_TEXT_200};
  height: 48px;
  padding: 8px 16px 8px 12px;
  display: flex;
  align-items: center;
  width: 100%;

  ${({ isActive }) => !isActive && css`
    &:hover {
      background-color: ${ADMIN_SURFACE_4};
    }
  `}
`;

export const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  & > svg > path {
    fill: ${ADMIN_HIGHLIGHT_SECONDARY};
  }
`;

export const CheckIcon = styled(Icon).attrs({
  name: 'checkmark',
})`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  & path {
    fill: ${ADMIN_ACCENT_PRIMARY};
    stroke: ${ADMIN_ACCENT_PRIMARY};
  }
`;

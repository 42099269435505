import React from 'react';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import {
  Container,
  StyledPriceInput,
  StyledCurrencyDropdown,
  StyledRemoveButton,
  IPrice,
  DropdownContainer,
  PriceContainer,
  CurrencyRow,
  ErrorsRow,
  ToggleContainer,
} from './styles';
import ErrorMessage from 'components/admin2/ui/ErrorMessage';
import { formatSmallestUnit } from 'shared/string-utils';
import debounce from 'lodash/debounce';

interface ICurrencyComponentProps {
  currentDefault: number;
  disabled: boolean;
  displayErrors: boolean;
  errors: any;
  filterOptions: (currentCurrency: string, currency: string) => void;
  index: number;
  options: string[];
  price: IPrice;
  prices: IPrice[];
  setCurrentDefault: (index: number) => void;
  updatePrice: (prices: IPrice[]) => void;
}

const PRICE_CHANGE_DEBOUNCE = 200;

const CurrencyComponent = ({
  currentDefault,
  disabled,
  displayErrors,
  errors,
  filterOptions,
  index,
  options,
  price = { currency: '', default: false, value: '' },
  prices,
  setCurrentDefault,
  updatePrice,
}: ICurrencyComponentProps) => {

  const handleRemove = () => {
    if (prices.length === 1) return;
    const tempPrices: IPrice[] = [...prices];
    tempPrices.splice(index, 1);
    setCurrentDefault(index > currentDefault ? currentDefault : currentDefault - 1);
    updatePrice(tempPrices);
  };

  const handleDefault = () => {
    if (price.default) return;
    const tempPrices: IPrice[] = [...prices];
    if (!price.default) {
      tempPrices[currentDefault] = { ...prices[currentDefault], default: false };
      tempPrices[index] = { ...price, default: true };
      updatePrice(tempPrices);
      setCurrentDefault(index);
    }
  };

  const handlePriceChange = (input: string) => {
    const newPrice = input
      .replace(/[^0-9.]/g, '') // Remove all non-numeric and non-period characters
      .replace(/^0+/, '') // Remove leading zeros
      .replace(/(\d*\.\d{0,2})\d*/, '$1'); // Limit to two decimal places

    // Format the price according to currency
    const priceFormatUnit = formatSmallestUnit(price.currency || 'usd', Number(newPrice));

    // Check for invalid length conditions
    if (
      newPrice.length > prices[index].value.length &&
      (newPrice.charAt(0) === '.' || String(priceFormatUnit).length > 8)
    ) {
      return;
    }

    // Update the price in the array
    const updatedPrices = prices.map((p, idx) => (idx === index ? { ...price, value: newPrice } : p));
    updatePrice(updatedPrices);
  };

  const handleCurrency = (currency: string) => {
    const tempPrices: IPrice[] = [...prices];
    tempPrices[index] = { ...price, currency };
    updatePrice([...tempPrices]);
    filterOptions(currency, price.currency);
  };

  return (
    <Container data-testid={`multiCurrencyComponent-${index}`} >
      <CurrencyRow>
        <StyledPriceInput
          data-testid={`priceInput-${index}`}
          onChange={handlePriceChange}
          placeholderKey="ADMIN_PLACEHOLDER_PRICE"
          value={price.value}
          type="text"
        />
        <StyledCurrencyDropdown
          data-testid={`currencyDropdown-${index}`}
          dropUp={false}
          onChange={(handleCurrency)}
          options={options}
          value={price.currency}
          // We disable the dropdown if the price is already associated with a stripe price
          // Because stripe don't allow us to change the currency of a price
          isDisabled={Boolean(price.stripePriceId)}
        />
        <ToggleSwitch
          data-testid={`toggleCurrencyDefault-${index}`}
          checked={price.default}
          onChange={handleDefault}
        />
        {!price.default ? (
          <StyledRemoveButton data-testid={`removeCurrencyComponent-${index}`} disabled={disabled} onClick={handleRemove} />
        ) :
          null
        }
      </CurrencyRow>
      {displayErrors &&
        (
          <ErrorsRow>
            <PriceContainer>
              {errors?.value && <ErrorMessage errorMessageKey={errors?.value} />}
            </PriceContainer>
            <DropdownContainer>
              {errors?.currency && <ErrorMessage errorMessageKey={errors?.currency} />}
            </DropdownContainer>
            <ToggleContainer>
              {errors?.default && <ErrorMessage errorMessageKey={errors?.default} />}
            </ToggleContainer>
          </ErrorsRow>
        )
      }
    </Container>
  );
};

export default CurrencyComponent;

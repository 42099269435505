
import { getSiteId } from 'services/app/selectors';
import { getRefreshKey } from 'services/admin/selectors';
import { getPrimaryToken } from 'services/auth/selectors';
import { connect } from 'react-redux';
import ImageLibraryModal from './view';

const mapStateToProps = state => ({
  primaryToken: getPrimaryToken(state),
  refreshKey: getRefreshKey(state),
  siteId: getSiteId(state),
});

export default connect(mapStateToProps)(ImageLibraryModal);

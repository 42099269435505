import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import TextInput from 'components/admin2/TextInput';
import CollapsibleItem from 'components/admin2/ui/CollapsibleItem';
import UploadZone from 'components/admin2/UploadZone';
import { ADMIN_SURFACE_4 } from 'style/constants';
import { useSelector } from 'react-redux';
import { getAdminSurface2 } from 'services/themes';

const propTypes = {
  imageUrl: PropTypes.string,
  onChangeImage: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  imageUrl: null,
};

const Content = styled.div`
  display: flex;
  flex-flow: column;
  padding: 16px;
  background: ${ADMIN_SURFACE_4};
`;

export default function Answer({ imageUrl, onChangeImage, onChangeText, text, title }) {
  const [open, setOpen] = useState(true);
  const adminSurface2 = useSelector(getAdminSurface2);

  return (
    <div>
      { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */ }
      <CollapsibleItem
        headerBackground={adminSurface2}
        noDefaultIcon
        onSelect={() => setOpen(oldOpen => !oldOpen)}
        open={open}
        showArrow
        type={title}
      >
        <Content>
          <TextInput
            labelKey="ADMIN_LABEL_OPTION"
            onChange={onChangeText}
            placeholderText={title}
            value={text}
          />
          <UploadZone
            canDrop={false}
            height={150}
            imagePreview={imageUrl || undefined}
            labelKey="ADMIN_CARD_IMAGE_NAME"
            onClearImage={onChangeImage}
            onFileSubmit={onChangeImage}
          />
        </Content>
      </CollapsibleItem>
    </div>
  );
}

Answer.propTypes = propTypes;
Answer.defaultProps = defaultProps;

import styled, { css, StyledProps } from 'styled-components';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import withTooltip from 'components/core/withTooltip';
import { bw, lightenDarken } from 'colors';
import {
  ADMIN_BUTTON_BORDER_RADIUS,
  ADMIN_BUTTON_TEXT_CAPITALIZATION,
  ADMIN_ACCENT_PRIMARY,
  MAESTRO_BLACK,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_300,
  ADMIN_SURFACE_6,
  ADMIN_TEXT_100,
  ADMIN_ALERT_WARNING,
  ADMIN_ACCENT_SECONDARY,
  ADMIN_ACCENT_TERTIARY,
  ADMIN_TEXT_500,
} from 'style/constants';
import {
  ADMIN_BACKGROUND_BLACK,
  ADMIN_BACKGROUND_DARK,
  SINGLE_LINE_ELLIPSIS,
  NO_HIGHLIGHT,
} from 'style/mixins';
import { createTextContrastOnBackgroundColor } from 'services/themes/utils';
import { ADMIN_TEXT_BODY_M_REGULAR, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_XS_BOLD, ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';
import type { TranslationKey } from 'hooks/use-translation';

export interface ButtonProps {
  background?: string;
  black?: boolean;
  borderColor?: string;
  disabled?: boolean;
  disabledBackground?: string;
  disabledColor?: string;
  fullwidth?: boolean;
  hoverBackground?: string;
  hoverBorderColor?: string;
  hovering?: boolean;
  hoverTextColor?: string;
  left?: boolean;
  minWidth?: string;
  spacing?: string;
  textColor?: string;
  variant?: keyof typeof VariantMap;
  width?: string;
}

const backgroundColor = ({ background, ...props }: StyledProps<ButtonProps>) => background || ADMIN_ACCENT_PRIMARY(props);
const color = ({ background, textColor }: StyledProps<ButtonProps>) => textColor || (background ? bw(background) : ADMIN_TEXT_100);

const VariantMap = {
  'border-only': css<ButtonProps>`
    border: 1.5px solid ${backgroundColor};
    ${({ hoverBorderColor }) => hoverBorderColor && `
      &:hover {
        border-color: ${hoverBorderColor};
      }
    `}
  `,
  default: css<ButtonProps>`
    background: ${backgroundColor};
    border: none;
  `,
};

const ACTIVE_STYLE = css<ButtonProps>`
  ${NO_HIGHLIGHT}
  ${({ fullwidth }) => fullwidth && 'width: 100%;'}
  ${({ width }) => width && `width: ${width};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ black, variant = 'default', ...props }) => black ? css`
    background: ${ADMIN_SURFACE_4};
    && {
      color: ${ADMIN_TEXT_100};
    }
  ` : css`
    ${VariantMap[variant]}
    && {
      color: ${color(props)};
    }
  `}
  & svg, & svg > path {
    fill: ${color};
  }
  ${({ hovering }) => hovering && '&, '}&:hover, &:active {
    background: ${({ hoverBackground = MAESTRO_BLACK }) => hoverBackground};
    &, & *, & * * {
      color: ${({ hoverTextColor = ADMIN_TEXT_100 }) => hoverTextColor};
    }
    & svg, & svg > path {
      fill: ${({ hoverTextColor = ADMIN_TEXT_100 }) => hoverTextColor};
    }
  }
  ${({ disabled }) => disabled && '&, '}&:disabled {
    pointer-events: none;
    background: ${({ disabledBackground = ADMIN_SURFACE_3 }) => disabledBackground} !important;
    &, & *, & * * {
      color: ${({ disabledColor = ADMIN_SURFACE_5 }) => disabledColor} !important;
      cursor: default;
    }
    & svg, & svg > path {
      fill: ${({ disabledColor = ADMIN_SURFACE_5 }) => disabledColor} !important;
    }
  }
  ${SINGLE_LINE_ELLIPSIS}
  ${({ spacing }) => spacing && `margin: ${spacing} !important;`}
`;

const DARK_STYLE = css`
  ${ADMIN_BACKGROUND_DARK}
  &:hover, &:active {
    ${ADMIN_BACKGROUND_BLACK}
  }
`;

export const BASE_BUTTON = css`
  ${ACTIVE_STYLE}
  border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};
  text-transform: ${ADMIN_BUTTON_TEXT_CAPITALIZATION};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const BUTTON_NORMAL = css`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  ${BASE_BUTTON}
`;

export const BUTTON_BOLD = css`
  ${ADMIN_TEXT_BODY_S_BOLD}
  ${BASE_BUTTON}
`;

export const SmallButton = withTooltip(styled.button`
  ${BUTTON_NORMAL}
  ${DARK_STYLE}
  height: 23px;
  padding: 4px 12px;
  font-weight: 400;
  text-transform: none;
`, { admin: true });

export const Button = withTooltip(styled.button`
  ${BUTTON_NORMAL}
  height: 28px;
  padding: 0 15px;
`, { admin: true });

export const LinkButton = withTooltip(styled(Link)`
  ${BUTTON_NORMAL}
  height: 24px;
  padding: 0 10px;
`, { admin: true });

export const LargeButton = withTooltip(styled.button`
  ${BUTTON_BOLD}
  min-width: 76px;
  height: 40px;
  padding: 0 30px;
  font-weight: 700;
`, { admin: true });

export const TertiaryButton = styled(LargeButton).attrs(props => ({
  background: ADMIN_ACCENT_TERTIARY(props),
  hoverBackground: lightenDarken({ hex: ADMIN_ACCENT_TERTIARY(props), percentage: 0.1 }),
  textColor: createTextContrastOnBackgroundColor(ADMIN_ACCENT_TERTIARY(props), ADMIN_TEXT_100(props), ADMIN_TEXT_500(props)),
  hoverTextColor: createTextContrastOnBackgroundColor(ADMIN_ACCENT_TERTIARY(props), ADMIN_TEXT_100(props), ADMIN_TEXT_500(props)),
}))``;

export const NeonButton = styled(Button).attrs(props => ({
  background: ADMIN_ALERT_WARNING(props),
}))`
  font-weight: 700;
` as typeof Button;

export const LabelButton = styled(Button).attrs({
  background: MAESTRO_BLACK,
  hoverBackground: MAESTRO_BLACK,
})`
  cursor: default;
` as typeof Button;

export const BorderButton = styled(LargeButton).attrs(props => ({
  background: ADMIN_SURFACE_6(props),
  color: ADMIN_TEXT_100(props),
  fullwidth: true,
  hoverBackground: 'transparent',
  hoverBorderColor: ADMIN_SURFACE_6(props),
  variant: 'border-only',
}))``;

const IconButtonBase = styled(Icon) <ButtonProps>`
  ${BUTTON_NORMAL}
  ${props => props.color && css`background-color: ${props.color};`}
  ${ADMIN_TEXT_BODY_M_REGULAR}
  width: 24px;
  height: 24px;
  padding: 5px;
`;

const iconTooltip = <T>(component: T, tooltipKey?: TranslationKey) => (
  withTooltip(component, { admin: true, tooltipKey })
);

interface NavButtonStyleProps {
  large?: boolean;
  small?: boolean;
}

export const NavButton = styled.div.attrs({
  black: true,
}) <ButtonProps & NavButtonStyleProps>`
  ${BUTTON_NORMAL}
  width: 64px;
  height: ${({ small }) => small ? 21 : 28}px;
  overflow: hidden;
  ${({ left }) => css`
    justify-content: flex-${left ? 'start' : 'end'};
    margin-right: ${left ? 9 : 0}px;
    & label {
      margin-${left ? 'left' : 'right'}: 8px;
    }
  `}
  ${({ large }) => large && css`
    ${ADMIN_TEXT_LABEL_M_BOLD}
    height: 40px;
    padding: 0 21px 0 8px;
    width: auto;
    background: ${MAESTRO_BLACK}
  `}
`;

export const IconButton = iconTooltip(styled(IconButtonBase)`
  ${BUTTON_NORMAL}
  ${ADMIN_TEXT_BODY_M_REGULAR}
  width: 24px;
  height: 24px;
  padding: 5px;
`);

export const IconButtonNoTooTip = styled(IconButtonBase)`
  ${BUTTON_NORMAL}
  ${ADMIN_TEXT_BODY_M_REGULAR}
  width: 24px;
  height: 24px;
  padding: 7px;
`;

export const CloseButton = iconTooltip(styled(IconButtonBase).attrs({
  black: true,
  name: 'cancel-thin',
})`
  width: 21px;
  height: 21px;
  cursor: pointer;
`, 'CLOSE');

export const CloseButtonNoToolTip = styled(IconButtonBase).attrs(() => ({
  black: true,
  name: 'cancel-thin',
}))`
  width: 19px;
  height: 19px;
  background-color: rgb(49, 50, 62, 0.3);
  padding: 3px;
  &:hover {
    background: rgb(49, 50, 62, 0.7);
  }
`;

export const EditButton = iconTooltip(styled(IconButtonBase).attrs(props => ({
  name: 'pencil',
  ...props,
}))``, 'ADMIN_LABEL_EDIT');

export const EditSettingsButton = iconTooltip(
  styled(IconButtonBase).attrs(
    props => ({
      name: 'settingsOutlined',
      ...props,
    }),
  )`
  position: relative;
  & svg, svg path {
    width: 100%;
    height: 100%;
  }
  `,
  'ADMIN_LABEL_EDIT',
);

export const CopyButton = iconTooltip(styled(IconButtonBase).attrs({
  name: 'copy',
})``, 'LABEL_COPY');

export const AddButton = iconTooltip(styled(IconButtonBase).attrs(props => ({
  'data-testid': props['data-testid'] ? props['data-testid'] : 'useItemButton',
  name: 'plus-thin',
}))``, 'ADMIN_LABEL_USE');

export const DeleteButton = iconTooltip(styled(IconButtonBase).attrs({
  name: 'trash',
})``, 'ACTION_DELETE');

export const RemoveButton = iconTooltip(styled(IconButtonBase).attrs({
  name: 'dash',
})``, 'REMOVE');

export const BroadcastButton = iconTooltip(styled(IconButtonBase).attrs({
  name: 'megaphone',
})`
  padding: 0;
  background: ${ADMIN_ACCENT_SECONDARY};
  & svg {
    margin-bottom: -1px;
    & > path {
      fill: ${MAESTRO_BLACK};
    }
  }
`, 'ADMIN_ACTION_BROADCAST');

export const WatchButton = iconTooltip(styled(IconButtonBase).attrs(() => ({
  name: 'eyeFilled',
}))`
  padding: 0;
  & svg, svg path {
    width: 17px !important;
    height: 17px !important;
  }
`, 'ADMIN_LABEL_PREVIEW');

export const LockButton = iconTooltip(styled(IconButtonBase).attrs(() => ({
  name: 'lock',
}))`
  line-height: 0;
  & svg {
    width: 13px;
    height: 13px;
  }
`, 'LOCKED');

export const AccessCodeButton = iconTooltip(styled(IconButtonBase).attrs(() => ({
  name: 'key',
}))`
  & svg {
    width: 13px;
    height: 13px;
  }
`, 'LABEL_ACCESS_CODE');

export const CalendarButton = iconTooltip(styled(IconButtonBase).attrs(() => ({
  'data-testid': 'calendarButton',
  name: 'calendar',
}))``, 'ADMIN_LABEL_CALENDAR');

export const GradientArrow = css<{ arrowColor?: string, hoverColor?: string }>`
  cursor: pointer;
  clip-path: inset(20px 22px 20px 22px);
  & svg {
    width: 60px;
    height: 60px;
  }
  ${({ arrowColor }) => arrowColor && css`
    & svg > g > path {
      fill: ${arrowColor};
    }
  `}
  ${({ hoverColor }) => hoverColor && css`
    &:hover svg > g > path {
      fill: ${hoverColor};
    }
  `}
`;

export const DownArrow = styled(Icon).attrs((props) => ({
  color: props.color || ADMIN_ACCENT_PRIMARY(props),
  name: 'cleanDownGradArrow',
})) <{ size?: number, spacing?: string, up?: boolean }>`
  line-height: 0;
  transition: transform 0.5s;
  transform: rotate(${({ up }) => up ? 180 : 0}deg);
  ${({ spacing }) => spacing && `margin: ${spacing} !important;`}
  ${({ size }) => size && css`
    & svg {
      width: ${size}px;
      height: ${size}px;
    }
  `}
`;

export const LeftArrow = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
  name: 'leftArrowGrad',
})) <{ disabled?: boolean, spacing?: string }>`
  ${GradientArrow}
  ${({ disabled }) => disabled ? css`
    pointer-events: none;
    & svg > g > path {
      fill: ${ADMIN_TEXT_300};
    }
    ` : null}
`;

export const RightArrow = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
  name: 'rightArrowGrad',
})) <{ disabled?: boolean, spacing?: string }>`
  ${GradientArrow}
  ${({ disabled }) => disabled ? css`
    pointer-events: none;
    & svg > g > path {
      fill: ${ADMIN_TEXT_300};
    }
    ` : null}
`;

export const ResendButton = iconTooltip(styled(IconButtonBase).attrs({
  name: 'resend',
})``, 'ADMIN_RESEND');

export default Button;

import styled from 'styled-components';
import Link from 'components/ui/Link';
import Icon from 'components/ui/Icon';
import { rgba } from 'colors';
import {
  ACTION_NORMAL,
} from 'style/mixins';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_ACTION_COLOR,
  ADMIN_ACTION_COLOR_HOVER,
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
  ADMIN_TEXT_300,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled(Link).attrs({
  plain: true,
})`
  && {
    ${ACTION_NORMAL}
    ${ADMIN_TEXT_BODY_S_MEDIUM}
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    color: ${ADMIN_TEXT_200};
    & svg > path {
      fill: ${ADMIN_TEXT_300}
    }
    &:hover svg > path  {
      fill: ${ADMIN_ACTION_COLOR_HOVER};
    }
    &.active {
      border-bottom: 2px solid ${ADMIN_ACCENT_PRIMARY};
      background: linear-gradient(0deg, ${ADMIN_ACCENT_PRIMARY} -66%, ${props => rgba(ADMIN_ACCENT_PRIMARY(props), 0)} 67.9%);
      color: ${ADMIN_TEXT_100};
      & svg > path {
        fill: ${ADMIN_TEXT_100};
      }
    }
  }
`;

export const Butt = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-bottom-color: ${ADMIN_ACCENT_PRIMARY};
  border-width: 0 6.5px 7px 6.5px;
  border-style: solid;
  bottom: 0;
  pointer-events: none;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 10px;
  & svg {
    width: 18px;
    height: 18px;
    & > path {
      fill: ${ADMIN_ACTION_COLOR};
    }
  }
`;

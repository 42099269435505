/* tslint:disable: jsx-boolean-value */
import React, { useState, useMemo } from 'react';
import { URLInputProps } from 'components/admin2/ui/URLInput';
import URLInput from 'components/admin2/ui/URLInput';
import AdminConfirmationModal from 'components/admin2/AdminConfirmationModal';
import {
  ButtonContainer,
  Container,
  InstallButton,
  StyledButton,
} from './styles';
import withConfirmation from 'components/core/withConfirmation';
import { MAESTRO_SHOPIFY_APP_URL } from 'shared/constants';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';

interface ShopifyConnectProps extends URLInputProps {
  confirmTextKey?: TranslationKey;
  hostname?: string;
  isConnected?: boolean;
  notConnectedText: string;
  onDisconnect?: (url?: string) => void;
  protocol?: string;
}

const ConfirmDisconnect = withConfirmation(StyledButton, AdminConfirmationModal);

function ShopifyConnect({
  className,
  children,
  confirmTextKey = 'ADMIN_LABEL_APP_DISCONNECT_CONFIRM',
  isConnected,
  notConnectedText,
  onDisconnect,
  url: urlInput,
  ...props
}: ShopifyConnectProps) {
  const [url, setURL] = useState(urlInput);
  const { t } = useAdminTranslation();

  const handleChange = (inputURL: string) => {
    setURL(inputURL);
  };

  const handleDisconnect = () => {
    onDisconnect?.(url);
  };

  const handleOpenShopifyApp = () => {
    window.open(MAESTRO_SHOPIFY_APP_URL, '_blank');
  };

  const renderedShopifyOptions = useMemo(() => {
    if (isConnected) {
      return  <URLInput compact onChange={handleChange} url={urlInput} {...props} />;
    }
    return <InstallButton onClick={handleOpenShopifyApp}>{notConnectedText}</InstallButton>;
  }, [isConnected]);

  return (
    <Container className={className}>
      {renderedShopifyOptions}
      {isConnected && (
        <ButtonContainer>
          <ConfirmDisconnect action={handleDisconnect} messageKey={confirmTextKey} variant="border-only">
            {t('ADMIN_CARD_SHOPIFY_DISCONNECT')}
          </ConfirmDisconnect>
        </ButtonContainer>
      )}
    </Container>
  );
}

export default ShopifyConnect;

import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';
import {
  ADMIN_TEXT_COLOR_CONTENT_DISABLED,
  ADMIN_TEXT_300,
} from 'style/constants';
import {
  ADMIN_FIELD_TEXT_INPUT,
  ADMIN_SCROLL_BAR,
} from 'style/mixins';
import { INPUT_LENGTH } from 'style/mixins/admin/adminText';
import TranslatedText from 'components/i18n/TranslatedText';
import withLabel from 'components/core/withLabel';
import withPadding from 'components/core/withPadding';
import { ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';

const CharsLengthHelper = styled.span`
  ${INPUT_LENGTH}
  align-self: flex-end;
  margin-bottom: 5px;
`;

const Container = styled.div`
  ${ADMIN_SCROLL_BAR}
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`;

const Input = styled.textarea.attrs({
  type: 'text',
})`
  ${ADMIN_FIELD_TEXT_INPUT}
  ${ADMIN_SCROLL_BAR}
  height: 100%;
  min-height: 80px;
  resize: none;
  width: 100%;
  &::placeholder {
    color: ${ADMIN_TEXT_COLOR_CONTENT_DISABLED};
  }
`;

const HelperText = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 11px 10px 0;
  ${ADMIN_TEXT_LABEL_S_BOLD}
  color: ${ADMIN_TEXT_300};
  font-style: italic;
`;

class TextArea extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    'data-testid': PropTypes.string,
    disabled: PropTypes.bool,
    helperTextKey: PropTypes.string,
    inputTestId: PropTypes.string,
    maxlength: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholderKey: PropTypes.string,
    placeholderText: PropTypes.string,
    rows: PropTypes.number,
    value: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    'data-testid': undefined,
    disabled: false,
    helperTextKey: null,
    inputTestId: undefined,
    maxlength: '',
    onBlur: undefined,
    onChange: () => { },
    onFocus: undefined,
    placeholderKey: null,
    placeholderText: '',
    rows: 0,
    value: '',
  };

  handleChange = (event) => {
    const { onChange } = this.props;
    onChange(event.target.value);
  };

  renderWithPlaceholder(text) {
    const {
      className,
      'data-testid': testId,
      disabled,
      helperTextKey,
      inputTestId,
      onBlur,
      onFocus,
      placeholderText,
      value,
      rows,
      maxlength,
    } = this.props;
    const { handleChange } = this;
    return (
      <Container className={className} data-testid={testId}>
        <Input
          data-testid={inputTestId}
          disabled={disabled}
          maxLength={maxlength}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={text || placeholderText}
          rows={rows}
          value={value}
        />
        {helperTextKey && (
          <HelperText>
            <TranslatedText stringKey={helperTextKey} />
          </HelperText>
        )}
        {
        maxlength && (
        <CharsLengthHelper>
            {value.length}/{maxlength}
        </CharsLengthHelper>
        )
}
      </Container>
    );
  }

  render() {
    const { className, placeholderKey } = this.props;
    if (placeholderKey) {
      return (
        <TranslatedText className={className} stringKey={placeholderKey}>
          {
            text => this.renderWithPlaceholder(text)
          }
        </TranslatedText>
      );
    }
    return this.renderWithPlaceholder();
  }
}

export default memo(withPadding(withLabel(TextArea), '10px 0'));

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import set from 'lodash/set';
import { useDispatch, useSelector } from 'hooks';
import { getSiteId } from 'services/app/selectors';
import { saveQuest } from 'services/admin';
import QuestEditorView from './QuestEditorView';

const propTypes = {
  initialValue: PropTypes.shape({
    _id: PropTypes.string,
  }),
  onAfterSave: PropTypes.func,
  onBack: PropTypes.func,
};

const defaultProps = {
  initialValue: null,
  onAfterSave: null,
  onBack: null,
};

const newQuest = siteId => ({
  rewardType: 'prize',
  siteId,
});

// filter empty props for server validation
const clean = (obj) => {
  const copy = {};
  for (const propName in obj) {
    if (!(obj[propName] == null || obj[propName] === '')) {
      set(copy, propName, obj[propName]);
    }
  }
  return copy;
};

export default function QuestEditor({
  initialValue,
  onAfterSave,
  onBack,
  ...editorProps
}) {
  const dispatch = useDispatch();
  const siteId = useSelector(getSiteId);
  const questDoc = initialValue?._id ? initialValue : newQuest(siteId);
  const [value, onChange] = useState(questDoc);
  const [isNew, setNew] = useState(value !== initialValue);

  useEffect(
    () => {
      if (initialValue?._id && initialValue._id !== value._id) {
        // if we receive a different initial doc, always sync
        onChange(initialValue);
        setNew(false);
      } else if (!initialValue?._id && !isNew) {
        // if initialDoc becomes unset and we're editing an existing doc, switch to a new one
        onChange(newQuest(siteId));
        setNew(true);
      }
    },
    [initialValue?._id],
  );

  return (
    <QuestEditorView
      onBack={onBack}
      onChange={onChange}
      onSave={(doc) => {
        dispatch(
          saveQuest(clean(doc)),
        );
        if (onAfterSave) {
          onAfterSave();
        }
      }}
      value={value}
      {...editorProps}
    />
  );
}

QuestEditor.propTypes = propTypes;
QuestEditor.defaultProps = defaultProps;

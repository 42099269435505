import AdminButton from 'components/admin2/ui/Button';
import Button from 'components/ui/Button';
import styled, { css } from 'styled-components';
import { CIRCLE } from 'style/mixins';
import {
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_2,
  ADMIN_HIGHLIGHT_PRIMARY,
  ADMIN_BUTTON_BORDER_RADIUS,
  BUTTON_BORDER_RADIUS,
  SURFACE_5,
  HIGHLIGHT_PRIMARY,
  SURFACE_2,
  SURFACE_3,
  TEXT_100,
  ADMIN_TEXT_100,
  ADMIN_SURFACE_3,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_S_MEDIUM, TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 1;
`;

export const MenuButton = styled.button<{ admin: boolean; isOpen: boolean; }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: ${({ admin }) => admin ? ADMIN_BUTTON_BORDER_RADIUS : BUTTON_BORDER_RADIUS};
  width: 24px;
  height: 24px;
  pointer-events: all;

  ${props => props.isOpen && css`background: ${props.admin ? ADMIN_SURFACE_5 : SURFACE_5};`};
  &:hover {
    background: ${props => props.admin ? ADMIN_SURFACE_5 : SURFACE_5};
  }
`;

export const Dot = styled.span.attrs({
  radius: '3px',
})<{ admin: boolean; }>`
  ${CIRCLE}
  background: ${props => props.admin ? ADMIN_HIGHLIGHT_PRIMARY : HIGHLIGHT_PRIMARY};
`;

export const Menu = styled.div<{ admin: boolean; }>`
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 136px;
  gap: 5px;
  border-radius: 4.5px;
  padding: 10px;
  background: transparent;
  pointer-events: all;
  position: fixed;
  background: ${props => props.admin ? ADMIN_SURFACE_2 : SURFACE_2};
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.55);
`;

export const QuickActionAdmin = styled(AdminButton).attrs(props => ({
  background: ADMIN_SURFACE_3(props),
  textColor: ADMIN_TEXT_100(props),
  hoverBackground: ADMIN_SURFACE_5(props),
  hoverTextColor: ADMIN_TEXT_100(props),
}))`
  ${ADMIN_TEXT_LABEL_S_MEDIUM}
  height: 24px;
  padding: 6px 16px;
`;

export const QuickAction = styled(Button).attrs(props => ({
  background: SURFACE_3(props),
  textColor: TEXT_100(props),
  hoverBackground: SURFACE_5(props),
  hoverTextColor: TEXT_100(props),
}))`
  ${TEXT_LABEL_S_MEDIUM}
  height: 24px;
  padding: 6px 16px;
`;

import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { usePlaylist, useVideo } from 'hooks';
import hash from 'json-stable-stringify';
import { useAdminTranslation } from 'hooks/use-translation';

const propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'video',
    'playlist',
  ]),
};

const defaultProps = {
  type: 'video',
};

// use both because sometimes we don't know which schema an id belongs to
// "objects" collection strikes back
const hooks = {
  playlist: usePlaylist,
  video: useVideo,
};

export default function VideoDataProvider({ children: render, id, type }) {
  const { t } = useAdminTranslation();
  // one of these is null
  const { playlist, loaded: playlistLoaded } = usePlaylist(id);
  const { video, loaded: videoLoaded } = useVideo(id);
  const data = useMemo(() => (playlist || video || null),
    [hash(playlist), hash(video), playlistLoaded, videoLoaded]);
  const loaded = useMemo(() => (
    (type === 'playlist' && playlistLoaded) ||
    (type === 'video' && videoLoaded)
  ), [type, videoLoaded, playlistLoaded]);

  if (video && video.archived) {
    return render(null, t('ADMIN_LABEL_VIDEO_ARCHIVED_ERROR'), true);
  }

  if (!hooks[type]) {
    // eslint-disable-next-line no-console
    console.error(`VideoDataProvider missing document hook for ${type}`);
    return render(null, `Error loading ${type}`, true);
  }
  return render(data, null, loaded);
}

VideoDataProvider.propTypes = propTypes;
VideoDataProvider.defaultProps = defaultProps;

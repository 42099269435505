/* tslint:disable: jsx-boolean-value */
import React, { useCallback, useMemo, useState } from 'react';
import Dropdown from 'components/admin2/ui/Dropdown';
import {
  PaginationContainer,
  StyledNavigation,
} from './styles';

interface PaginationProps {
  onPageChange: (size: number) => void;
  onPageSizeChange: (size: number) => void;
  page: number;
  pages: number;
  pageSizeOptions: number[];
}

export default function Pagination({
  onPageChange,
  onPageSizeChange,
  page,
  pages,
  pageSizeOptions,
}: PaginationProps) {
  const [selectedPageSize, setSelectedPageSize] = useState({ label: '50 rows', value: 50 });

  const options = useMemo(() => pageSizeOptions.map((size) => ({
    label: `${size} rows`,
    value: size,
  })), [pageSizeOptions]);

  const handleChange = useCallback((option) => {
    setSelectedPageSize(option);
    onPageSizeChange(option.value);
  }, [onPageSizeChange]);

  const goToNextPage = useCallback(() => {
    onPageChange(page + 1);
  }, [onPageChange, page]);

  const goToPrevPage = useCallback(() => {
    onPageChange(page - 1);
  }, [onPageChange, page]);

  return (
    <PaginationContainer>
      <StyledNavigation
        admin
        onChange={onPageChange}
        onNextPage={goToNextPage}
        onPreviousPage={goToPrevPage}
        page={page}
        pages={pages}
      />
      <Dropdown dropUp width="128px" onChange={handleChange} options={options} value={selectedPageSize} />
    </PaginationContainer>
  );
}

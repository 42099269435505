import { IThemeColors } from 'models';
import { THEME_TYPES, ThemeTypes } from 'models/ITheme';
import styled, { css } from 'styled-components';

const DESKTOP_CARD_STYLES = css`
  & > svg {
    width: 223px;
    height: 139px;
  }
`;

const MOBILE_CARD_STYLES = css`
  & > svg {
    width: 70px;
    height: 139px;
  }
`;

export const SvgContainer = styled.span<{
  dangerouslySetInnerHTML: { __html: string };
  height?: number;
  mobile?: boolean;
  themeColors: IThemeColors;
  type?: ThemeTypes;
  width?: number;
}>`
  display: inline-block;
  ${({ mobile }) => (mobile ? MOBILE_CARD_STYLES : DESKTOP_CARD_STYLES)};
  & > svg {
    vertical-align: middle;
    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
      `}
    ${({ height }) =>
      height &&
      css`
        height: ${height}px;
      `}
    ${({ themeColors, type }) => css`
      & > .surface1 {
        fill: ${themeColors.surface1};
      }
      & > .surface2 {
        fill: ${themeColors.surface2};
      }
      & > .surface3 {
        fill: ${themeColors.surface3};
      }
      & > .surface4 {
        fill: ${type !== THEME_TYPES.CLASSIC ? themeColors.surface3 : themeColors.surface4};
      }
      & > .surface5 {
        fill: ${type !== THEME_TYPES.CLASSIC ? themeColors.surface4 : themeColors.surface5};
      }
      & > .accentPrimary {
        fill: ${themeColors.accentPrimary};
      }
      & > .accentSecondary {
        fill: ${themeColors.accentSecondary};
      }
      & > .highlightPrimary {
        fill: ${themeColors.highlightPrimary};
      }
      & > .highlightSecondary {
        fill: ${type !== THEME_TYPES.CLASSIC ? themeColors.highlightPrimary : themeColors.highlightSecondary};
      }
      & > .highlightTertiary {
        fill: ${themeColors.highlightTertiary};
      }
      & > .text100 {
        fill: ${themeColors.text100};
      }
      & > .text200 {
        fill: ${themeColors.text200};
      }
      & > .text300 {
        fill: ${themeColors.text300};
      }
      & > .text400 {
        fill: ${themeColors.text400};
      }
      & > .text500 {
        fill: ${themeColors.text500};
      }
    `}
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SOCIAL_SERVICE_BASE_URL } from 'config';
import AjaxProvider from 'components/core/AjaxProvider';
import StatusIndicator from 'components/admin2/StatusIndicator';
import Field from './Field';

const StyledStatusIndicator = styled(StatusIndicator)`
  display: flex;
  align-items: center;
  float: right;
  position: absolute;
  right: 8px;
  top: 18px;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const getValidationEndpointFromTweetUrl = (url = '') => {
  const twitterStatusRegEx = /twitter.com\/\w{1,15}\/status\/[0-9]{10,}/g;
  const statusIdRegEx = /[0-9]{10,20}/g;
  const statusId = url.match(statusIdRegEx);

  if (twitterStatusRegEx.test(url) && statusId.length) {
    return `${SOCIAL_SERVICE_BASE_URL}/twitter/${statusId[0]}`;
  }
  return null;
};

export default class TweetUrlField extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    const { config, onChange, renderer } = this.props;
    const { renderer: { tweetUrl } } = this.props;
    const validationUrl = getValidationEndpointFromTweetUrl(tweetUrl);

    return (
      <Container>
        { validationUrl && (
          <AjaxProvider url={validationUrl}>
            {
              (data, error, loaded) => (
                <StyledStatusIndicator
                  error={error ? 'NOT_FOUND' : null}
                  fetching={validationUrl && !loaded}
                  fetchingLabelKey="LOADING"
                  loaded={!error && loaded}
                />
              )
            }
          </AjaxProvider>
        )}
        <Field
          key={config.fieldName}
          config={config}
          onChange={onChange}
          renderer={renderer}
        />
      </Container>
    );
  }
}

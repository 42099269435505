import { isEqual } from 'lodash';
import React, { useContext, useMemo, useCallback } from 'react';
import { ILocale, LocalizationTabContext } from './LocalizationTabContext';
import { Dropdown } from '../ui/Dropdown';
import { getLocaleLabel } from './utils';

const DefaultLanguageDropdown: React.FC = () => {
  const {
    defaultLanguage,
    emptyDefaultLanguage,
    setDefaultLanguage,
    validLanguages,
  } = useContext(LocalizationTabContext);

  const handleChangeDefaultLanguage = useCallback((value: ILocale) => {
    if (!isEqual(value, defaultLanguage)) {
      setDefaultLanguage(value);
    }
  }, [defaultLanguage]);

  const options = useMemo(() => [emptyDefaultLanguage, ...validLanguages], [validLanguages]);

  return (
    <Dropdown
      placeholderKey="ADMIN_LOCALIZATION_DROPDOWN_PLACEHOLDER"
      options={options}
      valueKey="locale"
      getOptionLabel={getLocaleLabel}
      value={defaultLanguage}
      onChange={handleChangeDefaultLanguage}
      padding="0"
      isLocalization={true}
      isAdmin={true}
    />
  );
};

export default DefaultLanguageDropdown;

import { ADMIN_ACCENT_PRIMARY } from 'style/constants';
import styled, { keyframes } from 'styled-components';

const DOT_SIZE = 6;

export interface EllipsisStyleProps {
  color?: string;
  dotSize?: number;
}

const fade = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const Ellipsis = styled.div<EllipsisStyleProps>`
  display: flex;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  ${({ dotSize = DOT_SIZE }) => `
    & > div {
      width: ${dotSize}px;
      height: ${dotSize}px;
    }
  `}
`;

export const Dot = styled.div<EllipsisStyleProps>`
  background: ${props => props.color || ADMIN_ACCENT_PRIMARY(props)};
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  border-radius: 100%;
  animation: ${fade} 0.75s infinite alternate;
  margin-left: 5px;
  &:nth-child(2) {
    animation-delay: 0.25s;
  }
  &:nth-child(3) {
    animation-delay: 0.5s;
  }
`;

import styled, { css } from 'styled-components';
import { BASE_LABEL, SITE_NAVIGATION_TEXT, StyleProps, activeColor } from '../../styles';
import Input from 'components/admin2/TextInput';
import { ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';
import { ADMIN_FONT_FAMILY, ADMIN_HIGHLIGHT_SECONDARY, ADMIN_TEXT_200, ADMIN_TEXT_300 } from 'style/constants';
import { ELLIPSIS } from 'style/mixins';
import Icon from 'components/admin2/Icon';
import withTooltip from 'components/core/withTooltip';

export const SiteNavigationLabel = styled.div<StyleProps>`
  ${BASE_LABEL};
  ${({ isActive, ...props }) => isActive && css`
    color: ${activeColor(props)};
  `}
  padding: 5px 16px 5px 16px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const ItemNameInput = styled(Input)`
  ${SITE_NAVIGATION_TEXT};
  width: 100%;
  padding: 0;
  padding-right: 3px;
  margin-right: 6px;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const HitEnterText = styled.span`
  ${ADMIN_TEXT_LABEL_XS_MEDIUM};
  color: ${ADMIN_TEXT_300};
  font-family: ${ADMIN_FONT_FAMILY};
  white-space: nowrap;
`;

export const ItemNameText = styled.span<{ maxLength: number }>`
  display: inline-block;
  ${ELLIPSIS}
  max-width: ${({ maxLength }) => maxLength}ch;
`;

export const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 10px;
`;

const tooltipCSS = {
  admin: true,
  tooltipArrowCss: 'bottom: -10px;',
  tooltipCss: css`
    white-space: normal;
    text-align: left;
    padding: 4px, 6px, 4px, 6px;
    color: ${ADMIN_TEXT_200};
    font-family: ${ADMIN_FONT_FAMILY};
    margin-bottom: -10px;
    ${ADMIN_TEXT_BODY_XS_MEDIUM}
  `,
};

export const DuplicateActionTooltip = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'copy',
    color: ADMIN_HIGHLIGHT_SECONDARY,
  }))`
  `,
  tooltipCSS,
);

export const RenameActionTooltip = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'pencil',
    color: ADMIN_HIGHLIGHT_SECONDARY,
  }))`
  `,
  tooltipCSS,
);

export const DeleteActionTooltip = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'trashV2',
    color: ADMIN_HIGHLIGHT_SECONDARY,
  }))`
  `,
  tooltipCSS,
);

// @ts-check
import styled, { css } from 'styled-components';
import TextInput from 'components/admin2/TextInput';
import TextArea from 'components/admin2/TextArea';
import Label from 'components/admin2/ui/Label';
import { StyledIcon } from 'components/admin2/ui/Label/styles';
import RadioGroup, { RadioContent } from 'components/admin2/RadioGroup';
import {
  SPACING_SMALL,
  ADMIN_SURFACE_4,
  ADMIN_TEXT_COLOR_CONTENT_DISABLED,
  ADMIN_SURFACE_2,
  SPACING_X_SMALL,
} from 'style/constants';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import Icon from 'components/ui/Icon';
import withTooltip from 'components/core/withTooltip';
import DatetimePicker from '../DatetimePicker';
import NeedHelpSection from '../NeedHelpSection';

export const ContentContainer = styled.div`
  ${ADMIN_SCROLL_BAR}
  background-color: ${ADMIN_SURFACE_4};
  border-radius: 10px;
  border: 2px;
  display: flex;
  flex-direction: column;
  max-height: 95vh !important;
  padding: 30px;
  width: 100%;
`;

export const TextInputSection = styled.div`
  flex: 0 1 110px;
  position: relative;
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;

  ${({ disabled }) => disabled && 'opacity: 0.4;'};
`;

export const StyledSkuInput = styled(TextInput)`
  margin-bottom: 0;
  color: ${ADMIN_TEXT_COLOR_CONTENT_DISABLED};
  &::placeholder {
    color: ${ADMIN_TEXT_COLOR_CONTENT_DISABLED};
  };

  ${({ disabled }) => disabled && 'opacity: 0.4;'};
`;

export const StyledContainer = styled.div`
  padding: 10px 0;
  margin-bottom: ${SPACING_SMALL};
  a {
    text-decoration: underline !important;
  };
  ${({ style }) => ({ ...style })};
`;

export const FrequencyContainer = styled(StyledContainer)`
  flex-direction: column;
  position: relative;
  margin-bottom: 0;

  ${({ disabled }) => disabled && css`opacity: 0.4;`};
`;

export const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: column;
  ${RadioContent}{
    margin-bottom: 0;
  };
  span{
    text-transform: none;
  };

  ${({ disabled }) => disabled && css`
    opacity: 0.4;
    & button {
      pointer-events: none !important;
    }
  `};
`;

export const StyledDateTimePicker = styled(DatetimePicker).attrs(props => ({
  expandableBackGroundColor: ADMIN_SURFACE_2(props),
}))`
  width: 266px;
`;

export const StyledTextArea = styled(TextArea)`
  margin-bottom: ${SPACING_X_SMALL};
  flex-direction: row;
  margin-bottom: 0;
`;

export const StyledIconLabel = styled(Label)`
  ${StyledIcon}{
    margin-left: 0;
  }
`;

export const StyledNeedHelpSection = styled(NeedHelpSection)`
  margin-top: 10px;
`;

const StyledNav2AccessIcon = styled(Icon).attrs({
  name: 'nav2Access',
})`
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 1;
`;

export const CannotEditIcon = withTooltip(StyledNav2AccessIcon, {
  admin: true,
  tooltipArrowCss: 'left: 85%;',
  tooltipCss: 'margin-left: 412px;',
  tooltipWrapperCss: 'margin-top: 10px;',
});

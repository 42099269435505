import {
  ANSWER_ANY_POLL,
  ANSWER_ANY_PREDICTION,
  ANSWER_ANY_PREDICTION_CORRECTLY,
  ANSWER_ANY_TRIVIA,
  ANSWER_ANY_TRIVIA_CORRECTLY,
  ANSWER_SPECIFIC_POLL,
  ANSWER_SPECIFIC_PREDICTION,
  ANSWER_SPECIFIC_PREDICTION_CORRECTLY,
  ANSWER_SPECIFIC_TRIVIA,
  ANSWER_SPECIFIC_TRIVIA_CORRECTLY,
  DISABLED_ACTIONS,
  IMPOSSIBLE,
  OPEN_LINK,
  RETWEET,
  SEA_OF_THIEVES_KILL_PLAYER_ID,
  SEA_OF_THIEVES_KILL_SKELETON_ID,
  SEA_OF_THIEVES_VISIT_ISLAND_ID,
  SHARE_FACEBOOK,
  VIEW_PANEL,
  VISIT_CHANNEL,
  VISIT_TIME,
  WATCH_ANY_VIDEO,
  WATCH_SPECIFIC_VIDEO,
} from 'components/objects/Quest/QuestAction/constants';

// quest property fields
export const DESCRIPTION = 'description';
export const CLAIMED_MESSAGE = 'claimedMessage';
export const CHANNEL_IDS = 'channelIds';
export const REWARD_DESCRIPTION = 'rewardDescription';
export const REWARD_IMAGE = 'rewardImage';
export const REWARD_TYPE = 'rewardType';
export const TIME_MAXIMUM = 'timeMaximum';
export const TIME_MINIMUM = 'timeMinimum';
export const TITLE = 'title';
export const WEBHOOK_ID = 'webhookId';
export const WEBHOOK_VALUE = 'webhookValue';
export const WEBHOOK_TYPE = 'webhookType';

// action editor components
export const COMPONENT_TYPES = {
  CHANNEL_SELECT: 'CHANNEL_SELECT',
  DATE_TIME_PICKER: 'DATE_TIME_PICKER',
  HELPER_TEXT: 'HELPER_TEXT',
  LABEL: 'LABEL',
  LINK_TEXT: 'LINK_TEXT',
  MULTI_CHOICE_SELECT: 'MULTI_CHOICE_SELECT',
  PANEL_SELECT: 'PANEL_SELECT',
  TABS: 'TABS',
  TEXT_INPUT: 'TEXT_INPUT',
  TRANSLATED_TEXT: 'TRANSLATED_TEXT',
  VIDEO_BUTTON: 'VIDEO_BUTTON',
};

export const DEFAULT_DATA = {
  [ANSWER_ANY_POLL]: {
    valueRequired: 1,
  },
  [ANSWER_SPECIFIC_POLL]: {
    multiple_choice: 'multiple_choice',
  },
  [ANSWER_ANY_PREDICTION]: {
    valueRequired: 1,
  },
  [ANSWER_SPECIFIC_PREDICTION]: {
    multiple_choice: 'multiple_choice',
  },
  [ANSWER_ANY_TRIVIA]: {
    valueRequired: 1,
  },
  [ANSWER_SPECIFIC_TRIVIA]: {
    multiple_choice: 'multiple_choice',
  },
  [ANSWER_SPECIFIC_TRIVIA_CORRECTLY]: {
    multiple_choice: 'multiple_choice',
  },
  [OPEN_LINK]: {
    valueRequired: 1,
  },
  [RETWEET]: {
    valueRequired: 1,
  },
  [SEA_OF_THIEVES_KILL_PLAYER_ID]: {
    repeat: 1,
  },
  [SEA_OF_THIEVES_KILL_SKELETON_ID]: {
    repeat: 1,
  },
  [SEA_OF_THIEVES_VISIT_ISLAND_ID]: {
    repeat: 1,
  },
  [VISIT_CHANNEL]: {
  },
  [VISIT_TIME]: {
    timeMaximum: Date.now(),
    timeMinimum: Date.now(),
  },
  [WATCH_ANY_VIDEO]: {
    valueRequired: 1,
  },
  [WATCH_SPECIFIC_VIDEO]: {
    valueRequired: 1,
  },
};

const ALL_ACTIONS = [
  {
    kind: IMPOSSIBLE,
    stringKey: 'ADMIN_ACTION_IMPOSSIBLE_ACTION',
  },
  {
    kind: VISIT_CHANNEL,
    stringKey: 'ADMIN_ACTION_VISIT_CHANNEL',
  },
  {
    kind: VIEW_PANEL,
    stringKey: 'ADMIN_ACTION_VIEW_PANEL',
  },
  {
    kind: WATCH_ANY_VIDEO,
    stringKey: 'ADMIN_ACTION_WATCH_ANY_VIDEO',
  },
  {
    kind: WATCH_SPECIFIC_VIDEO,
    stringKey: 'ADMIN_ACTION_WATCH_SPEFCIFIC_VIDEO',
  },
  {
    kind: RETWEET,
    stringKey: 'ADMIN_ACTION_RETWEET',
  },
  {
    kind: SHARE_FACEBOOK,
    stringKey: 'ADMIN_ACTION_FACEBOOK_SHARE',
  },
  {
    kind: VISIT_TIME,
    stringKey: 'ADMIN_ACTION_VISIT_TIME',
  },
  {
    kind: OPEN_LINK,
    stringKey: 'ADMIN_ACTION_OPEN_LINK',
  },
  {
    kind: ANSWER_ANY_POLL,
    stringKey: 'ADMIN_ACTION_ANSWER_ANY_POLL',
  },
  {
    kind: ANSWER_SPECIFIC_POLL,
    stringKey: 'ADMIN_ACTION_ANSWER_SPECIFIC_POLL',
  },
  {
    kind: ANSWER_ANY_TRIVIA,
    stringKey: 'ADMIN_ACTION_ANSWER_ANY_TRIVIA',
  },
  {
    kind: ANSWER_SPECIFIC_TRIVIA,
    stringKey: 'ADMIN_ACTION_ANSWER_SPECIFIC_TRIVIA',
  },
  {
    kind: ANSWER_SPECIFIC_TRIVIA_CORRECTLY,
    stringKey: 'ADMIN_ACTION_ANSWER_SPECIFIC_TRIVIA_CORRECTLY',
  },
  {
    kind: ANSWER_ANY_TRIVIA_CORRECTLY,
    stringKey: 'ADMIN_ACTION_ANSWER_ANY_TRIVIA_CORRECTLY',
  },
  {
    kind: ANSWER_ANY_PREDICTION,
    stringKey: 'ADMIN_ACTION_ANSWER_ANY_PREDICTION',
  },
  {
    kind: ANSWER_SPECIFIC_PREDICTION,
    stringKey: 'ADMIN_ACTION_ANSWER_SPECIFIC_PREDICTION',
  },
  {
    kind: ANSWER_SPECIFIC_PREDICTION_CORRECTLY,
    stringKey: 'ADMIN_ACTION_ANSWER_SPECIFIC_PREDICTION_CORRECTLY',
  },
  {
    kind: ANSWER_ANY_PREDICTION_CORRECTLY,
    stringKey: 'ADMIN_ACTION_ANSWER_ANY_PREDICTION_CORRECTLY',
  },
  {
    kind: SEA_OF_THIEVES_VISIT_ISLAND_ID,
    stringKey: 'ADMIN_LABEL_QUEST_SEA_OF_THIEVES_ISLAND_VISITS',
  },
  {
    kind: SEA_OF_THIEVES_KILL_PLAYER_ID,
    stringKey: 'ADMIN_LABEL_QUEST_SEA_OF_THIEVES_PLAYER_KILLS',
  },
  {
    kind: SEA_OF_THIEVES_KILL_SKELETON_ID,
    stringKey: 'ADMIN_LABEL_QUEST_SEA_OF_THIEVES_SKELETON_KILLS',
  },
  {
    kind: SEA_OF_THIEVES_VISIT_ISLAND_ID,
    stringKey: 'ADMIN_ACTION_SEA_OF_THIEVES_VISIT_ISLAND',
  },
  {
    kind: SEA_OF_THIEVES_KILL_PLAYER_ID,
    stringKey: 'ADMIN_ACTION_SEA_OF_THIEVES_KILL_PLAYER',
  },
  {
    kind: SEA_OF_THIEVES_KILL_SKELETON_ID,
    stringKey: 'ADMIN_ACTION_SEA_OF_THIEVES_KILL_SKELETON',
  },
];

export const ACTIONS = ALL_ACTIONS.filter(({ kind }) => !DISABLED_ACTIONS.includes(kind));

import styled from 'styled-components';
import { BorderButton } from 'components/admin2/ui/Button';
import { ADMIN_SURFACE_1, ADMIN_SURFACE_4 } from 'style/constants';
import { ADMIN_BACKGROUND_TITLE } from 'style/mixins';

export const PollEditorContainer = styled.div`min-width: 500px;`;

export const Controls = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 16px;
  ${ADMIN_BACKGROUND_TITLE};
`;

export const Spacer = styled.div`flex-grow: 1;`;

export const SelectContainer = styled.div`
  padding: 16px;
  background: ${ADMIN_SURFACE_4};
`;

export const ContentContainer = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 2px solid ${ADMIN_SURFACE_1};
`;

export const LabelToggleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 16px;
`;

export const PredictionContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const ResultsWrapper = styled.div`flex: 1 1 50%;`;

export const CorrectOptionContainer = styled.div`
  flex: 1 1 50%;
  margin-left: 10px;
`;

export const ActionButton = styled(BorderButton).attrs({
  fullWidth: true,
  spacing: '10px 0 0 0',
})``;

import styled, { css } from 'styled-components';
import { ACCENT_PRIMARY, TEXT_100 } from 'style/constants';
import { BACKGROUND_ACTIVE } from 'style/mixins';
import Button from 'components/admin2/ui/Button';
import EllipsisLoader from '../EllipsisLoader';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_L_BOLD } from 'style/design-system/textStyles';

export const DefaultButton = styled(Button as any)`
  ${BACKGROUND_ACTIVE}
  min-width: 70px;
` as typeof Button;

export const SubInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0 16px 0;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_LABEL_L_BOLD}
  color: ${ACCENT_PRIMARY};
`;

export const Key = styled.span`
  width: min-content;
  &:after {
    content: ':'
  }
  margin-right: 5px;
`;

export const Value = styled.span``;

export const Prop = styled.div<{active?: boolean}>`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${TEXT_100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  ${({ active, ...props }) => active && css`
    margin-top: 3px;
    & ${Value} {
      color: ${ACCENT_PRIMARY(props)};
    }
  `}
`;

export const CancelsDateTextContainer = styled.div`
  :before {
    ${ADMIN_TEXT_BODY_S_MEDIUM}
    content: ' • ';
    white-space: pre;
    color: ${TEXT_100};
  }
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ ...props }) => css`
    & ${Value} {
      color: ${ACCENT_PRIMARY(props)};
    }
  `}
`;

export const Loading = styled(EllipsisLoader).attrs((props) => ({
  color: ACCENT_PRIMARY(props),
}))`
  width: 100%;
  justify-content: center;
`;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CollapsibleList from 'components/admin2/CollapsibleList';
import RendererEditor from 'components/admin2/RendererEditor';
import Divider from 'components/admin2/ui/Divider';
import TranslatedText from 'components/i18n/TranslatedText';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import produce from 'immer';
import shortid from 'shortid';
import withLabel from 'components/core/withLabel';
import hash from 'json-stable-stringify';
import { LargeButton } from 'components/admin2/ui/Button';

import { inputHelpers } from './utils';

function List({
  config: {
    typeOptions: {
      deleteButtonKey,
      deleteButtonText,
      draggable,
      onDragEnd,
      collapsible = false,
      addItemKey,
      emptyListKey,
      itemConfig,
      itemIcon,
      itemSubtitle,
      itemSubtitleKey,
      itemTitleField,
      emptyTitleKey,
      noPadding,
    },
    labelKey,
  },
  onChange,
  value: listValue,
}) {
  const [list, setList] = useState(listValue);
  const useInnerDeleteButton = useMemo(() => !!(
    deleteButtonKey || deleteButtonText
  ), [deleteButtonKey, deleteButtonText]);
  const handleAdd = () => {
    const newList = [...list, { arrayId: shortid.generate() }];
    setList(newList);
  };

  const handleDelete = (index) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  };

  const handleItemChange = (value, index, label) => {
    if (inputHelpers[label]) {
      // call registered util input formatting helper
      value.source = inputHelpers[label](value.source);
    }
    const newList = produce(list, (draft) => {
      draft[index] = value;
    });
    setList(newList);
  };

  const headerTextFn = item => (
    item[itemTitleField] || <TranslatedText stringKey={emptyTitleKey} />
  );

  useEffect(() => {
    onChange(list);
  }, [hash(list)]);

  if (collapsible) {
    return (
      <CollapsibleList
        addItemKey={addItemKey}
        draggable={draggable}
        emptyListKey={emptyListKey}
        headerText={headerTextFn}
        iconName={itemIcon}
        keyField={item => item.arrayId}
        list={list}
        nameKey={itemSubtitleKey}
        nameText={itemSubtitle}
        noPadding={noPadding}
        onAddItem={handleAdd}
        onDelete={handleDelete}
        onDragEnd={onDragEnd}
        useInnerDeleteButton={useInnerDeleteButton}
      >
        {
          (item, index, onDelete) => (
            <RendererEditor
              key={index}
              onChange={value => handleItemChange(value, index, labelKey)}
              properties={itemConfig}
              renderer={item}
              style={{ padding: '0 15px 15px 15px' }}
            >
              {useInnerDeleteButton && (
                <>
                  <Divider />
                  <OptionalTranslated
                    component={LargeButton}
                    fullwidth
                    onClick={e => onDelete(e, index)}
                    stringKey={deleteButtonKey}
                  >
                    {deleteButtonText}
                  </OptionalTranslated>
                </>
              )}
            </RendererEditor>
          )
        }
      </CollapsibleList>
    );
  }
  return 'noncollapsible list here';
}

List.propTypes = {
  config: PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
    labelKey: PropTypes.string,
    typeOptions: PropTypes.shape({
      addItemKey: PropTypes.string,
      collapsible: PropTypes.bool,
      deleteButtonKey: PropTypes.string,
      deleteButtonText: PropTypes.string,
      draggable: PropTypes.bool,
      emptyListKey: PropTypes.string,
      emptyTitleKey: PropTypes.string,
      itemConfig: PropTypes.array,
      itemIcon: PropTypes.oneOf([PropTypes.func, PropTypes.string]),
      itemSubtitle: PropTypes.oneOf([PropTypes.func, PropTypes.string]),
      itemSubtitleKey: PropTypes.string,
      itemTitleField: PropTypes.string,
      noPadding: PropTypes.bool,
      onDragEnd: PropTypes.func,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
};

List.defaultProps = {
  value: [],
};

export default withLabel(List);

import React from 'react';
import JsonDebug from 'components/dev/JsonDebug';
import { QUESTS_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';
import { IQuestsBlockData } from 'components/page-blocks/Quests/utils';
import BlockSettings from 'components/page-blocks/Quests/BlockSettings';

interface IQuestsBlockPanelRenderer {
  blockData: IQuestsBlockData;
  iconName: string;
  id: string;
  panelType: typeof QUESTS_BLOCK_PANEL_ID;
}

interface IQuestsBlockPanelEditorProps {
  onChange: (obj: IQuestsBlockPanelRenderer) => void;
  renderer: IQuestsBlockPanelRenderer;
}

const QuestsBlockPanelEditor = ({ renderer, onChange }: IQuestsBlockPanelEditorProps) => {
  const handleChangeBlockData = React.useCallback((blockData: IQuestsBlockData) => {
    onChange({
      ...renderer,
      blockData,
    });
  }, [onChange, renderer]);

  return (
    <>
      <BlockSettings region="panel" item={renderer.blockData} onChange={handleChangeBlockData} />
      <JsonDebug value={renderer} />
    </>
  );
};

export default QuestsBlockPanelEditor;

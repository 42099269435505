import { connect } from 'react-redux';
import { getPrimaryToken } from 'services/auth';
import { getRefreshKey, writeToCollectionLegacy } from 'services/admin';
import PlaylistVideoList from './view';

const mapStateToProps = state => ({
  primaryToken: getPrimaryToken(state),
  refreshKey: getRefreshKey(state),
});

const mapDispatchToProps = dispatch => ({
  writeToCollection: video => dispatch(writeToCollectionLegacy(video)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistVideoList);

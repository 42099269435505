import React from 'react';
import {
  CheckMark,
  StyledLoader,
  RefreshIcon,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';

export enum ExportStatusKeys {
  CREATE = 'CREATE',
  CREATING = 'CREATING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

const ExportStatus = ({ status }: { status: ExportStatusKeys }) => {
  switch (status) {
    case ExportStatusKeys.CREATING:
      return (
        <>
          <TranslatedText
            stringKey="ADMIN_LABEL_ACCESS_CODES_GENERATE_CREATING"
          />
          <StyledLoader dotSize={4} loading={true} />
        </>
      );
    case ExportStatusKeys.SUCCESS:
      return (
        <>
          <CheckMark />
          <TranslatedText
            stringKey="ADMIN_LABEL_ACCESS_CODES_GENERATE_SUCCESS"
          />
        </>
      );
    case ExportStatusKeys.FAILURE:
      return (
        <>
          <RefreshIcon />
          <TranslatedText
            stringKey="ADMIN_LABEL_ACCESS_CODES_GENERATE_FAILURE"
          />
        </>
      );
    default:
      return <TranslatedText stringKey="ADMIN_LABEL_ACCESS_CODES_GENERATE" />;
  }
};

export default ExportStatus;

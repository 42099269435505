import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Divider from 'components/admin2/ui/Divider';
import JsonDebug from 'components/dev/JsonDebug';
import Dropdown from 'components/admin2/ui/Dropdown';
import ButtonSelect from 'components/admin2/ui/ButtonSelect';
import { ADMIN_PANEL_EDITOR_BODY } from 'style/mixins';
import Field from './Field';
import TweetUrlField from './TweetUrlField';

import { get, withChange } from './utils';

const Container = styled.div`
  ${ADMIN_PANEL_EDITOR_BODY}
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const VariantContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 100%;
`;

const VariantSelect = styled(Dropdown)`
 width: 100%;
`;

const VariantButtonSelect = styled(ButtonSelect)`
 width: 100%;
`;

const StyledDivider = styled(Divider)`
  flex: 0 0 100%;
`;

let SubEditor = null; // Forward declare

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string.isRequired,
      labelKey: PropTypes.string,
      type: PropTypes.oneOf([
        'renderer', // static renderer
        'string', // single line string
        'firebaseKey', // escaped string input
        'number', // integer values only
        'switch', // polymorphic renderer
        'text', // multi line string
        'image', // pics or it didnt happen
        'array', // collapsible or uncollapsible lists
        'icon', // render IconPicker
        'toggle', // render EditModeRegionToggle
        'divider', // render divider
        'html', // raw HTML input
        'groupId', // select group from dropdown
        'pageSlug', // select page slug from dropdown
        'panelId', // select panel from dropdown
        'personId', // select person from dropdown
        'videoId', // select video from dropdown
        'pollResult', // select poll broadcast from dropdown
        'pollId', // select poll from dropdown
        'pollIds', // array of above (pollId, arrayId)
        'datetime', // datetime picker
        'questIds', // array of quests
        'quickPickIds', // quick pick thing for owl
        'multipleChoice',
        'tweetUrl', // validated tweet url
        'pageToggle', // list of pages with switch
        'libraryButton', // select value from a library modal
        'playlistId', // select playlist from dropdown
        'personOrGroupIds', // select people/groups as references for events
        'boolean',
        'radio', // select playlist layout and icon type
      ]).isRequired,
      typeOptions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
  ).isRequired,
  renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
  className: '',
};

const renderConfig = (config, renderer, onChange) => {
  if (config.type === 'renderer') {
    const {
      fieldName,
      typeOptions,
    } = config;

    return (
      <SubEditor
        onChange={sub => onChange(withChange(renderer, fieldName, sub))}
        properties={typeOptions.properties}
        renderer={get(renderer, fieldName)}
      />
    );
  }

  if (config.type === 'switch') {
    const {
      fieldName,
      labelKey,
      typeOptions,
    } = config;

    const {
      defaultId,
      optional,
      variantField,
      variants,
      style = 'dropdown',
      testId,
    } = typeOptions;

    const subEditorRenderer = get(renderer, fieldName);
    const variantId = get(subEditorRenderer, variantField);
    const selectedVariant = (
      variantId && variants.find(variant => variant.id === variantId)
    ) || (
      defaultId && variants.find(variant => variant.id === defaultId)
    );

    const variantProperties = selectedVariant && selectedVariant.properties;
    const variantValue = selectedVariant && {
      label: selectedVariant.labelKey,
      value: selectedVariant.id,
    };

    // force select on default variant to initialize sub editor renderer
    if (!subEditorRenderer && variantValue?.value) {
      const newSub = { [variantField]: variantValue.value };
      onChange(withChange(renderer, fieldName, newSub));
    }

    const getDropDownOptions = memoizeOne(options => options.map( // TODO: This probly doesn't work
      option => ({ icon: option.icon, label: option.labelKey, value: option.id }),
    ));

    const variantDropDownOptions = getDropDownOptions(variants);
    let defaultNone;
    if (optional) {
      defaultNone = { label: 'ADMIN_LABEL_NONE', value: null };
      variantDropDownOptions.unshift(defaultNone);
    }

    const renderSelector = () => {
      const dataTestId = `${testId}-${variantValue?.value || defaultNone?.value}`;

      switch (style) {
        case 'dropdown': {
          return (
            <VariantSelect
              data-testid={dataTestId}
              defaultLangText
              labelKey={labelKey}
              onChange={({ value }) => {
                onChange(withChange(renderer, `${fieldName}.${variantField}`, value));
              }}
              options={variantDropDownOptions}
              translated
              value={variantValue || defaultNone}
            />
          );
        }
        case 'buttons': {
          return (
            <VariantButtonSelect
              data-testid={dataTestId}
              labelKey={labelKey}
              onChange={value => {
                onChange(withChange(renderer, `${fieldName}.${variantField}`, value));
              }}
              options={variantDropDownOptions}
              translated
              value={variantValue?.value || defaultNone}
            >
              {variantProperties && (
                <SubEditor
                  onChange={newSub => onChange(withChange(renderer, fieldName, newSub))}
                  properties={variantProperties}
                  renderer={subEditorRenderer}
                />
              )}
            </VariantButtonSelect>
          );
        }
        default: {
          return null;
        }
      }
    };

    return (
      <VariantContainer>
        {renderSelector()}
        {
          variantProperties && style !== 'buttons' && (
            <SubEditor
              onChange={newSub => onChange(withChange(renderer, fieldName, newSub))}
              properties={variantProperties}
              renderer={subEditorRenderer}
            />
          )
        }
      </VariantContainer>
    );
  }

  if (config.type === 'divider') {
    return <StyledDivider {...config.typeOptions} />;
  }

  if (config.type === 'tweetUrl') {
    return (
      <TweetUrlField
        config={config}
        onChange={onChange}
        renderer={renderer}
      />
    );
  }

  return (
    <Field
      config={config}
      onChange={onChange}
      renderer={renderer}
    />
  );
};

const RendererEditor = (props) => {
  const {
    children,
    className,
    onChange,
    properties,
    renderer,
    style,
  } = props;

  return (
    <Container className={className} style={style}>
      {properties.map(config => renderConfig(config, renderer, onChange))}
      {children}
      <JsonDebug value={renderer} />
    </Container>
  );
};

RendererEditor.propTypes = propTypes;
RendererEditor.defaultProps = defaultProps;

SubEditor = styled(RendererEditor)`
  width: 100%;
  padding: 1em 0;
`;

export default RendererEditor;

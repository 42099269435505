import styled from 'styled-components';
import Switch from 'components/ui/Switch';

const AdminSwitch = styled(Switch).attrs((props) => {
  return {
    ...props,
    admin: true,
  };
})``;

export default AdminSwitch;

import styled from 'styled-components';
import withPadding from 'components/core/withPadding';
import Checkbox from '../Checkbox';

export const Radio = styled(Checkbox).attrs({
  compact: true,
  type: 'radio',
})``;

export default withPadding(Radio, '5px 0');

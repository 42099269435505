import React from 'react';
import { DROPDOWN_LABEL } from 'css-classes';
import { OptionViewProps } from '../../interfaces';
import { CheckIcon, CtaBehaviorOption, StyledIcon, Text } from './styles';
import { CTABehavior } from 'components/admin-bridge/CTAEditorModal/utils';

type Option = {
  iconName: string;
  label: string;
  value: CTABehavior;
};

function CtaBehaviorOptionView<T>({ isAdmin, ...props }: OptionViewProps<T>) {
  const item = props.data as Option;

  if (!item) {
    return null;
  }

  return (
    <CtaBehaviorOption
      isAdmin={true}
      className={DROPDOWN_LABEL}
      {...props}
    >
      <StyledIcon name={item.iconName} />
      <Text>{item.label}</Text>
      {props.isActive && <CheckIcon />}
    </CtaBehaviorOption>
  );
}

export default CtaBehaviorOptionView;

import { connect } from 'react-redux';
import { getSiteId } from 'services/app/selectors';
import { getPrimaryToken } from 'services/auth';
import { getRefreshKey } from 'services/admin';
import LibraryModal from './LibraryModal';

const mapStateToProps = state => ({
  primaryToken: getPrimaryToken(state),
  refreshKey: getRefreshKey(state),
  siteId: getSiteId(state),
});

export default connect(mapStateToProps)(LibraryModal);

import React, { useRef } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import ImageLibraryModal from 'components/admin2/ImageLibraryModal';
import { NO_IMAGE } from 'services/admin/constants';
import Label from 'components/admin2/ui/Label';
import withPadding from 'components/core/withPadding';
import { RemoveButton } from 'components/admin2/ui/Button';
import {
  ActionContainer,
  BigTitle,
  Container,
  ErrorText,
  ImageContainer,
  ProgressIndicator,
  SmallTitle,
  TextContainer,
  StyledDropzone as Dropzone,
  ButtonContainer,
  UploadButton,
  UploadZoneWrapper,
  DragMessage,
  ErrorMsg,
  ErrorIcon,
} from './styles';
import getState from './store';
import OptionalTranslated from '../ui/OptionalTranslated';
import type TDropzone from 'react-dropzone';
import type { DropFilesEventHandler } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import { UploadZoneProps } from './types';
import { useAdminTranslation } from 'hooks/use-translation';

export type { UploadZoneProps };
export { uploadMedia } from './store';

export const IMAGE_ACCEPTED_TYPES = 'image/jpeg, image/png, image/tiff, image/bmp, image/gif, image/jpg';

/*
FIXME:
DRAG AND DROP WON'T WORK WHEN OVERLAPPING THE VIDEO IFRAME.
CURRENT ISSUE ON react-dropzone:
https://github.com/react-dropzone/react-dropzone/issues/720

Currently upload isn't disabled in functionality
canDrop just changes styles and text ... can't disable drop without
disabling entire component ... don't want to do that because
then we loose the validation they are doing.
*/
function UploadZone(props: UploadZoneProps) {
  const { t } = useAdminTranslation();
  const {
    acceptedTypes = IMAGE_ACCEPTED_TYPES,
    canDrop = true,
    className,
    description,
    descriptionKey,
    dimensionMessage = '',
    dimensionRequirements,
    disabled = false,
    hasDragAndDropLabel = true,
    height: dropzoneHeight,
    imagePreview = null,
    isRequired = false,
    label,
    labelIcon,
    labelIconSize = 15,
    labelKey,
    maxFiles = 1,
    maxSize = 5242880, // 5 MB in bytes
    nodeEmptyPlaceholder,
    onClearImage,
    onDrop,
    onProgress,
    testIdAddImage = 'addImage',
    testIdRemove,
    testIdSearchLibrary = 'searchLibrary',
    testIdThumbnail,
    title,
    titleKey,
    withWrapper = true,
  } = props;
  const primaryToken = useSelector(getPrimaryToken)!;
  const siteId = useSelector(getSiteId);
  const dropzoneRef = useRef<TDropzone>(null);
  const [state, actions] = getState(
    t,
    {
      ...props,
      primaryToken,
      siteId,
    },
  );

  const {
    reject,
    removeImage,
    toggleActive,
    toggleLibrary,
    upload,
    handleSelectFromLibrary,
  } = actions;
  const { errorKey, errorMsg, isLoading, libraryActive } = state;
  const isMultipleAllowed = maxFiles > 1;

  const showImage = imagePreview && imagePreview !== NO_IMAGE && !errorKey && !errorMsg;
  const {
    exactDimensions,
    exactWidth,
    exactHeight,
    minWidth,
    minHeight,
    pixelWidth,
    pixelHeight,
  } = dimensionRequirements || { exactDimensions: false, verify: false };

  let dimensionString = '';
  let innerDimensionString = dimensionMessage;

  if (!innerDimensionString && dimensionRequirements) {
    const width = Math.floor(pixelWidth || 0);
    const height = Math.floor(pixelHeight || 0);

    dimensionString = exactDimensions
      ? t('ADMIN_UPLOAD_ZONE_UPLOAD_AN_IMAGE_OF_EXACT_DIMENSIONS', { width, height })
      : t('ADMIN_UPLOAD_ZONE_UPLOAD_AN_IMAGE_OF_MAX_DIMENSIONS', { width, height });

    innerDimensionString = exactDimensions
      ? t('ADMIN_UPLOAD_ZONE_UPLOAD_IMAGE_SIZE', { width, height })
      : t('ADMIN_UPLOAD_ZONE_UPLOAD_IMAGE_MAX_SIZE', { width, height });

    if (exactWidth || exactHeight || minWidth || minHeight) {
      innerDimensionString = t('ADMIN_UPLOAD_ZONE_UPLOAD_IMAGE_SIZE', { width, height });
    }
  }

  const openFileDialog = () => {
    dropzoneRef.current?.open?.();
  };

  const renderInnerMessage = () => (
    <TextContainer>
      {(title || titleKey) &&
        <OptionalTranslated component={BigTitle} stringKey={titleKey}> {title}</OptionalTranslated>}
      {!labelKey && innerDimensionString && <SmallTitle>{innerDimensionString}</SmallTitle>}
      {hasDragAndDropLabel && (
      <SmallTitle>
        <TranslatedText
          stringKey={canDrop ?
          'ADMIN_LABEL_DRAG_AND_DROP_TO_UPLOAD' :
          'ADMIN_LABEL_DRAG_AND_DROP_CLICK_TO_UPLOAD'}
        />
      </SmallTitle>
      )}
    </TextContainer>
  );

  const renderContent = () => {
    if (isLoading) {
      return <ProgressIndicator />;
    }

    if (showImage) {
      return <ImageContainer src={imagePreview} />;
    }

    if (nodeEmptyPlaceholder) {
      return nodeEmptyPlaceholder;
    }

    return (
      <React.Fragment>
        {renderInnerMessage()}
      </React.Fragment>
    );
  };

  const renderActionContainer = () => (
    <ActionContainer>
      {showImage && onClearImage && (
        <RemoveButton data-testid={testIdRemove} onClick={removeImage} useAbsolutePosition={true} />
      )}
    </ActionContainer>
  );

  const Wrapper = withWrapper ? UploadZoneWrapper : React.Fragment;

  const handleDragEnter = () => toggleActive(true);
  const handleDragLeave = () => toggleActive(false);

  const handleDrop: DropFilesEventHandler = (accepted, rejected) => {
    if (accepted[0]) {
      upload(accepted);
    }
    if (rejected[0]) {
      reject(rejected);
    }
    if (onDrop) {
      const files = accepted || rejected;
      const status = accepted.length > 0 ? 'accepted' : 'rejected';
      onDrop(files, status);
    }
  };

  return (
    <Wrapper>
      {(label || labelKey) && (
        <Label
          description={description ? `${description} ${dimensionString}` : dimensionString}
          descriptionKey={descriptionKey}
          icon={labelIcon}
          iconSize={labelIconSize}
          label={label}
          labelKey={labelKey}
        />
      )}
      <Dropzone
        ref={dropzoneRef}
        accept={acceptedTypes}
        className={className}
        disabled={disabled}
        height={dropzoneHeight}
        maxSize={maxSize}
        multiple={isMultipleAllowed}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onProgress={onProgress}
      >
        <Container data-testid={testIdThumbnail}>
          {isRequired && !imagePreview && <TranslatedText component={ErrorMsg} stringKey="ADMIN_LABEL_REQUIRED" />}
          {
            renderContent()
          }
          {
            showImage && renderActionContainer()
          }
        </Container>
      </Dropzone>
      <ButtonContainer>
        <UploadButton
          data-testid={testIdAddImage}
          disabled={disabled}
          icon="addImage"
          labelKey="ADMIN_LABEL_UPLOAD_AN_IMAGE"
          onClick={openFileDialog}
          type="button"
        />
        <UploadButton
          data-testid={testIdSearchLibrary}
          disabled={disabled}
          icon="searchLibrary"
          labelKey="ADMIN_LABEL_LIBRARY"
          onClick={toggleLibrary}
          type="button"
        />
        <DragMessage>
          {renderInnerMessage()}
        </DragMessage>
      </ButtonContainer>
      {errorKey && (
        <td>
          <ErrorIcon />
          <OptionalTranslated component={ErrorText} stringKey={errorKey}>
            {errorMsg}
          </OptionalTranslated>
        </td>
      )}
      {libraryActive && (
        <ImageLibraryModal
          onClose={toggleLibrary}
          onSelect={handleSelectFromLibrary}
        />
      )}
    </Wrapper>
  );
}

export default withPadding(UploadZone);

export const UploadZoneWithoutPadding = UploadZone;

import styled from 'styled-components';
import Flag from 'react-world-flags';
import { ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  padding: 0px 0px 0px 12px;
  cursor: pointer;
`;

export const CurrencyString = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  margin-left: 10px;
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_100};
  text-transform: uppercase;
`;

export const StyledFlag = styled(Flag)`
  width: 35px;
  height: 25px;
`;

import styled, { css } from 'styled-components';
import React, { useEffect } from 'react';
import {
  SPACING_MEDIUM,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
} from 'style/constants';
import TranslatedText from 'components/i18n/TranslatedText';
import PropTypes from 'prop-types';
import EditModeRegionToggle from 'components/admin2/EditModeRegionToggle';
import KeyedListMapper from 'components/core/KeyedListMapper';
import Button from 'components/admin2/ui/Button';
import FixedModal from 'components/ui/FixedModal';
import { ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

const EditActionsWrapper = styled.div`
  position: absolute;
  align-items: center;
  background-color: ${ADMIN_SURFACE_4};
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  z-index: 100;
`;

const StyledToggle = styled(EditModeRegionToggle)`
  padding: 0 ${SPACING_MEDIUM};
`;

const ACTION_ITEM = css`
  height: 72px;
  padding: 0 ${SPACING_MEDIUM};
  align-items: center;
  justify-content: center;
  display: flex;
  width: max-content;
`;

const Title = styled.div`
  ${ACTION_ITEM}
  ${ADMIN_TEXT_TITLE_S}
  user-select: none;
  background-color: ${ADMIN_SURFACE_3};
`;

const StyledButton = styled(Button)`
  margin: 0 20px;
`;

const ToggleContainer = styled.div`
  user-select: none;
  padding: 0 ${SPACING_MEDIUM};
`;

export default function ActionModal({
  addKey,
  buttons,
  className,
  labelKey,
  hasChanges,
  offsetBottom,
  offsetLeft,
  offsetRight,
  offsetTop,
  onClose,
  onEdit,
  onSave,
  onToggle,
  testIdAdd,
  testIdToggle,
  toggleActive,
  toggleKey,
}) {
  useEffect(() => {
    if (hasChanges) {
      onSave();
    }
  }, [hasChanges]);
  return (
    <FixedModal
      className={className}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
      offsetRight={offsetRight}
      offsetTop={offsetTop}
      onClick={onClose}
    >
      <EditActionsWrapper>
        <TranslatedText component={Title} stringKey={labelKey} />
        { onToggle && (
          <ToggleContainer>
            <EditModeRegionToggle
              active={toggleActive}
              data-testid={testIdToggle}
              labelKey={toggleKey}
              onChange={onToggle}
            />
          </ToggleContainer>
        ) }
        { buttons.length > 0 && (
          <KeyedListMapper keyField="stringKey" list={buttons}>
            {
              (key, { stringKey, action, testId, type, value }) => type === 'toggle' ? (
                <StyledToggle
                  key={key}
                  active={value}
                  data-testid={testId}
                  labelKey={stringKey}
                  onChange={action}
                />
              ) : (
                <StyledButton key={key} data-testid={testId} onClick={action}>
                  <TranslatedText stringKey={stringKey} />
                </StyledButton>
              )
            }
          </KeyedListMapper>
        ) }
        { onEdit && (
          <StyledButton data-testid={testIdAdd} onClick={onEdit}>
            <TranslatedText stringKey={addKey} />
          </StyledButton>
        ) }
      </EditActionsWrapper>
    </FixedModal>
  );
}

ActionModal.propTypes = {
  addKey: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.func,
    stringKey: PropTypes.string,
  })),
  className: PropTypes.string,
  hasChanges: PropTypes.bool.isRequired,
  labelKey: PropTypes.string.isRequired,
  offsetBottom: PropTypes.number,
  offsetLeft: PropTypes.number,
  offsetRight: PropTypes.number,
  offsetTop: PropTypes.number,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  testIdAdd: PropTypes.string,
  testIdToggle: PropTypes.string,
  toggleActive: PropTypes.bool,
  toggleKey: PropTypes.string,
};

ActionModal.defaultProps = {
  addKey: 'ADMIN_ACTION_ADD',
  buttons: [],
  className: undefined,
  offsetBottom: 0,
  offsetLeft: 0,
  offsetRight: 0,
  offsetTop: 0,
  onEdit: undefined,
  onToggle: undefined,
  testIdAdd: undefined,
  testIdToggle: undefined,
  toggleActive: true,
  toggleKey: 'ADMIN_ACTION_HIDE',
};

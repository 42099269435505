import React from 'react';
import type { WithExtraProps } from 'utils';
import {
  Ellipsis,
  Dot,
} from './styles';

const EllipsisLoader = (({
  loading,
  ...props
}) => {
  return loading ? (
    <Ellipsis {...props}>
      <Dot color={props.color} />
      <Dot color={props.color} />
      <Dot color={props.color} />
    </Ellipsis>
  ) : null;
}) as WithExtraProps<typeof Ellipsis, { loading?: boolean }>;

export default EllipsisLoader;

import React from 'react';
import Label from 'components/admin2/ui/Label';
import TranslatedText from 'components/i18n/TranslatedText';
import ISubscription from 'models/ISubscription';
import { IItem } from '..';
import BundleItem from './BundleItem';
import { BundleItemsWrapper, WideButton } from './styles';
import SubscriptionsLibraryModal from 'components/admin2/SubscriptionsLibraryModal';
import ShopifyProductListModal from 'components/admin2/ShopifyProductListModal';
import { IProduct } from 'services/shopify/models';
import { useSelector } from 'react-redux';
import { getShopCurrencyCode } from 'services/shopify/selectors';

interface IBundleItemsProps {
  onAdd: (item: IItem) => void;
  onAddProducts: (items: IItem[]) => void;
  onRemove: (id: string) => () => void;
  products: IItem[];
  tickets: IItem[];
}

const BundleItems: React.FC<IBundleItemsProps> = ({ tickets, products, onAdd, onAddProducts, onRemove }) => {
  const shopifyCurrencyCode = useSelector(getShopCurrencyCode);
  const [selectedShopifyProducts, setSelectedShopifyProducts] = React.useState<IProduct[]>([]);

  const [isAddingItem, setIsAddingItem] = React.useState({
    product: false,
    ticket: false,
  });

  const handleRemoveProduct = (id: string) => () => {
    setSelectedShopifyProducts(prev => prev.filter(product => product.id !== id));
    onRemove(id)();
  };

  const renderTickets = React.useCallback(() => {
    return tickets.map(item => (<BundleItem key={item.id} item={item} onRemove={onRemove(item.id)} />));
  }, [tickets]);

  const renderProducts = React.useCallback(() => {
    return products.map(item => (<BundleItem key={item.id} item={item} onRemove={handleRemoveProduct(item.id)} />));
  }, [products]);

  const handleOpenTicketsModal = React.useCallback(() => {
    setIsAddingItem(prev => ({ ...prev, ticket: true }));
  }, []);

  const handleCloseTicketsModal = React.useCallback(() => {
    setIsAddingItem(prev => ({ ...prev, ticket: false }));
  }, []);

  const handleCloseProductsModal = React.useCallback(() => {
    setIsAddingItem(prev => ({ ...prev, product: false }));
  }, []);

  const handleOpenProductsModal = React.useCallback(() => {
    setIsAddingItem(prev => ({ ...prev, product: true }));
  }, []);

  const handleAddTicket = React.useCallback((item: ISubscription) => {
    const shopifyCurrencyPrice = item.prices.find(price => price.currency.toLowerCase() === shopifyCurrencyCode.toLowerCase());
    const firstPrice = item.prices[0];
    const defaultPrice = item.prices.find(price => price.default);

    onAdd({
      id: item._id!,
      name: item.name,
      price: shopifyCurrencyPrice?.value || defaultPrice?.value || firstPrice.value,
      currency: shopifyCurrencyPrice?.currency || defaultPrice?.currency || firstPrice.currency,
      type: 'ticket',
      sku: item.sku,
    });
    setIsAddingItem({ product: false, ticket: false });
  }, [onAdd, setIsAddingItem]);

  const parseProduct = (item: IProduct): IItem => {
    const variants = item.variants?.edges || [];
    const cheapestVariant = variants.reduce((prev, curr) => {
      if (Number(prev.node.price.amount) < Number(curr.node.price.amount)) {
        return prev;
      }
      return curr;
    }, item.variants.edges[0]);

    return {
      id: item.id,
      name: item.title,
      price: Number(cheapestVariant.node.price.amount),
      currency: shopifyCurrencyCode,
      type: 'shopify',
    };
  };

  const handleAddProducts = (newProducts: IProduct[]) => {
    const items: IItem[] = newProducts.map(parseProduct);
    onAddProducts(items);
    setSelectedShopifyProducts(newProducts);
    setIsAddingItem({ product: false, ticket: false });
  };

  const handleFilterTickets = React.useCallback((subscription: ISubscription[]) => {
    return subscription.filter(sub => sub.type === 'ticket' && tickets.every((item: IItem) => item.id !== sub._id));
  }, [tickets]);

  return (
    <>
      <Label labelKey="INCLUDED_TICKETS" />
      <BundleItemsWrapper>
        {renderTickets()}
        <WideButton onClick={handleOpenTicketsModal} data-testid="newBundleOpenTicketModalCTA">
          <TranslatedText stringKey="ADD_TICKET" />
        </WideButton>
      </BundleItemsWrapper>
      <Label labelKey="INCLUDED_PRODUCTS" />
      <BundleItemsWrapper>
        {renderProducts()}
        <WideButton onClick={handleOpenProductsModal} data-testid="newBundleOpenProductsModalCTA">
          <TranslatedText stringKey="ADD_PRODUCT" />
        </WideButton>
      </BundleItemsWrapper>
      {isAddingItem.ticket && (
        <SubscriptionsLibraryModal
          formatData={handleFilterTickets}
          disableSelecting={false}
          onClose={handleCloseTicketsModal}
          onSelectItem={handleAddTicket}
          hideCreateBundle={true}
          hideCreateSubscription={true}
        />
      )}
      {isAddingItem.product && (
        <ShopifyProductListModal
          onClose={handleCloseProductsModal}
          isMultipleSelection={true}
          onSelectMultipleProducts={handleAddProducts}
          selectedProducts={selectedShopifyProducts}
        />
      )}
    </>
  );
};

export default BundleItems;

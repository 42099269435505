import styled from 'styled-components';
import { ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';
import { ADMIN_TEXT_100 } from 'style/constants';

const Label = styled.div`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  color: ${ADMIN_TEXT_100};
  margin-bottom: 5px;
  text-transform: none;
`;

export default Label;

import Icon from 'components/ui/Icon';
import { ADMIN_ACCENT_PRIMARY } from 'style/constants';
import styled from 'styled-components';

export const HelpSection = styled.div`
  padding: 10px 0;
  display: flex;
  a {
    text-decoration: underline !important;
    color: ${ADMIN_ACCENT_PRIMARY} !important;
  }
`;

export  const HelpIcon = styled(Icon).attrs(props => ({
  name: 'helpIcon',
  color: ADMIN_ACCENT_PRIMARY(props),
}))`
  margin-right: 5px;
  & svg {
    width: 15px;
    height: 15px;
  }
`;

import styled from 'styled-components';
import ActionButton from 'components/admin2/ActionButton';
import { ADMIN_SURFACE_1, ADMIN_TEXT_100 } from 'style/constants';

export const BundleItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const WideButton = styled(ActionButton)`
  width: 100%;
  justify-content: center;
  padding: 12px 15px;
  background-color: transparent;
  font-size: 14px !important;
  line-height: 14px !important;
  color: ${ADMIN_TEXT_100} !important;
  border: 1.5px solid ${ADMIN_TEXT_100};
  border-radius: 5px;
  margin: 10px 0;

  &:hover {
    background-color: ${ADMIN_SURFACE_1};
    border-color: ${ADMIN_SURFACE_1};
  }
`;


import PropTypes from 'prop-types';
import React from 'react';

import LegacyCollectionProvider from 'components/admin2/LegacyCollectionProvider';
import withLabel from 'components/core/withLabel';
import View from './ObjectSelectView';

const propTypes = {
  collection: PropTypes.string.isRequired,
  onlyShowRendererizedObjects: PropTypes.bool,
};

const defaultProps = {
  onlyShowRendererizedObjects: false,
};

function ObjectSelect({
  collection,
  onlyShowRendererizedObjects,
  ...props
}) {
  return (
    <LegacyCollectionProvider
      collection={collection}
      limit={1000}
    >
      {(results, count, error, loaded) => {
        if (!loaded) {
          return null; // TODO: Spinner
        }

        let filteredResults = results;
        if (onlyShowRendererizedObjects) {
          filteredResults = results.filter(result => Boolean(result.renderer));
        }
        return (
          <View docs={filteredResults} {...props} />
        );
      }}
    </LegacyCollectionProvider>
  );
}

ObjectSelect.propTypes = propTypes;
ObjectSelect.defaultProps = defaultProps;

export default withLabel(ObjectSelect);

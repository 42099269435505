import React from 'react';
import { useSelector } from 'react-redux';
import { isOverlayEnabled, isPanelEnabled } from 'services/feature-gate/selectors';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import IState from 'services/state';
import CatalogItem, { ClickHandlers, Kind } from './Item';
import { CatalogContainer } from './styles';

export type { Kind } from './Item';

interface KindCatalogProps extends ClickHandlers {
  kinds: Kind[];
  testIdAdd?: string;
  testIdBroadcast?: string;
  testIdDelete?: string;
  testIdEdit?: string;
  testIdItem?: string;
  testIdPreview?: string;
  testIdRemove?: string;
  type: string;
  typeNameTestId?: string;
}

interface ICatalogItemWrapperProps extends Omit<KindCatalogProps, 'kinds'> {
  index: number;
  key: string;
  props: Kind;
}

const CatalogItemWrapper = ({
  key,
  type,
  props,
  index,
  onConfirmClick,
  onPreviewClick,
  testIdAdd,
  testIdBroadcast,
  testIdDelete,
  testIdEdit,
  testIdItem,
  testIdPreview,
  testIdRemove,
  typeNameTestId,
}: ICatalogItemWrapperProps) => {
  const selector = (state: IState) =>
    type === 'overlays' ? isOverlayEnabled(state, props.id) : isPanelEnabled(state, props.id);
  const isEnabled = useSelector(selector);

  if (!isEnabled) return <></>;
  return (
    <CatalogItem
      data-testid={`${testIdItem}-${index}`}
      key={key}
      onConfirmClick={onConfirmClick}
      onPreviewClick={onPreviewClick}
      tabIndex={index}
      testIdAdd={`${testIdAdd}-${index}`}
      testIdBroadcast={`${testIdBroadcast}-${index}`}
      testIdDelete={`${testIdDelete}-${index}`}
      testIdEdit={`${testIdEdit}-${index}`}
      testIdPreview={`${testIdPreview}-${index}`}
      testIdRemove={`${testIdRemove}-${index}`}
      typeNameTestId={`${typeNameTestId}-${index}`}
      {...props}
    />
  );
};

export default function KindCatalog({ kinds, ...rest }: KindCatalogProps) {
  return (
    <CatalogContainer>
      <NonKeyedListMapper list={kinds}>
        {(key: string, props: Kind, index: number) => {
          return <CatalogItemWrapper key={key} props={props} index={index} {...rest} />;
        }}
      </NonKeyedListMapper>
    </CatalogContainer>
  );
}

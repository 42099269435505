import styled from 'styled-components';
import { ADMIN_ALERT_WARNING, ADMIN_FONT_FAMILY } from 'style/constants';
import { ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${ADMIN_ALERT_WARNING};
  margin-right: 4px;
`;

export const Container = styled.div`
color: ${ADMIN_ALERT_WARNING};
font-family: ${ADMIN_FONT_FAMILY};
display: flex;
flex-direction: row;
align-items: center;
${ADMIN_TEXT_LABEL_XS_MEDIUM};
`;

import React, { useState } from 'react';
import { SubscriptionType } from 'models/ISubscription';
import PropTypes from 'prop-types';
import LibraryModalSubscription from 'components/admin2/LibraryModalSubscription';
import AdminModal from 'components/admin2/ui/AdminModal';
import SubscriptionAccessCodeModal from 'components/admin2/SubscriptionAccessCodeModal';
import SetSubscriptionModal from 'components/admin2/SetSubscriptionModal';
import { showAdminErrorModal } from 'services/modals';
import { useDispatch } from 'react-redux';
import { useAdminTranslation } from 'hooks/use-translation';
import libraryColumns from './columns';
import { StyledAdminModal } from './styles';
import NewBundleModal from '../NewBundleModal';

const propTypes = {
  disableEditing: PropTypes.bool,
  disableKeyClick: PropTypes.bool,
  disableSelecting: PropTypes.bool.isRequired,
  formatData: PropTypes.func,
  hideCreateBundle: PropTypes.bool,
  hideCreateSubscription: PropTypes.bool,
  hideCreateTicket: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func,
};
const defaultProps = {
  disableEditing: false,
  formatData: undefined,
  hideCreateBundle: false,
  hideCreateSubscription: false,
  hideCreateTicket: false,
  onSelectItem: () => null,
};

const SubscriptionsLibraryModal = (props) => {
  const { t } = useAdminTranslation();
  const [addSubscription, setAddSubscription] = useState(false);
  const [addBundle, setAddBundle] = useState(false);
  const [updateSubscription, setUpdateSubscription] = useState(false);
  const [addAccessCode, setAddAccessCode] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const dispatch = useDispatch();

  const {
    onClose,
    onSelectItem,
    disableSelecting,
    disableEditing,
    disableKeyClick,
    formatData,
    hideCreateSubscription,
    hideCreateBundle,
    hideCreateTicket,
  } = props;

  const subscriptionModalConfig = {
    buttonProps: [
      ...hideCreateTicket ? [] : [{
        labelKey: 'ADMIN_LABEL_CREATE_TICKET',
        onClick: () => {
          setAddSubscription(true);
          setSelectedOption(SubscriptionType.ticket);
        },
        testId: 'createTicketButton',
      }],
      ...(hideCreateBundle) ? [] : [{
        labelKey: 'ADMIN_LABEL_CREATE_BUNDLE',
        onClick: () => {
          setAddBundle(true);
        },
        testId: 'createBundleButton',
      }],
      ...hideCreateSubscription ? [] : [{
        labelKey: 'ADMIN_LABEL_CREATE_SUBSCRIPTION',
        onClick: () => {
          setAddSubscription(true);
          setSelectedOption(SubscriptionType.subscription);
        },
        testId: 'createSubscriptionButton',
      }],
    ],
    columns: libraryColumns(t).SUBSCRIPTIONS,
    headerTextKey: 'ADMIN_LABEL_PAGE_TICKETS_BUNDLES_SUBSCRIPTIONS_LIBRARY_HELP',
    service: '/subscription/v2',
  };

  const closeUpdateSubscriptionModal = () => {
    setUpdateSubscription(false);
    setSelectedOption('');
    setForceRefresh(!forceRefresh);
  };

  const closeItemSelectModal = () => {
    setAddSubscription(false);
    setSelectedOption('');
    setForceRefresh(!forceRefresh);
  };

  const closeKeySelectModal = () => {
    setAddAccessCode(false);
  };

  const handleCloseBundleModal = () => {
    setAddBundle(false);
    setForceRefresh(!forceRefresh);
  };

  const onKeyClickItem = (value) => {
    setSelectedSubscription(value);
    setAddAccessCode(true);
  };

  const handleOnEditItem = (value) => {
    if (value.type === 'bundle') {
      dispatch(showAdminErrorModal(t('ADMIN_LABEL_BUNDLE_EDIT_ERROR')));
      return;
    }
    setSelectedOption(value.type);
    setSelectedSubscription(value);
    setUpdateSubscription(true);
  };

  const modalData = subscriptionModalConfig;

  return (
    <>
      <LibraryModalSubscription
        buttonProps={modalData.buttonProps}
        columns={modalData.columns}
        disableEditing={disableEditing}
        disableKeyClick={disableKeyClick}
        disableSelecting={disableSelecting}
        fetchObject={{
          method: 'GET',
          service: modalData.service,
        }}
        forceRefresh={forceRefresh}
        formatData={formatData}
        helperText={modalData.headerTextKey}
        onClose={onClose}
        onEditItem={handleOnEditItem}
        onKeyClick={onKeyClickItem}
        onSelectItem={onSelectItem}
        searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH_FOR_A_TICKET_BUNDLE_OR_SUBSCRIPTION"
        titleKey="ADMIN_LABEL_TICKETS_BUNDLES_AND_SUBSCRIPTION_LIBRARY"
      />
      {
        addSubscription && (
          <StyledAdminModal allowOverflow={false} fixedHeight maxHeight="100%" maxWidth="580px" onClose={closeItemSelectModal}>
            <SetSubscriptionModal
              onClose={closeItemSelectModal}
              type={selectedOption}
            />
          </StyledAdminModal>
        )
      }
      {
        addBundle && (
          <StyledAdminModal allowOverflow={false} fixedHeight maxHeight="calc(100% - 40px)" maxWidth="544px" onClose={handleCloseBundleModal}>
            <NewBundleModal
              onClose={handleCloseBundleModal}
            />
          </StyledAdminModal>
        )
      }
      {
        addAccessCode && (
          <AdminModal allowOverflow fixedHeight="fit-content" maxHeight="400px" maxWidth="428px" onClose={closeKeySelectModal}>
            <SubscriptionAccessCodeModal
              onClose={closeKeySelectModal}
              selectedSubscription={selectedSubscription.sku}
            />
          </AdminModal>
        )
      }
      {
        updateSubscription && (
          <StyledAdminModal allowOverflow={false} fixedHeight maxHeight="100%" maxWidth="580px" onClose={closeUpdateSubscriptionModal}>
            <SetSubscriptionModal
              onClose={closeUpdateSubscriptionModal}
              selectedSubscription={selectedSubscription}
              type={selectedOption}
            />
          </StyledAdminModal>
        )
      }
    </>
  );
};

SubscriptionsLibraryModal.propTypes = propTypes;
SubscriptionsLibraryModal.defaultProps = defaultProps;
export default SubscriptionsLibraryModal;

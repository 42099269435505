import styled from 'styled-components';
import {
  ADMIN_SURFACE_4,
  ADMIN_TEXT_300,
} from 'style/constants';
import {
  ADMIN_FIELD_TEXT_INPUT,
} from 'style/mixins';

export const URLLabelContainer = styled.div`
  display: flex;
`;

export const Constant = styled.div<{right?: boolean}>`
  display: flex;
  align-items: center;
  flex: 0;
  background: ${ADMIN_SURFACE_4};
  color: ${ADMIN_TEXT_300};
  padding: 10px;
  border-radius: ${({ right }) => right ? '0 4px 4px 0' : '4px 0 0 4px'};
  user-select: none;
`;

export const Input = styled.input`
  ${ADMIN_FIELD_TEXT_INPUT}
  border-radius: 0;
  padding: 10px;
  min-width: 0;
`;

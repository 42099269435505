import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_200,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
} from 'style/constants';
import withTooltip from 'components/core/withTooltip';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div<{
  isActive?: boolean;
  isLoading?: boolean;
  shouldFill?: boolean;
}>`
  display: flex;
  flex-direction: column;
  background: ${ADMIN_SURFACE_2};
  max-width: 150px;
  border: 1px solid transparent;
  border-radius: 5px;
  height: fit-content;

  &:hover {
    ${({ isActive }) =>
      !isActive &&
      css`
        border: 1px solid ${ADMIN_SURFACE_5};
      `};
    cursor: ${({ isLoading }) => (isLoading ? 'unset' : 'pointer')};
  }
  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${ADMIN_ACCENT_PRIMARY};
    `}
  ${({ shouldFill }) =>
    shouldFill &&
    css`
      background: ${ADMIN_SURFACE_1};
    `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px;
  border-radius: 0px 0px 5px 5px;
  background-color: transparent;
  min-height: 32px;
`;

export const ThemeOptionRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ThemeName = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_200};
  align-items: center;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  overflow: hidden;
  width: 100%;
  max-width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ThreeDotsIcon = styled(Icon).attrs({
  name: 'threeDots',
})``;

export const FavoriteIcon = styled(Icon).attrs(props => ({
  name: 'channelFavorite',
  color: ADMIN_ACCENT_PRIMARY(props),
}))`
  margin-right: 5px;
`;

export const CloseIcon = styled(Icon).attrs({
  name: 'delete',
})`
  padding: 3px;
  & > svg {
    width: 8px;
    height: 8px;
    & > path {
      fill: ${ADMIN_TEXT_100};
    }
  }
`;

export const ActionButton = styled.button`
  background: ${ADMIN_ACCENT_PRIMARY};
  width: 20px;
  height: 20px;
  &:hover {
    background: ${ADMIN_SURFACE_1};
  }
  border-radius: 4.17px;
  border: none;
  margin-right: 5px;
`;

export const OptionsButton = styled.button<{ settingsOpen: boolean }>`
  ${({ settingsOpen }) => settingsOpen && css`background: ${ADMIN_SURFACE_1};`};
  cursor: pointer;
  border: none;
  border-radius: 6.21px;
`;

export const CustomizeIcon = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'adminbarEditor',
  }))`
    & > svg {
      width: 13.33px;
      height: 13.33px;
      padding-bottom: 1px;
      padding-left: 1px;
      & > path {
        fill: ${ADMIN_TEXT_100};
      }
    }
  `,
  {
    admin: true,
  },
);

export const SetFavoriteIcon = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'channelFavorite',
  }))`
    & > svg {
      width: 12.67px;
      height: 12.67px;
      & > rect {
        fill: ${ADMIN_TEXT_100};
      }
      & > path {
        fill: ${ADMIN_ACCENT_PRIMARY};
      }
    }
  `,
  {
    admin: true,
  },
);

export const TrashIcon = withTooltip(
  styled(Icon).attrs(() => ({
    name: 'trash',
  }))`
    & > svg {
      width: 10.83px;
      height: 10.83px;
      & > path {
        fill: ${ADMIN_TEXT_100};
      }
    }
  `,
  {
    admin: true,
  },
);

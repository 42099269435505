import React, { useState, useEffect } from 'react';
import { Container, ProviderBox, SectionTitle } from './styles';
import { StreamingProviderAccountForm } from './StreamingProviderAccountForm';
import { getStreamProviderAccount, listUserAccountProviders } from 'services/livestream';
import { ResolvedReturnType } from 'utils';
import { useSelector } from 'hooks';
import { getDefaultStreamingProvider } from 'services/app';
import { useAdminTranslation } from 'hooks/use-translation';

export const StreamingProviders = () => {
  const { t } = useAdminTranslation();
  const [providers, setProviders] = useState<ResolvedReturnType<typeof listUserAccountProviders>>([]);
  const defaultStreamingProvider = useSelector(getDefaultStreamingProvider);
  const activeAccount = useSelector(getStreamProviderAccount);

  useEffect(
    () => {
      listUserAccountProviders()
        .then(setProviders);
    },
    [],
  );

  return (
    <Container>
      <SectionTitle style={{ marginBottom: 20 }}>{t('ADMIN_THIRDPARY_STREAM_PROVIDERS_TITLE')}</SectionTitle>
      {defaultStreamingProvider !== 'brightcove' && (
        <ProviderBox>
          <StreamingProviderAccountForm
            active={!activeAccount}
            providerName={t('ADMIN_THIRDPARY_STREAM_PROVIDERS_MAESTRO_DEFAULT')}
            valid={true}
          />
        </ProviderBox>
      )}
      {providers.map(({ name, accountCredentials }) => {
        const isActive = activeAccount?.providerName === name;
        return (
          <ProviderBox key={name}>
            <StreamingProviderAccountForm
              providerName={name}
              accountCredentials={accountCredentials}
              active={isActive}
              savedCredentials={isActive ? activeAccount?.credentials : undefined}
              valid={isActive && activeAccount.valid}
            />
          </ProviderBox>
        );
      })}
    </Container>
  );
};

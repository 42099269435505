import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'hooks';
import Dropdown from 'components/admin2/ui/Dropdown';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { getShopifyCatalogs, isProductLoading } from 'services/shopify/selectors';
import { useAdminTranslation } from 'hooks/use-translation';

const SelectShopifyProducts = (props) => {
  const { t } = useAdminTranslation();
  const { onChange, renderer } = props;
  const { product } = renderer;
  const updatePanel = useCallback((key, clear) => (val) => onChange({
    ...renderer,
    [clear]: undefined,
    [key]: val,
  }), [renderer, onChange]);
  const productsLoading = useSelector(isProductLoading);
  const { products } = useSelector(getShopifyCatalogs);

  return (productsLoading && !products.length ?
    <LoadingSpinner name="line-spin-fade-loader" /> :
    (
      <Dropdown
        isSearchable={false}
        labelKey="ADMIN_CARD_SHOPIFY_SELECT_PRODUCT"
        name="ADMIN_CARD_SHOPIFY_SELECT_PRODUCT"
        onChange={updatePanel('product', 'collection')}
        options={products}
        placeholder={t('ADMIN_SHOPIFY_PANEL_EDITOR_SELECT_PRODUCT')}
        value={product}
      />
    )
  );
};

SelectShopifyProducts.propTypes = {
  onChange: PropTypes.func.isRequired,
  renderer: PropTypes.shape({
    product: PropTypes.shape({
      handle: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SelectShopifyProducts;

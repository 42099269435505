import React, { ComponentType, forwardRef, HTMLAttributes, Ref } from 'react';
import styled, { css } from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';

interface OptionalTranslatedProps<T> extends HTMLAttributes<HTMLElement> {
  component?: ComponentType<T>;
  stringKey?: TI18nKey;
}

const StyledComponent = styled(React.Component)``;

function OptionalTranslated<T>({
  children,
  stringKey,
  component,
  ...props
}: OptionalTranslatedProps<T> & T, ref: Ref<HTMLElement>) {
  const Component = component as ComponentType;
  return stringKey ? (
    <TranslatedText component={Component} componentProps={{ ref, ...props }} stringKey={stringKey} />
  ) : <>{Component && children ? <StyledComponent as={Component} ref={ref} {...props}>{children}</StyledComponent> : children}</>;
}

export default forwardRef(OptionalTranslated) as typeof OptionalTranslated;

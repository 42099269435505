import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import TranslatedText from 'components/i18n/TranslatedText';
import UploadZone from 'components/admin2/UploadZone';
import ImageLibraryModal from 'components/admin2/ImageLibraryModal';
import EditImageModal from 'components/admin2/EditImageModal';
import Gallery from 'components/admin2/ui/Gallery';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import Divider from 'components/admin2/ui/Divider';
import { AdminTabTextBlock } from 'components/admin2/ui/Text';
import getState from 'components/admin2/UploadZone/store';

import { saveSiteSettings, settingsTypes } from 'services/admin';
import { getSiteId, getSiteProfileImages, getSiteProfileSettings } from 'services/app/selectors';
import { showAdminErrorKey } from 'services/modals';
import isEqual from 'lodash/isEqual';
import { MEDIA_ASSETS_BASE_URL } from 'config';
import { useAdminTranslation } from 'hooks/use-translation';

const UserProfilesTab = (props) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const handleSave = useCallback((data) => {
    dispatch(saveSiteSettings(settingsTypes.USER_PROFILES, data));
  }, [
    dispatch,
    saveSiteSettings,
  ]);
  const callErrorModal = useCallback((error) => {
    dispatch(showAdminErrorKey(error));
  }, [
    dispatch,
    showAdminErrorKey,
  ]);
  const currentProfileImages = useSelector(getSiteProfileImages, isEqual);
  const currentProfileSettings = useSelector(getSiteProfileSettings, isEqual);

  const siteId = useSelector(getSiteId, isEqual);

  const [state, actions] = getState(t, props);
  const {
    libraryActive,
  } = state;
  const {
    toggleLibrary,
  } = actions;

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingImage, setEditingImage] = useState(false);
  const [profileImages, setProfileImages] = useState(currentProfileImages);
  const [changeName, setChangeName] = useState(currentProfileSettings.changeName);

  const selectProfileFromLibrary = useCallback((fileData) => {
    let imgsrc = null;
    if (typeof fileData === 'string') {
      imgsrc = fileData;
    } else if ('ext' in fileData && '_id' in fileData) {
      const { ext, _id: mediaId } = fileData;
      imgsrc = `${MEDIA_ASSETS_BASE_URL}/${siteId}/${mediaId}.${ext}`;
    }

    if (imgsrc) {
      const newProfileImage = {
        isAdmin: false,
        url: imgsrc,
      };
      const pending = [...currentProfileImages];
      pending.push(newProfileImage);
      handleSave({ ...currentProfileSettings, profileImages: pending });
    } else {
      callErrorModal('ADMIN_ERROR_INVALID_IMAGE');
    }
  }, [
    currentProfileImages,
    handleSave,
    siteId,
  ]);

  const toggleEditImageModal = useCallback(() => {
    setEditingImage(!editingImage);
  }, [editingImage]);

  const openEditImage = useCallback((image, index) => {
    setSelectedIndex(index);
    setSelectedImage(image);
    toggleEditImageModal();
  }, []);

  const images = profileImages || [];

  useEffect(
    () => {
      if (currentProfileImages) {
        setProfileImages(currentProfileImages);
      }
    },
  );

  return (
    <AdminBarTab>
      <AdminBarTabHeader
        headerKey="ADMIN_LABEL_SETTINGS"
        subHeaderKey="ADMIN_SETTINGS_USER_PROFILES_TITLE"
        subtitleKey="ADMIN_LABEL_USER_PROFILES"
      />
      <TranslatedText component={AdminTabTextBlock} stringKey="ADMIN_SETTINGS_USER_PROFILES_HELPER_TEXT" />
      <LabelToggle
        checked={changeName}
        labelKey="ADMIN_TEXT_CHANGE_NAME"
        onChange={() => {
          setChangeName(!changeName);
          handleSave({
            ...currentProfileSettings,
            changeName: !currentProfileSettings.changeName,
          });
        }}
      />
      <UploadZone
        canDrop
        dimensionRequirements={{
          exactDimensions: false,
          pixelHeight: 200,
          pixelWidth: 200,
          verify: true,
        }}
        onFileSubmit={selectProfileFromLibrary}
        onSelect={selectProfileFromLibrary}
      />
      <Divider />
      <Gallery
        hoverIcon="pencil"
        images={images}
        imageTooltipKey="ADMIN_LABEL_EDIT"
        labelIcon="uploadPicture"
        labelKey="ADMIN_LABEL_GALLERY"
        onSelect={openEditImage}
      />
      {libraryActive &&
        <ImageLibraryModal onClose={toggleLibrary} onSelect={selectProfileFromLibrary} />}
      {editingImage && (
        <EditImageModal
          image={selectedImage}
          index={selectedIndex}
          onClose={toggleEditImageModal}
        />
      )}
    </AdminBarTab>
  );
};

export default UserProfilesTab;

import styled from 'styled-components';
import { Button } from 'components/admin2/ui/Button';
import Label from 'components/admin2/ui/Label';
import URLInput from 'components/admin2/ui/URLInput';
import {
  ADMIN_TEXT_200,
} from 'style/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const StyledButton = styled(Button as  any)`
  flex: 1;
  &:last-child {
    margin-left: 10px;
  }
` as typeof Button;

export const StyledLabel = styled(Label).attrs((props) => ({
  color: ADMIN_TEXT_200(props),
  iconSize: 20,
}))``;

export const StyledURLInput = styled(URLInput)`
  margin-top: 10px;
`;

export const InstallButton = styled(Button as  any)`
  margin-top: 10px;
` as typeof Button;

import React, { useState, useEffect, useMemo } from 'react';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import TranslatedText from 'components/i18n/TranslatedText';
import { AdminTabTextBlock } from 'components/admin2/ui/Text';
import TextInput from 'components/admin2/TextInput';
import Label from 'components/admin2/ui/Label';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { getAdminText200 } from 'services/themes';

export default function VideoPlayerSettingsTab(props) {
  const {
    showConcurrents: currentShowConcurrents,
    minimumConcurrents: currentMinimumConcurrents,
    handleSave,
    shouldShowVideoTitle: oldVideoTitleFlag,
    shouldShowVideoUrl: oldVideoUrlFlag,
    shouldShowVideoEmbed: oldVideoEmbedFlag,
    shouldShowYoutubeNoCookie: oldYoutubeNoCookieFlag,
    saveChanges,
    socialsData: oldSocialsData,
  } = props;

  const adminText200 = useSelector(getAdminText200);
  const [showConcurrents, setShowConcurrents] = useState(currentShowConcurrents);
  const [minimumConcurrents, setMinimumConcurrents] = useState(currentMinimumConcurrents);
  const [newVideoTitleFlag, setVideoTitleFlag] = useState(oldVideoTitleFlag);
  const [newVideoUrlFlag, setVideoUrlFlag] = useState(oldVideoUrlFlag);
  const [newVideoEmbedFlag, setVideoEmbedFlag] = useState(oldVideoEmbedFlag);
  const [newSocialsData, setSocialsData] = useState(oldSocialsData);
  const [newYoutubeNoCookieFlag, setYoutubeNoCookieFlag] = useState(oldYoutubeNoCookieFlag);
  const { facebook, twitter } = newSocialsData || {};

  const [saved, setSaved] = useState(false);

  const newData = [
    newSocialsData,
    newVideoEmbedFlag,
    newVideoTitleFlag,
    newVideoUrlFlag,
    newYoutubeNoCookieFlag,
  ];

  const oldData = [
    oldSocialsData,
    oldVideoEmbedFlag,
    oldVideoTitleFlag,
    oldVideoUrlFlag,
    oldYoutubeNoCookieFlag,
  ];

  const hasChanges = useMemo(() => {
    return !isEqual(
      {
        social: newSocialsData,
        videoEmbed: newVideoEmbedFlag,
        videoTitle: newVideoTitleFlag,
        videoUrl: newVideoUrlFlag,
        youtubeNoCookie: newYoutubeNoCookieFlag,
      },
      {
        social: oldSocialsData,
        videoEmbed: oldVideoEmbedFlag,
        videoTitle: oldVideoTitleFlag,
        videoUrl: oldVideoUrlFlag,
        youtubeNoCookie: oldYoutubeNoCookieFlag,
      },
    );
  }, [...newData, ...oldData]);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
  }, [...newData]);

  const canSave = (
    showConcurrents !== currentShowConcurrents ||
    minimumConcurrents !== currentMinimumConcurrents
  );

  const save = () => {
    if (hasChanges) {
      saveChanges(
        newVideoEmbedFlag,
        newSocialsData,
        newVideoTitleFlag,
        newVideoUrlFlag,
        newYoutubeNoCookieFlag,
      );
    }
    if (canSave) {
      handleSave({
        minimumConcurrents: Number(minimumConcurrents) || 0,
        showConcurrents,
      });
    }
    setSaved(true);
    return null;
  };

  return (
    <AdminBarTab>
      <AdminBarTabHeader
        hasUnsavedChanges={hasChanges || canSave}
        headerKey="ADMIN_LABEL_SETTINGS"
        onSave={save}
        saved={saved && !(hasChanges || canSave)}
        subHeaderKey="ADMIN_SETTINGS_VIDEO_PLAYER"
        subtitleKey="ADMIN_LABEL_VIDEO_PLAYER"
      />
      <TranslatedText component={AdminTabTextBlock} stringKey="ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT" />
      <LabelToggle
        checked={newVideoTitleFlag}
        descriptionKey="ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_VIDEO_TITLE"
        labelKey="ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_VIDEO_TITLE"
        onChange={setVideoTitleFlag}
      />
      <LabelToggle
        checked={twitter?.active}
        descriptionKey="ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_TWITTER_SHARE"
        labelKey="ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_TWITTER_SHARE"
        onChange={isActive => setSocialsData({
          ...newSocialsData,
          twitter: { active: isActive },
        })}
      />
      <LabelToggle
        checked={facebook?.active}
        descriptionKey="ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_FACEBOOK_SHARE"
        labelKey="ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_FACEBOOK_SHARE"
        onChange={isActive => setSocialsData({
          ...newSocialsData,
          facebook: { active: isActive },
        })}
      />
      <LabelToggle
        checked={newYoutubeNoCookieFlag}
        descriptionKey="ADMIN_SETTINGS_YOUTUBE_NO_COOKIE_EMBEDS_DESCRIPTION"
        labelKey="ADMIN_SETTINGS_YOUTUBE_NO_COOKIE_EMBEDS_TITLE"
        onChange={setYoutubeNoCookieFlag}
      />
      {
        // TODO: remove this false boolean when working on NS-5306
        false && (
          <>
            <LabelToggle
              checked={newVideoUrlFlag}
              descriptionKey="ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_LINK_SHARE"
              labelKey="ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_LINK_SHARE"
              onChange={setVideoUrlFlag}
            />
            <LabelToggle
              checked={newVideoEmbedFlag}
              descriptionKey="ADMIN_SETTINGS_VIDEO_PLAYER_HELPER_TEXT_EMBED_SHARE"
              labelKey="ADMIN_SETTINGS_VIDEO_PLAYER_LABEL_EMBED_SHARE"
              onChange={setVideoEmbedFlag}
            />
          </>
        )
      }
      <Label
        color={adminText200}
        compact
        labelKey="ADMIN_SETTINGS_CONCURRENTS_TITLE"
        uppercase
      />
      <TranslatedText
        component={AdminTabTextBlock}
        componentProps={{ compact: true }}
        stringKey="ADMIN_DESC_CONCURRENTS_TAB"
      />
      <LabelToggle
        checked={showConcurrents}
        data-testid="showConcurrentsToggle"
        labelKey="ADMIN_LABEL_SHOW_CONCURRENTS"
        onChange={setShowConcurrents}
      />
      <TextInput
        labelKey="ADMIN_LABEL_MIN_CONCURRENTS"
        onChange={(value) => {
          const number = Number(value);
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(number)) {
            setMinimumConcurrents(number);
          }
        }}
        value={minimumConcurrents}
      />
    </AdminBarTab>
  );
}

VideoPlayerSettingsTab.propTypes = {
  handleSave: PropTypes.func.isRequired,
  minimumConcurrents: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  playerData: PropTypes.shape({}).isRequired,
  saveChanges: PropTypes.func.isRequired,
  shouldShowVideoEmbed: PropTypes.bool.isRequired,
  shouldShowVideoTitle: PropTypes.bool.isRequired,
  shouldShowVideoUrl: PropTypes.bool.isRequired,
  showConcurrents: PropTypes.bool.isRequired,
  socialsData: PropTypes.shape({}),
};

VideoPlayerSettingsTab.defaultProps = { socialsData: null };

import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from 'components/admin2/TextInput';
import styled, { css } from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import Label from 'components/admin2/Label';
import { ADMIN_PANEL_EDITOR_BODY } from 'style/mixins';
import { getShopifyCatalogs, getShop } from 'services/shopify/selectors';
import useSelector from 'hooks/use-selector';
import { useDispatch } from 'react-redux';
import { ADMIN_SURFACE_4, ADMIN_TEXT_100, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_500, ADMIN_SURFACE_6 } from 'style/constants';
import Button from 'components/admin2/ui/Button';
import { navigateToAdminBarAction } from 'services/admin/actions';
import { ActionKey } from 'services/admin/constants';
import { MAESTRO_SHOPIFY_APP_URL } from 'shared/constants';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_REGULAR } from 'style/design-system/textStyles';
import Link from 'components/admin2/Link';
import PanelEditorHeader from '../PanelEditorHeader';
import ShopifyProductSelector from './ShopifyProductSelector';
import ShopifyCollectionSelector from './ShopifyCollectionSelector';

const Container = styled.div`
  ${ADMIN_PANEL_EDITOR_BODY}
`;

const ProductSection = styled.div`
  padding-bottom: 16px;
`;

const LabelContainer = styled.div`
  padding-top: 16px;
`;

const FeatureItems = styled.div`
  display: flex;
  margin: 16px 0;
`;

const BACKGROUND_SELECTED = props => props.selected ? (
  css`
    background: ${ADMIN_SURFACE_6};
  `
) : (
  css`
    background: none;
  `
);

const FeatureItem = styled.div`
  flex-grow: 1;
  ${BACKGROUND_SELECTED};
  border: 2px solid #444444;
  text-align: center;
  padding-top: 6px;
  &:hover {
    cursor: pointer;
  }
  &:last-child {
    border-left: 0;
  }
`;

const SelectedProductImg = styled.div`
  margin-top: 16px;
  border: 2px dotted #444444;
  img {
    width: 100%;
  }
`;

const NoStoreConnectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: ${ADMIN_SURFACE_4};
  width: 100%;
`;

const NoStoreTitle = styled.div`
  margin-bottom: 20px;
  & > span {
    ${ADMIN_TEXT_BODY_S_BOLD}
    color: ${ADMIN_TEXT_100};
  }
`;

const NoStoreDescription = styled.div`
   margin-bottom: 15px;
  & > span {
    ${ADMIN_TEXT_BODY_S_REGULAR}
    color: ${ADMIN_TEXT_100};
  }
`;

const InstructionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const NoStoreInstructions = styled(NoStoreDescription)`
  margin-bottom: 15px;
  margin-right: 4px;
`;

const InstructionsLink = styled(Link)`
  margin-bottom: 15px;
  & > span {
    ${ADMIN_TEXT_BODY_S_BOLD}
    color: ${ADMIN_ACCENT_PRIMARY};
    text-decoration: underline;
  }
  :hover {
    color: ${ADMIN_TEXT_500};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 192px;
  height: 42px;
`;

const ShopifyPanelEditor = (props) => {
  const dispatch = useDispatch();
  const { images } = useSelector(getShopifyCatalogs);
  const isShopConnected = Boolean(useSelector(getShop));
  const { shopInfo, renderer, onChange } = props;
  const { iconName, panelName, product } = renderer;
  const [shopName, setShopName] = useState('');
  const [selectedOption, setSelectedOption] = useState('product');

  const updatePanel = useCallback((key, clear) => val => onChange({
    ...renderer,
    [clear]: undefined,
    [key]: val,
  }), [renderer, onChange]);

  useEffect(() => {
    if (shopInfo.shop) {
      setShopName(shopInfo.shop);
    }
  }, [shopInfo]);

  const selectOption = (option) => {
    setSelectedOption(option);
  };

  const handleOpenThirdPartytab = () => {
    window.open(MAESTRO_SHOPIFY_APP_URL, '_blank');
    dispatch(navigateToAdminBarAction({ actionKey: ActionKey.thirdParty }));
  };

  const renderShopifySettings = useCallback(() => {
    if (isShopConnected) {
      return (
        <ProductSection>
          <TextInput labelKey="ADMIN_CARD_SHOPIFY_CONNECTED_STORE" onChange={text => setShopName(text)} value={shopName} />
          <LabelContainer>
            <TranslatedText component={Label} stringKey="ADMIN_CARD_SHOPIFY_FEATURE_ITEM" />
          </LabelContainer>
          <FeatureItems>
            <FeatureItem onClick={() => selectOption('product')} selected={selectedOption === 'product'}>
              <TranslatedText component={Label} stringKey="ADMIN_CARD_SHOPIFY_FEATURE_PRODUCT" />
            </FeatureItem>
            <FeatureItem onClick={() => selectOption('collection')} selected={selectedOption === 'collection'}>
              <TranslatedText component={Label} stringKey="ADMIN_CARD_SHOPIFY_FEATURE_COLLECTION" />
            </FeatureItem>
          </FeatureItems>
          {selectedOption === 'product' ? (
            <>
              <ShopifyProductSelector onChange={onChange} renderer={renderer} />
              <SelectedProductImg>
                {images ? (
                  images.map((image) => {
                    if (product && image.productId === product.value) {
                      return (
                        <img
                          alt={image.alt}
                          src={image.src}
                        />
                      );
                    }
                    return null;
                  })
                ) : null }
              </SelectedProductImg>
            </>
          ) : (
            <ShopifyCollectionSelector onChange={onChange} renderer={renderer} />
          )}
        </ProductSection>
      );
    }
    return (
      <NoStoreConnectedWrapper>
        <NoStoreTitle>
          <TranslatedText stringKey="ADMIN_CARD_SHOPIFY_NOT_CONNECTED_TITLE" />
        </NoStoreTitle>
        <NoStoreDescription>
          <TranslatedText stringKey="ADMIN_CARD_SHOPIFY_NOT_CONNECTED_DESCRIPTION" />
        </NoStoreDescription>
        <InstructionWrapper>
          <NoStoreInstructions>
            <TranslatedText stringKey="ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTRUCTIONS_TEXT" />
          </NoStoreInstructions>
          <InstructionsLink
            href="https://support.maestro.io/knowledge/shopify-install-the-maestro-integration"
            target="_blank"
          >
            <TranslatedText stringKey="ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTRUCTIONS_LINK" />
          </InstructionsLink>
        </InstructionWrapper>
        <ButtonWrapper>
          <StyledButton onClick={handleOpenThirdPartytab}>
            <TranslatedText stringKey="ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTALL_APP" />
          </StyledButton>
        </ButtonWrapper>
      </NoStoreConnectedWrapper>
    );
  }, [isShopConnected, selectedOption]);

  return (
    <Container>
      <PanelEditorHeader
        iconValue={iconName}
        nameValue={panelName}
        onIconChange={updatePanel('iconName')}
        onNameChange={updatePanel('panelName')}
      />
      { renderShopifySettings() }
    </Container>
  );
};

ShopifyPanelEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  renderer: PropTypes.shape({
    collection: PropTypes.object,
    description: PropTypes.string,
    iconName: PropTypes.string,
    panelName: PropTypes.string,
    product: PropTypes.object,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  shopInfo: PropTypes.object.isRequired,
};

export default ShopifyPanelEditor;

import React, { Children, ReactElement, HTMLAttributes, useMemo, useState } from 'react';
import { TabButtonVariant } from 'components/admin2/ui/TabButton';
import isNil from 'lodash/isNil';
import invariant from 'invariant';
import { TabProps } from './Tab';
import {
  ViewContainer,
  ViewHeader,
  Body,
  LargeTitle,
  StyledTitle,
} from './styles';

export { default as Tab } from './Tab';

type TabComponent = ReactElement<TabProps>;

interface TabbedViewProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children'>, TabButtonVariant {
  activeIndex?: number | string | null;
  bigTabs?: boolean;
  children?: TabComponent[];
  initialActiveIndex?: number;
  onTabChange?: (tab?: number) => void;
}

export default function TabbedView({
  activeIndex = null,
  bigTabs,
  children,
  className,
  initialActiveIndex = 0,
  onTabChange,
  variant,
}: TabbedViewProps) {
  const [innerActiveIndex, setInnerActiveIndex] = useState(typeof activeIndex === 'number' ? activeIndex : initialActiveIndex);

  const onTitleClick = (index: number) => {
    onTabChange?.(index);
    setInnerActiveIndex(index);
  };

  const TitleComponent = useMemo(() => {
    return bigTabs ? LargeTitle : StyledTitle;
  }, [bigTabs]);

  const tabIndex = useMemo(() => {
    return isNil(activeIndex) ? innerActiveIndex : activeIndex;
  }, [activeIndex, innerActiveIndex]);

  const tabs = useMemo(() => {
    const filtered: TabComponent[] = [];
    Children.forEach(children, child => {
      if (!child || typeof child === 'string' || typeof child === 'number') {
        invariant(false, 'Expecting TabbedView children to be of type Tab');
      }
      filtered.push(child);
    });
    return filtered;
  }, [children]);

  return (
    <ViewContainer className={className}>
      <ViewHeader>
        {tabs.map((tab, index) => {
          const id = tab.props.id || index;
          return (
            <TitleComponent
              key={tab.props.titleKey}
              active={id === tabIndex}
              iconName={tab.props.iconName}
              id={id}
              onTabClick={onTitleClick}
              stringKey={tab.props.titleKey}
              variant={variant}
              {...tab.props}
            />
          );
        })}
      </ViewHeader>
      <Body>
        {tabs.map((tab, index) => {
          const id = tab.props.id || index;
          return (
            <React.Fragment key={tab.props.titleKey}>
              {React.cloneElement(tab, { active: id === tabIndex })}
            </React.Fragment>
          );
        })}
      </Body>
    </ViewContainer>
  );
}

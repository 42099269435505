import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import IconDropdown from 'components/admin2/ui/IconDropdown';
import TextInput from 'components/admin2/TextInput';
import { SPACING_MEDIUM } from 'style/constants';

const Container = styled.div`
  display: flex;

`;

const StyledTextInput = styled(TextInput)`
  margin-left: ${SPACING_MEDIUM};
  flex: 1;
`;

export default class PanelEditorHeader extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    iconValue: PropTypes.string,
    nameValue: PropTypes.string,
    onIconChange: PropTypes.func.isRequired,
    onNameChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: undefined,
    iconValue: '',
    nameValue: '',
  };

  render() {
    const {
      className,
      onIconChange,
      onNameChange,
      iconValue,
      nameValue,
    } = this.props;
    return (
      <Container className={className}>
        <IconDropdown onChange={onIconChange} value={iconValue} width="35%" />
        <StyledTextInput
          labelKey="ADMIN_LABEL_NAME"
          onChange={onNameChange}
          placeholderKey="ADMIN_PLACEHOLDER_ENTER_PANEL_NAME"
          value={nameValue}
          width="65%"
        />
      </Container>
    );
  }
}

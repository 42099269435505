import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { PLAYLIST_SERVICE_BASE_URL } from 'config';
import LibraryModal from 'components/admin2/LibraryModal';
import AdminModal from 'components/admin2/ui/AdminModal';
import PlaylistEditorModal from 'components/admin2/videoPanels/PlaylistEditorModal';
import moment from 'moment';
import { DESC_KEY } from 'components/admin2/MediaLibrary/constants';

const COLUMNS = [
  {
    accessor: 'title',
    defaultThumbnail: 'play',
    headerKey: 'ADMIN_LABEL_NAME',
    isTitle: true,
    minWidth: 230,
    thumbnailAccessor: 'thumbnail',
    title: 'name',
  },
  {
    accessor: 'created',
    headerKey: 'ADMIN_LABEL_ADDED',
    minWidth: 100,
    sort: DESC_KEY,
    title: 'added',
    transformValue: value => moment(value).fromNow(),
  },
];

export default function PlaylistLibraryModal(props) {
  const [editing, setEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    onClose,
    onSelectItem,
  } = props;

  const defaultButtons = useMemo(() => (
    [{
      labelKey: 'ADMIN_LABEL_CREATE_CUSTOM_PLAYLIST',
      onClick: () => {
        setEditing(true);
        setSelectedItem(null);
      },
    }]
  ), [setEditing, setSelectedItem]);

  const onEditItem = useCallback((value) => {
    setEditing(true);
    setSelectedItem(value);
  }, [setEditing, setSelectedItem]);

  const closeEditorModal = useCallback(() => {
    setEditing(false);
    setSelectedItem(null);
  }, [setEditing, setSelectedItem]);

  return (
    <>
      <LibraryModal
        buttonProps={defaultButtons}
        columns={COLUMNS}
        fetchObject={{
          method: 'GET',
          service: PLAYLIST_SERVICE_BASE_URL,
        }}
        helperText="ADMIN_LABEL_SELECT_PLAYLIST"
        onClose={onClose}
        onEditItem={onEditItem}
        onSelectItem={onSelectItem}
        searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH"
        titleKey="ADMIN_LABEL_PLAYLIST_LIBRARY"
      />
      {
        editing && (
          <AdminModal allowOverflow fixedDimensions onClose={closeEditorModal}>
            <PlaylistEditorModal
              id={selectedItem?._id}
              onClose={closeEditorModal}
              skipRefresh
            />
          </AdminModal>
        )
      }
    </>
  );
}

PlaylistLibraryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func,
};

PlaylistLibraryModal.defaultProps = {
  onSelectItem: () => null,
};

import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { Button } from 'components/admin2/ui/Button';
import {
  ADMIN_TEXT_100,
  ADMIN_SURFACE_1,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';

export const Box = styled.div<{isEmpty?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  background: ${ADMIN_SURFACE_1};
  width: 100%;
  padding: 10px 9px;
  border-radius: 4px;
  ${({ isEmpty }) => !isEmpty && 'padding-bottom: 0;'}
`;

export const Tag = styled(Button as any)<{isSelected?: boolean}>`
  min-height: 21px;
  max-height: 21px;
  border-radius: 10px;
  padding: 3px 10px;
  margin: 0 5px 10px 5px;
  cursor: default;
  ${({ isSelected }) => isSelected && css`background: ${ADMIN_SURFACE_1};`}
`;

export const TagName = styled.div`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RemoveIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_100(props),
  name: 'cancel-thin',
}))`
  cursor: pointer;
  margin-right: 10px;
  line-height: 0;
  & svg {
    width: 9px;
    height: 9px;
  }
`;

import React from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import Radio from 'components/admin2/ui/Radio';
import withLabel from 'components/core/withLabel';
import type { TranslationKey } from 'hooks/use-translation';

interface RadioProps {
  checked: string;
  name: string;
  onChange: (id: string) => any;
  options: Options[];
}
interface Options {
  id: string;
  text: TranslationKey;
}
type MapperFn = (key: string, options: Options) => React.ReactNode;

function RadioWrapper({ checked, name, onChange, options }: RadioProps) {
  function handleChange(id: string) {
    return () => onChange(id);
  }
  return (
    <NonKeyedListMapper list={options}>
      {((key, { id, text }) => (
        <Radio
          key={key}
          checked={id === checked}
          id={key}
          name={name}
          onChange={handleChange(id)}
          labelKey={text}
        />
      )) as MapperFn}
    </NonKeyedListMapper>
  );
}

export default withLabel(RadioWrapper);

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ActionLabel from 'components/admin2/ui/ActionLabel';
import PollLibraryModal from 'components/admin2/PollLibraryModal';
import PollResultsLibraryModal from 'components/admin2/PollResultsLibraryModal';
import RealtimeDocument from 'components/core/RealtimeDocument';
import withLabel from 'components/core/withLabel';

const propTypes = {
  isResults: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  options: PropTypes.shape({
    type: PropTypes.string,
  }),
  value: PropTypes.string,
};

const defaultProps = {
  isResults: false,
  onDelete: null,
  options: {},
  value: false,
};

// TODO: Optional drag & drop icon on right:
//       https://projects.invisionapp.com/share/A5O9WX5NWEB#/screens/336193270
function PollSelect({
  isResults,
  onChange,
  onDelete,
  options: { type },
  value: id,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <RealtimeDocument collection="objects" id={id}>
        {(doc, loaded) => {
          const label = doc?.data?.title || doc?.data?.poll?.name || doc?.renderer?.name;
          let key = 'ADMIN_POLL_SELECT_NOTIFICATION_UNTITLED';
          if (!id) {
            key = 'ADMIN_POLL_SELECT_NOTIFICATION_NO_POLL';
          }
          if (!loaded && !isResults) {
            key = 'ADMIN_POLL_SELECT_NOTIFICATION_LOADING';
          }
          if (!doc && !isResults) {
            key = 'ADMIN_POLL_SELECT_NOTIFICATION_NOT_FOUND';
          }

          const pollFound = !!(label || key === 'ADMIN_POLL_SELECT_NOTIFICATION_UNTITLED');

          return (
            <ActionLabel
              innerLabel={label}
              innerLabelKey={label ? undefined : key}
              onAdd={pollFound ? undefined : () => setOpen(true)}
              onEdit={pollFound ? () => setOpen(true) : undefined}
              onRemove={onDelete}
            />
          );
        }}
      </RealtimeDocument>
      {open && !isResults && (
        <PollLibraryModal
          onClose={() => setOpen(false)}
          onSelectItem={onChange}
          type={type}
        />
      )}
      {open && isResults && (
        <PollResultsLibraryModal
          onClose={() => setOpen(false)}
          onSelectItem={onChange}
        />
      )}
    </>
  );
}

PollSelect.propTypes = propTypes;
PollSelect.defaultProps = defaultProps;

export default withLabel(PollSelect);

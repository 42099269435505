import {
  getPlayerData,
  getPlayerSocials,
  getShowVideoEmbedFlag,
  getShowVideoUrlFlag,
  getShowVideoTitleFlag,
  getShowYoutubeNoCookieFlag,
  showConcurrents,
  getMinimumConcurrents,
} from 'services/app/selectors';
import { saveSiteSettings, settingsTypes } from 'services/admin';
import { connect } from 'react-redux';
import VideoPlayerSettingsTab from './view';

const mapStateToProps = state => ({
  minimumConcurrents: getMinimumConcurrents(state),
  playerData: getPlayerData(state),
  shouldShowVideoEmbed: getShowVideoEmbedFlag(state),
  shouldShowVideoTitle: getShowVideoTitleFlag(state),
  shouldShowVideoUrl: getShowVideoUrlFlag(state),
  shouldShowYoutubeNoCookie: getShowYoutubeNoCookieFlag(state),
  showConcurrents: showConcurrents(state),
  socialsData: getPlayerSocials(state),
});

const mapDispatchToProps = dispatch => ({
  handleSave: data => dispatch(saveSiteSettings(settingsTypes.CONCURRENTS, data)),
  saveChanges: (embedFlag, socialsData,
    titleFlag, urlFlag, youtubeNoCookieFlag) => dispatch(
    saveSiteSettings(settingsTypes.VIDEO_PLAYER,
      {
        embedFlag,
        socialsData,
        titleFlag,
        urlFlag,
        youtubeNoCookieFlag,
      }),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayerSettingsTab);

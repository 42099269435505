import React, { useState, useCallback, useEffect, memo } from 'react';
import CurrencyComponent from './CurrencyComponent';
import KeyedListMapper from 'components/core/KeyedListMapper';
import TranslatedText from 'components/i18n/TranslatedText';
import { CURRENCY_COUNTRYFLAG_MAP } from './constants';
import { ZERO_DECIMAL_CURRENCIES, MINIMUM_PRICE_CURRENCIES, formatSmallestUnit } from 'shared/string-utils';
import {
  IPrice,
  IMultiCurrencyProps,
  MultiCurrencyContainer,
  Header,
  PriceLabel,
  CurrencyLabel,
  DefaultLabel,
  AddPriceButton,
  IErrorBody,
} from './styles';

const MultiCurrencyPriceInput = ({ setErrors, displayErrors, errors, handlePrices, initialPrices, disableRemovePrice = false }: IMultiCurrencyProps) => {
  const [prices, setPrices] = useState<IPrice[]>(initialPrices?.length ? initialPrices : [{ currency: '', default: true, value: '' }]);

  const [filteredOptions, setFilterOptions] = useState<string[]>(Object.keys(CURRENCY_COUNTRYFLAG_MAP));
  const [currentDefault, setCurrentDefault] = useState<number>(0);
  const [flag, setFlag] = useState<boolean>(false);
  const keyField = useCallback((price: IPrice) => `${price}`, []);

  const failsPrice = (value: string, currency: string) => {
    const valueNumber = Number(value);
    if (!value || value.length === 0) {
      return 'ADMIN_ENTITLEMENT_ERROR_PRICE';
    }
    if (!valueNumber || value.charAt(value.length - 1) === '.') {
      return 'ADMIN_LABEL_PRICE_ERROR';
    }
    if (ZERO_DECIMAL_CURRENCIES.includes(currency) && value.indexOf('.') !== -1) {
      return 'ADMIN_LABEL_PRICE_NO_DECIMALS';
    }
    if (MINIMUM_PRICE_CURRENCIES.includes(currency) && valueNumber < 5) {
      return 'ADMIN_LABEL_MINIMUM_PRICE';
    }
    const priceFormatUnit = formatSmallestUnit(currency || 'usd', Number(value));
    if (String(priceFormatUnit).length > 8) {
      return 'ADMIN_LABEL_PRICE_MAX_SUPPORTS';
    }
    return false;
  };

  const failsCurrency = (currency: string, index: number) => {
    if (!currency || currency.length === 0) {
      return 'ADMIN_ENTITLEMENT_ERROR_COUNTRY';
    }
    const findFirstSelectedCurrency = prices.map(item => item.currency).indexOf(currency);
    if (findFirstSelectedCurrency !== index) {
      return 'ADMIN_ENTITLEMENT_ERROR_REPEATED_COUNTRY';
    }
    return false;
  };

  const failsDefault = (value: boolean, index: number) => {
    if (index === 0) {
      const totalDefaultValues = prices.filter((item) => item.default);
      if (totalDefaultValues.length === 0) {
        return 'ADMIN_ENTITLEMENT_ERROR_NO_DEFAULT';
      }
    }
    if (value) {
      const findRepeatedValues = prices.map(item => '' + item.default).indexOf('' + value);
      if (findRepeatedValues !== index) {
        return 'ADMIN_ENTITLEMENT_ERROR_REPEATED_DEFAULT';
      }
    }
    return false;
  };

  useEffect(() => {
    setFlag(true);
    const reset = setTimeout(() => setFlag(false), 500);
    return () => clearTimeout(reset);
  }, [currentDefault]);

  useEffect(() => {
    const errorBody: IErrorBody[] = prices.map(() => {
      return { currency: false, value: false, default: false };
    });
    prices.forEach((price, index) => {
      errorBody[index] = {
        currency: failsCurrency(price.currency, index),
        value: failsPrice(price.value, price.currency),
        default: failsDefault(price.default, index),
      };
      const { currency, value, default: d  } =  errorBody[index];
      if (!currency && !value && !d) delete errorBody[index];
    });
    setErrors(errorBody);
    handlePrices(prices);
  }, [prices]);

  useEffect(() => {
    const currentCurrencies = prices.map((price) => price.currency);
    const tempOptions = Object.keys(CURRENCY_COUNTRYFLAG_MAP).filter((option: string) => !currentCurrencies.includes(option));
    setFilterOptions([...tempOptions]);
  }, [prices.length]);

  const addCurrency = () => {
    const tempPrices = [...prices];
    setPrices([...tempPrices, { currency: '', default: false, value: '' }]);
  };

  const filterOptions = (newCurrency: string, oldCurrency: string) => {
    const tempOptions = filteredOptions.filter((option: string) => option !== newCurrency);
    !oldCurrency ? setFilterOptions([...tempOptions]) : setFilterOptions([...tempOptions, oldCurrency]);
  };

  return (
    <MultiCurrencyContainer>
      <Header>
        <PriceLabel>
          <TranslatedText stringKey="ADMIN_LABEL_PRICE" />
        </PriceLabel>
        <CurrencyLabel>
          <TranslatedText stringKey="ADMIN_LABEL_CURRENCY" />
        </CurrencyLabel>
        <DefaultLabel>
          <TranslatedText stringKey="ADMIN_LABEL_CURRENCY_DEFAULT" />
        </DefaultLabel>
      </Header>
      <KeyedListMapper keyField={keyField} list={prices}>
        {
          (key: string, price: IPrice, i: number) => (
            <CurrencyComponent
              displayErrors={displayErrors}
              errors={errors[i]}
              currentDefault={currentDefault}
              disabled={flag || disableRemovePrice}
              filterOptions={filterOptions}
              index={i}
              key={`${key}-${i}`}
              options={filteredOptions}
              price={price}
              prices={prices}
              setCurrentDefault={setCurrentDefault}
              updatePrice={setPrices}
            />
          )
        }
      </KeyedListMapper>
      <AddPriceButton data-testid={'addCurrencyComponent'} onClick={addCurrency} >
        <TranslatedText stringKey="ADMIN_LABEL_ADD_PRICE" />
      </AddPriceButton>
    </MultiCurrencyContainer>
  );
};

export default memo(MultiCurrencyPriceInput);

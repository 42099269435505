import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { ActionWrapper, DeleteThemeBaseModal, DescriptionText, HeaderText, NoButton, StyledCloseButton, YesButton } from './styles';

interface DeleteThemeModalProps {
  descriptionTextKey: string;
  onBack: () => void;
  onConfirm?: () => void;
  textKey: string;
}

const DeleteThemeModal: React.FC<DeleteThemeModalProps> = ({
  descriptionTextKey,
  onBack,
  onConfirm,
  textKey,
}) => {
  return (
    <DeleteThemeBaseModal fixedDimensions={true} onClose={onBack}>
      <StyledCloseButton onClick={onBack} />
      <TranslatedText component={HeaderText} stringKey={textKey} />
      <TranslatedText component={DescriptionText} stringKey={descriptionTextKey} />
      <ActionWrapper>
        <TranslatedText component={NoButton} componentProps={{ onClick: onBack }} stringKey="ACTION_DENY" />
        <TranslatedText component={YesButton} componentProps={{ onClick: onConfirm }} stringKey="ACTION_CONFIRM" />
      </ActionWrapper>
    </DeleteThemeBaseModal>
  );
};

export default DeleteThemeModal;

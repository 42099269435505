import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import TextInput from 'components/admin2/TextInput';
import Icon from 'components/ui/Icon';
import UploadZone from 'components/admin2/UploadZone';
import {
  ADMIN_BACKGROUND_TITLE,
  ADMIN_BACKGROUND_CONTENT,
} from 'style/mixins';
import { useAdminTranslation } from 'hooks/use-translation';

const propTypes = {
  imageUrl: PropTypes.string,
  onChangeImage: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  imageUrl: null,
  text: '',
};

const Label = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledDiv = styled.div`cursor: pointer; margin-bottom: 2px; padding: 16px; ${ADMIN_BACKGROUND_TITLE}`;
const OtherStyledDiv = styled.div`display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 16px;
  ${ADMIN_BACKGROUND_CONTENT}`;
export default function Answer({ imageUrl, onChangeImage, onChangeText, text, title }) {
  const { t } = useAdminTranslation();
  const [open, setOpen] = useState(true);

  return (
    <div>
      { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */ }
      <StyledDiv
        onClick={() => setOpen(oldOpen => !oldOpen)}
      >
        <Icon name={open ? 'down-open' : 'right-open'} />
        {title}
      </StyledDiv>
      <OtherStyledDiv
        hidden={!open}
      >
        <div style={{ flex: '1 1 50%', 'margin-right': '16px' }}>
          <Label>{t('ADMIN_LABEL_OPTION')}</Label>
          <TextInput
            onChange={onChangeText}
            value={text}
          />
        </div>
        <div style={{ flex: '1 1 50%' }}>
          <Label>{t('ADMIN_LABEL_IMAGE')}</Label>
          <UploadZone
            canDrop={false}
            imagePreview={imageUrl || undefined}
            onClearImage={onChangeImage}
            onFileSubmit={onChangeImage}
            style={{ height: '150px' }}
          />
        </div>
      </OtherStyledDiv>
    </div>
  );
}

Answer.propTypes = propTypes;
Answer.defaultProps = defaultProps;

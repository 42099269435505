import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment-timezone';
// import get from 'lodash/get';
// import capitalize from 'lodash/capitalize';
// import TranslatedText from 'components/i18n/TranslatedText';
import { DESC_KEY } from 'components/admin2/MediaLibrary/constants';
import LibraryModalQuickPick from 'components/admin2/LibraryModalQuickPick';
import AdminModal from 'components/admin2/ui/AdminModal';
import QuickPickEditor from 'components/admin2/QuickPickEditor';
import { POLL_SERVICE_BASE_URL } from 'config';
import moment from 'moment-timezone';

const pollLibraryColumns = [
  {
    accessor: 'name',
    headerKey: 'ADMIN_LABEL_NAME',
    isTitle: true,
    title: 'name',
  },
  {
    accessor: 'question',
    title: 'question',
  },
  {
    accessor: 'open',
    title: 'is open',
    transformValue: value => value.toString(),
    width: 100,
  },
  {
    accessor: 'modified',
    sort: DESC_KEY,
    title: 'last modified',
    transformValue: value => moment(value).fromNow(),
    width: 150,
  },
  // {
  //   accessor: 'data.name',
  //   isTitle: true,
  //   minWidth: 200,
  //   title: 'name',
  //   transformValue: value => value || (<TranslatedText stringKey="ADMIN_LABEL_UNTITLED_POLL" />),
  // },
  // {
  //   accessor: 'data.question',
  //   title: 'question',
  //   width: 350,
  // },
  // {
  //   accessor: 'created',
  //   minWidth: 150,
  //   sort: DESC_KEY,
  //   title: 'added',
  //   transformValue: value => moment(value).fromNow(),
  // },
  // {
  //   accessor: (value) => {
  //     let kind = get(value, 'data.kind');
  //     if (kind && kind.includes('_')) {
  //       kind = kind.slice(kind.lastIndexOf('_') + 1);
  //     }
  //     return capitalize(kind);
  //   },
  //   minWidth: 100,
  //   title: 'kind',
  // },
];

// export const DEFAULT_POLL_DOC = {
//   collection: 'polls',
//   data: {
//     kind: 'two_option_poll',
//     showNumbers: false,
//   },
//   type: 'poll',
// };

// const FRESH_DOC = {
//   collection: 'polls',
//   data: {},
// };

// const setEditingType = (type) => {
//   const pollDoc = { ...FRESH_DOC };
//   switch (type) {
//     case 'poll':
//       pollDoc.data = {
//         kind: 'two_option_poll',
//         name: 'New Poll',
//         title: 'New Poll',
//       };
//       break;
//     case 'prediction':
//       pollDoc.data = {
//         kind: 'two_option_prediction',
//         name: 'New Prediction',
//         status: {
//           kind: 'open',
//         },
//         title: 'New Prediction',
//       };
//       break;
//     case 'trivia':
//       pollDoc.data = {
//         kind: 'two_option_trivia',
//         name: 'New Trivia',
//         title: 'New Trivia',
//       };
//       break;
//     default:
//       return DEFAULT_POLL_DOC;
//   }
//   pollDoc.type = type;
//   return pollDoc;
// };

export default class QuickPickModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSelectItem: PropTypes.func.isRequired,
    // type: PropTypes.string,
  };

  state = {
    editingItemId: null,
    showModal: false,
  };

  static defaultProps = {
    onClose: () => null,
  };

  selectItem = (value) => {
    const { onClose, onSelectItem } = this.props;
    onSelectItem(value);
    onClose();
  };

  handleEditItem = (quickPick) => {
    this.setState({
      editingItemId: quickPick._id,
      showModal: true,
    });
  };

  render() {
    const { onClose, onSelectItem } = this.props;
    const { showModal, editingItemId } = this.state;
    const buttonProps = {
      labelKey: 'ADMIN_LABEL_NEW_QUICK_PICK',
      onClick: () => this.setState({
        showModal: true,
      }),
    };

    return (
      <>
        <LibraryModalQuickPick
          buttonProps={buttonProps}
          columns={pollLibraryColumns}
          fetchObject={{
            // endpoint: `/legacy/multiple-choice${type ? `?type=${type}` : ''}`,
            endpoint: '',
            method: 'GET',
          }}
          onClose={onClose}
          onEditItem={this.handleEditItem}
          onSelectItem={this.selectItem}
          overrideUrl={`${POLL_SERVICE_BASE_URL}?type=prediction`}
          searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH_FOR_A_QUESTION"
          titleKey="ADMIN_LABEL_QUICK_PICK"
        />
        {showModal && (
          <AdminModal
            onClose={() => this.setState({
              editingItemId: null,
              showModal: false,
            })}
          >
            <QuickPickEditor
              editingItemId={editingItemId}
              onAdd={(id) => { onSelectItem(id); onClose(); }}
              onExit={() => this.setState({ editingItemId: null, showModal: false })}
              onSave={() => this.setState({ editingItemId: null, showModal: false })}
            />
          </AdminModal>
        )}
      </>
    );
  }
}

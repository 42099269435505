import React, { ComponentType } from 'react';
import { RendererProps } from 'components/admin2/ui/Dropdown/interfaces';
import {
  Result,
  Icon,
  IconName,
  InnerSearchInput,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';

interface IconProps {
  label: string;
  name: string;
}

function IconRenderer({
  data: icon,
  data: { name, label },
  isActive,
  isButton,
  isOpen,
  searchInputProps,
  ...props
}: RendererProps<IconProps>) {
  return (
    <Result {...props}>
      <Icon data-icon={icon} isActive={isActive} name={name} />
      {isButton && !isOpen && <TranslatedText component={IconName} stringKey={label as any} />}
      {isButton && isOpen && <InnerSearchInput {...searchInputProps} />}
    </Result>
  );
}

export default IconRenderer as ComponentType;

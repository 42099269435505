import React, { useEffect, useMemo, useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'hooks';
import isEqual from 'lodash/isEqual';
import hash from 'json-stable-stringify';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import TranslatedText from 'components/i18n/TranslatedText';
import { AdminTabTextBlock } from 'components/admin2/ui/Text';
import FeatureGate, { Feature } from 'components/core/FeatureGate';
import ShopifyStoreConnection from 'components/admin2/ShopifyStoreConnection';
import { Container, ProviderBox, SectionTitle, StyledTextInput } from './styles';
import { settingsTypes } from 'services/admin/constants';
import { get3rdPartySavedData } from 'services/app/selectors';
import { saveSiteSettings } from 'services/admin';
import { useCachedCallback } from 'hooks/use-cached-callback';
import { StreamingProviders } from './StreamingProviders';
import Divider from '../Divider';
import { useAdminTranslation } from 'hooks/use-translation';

const MemodTextInput = memo(StyledTextInput);

type TState = {
  facebookAppId: string;
  facebookPixelId: string;
  faceit: string;
  googleAnalyticsId: string;
};

const ThirdPartyTab = () => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const savedData = useSelector(get3rdPartySavedData);
  const [newData, setNewData] = useState<TState>(savedData);
  const [saved, setSaved] = useState(false);

  const handleSave = useCallback(() => {
    setSaved(true);
    dispatch(saveSiteSettings(settingsTypes.THIRD_PARTY, newData));
  }, [newData]);

  const hasChanges = useMemo(() => {
    return !isEqual(newData, savedData);
  }, [savedData, newData]);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
  }, [hash(newData)]);

  const fieldChangeHandler = useCachedCallback(
    useCallback(
      (field: keyof TState) =>
        (value: string) => setNewData(
          (prevData) => ({ ...prevData, [field]: value }),
        ),
      [],
    ),
  );

  const {
    facebookAppId,
    facebookPixelId,
    googleAnalyticsId,
  } = newData;

  return (
    <AdminBarTab>
      <AdminBarTabHeader
        hasUnsavedChanges={hasChanges}
        headerKey="ADMIN_LABEL_SETTINGS"
        onSave={handleSave}
        saved={saved}
        subHeaderKey="ADMIN_SETTINGS_THIRD_PARTY"
        subtitleKey="ADMIN_LABEL_3RD_PARTY"
      />
      <FeatureGate feature={Feature.STREAM_PROVIDER_ACCOUNT}>
        <StreamingProviders />
        <Divider />
      </FeatureGate>
      <Container>
        <SectionTitle>{t('ADMIN_THIRDPARTY_TAB_ANALYTICS_PROVIDERS')}</SectionTitle>
        <TranslatedText component={AdminTabTextBlock} stringKey="ADMIN_SETTINGS_3P_HELPER_TEXT" />
        <ProviderBox>
          <MemodTextInput
            descriptionKey="ADMIN_SETTINGS_3P_FACEBOOK_APP_DESCRIPTION"
            labelIcon="fbCircleColored"
            labelKey="ADMIN_SETTINGS_3P_FACEBOOK_APP"
            onChange={fieldChangeHandler('facebookAppId')}
            placeholderKey="ADMIN_SETTINGS_3P_FACEBOOK_APP_PLACEHOLDER"
            value={facebookAppId}
          />
        </ProviderBox>
        <ProviderBox>
          <MemodTextInput
            descriptionKey="ADMIN_SETTINGS_3P_FACEBOOK_PIXEL_DESCRIPTION"
            labelIcon="fbCircleColored"
            labelKey="ADMIN_SETTINGS_3P_FACEBOOK_PIXEL"
            onChange={fieldChangeHandler('facebookPixelId')}
            placeholderKey="ADMIN_SETTINGS_3P_FACEBOOK_PIXEL_PLACEHOLDER"
            value={facebookPixelId}
          />
        </ProviderBox>
        <ProviderBox>
          <MemodTextInput
            descriptionKey="ADMIN_SETTINGS_3P_GOOGLE_ANALYTICS_DESCRIPTION"
            labelIcon="gAnalyticsColored"
            labelKey="ADMIN_SETTINGS_3P_GOOGLE_ANALYTICS"
            onChange={fieldChangeHandler('googleAnalyticsId')}
            placeholderKey="ADMIN_SETTINGS_3P_GOOGLE_ANALYTICS_PLACEHOLDER"
            value={googleAnalyticsId}
          />
        </ProviderBox>
      </Container>
      <Divider />
      <FeatureGate feature={Feature.SHOPIFY}>
        <Container>
          <SectionTitle>{t('ADMIN_THIRDPARTY_TAB_ECOMMERCE_PROVIDERS')}</SectionTitle>
          <ProviderBox style={{ padding: '0 10px', marginTop: '15px' }}>
            <ShopifyStoreConnection />
          </ProviderBox>
        </Container>
      </FeatureGate>
    </AdminBarTab>
  );
};

export default ThirdPartyTab;

import { ILocale } from './LocalizationTabContext';

export function getLocaleLabel(option: ILocale) {
  if (!option) return '';
  if (!option.language) {
    return `${option.locale}`;
  }
  return `${option.locale} - ${option.language}`;
}

export const downloadCsvFile = (csvString: string, fileName: string): void => {
  const blob = new Blob([csvString], { type: 'text/csv' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // trigger download
  link.click();

  // revoking the object URL and clean link element
  URL.revokeObjectURL(link.href);
  link.remove();
};

import moment, { MomentInput } from 'moment-timezone';

export const ASC_KEY = 'asc';
export const DESC_KEY = 'desc';
export const NONE_KEY = 'none';

export const peopleColumns = [
  {
    accessor: 'id',
    id: 'id',
    show: false,
  },
  {
    accessor: 'personName',
    defaultThumbnail: 'gamer',
    id: 'personName',
    isRoundedThumbnail: true,
    isTitle: true,
    sort: ASC_KEY,
    thumbnailAccessor: 'personImageUrl',
    title: 'name',
    headerKey: 'ADMIN_LABEL_NAME',
  },
  {
    accessor: 'created',
    sort: DESC_KEY,
    title: 'added',
    headerKey: 'ADMIN_LABEL_ADDED',
    transformValue: (value: MomentInput) => moment(value).fromNow(),
    width: 200,
  },
];

export const groupColumns = [
  {
    accessor: 'id',
    id: 'id',
    show: false,
  },
  {
    accessor: 'groupName',
    defaultThumbnail: 'user',
    id: 'groupName',
    isRoundedThumbnail: true,
    isTitle: true,
    thumbnailAccessor: 'groupBannerUrl',
    title: 'name',
    headerKey: 'ADMIN_LABEL_NAME',
  },
  {
    accessor: 'created',
    sort: DESC_KEY,
    title: 'added',
    headerKey: 'ADMIN_LABEL_ADDED',
    transformValue: (value: MomentInput) => moment(value).fromNow(),
    width: 200,
  },
];

export const livestreamColumns = [
  {
    accessor: 'title',
    defaultThumbnail: 'play',
    isTitle: true,
    minWidth: 230,
    thumbnailAccessor: 'thumbnail',
    title: 'name',
    headerKey: 'ADMIN_LABEL_NAME',
  },
  {
    accessor: 'modified',
    minWidth: 100,
    sort: DESC_KEY,
    title: 'last modified',
    transformValue: (value: MomentInput) => moment(value).fromNow(),
  },
];

export const questColumns = [
  {
    accessor: 'title',
    defaultThumbnail: 'award',
    minWidth: 200,
    title: 'name',
    headerKey: 'ADMIN_LABEL_NAME',
    transformValue: (value: string) => value || 'Untitled quest',
  },
  {
    accessor: 'created',
    minWidth: 150,
    sort: DESC_KEY,
    title: 'added',
    headerKey: 'ADMIN_LABEL_ADDED',
    transformValue: (value: MomentInput) => moment(value).fromNow(),
  },
];

import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  ADMIN_ALERT_ERROR,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';

export const ErrorLabel = styled.div`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 9px;
  color: ${ADMIN_ALERT_ERROR};
  padding-left: 4px;
`;

export const ErrorIcon = styled(Icon).attrs({
  name: 'warning',
})`
  display: flex;
  margin-right: 5px;
  & svg {
    width: 10px;
    height: 8px;

    & > path {
      fill: ${ADMIN_ALERT_ERROR};
    }
  }
`;

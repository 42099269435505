import React, { ComponentType } from 'react';
import { ButtonViewProps, OptionViewProps } from 'components/admin2/ui/Dropdown/interfaces';
import { CURRENCY_COUNTRYFLAG_MAP } from '../constants';
import {
  CurrencyContainer,
  CurrencyString,
  StyledFlag,
} from './styles';

const CurrencyDropdownOption = ({ data = '', isButton, isOpen }: OptionViewProps<string> & ButtonViewProps<string>) => {
  return (
    isButton && isOpen ?
      null :
    (
      <CurrencyContainer>
        <StyledFlag code={CURRENCY_COUNTRYFLAG_MAP[data] || ''} height="16"/>
        <CurrencyString>{data.toUpperCase()} </CurrencyString>
      </CurrencyContainer>
    )
  );
};

export default CurrencyDropdownOption as ComponentType<OptionViewProps<string>>;

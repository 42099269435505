import isFinite from 'lodash/isFinite';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import TranslatedText from 'components/i18n/TranslatedText';
import DateTimePicker from 'components/admin2/DatetimePicker';
import IconDropdown from 'components/admin2/ui/IconDropdown';
import UploadZone from 'components/admin2/UploadZone';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import AdminTextInput from 'components/admin2/TextInput';
import AdminTextArea from 'components/admin2/TextArea';
import Schedule from 'components/admin2/ui/Schedule';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import { TextBlock } from 'components/admin2/ui/Text';
import { transformEventReferences } from 'components/panels/SchedulePanel/SchedulePanelRenderer/calendarHelpers';
import { isNil } from 'lodash';

import { ADMIN_TEXT_BODY_M_REGULAR, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import { ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import LibraryButton from './LibraryButton';
import List from './List';
import MultipleChoiceSelect from './MultipleChoiceSelect';
import QuickPickSelect from './QuickPickSelect';
import MultiQuestSelect from './MultiQuestSelect';
import ObjectSelect from './ObjectSelect';
import ObjectToggle from './ObjectToggle';
import PlaylistSelect from './PlaylistSelect';
import PollSelect from './PollSelect';
import QuestSelect from './QuestSelect';
import MultiPartySelect from './MultiPartySelect';
import Radio from './Radio';
import { get, withChange } from './utils';

const Container = styled.div`
  flex: 0 0 ${props => props.width || '100%'};
  width: ${props => props.width || '100%'};
  ${props => props.toggle && `
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  `}
`;

const StringInput = styled(AdminTextInput).attrs({
  type: 'text',
})`
  width: 100%;
`;

const TweetUrl = styled(StringInput)`
  & input {
    color: #5E5F6F;
  }
`;

const TextInput = styled(AdminTextArea)`
  height: auto;
  resize: none;
  width: 100%;
  line-height: 22px;
`;

const HtmlInput = styled(TextInput)`
  color: ${ADMIN_TEXT_100};
  opacity: 0.7;
  ${ADMIN_TEXT_BODY_M_REGULAR}
`;

const HelperText = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  margin-bottom: 8px;
`;

export default class Field extends React.Component {
  static propTypes = {
    config: PropTypes.shape({
      fieldName: PropTypes.string.isRequired,
      labelIcon: PropTypes.string,
      labelKey: PropTypes.string,
      labelPadding: PropTypes.string,
      labelWhiteIcon: PropTypes.bool,
      placeholderLabelKey: PropTypes.string,
      type: PropTypes.oneOf([
        'string', // single line string
        'number', // integer values only FUCKING BROKEN THIS OUTPUTS A STRING BC LIFE IS PAIN
        'text', // multi line string
        'image', // dropzone
        'array', // collapsible list
        'firebaseKey', // escaped string input
        'icon',
        'toggle',
        'libraryButton',
        'html', // raw HTML input
        'groupId', // select group from dropdown
        'pageSlug', // select page slug from dropdown
        'panelId', // select panel from dropdown
        'personId', // select person from dropdown
        'videoId', // select video from dropdown
        'multipleChoice', // multiple choice V2
        'pollResult', // select poll broadcast from dropdown
        'pollId', // select poll from dropdown
        'pollIds', // array of above (pollId, arrayId)
        'questId', // select quest from dropdown
        'questIds', // array of above (questId, arrayId)
        'quickPickIds', // quick pick stuff for owl
        'datetime', // datetime picker
        'tweetUrl', // validated tweet url
        'playlistId', // select playlist from dropdown
        'pageToggle', // list of pages with switch
        'personOrGroupIds',
        'schedule',
        'boolean',
        'integer', // ACTUAL WORKING NUMBER TYPE
        'radio',
        'description',
      ]).isRequired,
      typeOptions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      width: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  renderInput() {
    const {
      config,
      onChange,
      renderer,
    } = this.props;
    const {
      labelIcon,
      labelKey,
      labelPadding,
      labelWhiteIcon,
      fieldName,
      placeholderLabelKey = '',
      type,
      typeOptions: options = {},
    } = config;

    const labelProps = {
      labelIcon,
      labelKey,
      labelPadding: isNil(labelPadding) && labelIcon ? '6px' : labelPadding,
      labelWhiteIcon: isNil(labelWhiteIcon) && labelIcon ? true : labelWhiteIcon,
    };

    const { testId } = options;

    // todo: remove this HACK once necessary SchedulePanel events in old schema are updated
    const value = (type === 'personOrGroupIds' && fieldName === 'references') ?
      get(transformEventReferences(renderer), fieldName) :
      get(renderer, fieldName);

    const onChangeWith = (newValue) => {
      onChange(withChange(renderer, fieldName, newValue));
    };

    const onChangeWithSchedule = (schedule) => {
      onChange({ ...renderer, ...schedule });
    };

    const onChangeWithInput = (inputValue) => {
      onChangeWith(inputValue);
    };

    const onChangeWithNumberInput = (number) => {
      if (/^\d*$/.test(number)) {
        onChangeWith(number);
      }
    };

    const onChangeWithIntegerInput = (number) => {
      if (/^\d*$/.test(number)) {
        const raw = number;
        const intValue = raw ? Number.parseInt(raw, 10) : 0;
        onChangeWith(intValue);
      }
    };

    const onChangeWithFirebaseKey = (key) => {
      const str = key || '';
      const sanitized = str.replace(/[^a-z0-9|-]/gi, '');
      onChangeWith(sanitized);
    };

    switch (type) {
      case 'string': {
        const { helperTextKey, maxLength } = options;
        const StringInputField = placeholderLabelKey ? (
          <TranslatedText stringKey={placeholderLabelKey}>
            {
              i18nText => (
                <StringInput
                  inputTestId={testId}
                  maxLength={maxLength}
                  onChange={onChangeWithInput}
                  placeholderText={i18nText}
                  value={value || ''}
                  {...labelProps}
                />
              )
            }
          </TranslatedText>
        ) :
          (
            <StringInput
              inputTestId={testId}
              maxLength={maxLength}
              onChange={onChangeWithInput}
              value={value || ''}
              {...labelProps}
            />
          );
        return (
          <>
            { helperTextKey && (
              <HelperText labelKey={labelKey}>
                <TranslatedText stringKey={helperTextKey} />
              </HelperText>
            )}
            {StringInputField}
          </>
        );
      }

      case 'firebaseKey':
        return (
          <StringInput
            onChange={onChangeWithFirebaseKey}
            value={value || ''}
            {...labelProps}
          />
        );
      case 'datetime':
        return (
          <DateTimePicker
            onTimeChange={onChangeWith}
            timestamp={value || Date.now()}
            {...options}
            {...labelProps}
          />
        );
      case 'schedule': {
        const {
          endTimeFieldName = 'endTime',
          startTimeFieldName = 'startTime',
          timezoneFieldName = 'timezone',
        } = options;
        const schedule = {};
        schedule.endTime = get(renderer, endTimeFieldName);
        schedule.startTime = get(renderer, startTimeFieldName);
        schedule.timezone = get(renderer, timezoneFieldName);
        return (
          <Schedule
            onChange={onChangeWithSchedule}
            schedule={schedule}
            {...options}
            {...labelProps}
          />
        );
      }
      // TODO use admin2/TextArea
      case 'text':
        return (
          <TextInput
            inputTestId={testId}
            onChange={onChangeWithInput}
            rows={options.rows || 3}
            value={value || ''}
            {...labelProps}
          />
        );
      case 'image': {
        const { helperTextKey } = options;
        return (
          <UploadZone
            canDrop={options.canDrop || false}
            imagePreview={value}
            onClearImage={onChangeWith}
            onFileSubmit={onChangeWith}
            {...options}
            descriptionKey={helperTextKey}
            {...labelProps}
            inputTestId={testId}
          />
        );
      }
      case 'array':
        return (
          <List
            config={config}
            onChange={onChangeWith}
            value={value}
            {...labelProps}
          />
        );
      case 'icon':
        return (
          <IconDropdown
            onChange={onChangeWith}
            value={value}
            {...labelProps}
          />
        );
      case 'toggle':
        return (
          <LabelToggle
            checked={value}
            descriptionKey={options.helperTextKey}
            onChange={onChangeWith}
            {...labelProps}
          />
        );
      case 'pageToggle':
        return (
          <ObjectToggle
            collection="pages"
            onChange={onChangeWith}
            rendererNameField="groupName"
            value={value}
            {...labelProps}
          />
        );
      case 'libraryButton': {
        return (
          <LibraryButton
            onChange={onChangeWith}
            options={options}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'html': {
        return (
          <HtmlInput
            onChange={onChangeWithInput}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'groupId': {
        return (
          <ObjectSelect
            collection="groups"
            onChange={onChangeWith}
            rendererNameField="groupName"
            value={value}
            {...labelProps}
          />
        );
      }
      case 'pageSlug': {
        return (
          <ObjectSelect
            collection="pages"
            onChange={onChangeWith}
            rendererNameField="pageSlug"
            value={value}
            valueField="slug"
            {...labelProps}
          />
        );
      }
      case 'playlistId': {
        return (
          <PlaylistSelect
            onChange={onChangeWith}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'pollResult': {
        return (
          <PollSelect
            isResults
            onChange={onChangeWith}
            options={options}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'panelId': {
        return (
          <ObjectSelect
            collection="panels"
            onChange={onChangeWith}
            onlyShowRendererizedObjects
            rendererNameField="panelName"
            value={value}
            {...labelProps}
          />
        );
      }
      case 'personId': {
        return (
          <ObjectSelect
            collection="people"
            onChange={onChangeWith}
            onlyShowRendererizedObjects
            rendererNameField="personName"
            value={value}
            {...labelProps}
          />
        );
      }
      case 'videoId': {
        return (
          <ObjectSelect
            collection="videos-only"
            inputTestId={testId}
            onChange={onChangeWith}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'pollId': {
        return (
          <PollSelect
            onChange={onChangeWith}
            options={options}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'pollIds': {
        return (
          <MultipleChoiceSelect
            onChange={onChangeWith}
            options={options}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'quickPickIds': {
        return (
          <QuickPickSelect
            onChange={onChangeWith}
            option={options}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'questId': {
        return (
          <QuestSelect
            onChange={onChangeWith}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'questIds': {
        return (
          <MultiQuestSelect
            onChange={onChangeWith}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'number': {
        return (
          <StringInput
            onChange={onChangeWithNumberInput}
            value={value || ''}
            {...labelProps}
          />
        );
      }
      case 'integer': {
        return (
          <StringInput
            onChange={onChangeWithIntegerInput}
            value={isFinite(value) ? value.toString() : '0'}
            {...labelProps}
          />
        );
      }
      case 'tweetUrl': {
        return (
          <TweetUrl
            inputTestId={testId}
            onChange={onChangeWithInput}
            value={value || ''}
            {...labelProps}
          />
        );
      }
      case 'boolean': {
        return (
          <LabelToggle
            checked={value === undefined ? options.default : value}
            onChange={onChangeWith}
            {...labelProps}
          />
        );
      }
      case 'personOrGroupIds': {
        return (
          <MultiPartySelect
            onChange={onChangeWith}
            options={options}
            value={value}
            {...labelProps}
          />
        );
      }
      case 'radio': {
        const { name, choices, default: defaultChoice } = options;
        return (
          <Radio
            checked={value || defaultChoice}
            name={name}
            onChange={onChangeWith}
            options={choices}
            {...labelProps}
          />
        );
      }
      case 'description': {
        const { description, descriptionKey, ...descriptionOptions } = options;
        return (
          <OptionalTranslated
            component={TextBlock}
            stringKey={descriptionKey}
            {...descriptionOptions}
          >
            {description}
          </OptionalTranslated>
        );
      }
      default:
        // TODO: error out
        return (
          <div>Unknown field type: <strong>{ type }</strong></div>
        );
    }
  }

  render() {
    const {
      config,
    } = this.props;
    const {
      width,
      type,
    } = config;

    return (
      <Container toggle={type === 'boolean'} width={width}>
        {this.renderInput()}
      </Container>
    );
  }
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CollapsibleList from 'components/admin2/CollapsibleList';
import shortid from 'shortid';
import produce from 'immer';
import TranslatedText from 'components/i18n/TranslatedText';
import ActionPicker from './ActionPicker';
import {
  ACTIONS,
  DEFAULT_DATA,
} from './constants';
import ActionComponent from './ActionComponent';

const propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

const ACTION_TITLE_MAP = ACTIONS.reduce((map, obj) => {
  map[obj.kind] = obj.stringKey;
  return map;
}, {});

const ActionList = ({ list, onChange }) => {
  const [showActionPicker, setShowActionPicker] = useState(false);

  const handleClick = (kind) => {
    const defaultData = DEFAULT_DATA[kind] || {};
    const newList = produce(list, (draft) => {
      draft.push({
        ...defaultData,
        arrayId: shortid.generate(),
        kind,
      });
    });
    onChange(newList);
    setShowActionPicker(false);
  };

  const handleDelete = (index) => {
    const newList = produce(list, (draft) => {
      draft.splice(index, 1);
    });
    onChange(newList);
  };

  const handleActionUpdate = index => (value) => {
    const newList = produce(list, (draft) => {
      // because a value will always be an object
      // that can have 1-N key:values ... allows
      // for multiple datapoint returns
      draft[index] = {
        ...draft[index],
        ...value,
      };
    });
    onChange(newList);
  };

  const translatedText = (item) => <TranslatedText stringKey={ACTION_TITLE_MAP[item.kind]} />;

  return (
    <>
      <CollapsibleList
        addItemKey="ADMIN_LABEL_ADD_ACTION"
        emptyListKey="ADMIN_LABEL_NO_ACTIONS"
        headerText={translatedText}
        keyField="arrayId"
        list={list}
        onAddItem={() => setShowActionPicker(true)}
        onDelete={handleDelete}
      >
        {
          (actionData, index) => {
            const { kind } = actionData;
            return (
              <ActionComponent
                actionData={actionData}
                kind={kind}
                onChange={handleActionUpdate(index)}
              />
            );
          }
        }
      </CollapsibleList>
      {
        showActionPicker && (
          <ActionPicker
            onClick={handleClick}
            onClose={() => setShowActionPicker(false)}
          />
        )
      }
    </>
  );
};

ActionList.propTypes = propTypes;
export default ActionList;

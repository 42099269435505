import React, { useCallback, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import Dropdown from 'components/admin2/ui/Dropdown';
import { useCollection } from 'hooks';
import hash from 'json-stable-stringify';

const propTypes = {
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  sort: PropTypes.bool,
  value: PropTypes.string,
  valueField: PropTypes.string,
};

const defaultProps = {
  sort: true,
  value: null,
  valueField: '_id',
};

const DEFAULT_LABEL = 'Untitled';

export default function PlaylistSelect({
  labelKey,
  onChange: onSelectId,
  value: selectedId,
  valueField,
  sort,
}) {
  const [docs] = useCollection({ collection: 'playlists' });
  const options = useMemo(
    () => {
      const unsorted = docs
        .filter(doc => doc)
        .map((doc) => {
          const value = doc[valueField];
          const label = doc.title || DEFAULT_LABEL;
          return { label, value };
        });
      if (sort) {
        return sortBy(unsorted, option => option.label);
      }
      return unsorted;
    },
    [hash(docs)],
  );

  const value = useMemo(
    () => options.find(option => option.value === selectedId) || null,
    [hash(options), selectedId],
  );

  const onChange = useCallback((option) => onSelectId(option.value), [onSelectId]);

  return (
    <Dropdown
      labelKey={labelKey}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
}

PlaylistSelect.propTypes = propTypes;
PlaylistSelect.defaultProps = defaultProps;

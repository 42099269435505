import styled from 'styled-components';
import SearchInput from 'components/admin2/ui/SearchInput';
import BoxTag from 'components/admin2/ui/BoxTag';
import {
  ADMIN_SURFACE_1,
} from 'style/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ADMIN_SURFACE_1};
  border-radius: 4px;
`;

export const InnerSearchInput = styled(SearchInput)<{isOpen?: boolean}>`
  background: transparent;
  flex: 1 0 25%;
  height: min-content;
  &, & input {
    padding: 0;
    max-width: 100%;
  }
  & input {
    min-width: 100%;
    padding: 3px 0;
    margin-left: 5px;
    ${({ isOpen }) => !isOpen && 'cursor: pointer;'}
    & + div {
      margin-left: 5px;
    }
  }
`;

export const StyledBoxTag = styled(BoxTag)`
  padding-right: 35px;
  background: transparent;
  & button + ${InnerSearchInput} {
    margin-bottom: 10px;
  }
`;

export const Sizer = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`;

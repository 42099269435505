import React, { useState } from 'react';
import { ADMIN_ERROR_MODAL_ROOT } from 'global-ids';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  CancelButton,
  ContainerButtons,
  ContainerInputs,
  Content,
  Footer,
  Header,
  Label,
  Select,
  StyledModal,
  Title,
  Radio,
  RadioWrapper,
  RadioLabel,
  ErrorBox,
  ConfirmButton,
  Spinner,
} from './styles';
import { UpdateEntitlementPayload } from 'services/community/actions';
import moment from 'moment';
import { GrantedMethod } from 'services/gate/models';

enum RefundType {
  immediately = 'immediately',
  schedule = 'schedule',
}

enum RefundOptions {
  noRefund = 'noRefund',
  refund = 'refund',
}

const options = [
  { label: 'ADMIN_LABEL_OPTION_CANCEL_NO_REFUND_MODAL', value: RefundOptions.noRefund, index: 0 },
  { label: 'ADMIN_LABEL_OPTION_CANCEL_REFUND_MODAL', value: RefundOptions.refund, index: 1 },
];

interface LibraryModalCancelEntitlementProps {
  currentPeriodEnd?: number;
  grantedMethod?: GrantedMethod;
  onClose: () => void;
  onConfirm: (grantRefund: boolean, action: UpdateEntitlementPayload['action']) => void;
}

const LibraryModalCancelEntitlement = (props: LibraryModalCancelEntitlementProps) => {
  const { grantedMethod, currentPeriodEnd, onClose, onConfirm } = props;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refund, setRefund] = useState<{ label?: string, value?: string }>(options[0]);
  const [disableRefund, setDisableRefund] = useState(grantedMethod !== GrantedMethod.purchased);
  const [refundType, setRefundType] = useState(RefundType.immediately);

  const handleRefundType = (e: React.MouseEvent<HTMLDivElement>) => {
    const type = e.currentTarget.id as RefundType;
    setRefundType(type);
    if (type === RefundType.schedule || grantedMethod !== GrantedMethod.purchased) {
      setRefund(options[0]);
      setDisableRefund(true);
      return;
    }
    setDisableRefund(false);
  };

  const cancelSubscription = () => {
    if (!refund?.value) {
      setError(true);
      return;
    }
    const refundValue = grantedMethod === GrantedMethod.customerService ? RefundOptions.noRefund : refund.value;
    setLoading(true);
    onConfirm(
      refundValue === RefundOptions.refund,
      refundType === RefundType.immediately ? 'cancel' : 'scheduleCancel' ,
    );
  };

  return (
    <StyledModal
      id={ADMIN_ERROR_MODAL_ROOT}
      onClose={onClose}
      fixedDimensions={true}
    >
      <Header>
        <Title><TranslatedText stringKey="ADMIN_TITLE_CANCEL_ENTITLEMENT_MODAL" /></Title>
      </Header>
      <Content>
        {error && (
          <ErrorBox>
            <TranslatedText stringKey="ADMIN_LABEL_ERROR_CANCEL_ENTITLEMENT_MODAL" />
          </ErrorBox>
        )}
        <ContainerInputs>
          <Label><TranslatedText stringKey="CANCEL" /></Label>
          <div>
            <RadioWrapper>
              <Radio
                id="immediately"
                onClick={handleRefundType}
                readOnly={true}
                checked={refundType === RefundType.immediately}
              />
              <RadioLabel>
                <TranslatedText stringKey="ADMIN_LABEL_RADIO_IMMEDIATELY_CANCEL_MODAL" />
                {` ${moment().format('MMM DD, YYYY')}`}
              </RadioLabel>
            </RadioWrapper>
            <RadioWrapper>
              <Radio
                id="schedule"
                onClick={handleRefundType}
                readOnly={true}
                disabled={grantedMethod !== GrantedMethod.purchased}
                checked={refundType === RefundType.schedule}
              />
              <RadioLabel disabled={grantedMethod !== GrantedMethod.purchased}>
                <TranslatedText stringKey="ADMIN_LABEL_RADIO_SCHEDULE_CANCEL_MODAL" />
                {` ${moment(currentPeriodEnd).format('MMM DD, YYYY')}`}
              </RadioLabel>
            </RadioWrapper>
          </div>
        </ContainerInputs>
        <ContainerInputs>
          <Label><TranslatedText stringKey="REFUND" /></Label>
          <Select
            placeholderKey={'SELECT'}
            onChange={setRefund}
            translated={true}
            isDisabled={disableRefund}
            options={options}
            value={refund}
          />
        </ContainerInputs>

      </Content>
      <Footer>
        <ContainerButtons>
          <CancelButton onClick={onClose}>
            <TranslatedText stringKey="ADMIN_LABEL_DO_NOT_CANCEL_SUBSCRIPTION_MODAL" />
          </CancelButton>
          <ConfirmButton onClick={cancelSubscription} isLoading={loading} >
            <TranslatedText stringKey="ADMIN_LABEL_CANCEL_SUBSCRIPTION_MODAL" />
            {loading && <Spinner fade={false} size={9} />}
          </ConfirmButton>
        </ContainerButtons>
      </Footer>
    </StyledModal>
  );
};

export default LibraryModalCancelEntitlement;

// displays a table of playlist videos with remove functionality
// - youtube playlist videos are not removable
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import Dragger from 'components/admin2/LegacyDragger';
import { RemoveButton } from 'components/admin2/ui/Button';
import { DEFAULT_VIDEO_PLACEHOLDER_ICON } from 'shared/constants';
import { secondsToFormattedTime } from 'utils';

import {
  SPACING_SMALL,
  ADMIN_SURFACE_1,
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
} from 'style/constants';
import { youtubeVideoFallbackThumbnail } from 'services/video/utils';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

const StyledDragger = styled(Dragger)`
  max-height: 250px;
`;

const PlaylistItem = styled.div`
  align-items: center;
  border-bottom: 2px solid ${ADMIN_SURFACE_1};
  height: 75px;
  display: flex;
  padding: ${SPACING_SMALL} 0;
  width: 100%;
`;

const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    height: 45px;
  }
  flex: 0 0 65px;
`;

const Title = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
  flex: 2;
  padding: 0 ${SPACING_SMALL};
`;

const Duration = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
`;

const RemoveItem = styled(RemoveButton)`
  cursor: pointer;
  margin-left: ${SPACING_SMALL};
`;

const StyledIcon = styled(Icon)`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
`;

export default class PlaylistEditor extends React.Component {
  static propTypes = {
    isYouTubePlaylist: PropTypes.bool,
    onDragEnd: PropTypes.func.isRequired,
    onRemoveVideo: PropTypes.func.isRequired,
    videos: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    isYouTubePlaylist: false,
    videos: [],
  };

  render() {
    const { isYouTubePlaylist, onDragEnd, onRemoveVideo, videos } = this.props;

    return (
      <StyledDragger
        direction="vertical"
        droppableStyles={{ overflow: 'visible', width: '100%' }}
        innerDivStyles={{ flexDirection: 'column', maxHeight: '250px', padding: '0 24px', width: '100%' }}
        isDragDisabled={isYouTubePlaylist}
        keyField="id"
        list={videos}
        onDragEnd={onDragEnd}
      >
        {
          (video, index) => {
            const {
              durationSeconds,
              name,
              thumbnail,
              title,
            } = youtubeVideoFallbackThumbnail(video);
            return (
              <PlaylistItem>
                <Thumbnail>
                  <img alt="thumbnail" src={thumbnail || DEFAULT_VIDEO_PLACEHOLDER_ICON} />
                </Thumbnail>
                <Title>{ name || title }</Title>
                <Duration>{ secondsToFormattedTime(durationSeconds) }</Duration>
                {
                    !isYouTubePlaylist && (
                      <RemoveItem onClick={() => onRemoveVideo(index)}>
                        <StyledIcon name="cancel" />
                      </RemoveItem>
                    )
                  }
              </PlaylistItem>
            );
          }
        }
      </StyledDragger>
    );
  }
}

/* tslint:disable: jsx-boolean-value */
import React, { ComponentProps, forwardRef } from 'react';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import withPadding from 'components/core/withPadding';
import withLabel from 'components/core/withLabel';
import {
  Container,
  StyledLabel,
  LabelToggleStyleProps,
} from './styles';

interface LabelToggleProps extends ComponentProps<typeof LabeledToggle>, LabelToggleStyleProps {
  noSpaceBetween?: boolean;
}

const LabeledToggle = withLabel(ToggleSwitch, { compact: true, styledLabel: StyledLabel });

const LabelToggle = forwardRef<HTMLInputElement, LabelToggleProps>(({
  className,
  verticalCenter,
  noSpaceBetween,
  ...props
}, ref) => {
  return (
    <Container
      className={className}
      noSpaceBetween={noSpaceBetween}
      verticalCenter={!(props.description || props.descriptionKey)}
    >
      <LabeledToggle ref={ref} {...props} />
    </Container>
  );
});

export default withPadding(LabelToggle, '10px 0');

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DROPDOWN_LABEL } from 'css-classes';
import hash from 'json-stable-stringify';
import { useAdminTranslation } from 'hooks/use-translation';
import { createCustomNavigation, deleteNavigation } from 'services/navigationv2';
import { ModalKinds } from 'services/modals/types';
import { dismissModal, showModal } from 'services/modals';
import { INavigationBlockDropdownOption } from 'components/header/NavigationBar/BlockSettings/NavigationBlockDropdown';
import { NAVIGATION_TYPE } from 'models/INavigation';
import { OptionViewProps } from '../../interfaces';
import { PlusIcon, SiteNavigationCheckIcon } from '../../icons';
import { ActionsRow, DeleteActionTooltip, DuplicateActionTooltip, HitEnterText, InputRow, ItemNameInput, ItemNameText, Left, RenameActionTooltip, SiteNavigationLabel } from './styles';
import { NAV_NAME_MAX_LENGTH, NAV_NAME_MIN_LENGTH } from 'services/navigationv2/utils';

function SiteNavigationOptionView<T>({
  isAdmin, isFirstRow, isLastRow, name, ...props
}: OptionViewProps<T> & { isFirstRow: boolean, isLastRow: boolean, name: string }) {
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();
  const [showActions, setShowActions] = useState(false);
  const item = props.data as INavigationBlockDropdownOption;
  const isEditing = item.isEditing;
  const isApplied = item.isApplied;
  const isNormalOption = !isFirstRow && !isLastRow;

  const [itemName, setItemName] = useState(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const classNames = `${DROPDOWN_LABEL} ${isFirstRow ? 'first-row' : ''} ${isLastRow ? 'last-row' : ''} ${isEditing ? 'is-editing' : ''}`;

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const handleMouseEnter = useCallback(() => { setShowActions(true); }, []);
  const handleMouseLeave = useCallback(() => { setShowActions(false); }, []);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (itemName.trim().length < NAV_NAME_MIN_LENGTH) return;
      if (itemName.trim().length > NAV_NAME_MAX_LENGTH) return;

      item.renameCustomNavigation?.(itemName.trim());
      item.setEditableCustomNavId!(); // close editing mode
    }
  }, [itemName, hash(item)]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isEditing) event.stopPropagation();
  }, [isEditing]);

  const handleItemNameChange = useCallback((value: string) => {
    setItemName(value.slice(0, NAV_NAME_MAX_LENGTH));
  }, []);

  const duplicateNavigation = useCallback(() => {
    dispatch(createCustomNavigation({ navigationToCopyDataFrom: item }));
  }, [dispatch, hash(item)]);

  const renameCustomNavigation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    item.setEditableCustomNavId!();
  }, [hash(item)]);

  const deleteCustomNavigation = useCallback(() => {
    dispatch(
      showModal({
        kind: ModalKinds.adminConfirmation,
        data: {
          onConfirmClick: () => {
            dispatch(deleteNavigation(item._id!));
            dispatch(dismissModal('adminConfirmation'));
          },
          titleKey: 'ADMIN_NAVIGATION_BLOCK_CARDS_MENU_CUSTOM_NAVIGATION_DELETE',
        },
      }),
    );
  }, [dispatch, item._id]);

  const renderActions = useCallback(() => {
    const isCustom = item.type === NAVIGATION_TYPE.custom;
    return (
      <ActionsRow>
        <DuplicateActionTooltip
          id={`navigation_dropdown-${isCustom ? 'custom' : 'default'}_duplicate`}
          data-testid={`navigation_dropdown-${isCustom ? 'custom' : 'default'}_duplicate`}
          tooltipKey="ADMIN_NAVIGATION_BLOCK_DROPDOWN_MENU_ACTION_DUPLICATE_NAVIGATION"
          onClick={duplicateNavigation}
        />
        {isCustom && (
          <>
            <RenameActionTooltip
              id="navigation_dropdown-custom_rename"
              data-testid="navigation_dropdown-custom_rename"
              tooltipKey="ADMIN_NAVIGATION_BLOCK_DROPDOWN_MENU_ACTION_RENAME"
              onClick={renameCustomNavigation}
            />
            <DeleteActionTooltip
              id="navigation_dropdown-custom_delete"
              data-testid="navigation_dropdown-custom_delete"
              tooltipKey="ADMIN_NAVIGATION_BLOCK_DROPDOWN_MENU_ACTION_DELETE_NAVIGATION"
              onClick={deleteCustomNavigation}
            />
          </>
        )}
      </ActionsRow>
    );
  }, [item.type, duplicateNavigation, renameCustomNavigation, deleteCustomNavigation]);


  return (
    <SiteNavigationLabel
      isActive={isApplied}
      isAdmin={isAdmin}
      className={classNames}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      <Left>
        {isNormalOption && <SiteNavigationCheckIcon isAdmin={isAdmin} isActive={isApplied} />}
        {isLastRow && <PlusIcon isAdmin={isAdmin} />}
        {isEditing ? (
          <InputRow>
            <ItemNameInput
              setInputRef={inputRef}
              value={itemName}
              onChange={handleItemNameChange}
              onKeyDown={handleKeyDown}
              padding="0"
            />
            <HitEnterText>{t('ADMIN_NAVIGATION_BLOCK_DROPDOWN_HIT_ENTER')}</HitEnterText>
          </InputRow>
        ) : (
          <ItemNameText
            maxLength={showActions ? NAV_NAME_MAX_LENGTH - 3 : NAV_NAME_MAX_LENGTH}
          >
            {name}
          </ItemNameText>
        )}
      </Left>
      {isNormalOption && showActions && renderActions()}
    </SiteNavigationLabel>
  );
}

export default SiteNavigationOptionView;

import styled from 'styled-components';
import React from 'react';
import { SPACING_X_SMALL } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

const InputLabel = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  letter-spacing: 1px;
  margin-bottom: ${SPACING_X_SMALL};
  text-transform: uppercase;
`;

// eslint-disable-next-line react/prop-types
export default props => (
  <InputLabel {...props} />
);

export const SERVER_ERRORS_MAP = {
  DUPLICATED_SKU: 'duplicate key error collection',
  LIMIT_PRICE: 'price cannot be more than 100000',
};

export const NAME_ERRORS = {
  NO_NAME: 'ADMIN_ENTITLEMENT_ERROR_NAME',
};

export const SKU_ERRORS = {
  DUPLICATED_SKU: 'ADMIN_ENTITLEMENT_ERROR_DUPLICATED_SKU',
  NO_SKU: 'ADMIN_ENTITLEMENT_ERROR_SKU',
};

export const DATE_ERRORS = {
  NO_DATE: 'ADMIN_LABEL_CALENDAR_NO_DATE',
};

export const FREQUENCY_OPTIONS = [
  {
    stringKey: 'ADMIN_LABEL_FREQUENCY_MONTHLY_RECURRING',
    target: { value: 'month' },
    testId: 'monthOption',
  },
  {
    stringKey: 'ADMIN_LABEL_FREQUENCY_ANNUALLY_RECURRING',
    target: { value: 'year' },
    testId: 'yearOption',
  },
];

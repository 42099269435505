import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'hooks';
import { getShopifyCatalogs, isCollectionLoading } from 'services/shopify/selectors';
import Dropdown from 'components/admin2/ui/Dropdown';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { useAdminTranslation } from 'hooks/use-translation';

const ShopifyCollectionSelector = (props) => {
  const { t } = useAdminTranslation();
  const { onChange, renderer } = props;
  const { collection } = renderer;
  const updatePanel = useCallback((key, clear) => (val) => {
    onChange({
      ...renderer,
      [clear]: undefined,
      [key]: val,
    });
  }, [renderer, onChange]);
  const collectionsLoading = useSelector(isCollectionLoading);
  const { collections } = useSelector(getShopifyCatalogs);

  return (collectionsLoading && !collections.length ?
    <LoadingSpinner name="line-spin-fade-loader" /> :
    (
      <Dropdown
        isSearchable={false}
        labelKey="ADMIN_CARD_SHOPIFY_SELECT_COLLECTION"
        name="ADMIN_CARD_SHOPIFY_SELECT_COLLECTION"
        onChange={updatePanel('collection', 'product')}
        options={collections}
        placeholder={t('ADMIN_SHOPIFY_PANEL_EDITOR_SELECT_COLLECTION')}
        value={collection}
      />
    ));
};

ShopifyCollectionSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  renderer: PropTypes.shape({
    collection: PropTypes.shape({
      handle: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ShopifyCollectionSelector;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addFolderToCustomNavigation, createCustomNavigation, setCreateLinkForCustomNavigation } from 'services/navigationv2';

const useCustomOptionViews = () => {

  const dispatch = useDispatch();

  const siteNavigationHandler = useCallback((isFirstRow: boolean, isLastRow: boolean, data: Record<string, any>) => {
    if (isFirstRow) return;
    if (isLastRow) {
      dispatch(createCustomNavigation({ navName: data.navName }));
      return;
    }
    if (data.isEditing) return;
  }, []);

  const customNavigationHandler = useCallback((isFirstRow: boolean, isSecondLastRow: boolean, isLastRow: boolean, data: Record<string, any>) => {
    if (isFirstRow) return;
    if (isSecondLastRow) {
      dispatch(addFolderToCustomNavigation(data.folderName));
      return;
    }
    if (isLastRow) {
      dispatch(setCreateLinkForCustomNavigation({ isAddingLink: true, linkName: data.linkName }));
      return;
    }
    if (data.isEditing) return;
  }, []);

  return { siteNavigationHandler, customNavigationHandler };
};

export default useCustomOptionViews;

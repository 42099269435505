import styled from 'styled-components';
import {
  ADMIN_SURFACE_3,
  ADMIN_TEXT_100,
  SPACING_MEDIUM,
  ADMIN_TEXT_300,
  ADMIN_TEXT_200,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_6,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';

export const Header = styled.div`
  background-color: ${ADMIN_SURFACE_3};
  color: ${ADMIN_TEXT_100};
  padding: 0 ${SPACING_MEDIUM};
  min-height: 75px;
  max-height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const SubHeader = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD}
  background-color: ${ADMIN_SURFACE_4};
  padding: 0 ${SPACING_MEDIUM};
  display: flex;
  align-items: center;
  min-height: 41px;
  height: 41px;
  border-top: 1px solid ${ADMIN_SURFACE_6};
  border-bottom: 1px solid ${ADMIN_SURFACE_6};
  color: ${ADMIN_TEXT_200};
`;

export const SubHeaderText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  color: ${ADMIN_TEXT_300};
  text-transform: uppercase;
`;

export const Subtitle = styled.div`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  color: ${ADMIN_TEXT_200};
  text-transform: capitalize;
`;

export const NavLinkContainer = styled.span`
  ${({ onClick }) => onClick && `
    cursor: pointer;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-left: 10px;
  margin-left: auto;
  & > * {
    margin-left: 10px;
  }
`;

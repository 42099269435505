import React, { FC } from 'react';
import { lazy } from 'utils/lazy';
import videoListIcon from 'assets/icons/nav2-channels.svg';
import TranslatedText from 'components/i18n/TranslatedText';
import type { TModalActionsMode } from './actionsMap';
import {
  Title,
  ActionsContainer,
  ModalHeader,
  HeaderButton,
  SelectedCountBadge,
  HeaderActionsSpacer,
  HeaderActionsSeparator,
} from './styles';
import { useSelector } from 'react-redux';
import { getAdminSurface1, getAdminSurface3 } from 'services/themes';
import { useAdminTranslation } from 'hooks/use-translation';

const videoListIconImgUrl = lazy(
  () => `data:image/svg+xml,${encodeURIComponent(videoListIcon)}`,
);

export const Header: FC<{
  handleBulkDelete:  () => unknown;
  handleBulkEdit: () => unknown;
  handleClose: () => unknown;
  mode?: TModalActionsMode;
  selectedItems: Set<string>;
}> = ({
  handleBulkDelete,
  handleBulkEdit,
  handleClose,
  mode,
  selectedItems,
}) => {
  const { t } = useAdminTranslation();
  const adminSurface1 = useSelector(getAdminSurface1);
  const adminSurface3 = useSelector(getAdminSurface3);

  return (
    <ModalHeader>
      <Title>
        <img src={videoListIconImgUrl()} alt="" width={28.67} />
        {
          mode?.headerTextKey
            ? <TranslatedText stringKey={mode.headerTextKey} />
            : t('ADMIN_LABEL_VIDEO_LIBRARY')
        }
      </Title>
      <ActionsContainer>
        {selectedItems.size > 0 && (
          <>
            <SelectedCountBadge>
              {selectedItems.size}
            </SelectedCountBadge>
            <HeaderActionsSpacer />
              {t('ADMIN_MODAL_VIDEO_METADATA_SELECTED')}
            <HeaderActionsSpacer />
            <HeaderButton
              onClick={handleBulkEdit}
            >
              {t('ADMIN_ACTION_BULK_EDIT')}
            </HeaderButton>
            <HeaderActionsSpacer size={0.5} />
            <HeaderButton
              onClick={handleBulkDelete}
            >
              {t('ADMIN_ACTION_BULK_DELETE')}
            </HeaderButton>
            <HeaderActionsSpacer />
            <HeaderActionsSeparator />
            <HeaderActionsSpacer />
          </>
        )}
        <HeaderButton
          background={adminSurface1}
          hoverBackground={adminSurface3}
          onClick={handleClose}
        >
          {t('ADMIN_ACTION_CANCEL')}
        </HeaderButton>
      </ActionsContainer>
    </ModalHeader>
  );
};

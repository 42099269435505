import React from 'react';
import TabbedView, { Tab } from 'components/admin2/ui/TabbedView';
import KindCatalog, { Kind } from 'components/admin2/ui/KindCatalog';
import Library, { LibraryItem, ItemPropGetters, SearchTextProps, ClickHandler } from 'components/admin2/ui/Library';

interface CatalogLibraryProps<T, CH = ClickHandler<T>> extends ItemPropGetters<T>, SearchTextProps {
  actionsWidth?: number;
  activeTabIndex?: number;
  catalogItems: Kind[];
  catalogType: string;
  libraryItems: LibraryItem<T>[];
  libraryVerticalActions?: boolean;
  onCatalogPreview?: (id: string, highlighted: boolean) => void;
  onCatalogUse: (id: string) => void;
  onLibraryAdd?: CH;
  onLibraryBroadcast?: CH;
  onLibraryDelete?: CH;
  onLibraryEdit?: CH;
  onLibrarySelect?: CH;
  onSearch?: (value: string) => void;
  onTabChange?: (tabIndex: number, tabNameKey: string) => void;
  testIdCatalog?: string;
  testIdCatalogAdd?: string;
  testIdCatalogBroadcast?: string;
  testIdCatalogDelete?: string;
  testIdCatalogEdit?: string;
  testIdCatalogItem?: string;
  testIdCatalogItemTitle?: string;
  testIdCatalogPreview?: string;
  testIdCatalogRemove?: string;
  testIdLibrary?: string;
  testIdLibraryAdd?: string;
  testIdLibraryBroadcast?: string;
  testIdLibraryDelete?: string;
  testIdLibraryEdit?: string;
  testIdLibraryItem?: string;
  testIdLibraryItemTitle?: string;
  testIdLibraryItemType?: string;
  testIdLibraryPreview?: string;
  testIdLibraryRemove?: string;
  testIdLibrarySearchInput?: string;
}

const tabs = {
  catalog: 'ADMIN_LABEL_CATALOG',
  library: 'ADMIN_LABEL_LIBRARY',
};

export default function CatalogLibrary<T>({
  actionsWidth,
  activeTabIndex,
  catalogItems,
  catalogType,
  disableInternalSearch,
  libraryItems,
  libraryVerticalActions,
  getItemIcon,
  getItemLastModified,
  getItemName,
  getItemTypeKey,
  onCatalogPreview,
  onCatalogUse,
  onLibraryAdd,
  onLibraryBroadcast,
  onLibraryDelete,
  onLibraryEdit,
  onLibrarySelect,
  onSearch,
  onTabChange,
  searchHelperKey,
  searchPlaceholderKey,
  testIdCatalog,
  testIdCatalogAdd,
  testIdCatalogBroadcast,
  testIdCatalogDelete,
  testIdCatalogEdit,
  testIdCatalogItem,
  testIdCatalogItemTitle,
  testIdCatalogPreview,
  testIdCatalogRemove,
  testIdLibrary,
  testIdLibraryAdd,
  testIdLibraryBroadcast,
  testIdLibraryDelete,
  testIdLibraryEdit,
  testIdLibraryItem,
  testIdLibraryItemTitle,
  testIdLibraryItemType,
  testIdLibraryPreview,
  testIdLibraryRemove,
  testIdLibrarySearchInput,
}: CatalogLibraryProps<T>) {
  const handleTabChange = (index: number = 0) => {
    onTabChange?.(index, index ? tabs.library : tabs.catalog);
  };

  return (
    <TabbedView activeIndex={activeTabIndex} onTabChange={handleTabChange}>
      <Tab iconName="catalog" data-testid={testIdCatalog} titleKey={tabs.catalog}>
        <KindCatalog
          kinds={catalogItems}
          onConfirmClick={onCatalogUse}
          onPreviewClick={onCatalogPreview}
          testIdAdd={testIdCatalogAdd}
          testIdBroadcast={testIdCatalogBroadcast}
          testIdDelete={testIdCatalogDelete}
          testIdEdit={testIdCatalogEdit}
          testIdItem={testIdCatalogItem}
          testIdPreview={testIdCatalogPreview}
          testIdRemove={testIdCatalogRemove}
          typeNameTestId={testIdCatalogItemTitle}
          type={catalogType}
        />
      </Tab>
      <Tab iconName="library" data-testid={testIdLibrary} titleKey={tabs.library}>
        <Library
          actionsWidth={actionsWidth}
          disableInternalSearch={disableInternalSearch}
          getItemIcon={getItemIcon}
          getItemLastModified={getItemLastModified}
          getItemName={getItemName}
          getItemTypeKey={getItemTypeKey}
          items={libraryItems}
          onAdd={onLibraryAdd}
          onBroadcast={onLibraryBroadcast}
          onDelete={onLibraryDelete}
          onEdit={onLibraryEdit}
          onSelect={onLibrarySelect}
          onSearch={onSearch}
          searchHelperKey={searchHelperKey}
          searchPlaceholderKey={searchPlaceholderKey}
          testIdAdd={testIdLibraryAdd}
          testIdBroadcast={testIdLibraryBroadcast}
          testIdDelete={testIdLibraryDelete}
          testIdEdit={testIdLibraryEdit}
          testIdItem={testIdLibraryItem}
          testIdItemTitle={testIdLibraryItemTitle}
          testIdItemType={testIdLibraryItemType}
          testIdPreview={testIdLibraryPreview}
          testIdRemove={testIdLibraryRemove}
          testIdSearchInput={testIdLibrarySearchInput}
          verticalActions={libraryVerticalActions}
        />
      </Tab>
    </TabbedView>
  );
}

import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useAjax, useSelector, useRealtimeDocumentList, useDispatch } from 'hooks';
import JsonDebug from 'components/dev/JsonDebug';
import { PLAYLIST_SERVICE_BASE_URL } from 'config';
import { getSiteId } from 'services/app/selectors';
import AdminModal from 'components/admin2/ui/AdminModal';
import TranslatedText from 'components/i18n/TranslatedText';
import PlaylistVideoList from 'components/admin2/videoPanels/PlaylistVideoList';
import VideoLibraryModal from 'components/admin2/videoPanels/VideoLibraryModal';
import videoLibraryModalTypes from 'components/modals/videoLibraryModalTypes';
import { secondsToFormattedTime } from 'utils';
import Button from 'components/admin2/ui/Button';
import BackButton from 'components/admin2/NavControls/BackButton';
import hash from 'json-stable-stringify';
import Label from 'components/admin2/ui/Label';
import useVideoEditor from 'hooks/use-video-editor';
import { showAdminErrorModal } from 'services/modals';
import {
  AddVideoButton,
  Body,
  Container,
  Header,
  InfoLabel,
  InfoValue,
  InputContainer,
  NoPlaylistVideosNotice,
  PlaylistInfoContainer,
  PlaylistVideosContainer,
  SaveButtonWrapper,
  StyledTextInput,
  StyledStatusIndicator,
  SubHeader,
  InfoContainer,
  ButtonContainer,
} from './styles';

const calculatePlaylistDuration = (videos) => {
  return videos.reduce((acc, video) => {
    return acc + (video.durationSeconds || 0);
  }, 0);
};

const FlexDiv = styled.div`display: flex;`;

export const PlaylistEditorModal = (props) => {
  const { id: playlistId, onAfterSave, onClose, skipRefresh } = props;
  const dispatch = useDispatch();
  const siteId = useSelector(getSiteId);
  const [isYoutubeImportOpen, setIsYoutubeImportOpen] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDecription] = useState('');
  const [editingMembers, setEditingMembers] = useState([]);
  const [playlistDuration, setPlaylistDuration] = useState();
  const handlePlaylistNameChange = useCallback((playlistName) => setTitle(playlistName), []);
  const handleYoutubeUrl = useCallback((url) => {
    setYoutubeUrl(url);
    setFetching(true);
  }, []);

  const { upsertPlaylist } = useVideoEditor();
  const { data: youtubeData, loaded: youtubeLoaded, error: youtubeError } = useAjax({
    body: { skipInsert: true, url: youtubeUrl },
    method: 'POST',
    url: (isYoutubeImportOpen && youtubeUrl !== null) ? `${PLAYLIST_SERVICE_BASE_URL}/external/import/youtube` : null,
  }, [youtubeUrl]);

  const { data: playlistData, loaded: playlistLoaded, error: playlistGetError } = useAjax({
    method: 'GET',
    url: playlistId !== null ? `${PLAYLIST_SERVICE_BASE_URL}/${playlistId}` : null,
  });

  useEffect(() => {
    if (playlistData && playlistLoaded && !playlistGetError) {
      const { members, title: playlistTitle, description: playlistDescription = '' } = playlistData;
      setTitle(playlistTitle);
      setEditingMembers(members);
      setDecription(playlistDescription);
      setLoaded(playlistLoaded);
    }
  }, [playlistId, hash(playlistData), hash(playlistGetError), playlistLoaded]);

  useEffect(() => {
    setFetching(!!youtubeUrl);
    if (youtubeError) {
      setFetching(false);
    }
    if (youtubeLoaded && youtubeData && !youtubeError) {
      const { members, title: playlistTitle, description: playlistDescription = '' } = youtubeData;
      setTitle(playlistTitle);
      setEditingMembers(members);
      setDecription(playlistDescription);
      setLoaded(youtubeLoaded);
    }
  }, [hash(youtubeData), hash(youtubeError), youtubeLoaded, youtubeUrl]);

  const addVideoToPlaylist = useCallback((video) => {
    const newVideoArray = [...editingMembers, video];
    const duration = calculatePlaylistDuration(newVideoArray);
    setPlaylistDuration(duration);
    setLoaded(false);
    setEditingMembers(newVideoArray);
  }, [hash(editingMembers)]);

  const { loadedDocuments } = useRealtimeDocumentList({
    collection: 'video',
    items: editingMembers,
  });

  useEffect(() => {
    if (loadedDocuments.length && loaded) {
      setEditingMembers(loadedDocuments);
      setIsYoutubeImportOpen(false);
    }
  }, [hash(loadedDocuments), loaded]);

  const removeVideo = useCallback((index) => {
    const newVideoArray = [
      ...editingMembers.slice(0, index),
      ...editingMembers.slice(index + 1),
    ];
    const duration = calculatePlaylistDuration(newVideoArray || []);
    setPlaylistDuration(duration);
    setLoaded(false);
    setEditingMembers(newVideoArray);
  }, [hash(editingMembers)]);

  const reorderVideos = useCallback((videoOrder) => {
    setLoaded(false);
    setEditingMembers(videoOrder);
  });

  const handleSave = useCallback(async () => {
    const members = editingMembers.map(({ _id, id }) => ({
      id: _id ?? id,
      schema: 'video',
    }));
    const playlist = {
      _id: playlistId,
      description,
      members,
      siteId,
      title,
    };

    try {
      await upsertPlaylist(playlist);
    } catch (err) {
      const error = err.response?.message || err.message || 'Error saving playlist';
      dispatch(showAdminErrorModal(error));
      return;
    }
    onClose();
    onAfterSave?.();
  }, [playlistId, hash(editingMembers), siteId, title]);

  return (
    <Container>
      <Header>
        <Label
          labelKey="ADMIN_LABEL_CREATE_YOUR_PLAYLIST"
          spacing="6px 30px 6px 0"
          textSize="h3"
        />
        <ButtonContainer>
          {
            (!isYoutubeImportOpen && !title) && (
              <TranslatedText
                component={Button}
                componentProps={{ onClick: () => setIsYoutubeImportOpen(true) }}
                stringKey="ADMIN_ACTION_IMPORT_YOUTUBE_PLAYLIST"
              />
            )
          }
          { !isYoutubeImportOpen && (
          <FlexDiv>
            <BackButton onClick={onClose} />
          </FlexDiv>
          )}
          {
            title && (
              <SaveButtonWrapper>
                <TranslatedText
                  component={Button}
                  componentProps={{ onClick: () => handleSave() }}
                  stringKey="ADMIN_ACTION_SAVE"
                />
              </SaveButtonWrapper>
            )
          }
        </ButtonContainer>
      </Header>
      {
        (!isYoutubeImportOpen || loaded === true) && (
          <>
            <SubHeader>
              <InputContainer>
                <StyledTextInput
                  labelKey="ADMIN_LABEL_NAME"
                  onChange={handlePlaylistNameChange}
                  placeholderKey="ADMIN_PLACEHOLDER_NAME_YOUR_PLAYLIST"
                  value={title}
                />
              </InputContainer>
              <PlaylistInfoContainer>
                <InfoContainer>
                  <InfoLabel><TranslatedText stringKey="ADMIN_LABEL_VIDEOS" /></InfoLabel>
                  <InfoValue>{ editingMembers.length }</InfoValue>
                </InfoContainer>
                <InfoContainer>
                  <InfoLabel><TranslatedText stringKey="ADMIN_LABEL_DURATION" /></InfoLabel>
                  <InfoValue>
                    { playlistDuration && secondsToFormattedTime(playlistDuration) }
                  </InfoValue>
                </InfoContainer>
              </PlaylistInfoContainer>
            </SubHeader>
            <Body>
              <PlaylistVideosContainer>
                {
                 (editingMembers.length > 0 && editingMembers[0].title) && (
                 <PlaylistVideoList
                   onDragEnd={reorderedVideos => reorderVideos(reorderedVideos)}
                   onRemoveVideo={index => removeVideo(index)}
                   videos={editingMembers}
                 />
                 )
                }
                {
                  editingMembers.length === 0 && (
                  <NoPlaylistVideosNotice>
                    <TranslatedText stringKey="ADMIN_PLACEHOLDER_YOUR_PLAYLIST_IS_EMPTY" />
                  </NoPlaylistVideosNotice>
                  )
                }
              </PlaylistVideosContainer>
            </Body>
            <AddVideoButton data-testid="addScheduleVideoButton" onClick={() => setIsVideoModalOpen(true)}>
              <TranslatedText stringKey="ADMIN_LABEL_ADD_VIDEO" />
            </AddVideoButton>
          </>
        )
      }
      {
        isVideoModalOpen && (
          <AdminModal fixedDimensions onClose={() => setIsVideoModalOpen(false)}>
            <VideoLibraryModal
              onClose={() => setIsVideoModalOpen(false)}
              onSelectItem={addVideoToPlaylist}
              skipRefresh={skipRefresh}
              type={videoLibraryModalTypes.PLAYLIST_EDIT}
            />
          </AdminModal>
        )
      }
      <JsonDebug value={editingMembers} />
      {
        (isYoutubeImportOpen && !loaded) && (
          <SubHeader>
            <InputContainer>
              <StyledStatusIndicator
                errorKey={youtubeError ? 'ADMIN_PLAYLIST_EDITOR_INVALID_YOUTUBE_URL' : null}
                fetching={fetching}
              />
              <StyledTextInput
                onChange={handleYoutubeUrl}
                placeholderKey="ADMIN_PLACEHOLDER_ENTER_YOUTUBE_PlAYLIST_URL"
                type="text"
                value={youtubeUrl}
              />
            </InputContainer>
          </SubHeader>
        )
      }
    </Container>
  );
};

PlaylistEditorModal.propTypes = {
  id: PropTypes.string,
  onAfterSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  skipRefresh: PropTypes.bool,
};

PlaylistEditorModal.defaultProps = {
  id: null,
  onAfterSave: () => {},
  skipRefresh: false,
};

export default PlaylistEditorModal;

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import AjaxProvider from 'components/core/AjaxProvider';
import { ADMIN_SERVICE_BASE_URL } from 'config';
import { useSelector } from 'hooks';
import { getPrimaryToken } from 'services/auth';

const propTypes = {
  children: PropTypes.func.isRequired,
  collection: PropTypes.oneOf([
    'cards',
    'groups',
    'pages',
    'panels',
    'people',
    'polls',
    'videos-only',
    'poll-results',
    'playlists',
  ]).isRequired,
  limit: PropTypes.number,
  pageOffset: PropTypes.number,
  refreshKey: PropTypes.string,
  search: PropTypes.string,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
};

const transformCollection = (collection) => {
  switch (collection) {
    case 'polls':
      return 'multiple-choice';
    default:
      return collection;
  }
};

const defaultProps = {
  limit: 1000,
  pageOffset: 0,
  refreshKey: undefined,
  search: undefined,
  sortBy: 'last_modified',
  sortDirection: 'desc',
};

const EMPTY_ARRAY = [];

const LegacyCollectionProvider = ({
  children: render,
  collection: rawCollection,
  limit,
  pageOffset,
  search,
  refreshKey,
  sortDirection,
  sortBy,
}) => {
  // hooks
  const token = useSelector(getPrimaryToken);
  const cacheBuster = useMemo(
    () => {
      return Date.now();
    },
    [refreshKey],
  );

  // fetch params
  const collection = transformCollection(rawCollection);
  const url = `${ADMIN_SERVICE_BASE_URL}/legacy/${collection}?_=${cacheBuster}`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const params = {
    limit,
    search,
    skip: limit * pageOffset,
    sortBy,
    sortDirection,
  };

  return (
    <AjaxProvider
      headers={headers}
      params={params}
      refreshKey={refreshKey}
      url={url}
    >
      { (data, error, loaded) => {
        const results = (loaded && !error) ? (data.results || EMPTY_ARRAY) : null;
        const count = data?.count || null;
        return render(results, count, error, loaded);
      } }
    </AjaxProvider>
  );
};

LegacyCollectionProvider.propTypes = propTypes;
LegacyCollectionProvider.defaultProps = defaultProps;
export default LegacyCollectionProvider;

import Button from 'components/ui/Button';
import { TEXT_BODY_S_BOLD } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  min-width: 167px;
  height: 32px;
  padding: 14px 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${TEXT_BODY_S_BOLD}
`;

export const EntitlementPurchaseText = styled.span`
  line-height: 15px;
  margin-right: 34px;
`;

export const EntitlementPrice = styled.strong`
  line-height: 14px;
`;

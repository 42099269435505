import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AdminModal from 'components/admin2/ui/AdminModal';
import QuestLibraryModal from 'components/admin2/QuestLibraryModal';
import QuestEditor from 'components/admin2/QuestEditor';
import RealtimeDocument from 'components/core/RealtimeDocument';
import Row from 'components/admin2/ui/Library/Row';
import withLabel from 'components/core/withLabel';
import { useAdminTranslation } from 'hooks/use-translation';

const propTypes = {
  className: PropTypes.string,
  draggable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  value: PropTypes.string,
};

const defaultProps = {
  className: '',
  draggable: false,
  onDelete: null,
  value: null,
};

function QuestSelect({
  className,
  draggable,
  onChange,
  onDelete,
  value: id,
}) {
  const { t } = useAdminTranslation();
  const [open, setOpen] = useState(false);
  const [selectedQuestDoc, setSelectedQuestDoc] = useState(null);

  return (
    <div className={className}>
      <RealtimeDocument collection="quest" id={id}>
        {(doc, loaded) => {
          if (!id) {
            return t('ADMIN_RENDERER_EDITOR_NO_QUEST_SELECTED');
          }
          if (!loaded) {
            return t('ADMIN_IMAGE_LIBRARY_LOADING');
          }
          if (!doc) {
            return t('ADMIN_RENDERER_EDITOR_QUEST_NOT_FOUND');
          }
          setSelectedQuestDoc(doc);
          return (
            <Row
              draggable={draggable}
              icon="award"
              name={doc.title || t('ADMIN_RENDERER_EDITOR_UNTITLED_QUEST')}
              onEdit={() => setOpen(true)}
              onRemove={onDelete}
              typeKey="ADMIN_LABEL_QUEST"
            />
          );
        }}
      </RealtimeDocument>
      {open && id && (
        <AdminModal
          fixedHeight
          maxWidth="800px"
          onClose={() => setOpen(false)}
        >
          <QuestEditor
            initialValue={selectedQuestDoc}
            onAfterSave={() => setOpen(false)}
            onBack={() => setOpen(false)}
          />
        </AdminModal>
      )}
      {open && !id && (
        <QuestLibraryModal
          onClose={() => setOpen(false)}
          onSelectItem={onChange}
        />
      )}
    </div>
  );
}

QuestSelect.propTypes = propTypes;
QuestSelect.defaultProps = defaultProps;

export default withLabel(QuestSelect);

import styled from 'styled-components';
import { SubHeader } from 'components/admin-bridge/SidebarEditSheet/styles';
import TabButton from 'components/admin2/ui/TabButton';
import SearchInput from 'components/admin2/ui/SearchInput';
import {
  ACTION_NORMAL,
  ADMIN_SCROLL_BAR,
} from 'style/mixins';
import { ADMIN_SURFACE_1, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

export const ViewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
`;

export const ViewHeader = styled(SubHeader)`
  padding: 0;
  background: ${ADMIN_SURFACE_1};
`;

export const StyledTitle = styled(TabButton)`
  ${ACTION_NORMAL}
  flex: 1 1 0;
`;

export const LargeTitle = styled(TabButton)`
  ${ADMIN_TEXT_TITLE_S}
  text-transform: uppercase;
  flex: 1 1 0;
`;

export const Body = styled.div`
  ${ADMIN_SCROLL_BAR}
  flex: 1;
  overflow: inherit;
`;

export const TabContainer = styled.div``;

export const TabHeader = styled.div<{center?: boolean}>`
  padding: 0 22px 20px 22px;
  ${({ center }) => !center && `
    display: fleX;
    align-items: center;
    & div {
      flex: 1;
    }
  `}
`;

export const HeaderText = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_200};
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const StyledSearchInput = styled(SearchInput)`
  margin-top: 20px;
`;

import PropTypes from 'prop-types';
import React from 'react';
import {
  ADMIN_SURFACE_3,
  ADMIN_TEXT_200,
} from 'style/constants';
import KeyedListMapper from 'components/core/KeyedListMapper';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import styled from 'styled-components';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

const Container = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  background: ${ADMIN_SURFACE_3};
  color: ${ADMIN_TEXT_200};
  height: 62px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
  padding: 0 20px;
`;

const DEFAULT_LABEL = 'Untitled';

export default class ObjectToggleView extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        data: PropTypes.shape({}),
        renderer: PropTypes.shape({}),
      }).isRequired,
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    rendererNameField: PropTypes.string,
    value: PropTypes.shape([PropTypes.string]),
  };

  static defaultProps = {
    className: '',
    rendererNameField: null,
    value: [],
  };

  handleChange = (doc) => {
    const { onChange, value } = this.props;
    const { _id: docId, active } = doc;
    const newValue = active ? value.filter(item => item !== docId) : [...value, docId];
    onChange?.(newValue);
  };

  render() {
    const { handleChange } = this;
    const { className, docs, rendererNameField: nameField, value } = this.props;
    const list = docs.map(doc => ({
      _id: doc._id,
      active: value.includes(doc._id),
      label: (nameField && doc.renderer?.[nameField]) ||
        doc.name || // TODO: Semi-hack for videos
        doc.data?.title ||
        doc.data?.name ||
        DEFAULT_LABEL,
    }));

    return (
      <KeyedListMapper keyField="_id" list={list}>
        {
          (key, item) => (
            <Container key={key} className={className} onClick={() => handleChange(item)}>
              <LabelToggle checked={item.active} label={item.label} />
            </Container>
          )
        }
      </KeyedListMapper>
    );
  }
}

/* tslint:disable: jsx-boolean-value */
import React, { useEffect, useMemo, useState } from 'react';
import hash from 'json-stable-stringify';
import difference from 'lodash/difference';
import Dropdown from 'components/admin2/ui/Dropdown';
import withPadding , { PaddedComponentProps } from 'components/core/withPadding';
import { DropdownProps } from 'components/admin2/ui/Dropdown/interfaces';
import Renderer from './Renderer';
import { Container } from './styles';

interface BoxTagDropdownProps<T, V = T | string> extends Omit<DropdownProps<T>, 'onChange'> {
  getOptionValue?: (option: T) => V;
  onChange?: (selectedOptions: V[]) => void;
  values?: V[];
}

function BoxTagDropdown<T>({
  className,
  options,
  getOptionLabel,
  getOptionValue = option => option,
  onChange,
  values = [],
  ...props
}: BoxTagDropdownProps<T> & PaddedComponentProps) {
  const filterOptions = useMemo(() => {
    return options.filter(option => values.includes(getOptionValue(option)));
  }, [hash(options), hash(values)]);

  const [boxTagOptions, setBoxTagOptions] = useState(filterOptions);

  const selectedValues = useMemo(() => boxTagOptions.map(getOptionValue), [boxTagOptions.length]);

  const remainingOptions = useMemo(() => {
    return difference(options, boxTagOptions);
  }, [hash(boxTagOptions), hash(options)]);

  const addOption = (option: T) => {
    setBoxTagOptions(tags => ([...tags, option]));
  };

  const removeOption = (_: any, index: number) => {
    setBoxTagOptions(tags => tags.filter((__: any, i: number) => i !== index));
  };

  useEffect(() => {
    onChange?.(selectedValues);
  }, [hash(selectedValues)]);

  useEffect(() => {
    setBoxTagOptions(filterOptions);
  }, [hash(options)]);

  return (
    <Container className={className}>
      <Dropdown
        {...props}
        compact
        disableSelection
        getOptionLabel={getOptionLabel}
        onChange={addOption}
        options={remainingOptions}
        replaceButton
        replaceSearchInput
        value={null}
      >
        <Renderer
          boxTagOptions={boxTagOptions}
          getOptionLabel={getOptionLabel}
          removeOption={removeOption}
        />
      </Dropdown>
    </Container>
  );
}

export default withPadding(BoxTagDropdown) as typeof BoxTagDropdown;

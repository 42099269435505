import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  ADMIN_TEXT_100,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_5,
} from 'style/constants';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export interface ButtonSelectStyleProps {
  activeBackground?: string | ((props: ThemeProps<DefaultTheme>) => string);
  bodyBackground?: string | ((props: ThemeProps<DefaultTheme>) => string);
  bodyPadding?: string;
  disabled?: boolean;
  fullwidth?: boolean;
  noSelectedValue?: boolean;
  scrollable?: boolean;
  selected?: boolean;
  selectedIndex?: number;
  variant?: keyof typeof VariantMap;
}

const getBodyBackground = ({ bodyBackground = ADMIN_SURFACE_5 }: ButtonSelectStyleProps) => bodyBackground;
const getActiveBackground = ({ activeBackground = ADMIN_SURFACE_2 }: ButtonSelectStyleProps) => activeBackground;

export const ButtonSelectContainer = styled.div<ButtonSelectStyleProps>`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  ${({ fullwidth }) => fullwidth && 'width: 100%;'}
  ${({ variant = 'tab' }) => VariantMap[variant]}
`;

export const Body = styled.div<ButtonSelectStyleProps>`
  ${({ scrollable }) => scrollable && ADMIN_SCROLL_BAR}
  background: ${getActiveBackground};
  border-radius: 0 0 5px 5px;
  padding: ${({ bodyPadding = '10px' }) => bodyPadding};;
`;

export const StyledIcon = styled(Icon).attrs(props => ({
  color: ADMIN_TEXT_100(props),
}))`
  margin-right: 10px;
  & svg {
    width: 18.5px;
    height: 18.5px;
  }
`;

export const TabWrapper = styled.div<ButtonSelectStyleProps>`
  display: flex;
`;

export const TabButton = styled.button<ButtonSelectStyleProps>`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  text-transform: uppercase;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  color: ${ADMIN_TEXT_100};
  ${({ selected, disabled }) => css`
    border-radius: 5px 5px 0 0;
    background: ${selected ? getActiveBackground : getBodyBackground};
    pointer-events: ${disabled ? 'none' : 'auto'};
    opacity: ${disabled ? 0.5 : 1};
  `}
`;

const VariantMap = {
  slide: css<ButtonSelectStyleProps>`
    & ${TabWrapper} {
      border-radius: 30px;
      height: 40px;
      background: ${getBodyBackground};
      & + ${Body} {
        background: transparent;
        padding: 0 10px;
      }
      & button {
        background: ${getBodyBackground};
        border-radius: 30px !important;
      }
      ${({ selectedIndex }) => selectedIndex && css`
        & button:nth-child(${selectedIndex}) {
          background: ${ADMIN_ACCENT_PRIMARY};
        }
      `}
    }
  `,
  tab: css<ButtonSelectStyleProps>`
    background: ${getActiveBackground};
    & ${TabWrapper} {
      border-radius: 5px 5px 0 0;
      height: 48px;
      ${({ selectedIndex }) => selectedIndex ? css`
      background: linear-gradient(${getBodyBackground} 50%, ${getActiveBackground} 50%);
      & button {
        &:nth-child(${selectedIndex + 1}) {
          border-radius: 5px 5px 0 5px;
        }
        &:nth-child(${selectedIndex - 1}) {
          border-radius: 5px 5px 5px 0;
        }
      }
      ` : css`
        background: ${getBodyBackground};
      `}
    }
  `,
};

import React from 'react';
import PropTypes from 'prop-types';
import QuickPickFancyButton from 'components/admin2/QuickPickFancyButton';
import AdminModal from 'components/admin2/ui/AdminModal';
import QuickPickLibraryModal from 'components/admin2/QuickPickLibraryModal';
import QuickPickEditor from 'components/admin2/QuickPickEditor';

export default class QuickPickLibraryButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    options: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    value: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    onDelete: () => {},
    value: '',
  };

  state = {
    editingItemId: null,
    editModalOpen: false,
    libraryModalOpen: false,
  };

  handleSelectItem = (item) => {
    const { onChange, options: { valueField } } = this.props;
    onChange(item[valueField]);
    this.setState({
      libraryModalOpen: false,
    });
  };

  handleRemoveItem = () => {
    const { onChange } = this.props;
    onChange(null);
  };

  handleRemoveDraggableItem = () => {
    const { onDelete } = this.props;
    onDelete();
  };

  handleCloseEditModal = () => this.setState({
    editingItemId: null,
    editModalOpen: false,
  });

  render() {
    const { editModalOpen, libraryModalOpen, editingItemId } = this.state;
    const { className, options, value } = this.props;
    const {
      draggable,
      fancyButtonProps,
    } = options;

    const removeItem = draggable ? this.handleRemoveDraggableItem : this.handleRemoveItem;

    return (
      <>
        { libraryModalOpen && (
          <QuickPickLibraryModal
            onClose={() => this.setState({ libraryModalOpen: false })}
            onExit={() => this.setState({ libraryModalOpen: false })}
            onSelectItem={this.handleSelectItem}
          />
        ) }
        { editModalOpen && (
          <AdminModal
            onClose={this.handleCloseEditModal}
          >
            <QuickPickEditor
              editingItemId={editingItemId}
              onClose={this.handleCloseEditModal}
              onExit={this.handleCloseEditModal}
              onSave={this.handleCloseEditModal}
            />
          </AdminModal>
        )}
        <QuickPickFancyButton
          className={className}
          draggable={draggable}
          onAdd={() => this.setState({ libraryModalOpen: true })}
          onEdit={pollId => this.setState({
            editingItemId: pollId,
            editModalOpen: true,
          })}
          onRemove={removeItem}
          value={value}
          {...fancyButtonProps}
        />
      </>
    );
  }
}

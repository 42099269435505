import React from 'react';
import PropTypes from 'prop-types';
import LibraryModal from 'components/admin2/LibraryModal';
import FancyButton from 'components/admin2/FancyButton';
import AdminModal from 'components/admin2/ui/AdminModal';
import NewPersonEditor from 'components/admin2/NewPersonEditor';
import PollLibraryModal from 'components/admin2/PollLibraryModal';
import PollEditor from 'components/admin2/PollEditor';
import RealtimeDocument from 'components/core/RealtimeDocument';
import NewGroupEditor from 'components/admin2/NewGroupEditor';
import withLabel from 'components/core/withLabel';
import {
  peopleColumns as peopleLibraryColumns,
  groupColumns as groupLibraryColumns,
} from 'components/admin2/MediaLibrary/constants';
import { PollTypes } from 'services/polls/models';

class LibraryButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    options: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    value: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    onDelete: () => {},
    value: '',
  };

  state = {
    editModalOpen: false,
    libraryModalOpen: false,
  };

  handleSelectItem = (item) => {
    const { onChange, options: { valueField } } = this.props;
    onChange(item[valueField]);
    this.setState({
      libraryModalOpen: false,
    });
  };

  handleRemoveItem = () => {
    const { onChange } = this.props;
    onChange(null);
  };

  handleRemoveDraggableItem = () => {
    const { onDelete } = this.props;
    onDelete();
  };

  handleCloseEditModal = () => this.setState({ editModalOpen: false });

  render() {
    const { editModalOpen, libraryModalOpen } = this.state;
    const { className, options, value } = this.props;
    const {
      draggable,
      fancyButtonProps,
      icon,
      newItemBackLabel = 'ADMIN_ACTION_BACK_TO_LIBRARY',
      type,
    } = options;

    const removeItem = draggable ? this.handleRemoveDraggableItem : this.handleRemoveItem;

    const [libraryModal, editModal] = ((libraryType) => {
      switch (libraryType) {
        case 'person':
          return [
            <LibraryModal
              columns={peopleLibraryColumns}
              fetchObject={{
                endpoint: '/legacy/people',
                method: 'GET',
              }}
              helperText="ADMIN_LABEL_PEOPLE_HELPER_TEXT"
              NewItemComponent={NewPersonEditor}
              newItemLabel="ADMIN_LABEL_NEW_PERSON"
              onClose={() => this.setState({ libraryModalOpen: false })}
              onSelectItem={this.handleSelectItem}
              searchPlaceholderKey="LABEL_PERSON_SEARCH_PLACEHOLDER"
              titleKey="ADMIN_PERSON_BUTTON_PLACEHOLDER"
              transformRequestWithRenderer
            />,
            <NewPersonEditor
              backButtonKey={newItemBackLabel}
              editingItemId={value}
              onClose={this.handleCloseEditModal}
            />,
          ];
        case 'trivia':
        case 'poll':
        case 'prediction':
          return [
            <PollLibraryModal
              onClose={() => this.setState({ libraryModalOpen: false })}
              onSelectItem={this.handleSelectItem}
              type={type}
            />,
            <RealtimeDocument
              collection="poll"
              id={value}
            >
              {(doc, loaded) => {
                if (!loaded || !doc) { return null; }
                return (
                  <PollEditor
                    initialDoc={doc}
                    onClose={this.handleCloseEditModal}
                    onSave={this.handleCloseEditModal}
                    type={type}
                  />
                );
              }}
            </RealtimeDocument>,
          ];
        // general multiple choice
        case 'multipleChoice':
          return [
            <PollLibraryModal
              onClose={() => this.setState({ libraryModalOpen: false })}
              onSelectItem={this.handleSelectItem}
              type={null}
            />,
            <RealtimeDocument
              collection="poll"
              id={value}
            >
              {(doc, loaded) => {
                if (!loaded || !doc) { return null; }
                return (
                  <PollEditor
                    initialDoc={doc}
                    onClose={this.handleCloseEditModal}
                    onSave={this.handleCloseEditModal}
                    type={null}
                  />
                );
              }}
            </RealtimeDocument>,
          ];
        case 'group':
          return [
            <LibraryModal
              columns={groupLibraryColumns}
              fetchObject={{
                endpoint: '/legacy/groups',
                method: 'GET',
              }}
              helperText="ADMIN_LABEL_GROUPS_HELPER_TEXT"
              NewItemComponent={NewGroupEditor}
              newItemLabel="ADMIN_LABEL_NEW_GROUP"
              onClose={() => this.setState({ libraryModalOpen: false })}
              onSelectItem={this.handleSelectItem}
              searchPlaceholderKey="LABEL_PERSON_SEARCH_PLACEHOLDER"
              titleKey="ADMIN_PERSON_BUTTON_PLACEHOLDER"
              transformRequestWithRenderer
            />,
            <NewGroupEditor
              backButtonKey={newItemBackLabel}
              editingItemId={value}
              onClose={this.handleCloseEditModal}
            />,
          ];
        // TODO case 'video':
        default:
          return [null, null];
      }
    })(type);

    // it uses "objects" by default
    const collection = [PollTypes.Poll, PollTypes.Prediction, PollTypes.Trivia].includes(type) ? 'poll' : undefined;
    return (
      <>
        { libraryModalOpen && libraryModal }
        { editModalOpen && (
          <AdminModal
            fixedHeight
            fixedWidth
            onClose={this.handleCloseEditModal}
          >
            { editModal }
          </AdminModal>
        )}
        <FancyButton
          className={className}
          collection={collection}
          draggable={draggable}
          icon={icon}
          onAdd={() => this.setState({ libraryModalOpen: true })}
          onEdit={() => this.setState({ editModalOpen: true })}
          onRemove={removeItem}
          value={value}
          {...fancyButtonProps}
        />
      </>
    );
  }
}

export default withLabel(LibraryButton);

import styled from 'styled-components';
import Button from 'components/admin2/ui/Button';
import Icon from 'components/ui/Icon';
import VideoUploaderZone from 'components/admin-bridge/VideoLibrary/components/VideoUploaderZone';
import {
  ADMIN_SURFACE_5,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_2,
  ADMIN_TEXT_300,
  ADMIN_TEXT_200,
  ADMIN_ALERT_WARNING,
  ADMIN_ALERT_ERROR,
  ADMIN_SURFACE_3,
  MAESTRO_WHITE,
} from 'style/constants';
import {
  ADMIN_TEXT_BODY_S_BOLD,
  ADMIN_TEXT_BODY_S_REGULAR,
  ADMIN_TEXT_BODY_XS_BOLD,
  ADMIN_TEXT_BODY_XS_REGULAR,
  ADMIN_TEXT_TITLE_L,
} from 'style/design-system/textStyles';

export const ModalContainer = styled.div`
  padding: 30px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  ${ADMIN_TEXT_TITLE_L}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 0;

  img {
    margin-right: 20px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  ${ADMIN_TEXT_BODY_S_REGULAR}
  align-items: center;
`;

export const SelectedCountBadge = styled.span`
  display: inline-block;
  padding: 0 7.5px;
  border-radius: 10px;
  background: ${ADMIN_ACCENT_PRIMARY};
  ${ADMIN_TEXT_BODY_XS_BOLD}
`;

export const HeaderActionsSpacer = styled.div<{ size?: number }>`
  width: ${({ size }) => (size ?? 1) * 10}px;
`;

export const HeaderActionsSeparator = styled.div`
  align-self: stretch;
  width: 1px;
  background: ${ADMIN_SURFACE_5};
`;

export const HeaderButton = styled(Button).attrs({
  textColor: MAESTRO_WHITE,
  hoverTextColor: MAESTRO_WHITE,
})`
  line-height: 40px;
  padding: 0 20px;
  ${ADMIN_TEXT_BODY_S_BOLD}
`;

export const DropzoneContainer = styled.div`
  background: ${ADMIN_SURFACE_2};
  margin: 30px 0;
  padding: 30px;
  border: 1px dashed ${ADMIN_SURFACE_5};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Dropzone = styled(VideoUploaderZone)`
  height: 115.49px;
`;

export const TableContainer = styled.div`
  margin: -15px -20px 0;
`;

export const DropzoneSeparator = styled.div`
  display: flex;
  align-items: center;
  ${ADMIN_TEXT_BODY_S_BOLD}
  margin: 10px 0 6px;

  &::before, &::after {
    content: '';
    flex: 1;
    height: 1px;
    background: ${ADMIN_SURFACE_5};
    margin: 0 20px 0 0;
  }

  &::after {
    margin: 0 0 0 20px;
  }
`;

export const AdvancedOptionsButton = styled.button`
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  margin: 0;
  padding: 0;
  color: ${ADMIN_ACCENT_PRIMARY};
  text-decoration: underline;
  cursor: pointer;
  align-self: flex-end;
`;

export const VideoUrl = styled.div`
  margin: 6px 0 0;
  position: relative;

  input {
    display: block;
    width: 100%;
    background: ${ADMIN_SURFACE_3};
    ${ADMIN_TEXT_BODY_S_REGULAR}
    padding: 0 12px;
    line-height: 36px;
    border: 1px solid ${ADMIN_SURFACE_5};
    outline: none;
    border-radius: 5px;
    color: ${MAESTRO_WHITE};

    &::placeholder {
      color: ${ADMIN_TEXT_300};
    }
  }

  b {
    color: ${ADMIN_ALERT_WARNING};
    ${ADMIN_TEXT_BODY_XS_REGULAR}
    margin-top: 8px;
    display: block;

    &.error {
      color: ${ADMIN_ALERT_ERROR};
    }
  }
`;

export const UseIframeBox = styled.div`
  background: ${ADMIN_SURFACE_5};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;

  & > div {
    color: ${ADMIN_TEXT_200};
    ${ADMIN_TEXT_BODY_S_REGULAR}

    b {
      ${ADMIN_TEXT_BODY_S_BOLD}
      margin-bottom: 4px;
      display: block;
      color: ${MAESTRO_WHITE};
    }
  }
`;

export const ErrorIcon = styled(Icon).attrs((props) => ({
  name: 'warning',
  color: ADMIN_ALERT_ERROR(props),
}))`
  display: contents;

  svg {
    height: 8.76px;
    margin: 0 0 0 -6px;
  }
`;

export const EmptyListNotice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 119px;
  ${ADMIN_TEXT_BODY_S_REGULAR}
  color: ${ADMIN_TEXT_200};
`;


import React, { useCallback, useState, useEffect } from 'react';
import { useThrottle } from 'hooks';

import TextArea, { ITextAreaProps } from 'components/admin2/TextArea';
import TextInput, { ITextInputProps } from 'components/admin2/TextInput';

interface IThrottledInput
  extends Omit<ITextAreaProps, 'maxlength'>,
    Omit<ITextInputProps, 'maxlength'> {
  onChange: (value: string | number) => void;
  type?: 'text' | 'textArea' | 'number';
  value: string | number;
}

// limits re-renders/UI updates to the input component whilst typing
// use when a text input/area's onChange method triggers a costly re-render
const ThrottledInput: React.FC<IThrottledInput> = ({ onChange, value = '', type = 'text', ...props }) => {
  const [renderedValue, setRenderedValue] = useState(value);
  const throttledValue = useThrottle(renderedValue, 300);
  const InputComponent = type === 'textArea' ? TextArea : TextInput;

  // pass new value up to handler
  useEffect(() => {
    onChange(renderedValue);
  }, [throttledValue]);

  // set/reset local value if props.value changes without component unmounting
  useEffect(() => {
    if (value !== renderedValue) {
      setRenderedValue(value || '');
    }
  }, [value]);

  // set locally controlled value
  const handleChange = useCallback((val) => {
    if (val && type === 'number') {
      const numberValue = parseInt(val, 10);
      const parsedVal = Number.isNaN(numberValue) ? 0 : numberValue;
      setRenderedValue(parsedVal);
    } else {
      setRenderedValue(val || '');
    }
  },[type]);

  return (
    <InputComponent
      {...props}
      onChange={handleChange}
      value={renderedValue}
    />
  );
};

export default ThrottledInput;

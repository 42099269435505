import React from 'react';
import { Option } from 'hooks/use-scroll-to-view';
import { RemoveIcon } from './styles';

export type RemoveHandler<T> = (tag: T, index: number) => void;

interface RemoveButtonProps<T, O = Option<T>> {
  index: number;
  onRemove?: RemoveHandler<O>;
  tag: O;
}

export default function RemoveButton<T>({ index, onRemove, tag }: RemoveButtonProps<T>) {
  const removeTag: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    onRemove?.(tag, index);
  };

  return <RemoveIcon onClick={removeTag} />;
}

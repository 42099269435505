import React, { useMemo } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import TranslatedText from 'components/i18n/TranslatedText';
import useChildRenderer from 'hooks/use-child-renderer';
import hash from 'json-stable-stringify';
import DropdownOption, { CustomNavigationOptionView, DefaultOptionView, LocalizationOptionView, SiteNavigationOptionView } from './Option';
import {
  OptionContainer,
  SectionContainer,
  SectionName,
  EmptyOption,
} from './styles';
import {
  OptionRendererProps,
} from './interfaces';
import { useAdminTranslation } from 'hooks/use-translation';
import useCustomOptionViews from './CustomOptionViews/useCustomOptionViews';
import { NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ITEM_ACTIVE_ID } from 'global-ids';
import CtaBehaviorOptionView from './CustomOptionViews/CtaBehaviorOptionView';

interface Section<T> {
  options?: T[];
}

export default function OptionRenderer<T extends Record<string, any>>({
  activeIndex,
  children,
  contentRef,
  getOptionLabel,
  hasSections,
  isAdmin,
  onOptionSelect,
  options,
  replace,
  translated,
  itemRefs,
  isLocalization,
  isSiteNavigation,
  isCustomNavigation,
  isCtaBehavior,
  ...props
}: OptionRendererProps<T>) {
  const { t } = useAdminTranslation();
  const optionsLengths = useMemo(() => {
    if (hasSections) {
      const lengths: number[] = [];
      let prevLength = 0;
      for (const option of options) {
        lengths.push(prevLength);
        prevLength += option?.options?.length || 0;
      }
      return lengths;
    }
    return null;
  }, [hasSections, hash(options)]);

  const optionRenderer = useChildRenderer(replace ? children : null);

  const { siteNavigationHandler, customNavigationHandler } = useCustomOptionViews();

  const renderOptions = (sectionOptions = options, prevIndex: number = 0) => sectionOptions.length > 0 ? (
    <NonKeyedListMapper list={sectionOptions}>
      {(key: string, rowData: T, index: number) => {
        const prevLength = optionsLengths?.[prevIndex] || 0;
        const calculatedIndex = prevLength + index;
        const ref = itemRefs[calculatedIndex];
        const label = getOptionLabel(rowData);
        const optionProps = {
          isActive: activeIndex === calculatedIndex,
          data: rowData,
          ...props,
        };
        const uniqueToParentProps = {
          'data-testid': optionProps.data['data-testid'],
        };

        if (isCustomNavigation && optionProps.isActive) {
          uniqueToParentProps['data-testid'] = NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ITEM_ACTIVE_ID;
        }
        if (optionProps.data.disableSelection) {
          optionProps.style = { pointerEvents: 'none' };
        }
        const optionChildren = translated && label ? (
          <TranslatedText stringKey={label as any} />
        ) : label;

        const isFirstRow = index === 0;
        const isSecondLastRow = index === sectionOptions.length - 2;
        const isLastRow = index === sectionOptions.length - 1;

        const handleChange = (data: T) => {
          if (!!isSiteNavigation) {
            siteNavigationHandler(isFirstRow, isLastRow, optionProps.data);
          }

          if (!!isCustomNavigation) {
            customNavigationHandler(isFirstRow, isSecondLastRow, isLastRow, optionProps.data);
          }

          onOptionSelect(data);
        };

        const renderOptionView = () => {
          if (isLocalization) {
            return <LocalizationOptionView isAdmin={isAdmin} {...optionProps}/>;
          }

          if (isSiteNavigation) {
            return (
              <SiteNavigationOptionView
                isAdmin={isAdmin}
                isFirstRow={isFirstRow}
                isLastRow={isLastRow}
                name={label!}
                {...optionProps}
              />
            );
          }

          if (isCustomNavigation) {
            return (
              <CustomNavigationOptionView
                isAdmin={isAdmin}
                isFirstRow={isFirstRow}
                isCreateFolder={isSecondLastRow}
                isCreateLink={isLastRow}
                {...optionProps}
              >
                {optionChildren}
              </CustomNavigationOptionView>
            );
          }

          if (isCtaBehavior) {
            return <CtaBehaviorOptionView {...optionProps} />;
          }

          return (optionRenderer(optionProps, optionChildren) || (
            <DefaultOptionView isAdmin={isAdmin} {...optionProps}>
              {optionChildren}
            </DefaultOptionView>
          ));
        };

        return (
          <DropdownOption
            key={key}
            onChange={handleChange}
            {...optionProps}
            {...uniqueToParentProps}
            isAdmin={isAdmin}
            ref={ref}
          >
            {renderOptionView()}
          </DropdownOption>
        );
      }}
    </NonKeyedListMapper>
  ) : <EmptyOption>{t('ADMIN_LABEL_NO_RESULTS')}</EmptyOption>;

  return (
    <OptionContainer ref={contentRef}>
      {hasSections ? (
        <NonKeyedListMapper list={options}>
          {(key: string, { options: sectionOptions, ...label }: Section<T>, i: number) => (
            <SectionContainer key={key}>
              <SectionName>{getOptionLabel(label as T)}</SectionName>
              {renderOptions(sectionOptions, i)}
            </SectionContainer>
          )}
        </NonKeyedListMapper>
      ) : renderOptions()}
    </OptionContainer>
  );
}

import styled from 'styled-components';
import ActionButton from 'components/admin2/ActionButton';
import { ADMIN_BACKGROUND_TITLE, ADMIN_BACKGROUND_CONTENT } from 'style/mixins';
import { ADMIN_SURFACE_1 } from 'style/constants';
import Icon from 'components/ui/Icon';

export const Container = styled.div`
  position: relative;
`;

export const AddMoreButton = styled(ActionButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  height: 40px;
  width: 100%;
  outline: 0;
  ${ADMIN_BACKGROUND_TITLE};
`;

export const DottedDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dotted ${ADMIN_SURFACE_1};
  height: 56px;
`;

export const OptionContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  ${ADMIN_BACKGROUND_CONTENT};
`;

export const OptionMenu = styled.div`
`;

export const Option = styled.div`
  cursor: pointer;
  padding: 16px;
  ${ADMIN_BACKGROUND_CONTENT};
`;

export const SearchWrapper = styled.div`
  display: flex;
  padding: 0 16px;
  ${ADMIN_BACKGROUND_TITLE};
`;

export const ValuesContainer = styled.div`
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 2px;
  ${ADMIN_BACKGROUND_TITLE};
`;

export const RemoveIcon = styled(Icon)
  .attrs({
    name: 'cancel-thin',
  })``;

export const SelectedValue = styled.div`
`;

import styled, { css } from 'styled-components';
import withPadding from 'components/core/withPadding';
import {
  ADMIN_TEXT_100,
  ADMIN_ALERT_ERROR,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';

interface ErrorBoxProps {
  borderless?: boolean
  uppercase?: boolean;
}

const ErrorBox = styled.div<ErrorBoxProps>`
  ${({ uppercase, borderless }) => css`
    ${uppercase && 'text-transform: uppercase;'}
    ${borderless ? css`
      ${ADMIN_TEXT_LABEL_S_BOLD}
      color: ${ADMIN_ALERT_ERROR};
    ` : css`
      ${ADMIN_TEXT_BODY_S_MEDIUM}
      border: 1px solid ${ADMIN_ALERT_ERROR};
      padding: 12px;
      color: ${ADMIN_TEXT_100};
    `}
  `}
`;

export default withPadding(ErrorBox, '10px 0');

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ShopifyConnect from 'components/admin2/ui/ShopifyConnect';
import { useAdminTranslation } from 'hooks/use-translation';

const ShopifyStoreConnection = (props) => {
  const { loadingCollections, loadingProducts, removeShopifyStore, shopInfo } = props;
  const [connectStarted, setConnectStarted] = useState(false);
  const { t } = useAdminTranslation();

  useEffect(() => {
    loadingCollections();
    loadingProducts();
  }, []);

  useEffect(() => {
    if (shopInfo && shopInfo.shop !== '') {
      setConnectStarted(false);
    }
    if (connectStarted && shopInfo.shop === '') {
      loadingCollections();
      loadingProducts();
    }
  }, [shopInfo, connectStarted]);

  const handleUninstall = (shop) => {
    if (shop) {
      removeShopifyStore({ shop });
    }
  };

  const isConnected = React.useMemo(() => Boolean(shopInfo && shopInfo.shop), [shopInfo]);
  const descriptionText = React.useMemo(() => {
    if (isConnected) {
      return t('ADMIN_CARD_SHOPIFY_INSTALLED_APP');
    }
    return t('ADMIN_CARD_SHOPIFY_NOT_CONNECTED_DESCRIPTION');
  }, [isConnected]);

  return (
    <ShopifyConnect
      confirmTextKey="ADMIN_LABEL_SHOPIFY_DISCONNECT_CONFIRM"
      description={descriptionText}
      hostname="myshopify.com"
      isConnected={isConnected}
      label={t('ADMIN_SETTINGS_SHOPIFY')}
      labelIcon="shopifyColored"
      notConnectedText={t('ADMIN_CARD_SHOPIFY_NOT_CONNECTED_INSTALL_APP')}
      onDisconnect={handleUninstall}
      placeholder="your-shop"
      url={shopInfo.shop}
    />
  );
};

ShopifyStoreConnection.propTypes = {
  loadingCollections: PropTypes.func,
  loadingProducts: PropTypes.func,
  removeShopifyStore: PropTypes.func,
  shopInfo: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ShopifyStoreConnection;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DESC_KEY } from 'components/admin2/MediaLibrary/constants';
import LibraryModal from 'components/admin2/LibraryModal';
import { injectT, withT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';

const pollResultsLibraryColumns = injectT(
  (t) => lazy(
    () => [
      {
        accessor: 'name',
        isTitle: true,
        minWidth: 175,
        title: t('NAME'),
      },
      {
        accessor: 'created',
        minWidth: 100,
        sort: DESC_KEY,
        title: t('ADMIN_LABEL_BROADCASTED'),
        transformValue: value => moment(value).fromNow(),
      },
    ],
  ),
);

export default withT(
  class PollResultsLibraryModal extends React.Component {
    static propTypes = {
      onClose: PropTypes.func,
      onSelectItem: PropTypes.func.isRequired,
    };

    static defaultProps = {
      onClose: () => null,
    };

    selectItem = (value) => {
      const { onClose, onSelectItem } = this.props;
      onSelectItem(value._id);
      onClose();
    };

    render() {
      const { onClose, t } = this.props;

      return (
        <LibraryModal
          columns={pollResultsLibraryColumns(t)}
          disableEditing
          fetchObject={{
            endpoint: '/legacy/poll-results',
            method: 'GET',
          }}
          helperText="ADMIN_OVERLAY_POLL_RESULTS_INFO"
          onClose={onClose}
          onSelectItem={this.selectItem}
          searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH_FOR_A_QUESTION"
          titleKey="ADMIN_OVERLAY_POLL_RESULTS_NAME"
        />
      );
    }
  },
);

import styled  from 'styled-components';
import { ADMIN_ALERT_ERROR } from 'style/constants';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const ErrorContainer  = styled.div`
  margin-top: 7px;
  display: flex;
  color: ${ADMIN_ALERT_ERROR};
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
`;

// @ts-ignore
export const WarningIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_ALERT_ERROR(props),
  name: 'warning',
}))`
  display: flex;
  align-items: center;
  margin-right: 6.27px;
  svg {
    width: 11.45px;
    height: 8.76px;
  }
`;

import styled from 'styled-components';
import { RemoveButton } from 'components/admin2/ui/Button';
import CurrencyDropDown from './CurrencyDropdown';
import TextInput from 'components/admin2/TextInput';
import ActionButton from 'components/admin2/ActionButton';
import {
  ADMIN_TEXT_200,
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_1,
  ADMIN_TEXT_100,
} from 'style/constants';
import { ADMIN_TEXT_BODY_M_REGULAR } from 'style/design-system/textStyles';

export interface IPrice {
  currency: string;
  default: boolean;
  stripePriceId?: string;
  value: string;
}

export interface IMultiCurrencyProps {
  disableRemovePrice?: boolean;
  displayErrors: boolean;
  errors: any;
  handlePrices: (prices: IPrice[]) => void;
  initialPrices?: IPrice[];
  setErrors: (errors: any) => void;
}

export interface IErrorBody {
  currency: string | boolean;
  default: string | boolean;
  value: string | boolean;
}

export const MultiCurrencyContainer = styled.div`
  align-items: center;
  border-radius: 5px;
  width: 520px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const Header = styled.div`
  align-items: center;
  width: 520px;
  display: flex;
  flex-direction: row;
  ${ADMIN_TEXT_BODY_M_REGULAR}
  color: ${ADMIN_TEXT_200};
  margin-bottom: 10px;
`;

export const PriceLabel = styled.div`
  width: 193px;
  margin: 0px 43px 0px 4px;
`;

export const CurrencyLabel = styled.div`
  width: 125px;
  margin-right: 34px;
`;

export const DefaultLabel = styled.div`
`;

export const AddPriceButton = styled(ActionButton)`
  width: 100%;
  border-radius: 5px;
  justify-content: center;
  padding: 12px 15px;
  background-color: transparent;
  border: 1.5px solid ${ADMIN_TEXT_100};

  && {
    color: ${ADMIN_TEXT_100};
  }

  &:hover {
    background-color: ${ADMIN_SURFACE_1};
    border-color: ${ADMIN_SURFACE_1};
  }
`;

export const Container = styled.div`
  align-items: center;
  border-radius: 5px;
  background: ${ADMIN_SURFACE_2};
  width: 520px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const StyledPriceInput = styled(TextInput)`
  margin: 0px 43px 0px 10px;
  & div input {
      width: 188px;
      background: ${ADMIN_SURFACE_3};
  }
`;

export const StyledCurrencyDropdown = styled(CurrencyDropDown)`
  margin-right: 44px;
  width: 126px;
  & div:first-child {
    min-height: 40px;
  }
`;

export const StyledRemoveButton = styled(RemoveButton)`
  margin-right: 10px;
  margin: 0px 18px 0px 30px;
`;

export const DropdownContainer = styled.div`
  width: 141px;
`;

export const PriceContainer = styled.div`
  width: 241px;
  padding: 0 13px;
`;

export const ToggleContainer = styled.div``;

export const CurrencyRow = styled.div`
  align-items: center;
  border-radius: 5px;
  background: ${ADMIN_SURFACE_2};
  width: 520px;
  display: flex;
  flex-direction: row;
`;

export const ErrorsRow = styled(CurrencyRow)`
  margin-top: -10px;
  margin-bottom: 8px;
`;

import React from 'react';
import JsonDebug from 'components/dev/JsonDebug';
import { IFortniteLeaderboardBlockData } from 'components/page-blocks/FortniteLeaderboard/utils';
import { FORTNITE_LEADERBOARD_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';
import BlockSettings from 'components/page-blocks/FortniteLeaderboard/BlockSettings';

interface IFortniteLeaderboardBlockPanelRenderer {
  blockData: IFortniteLeaderboardBlockData;
  iconName: string;
  id: string;
  panelType: typeof FORTNITE_LEADERBOARD_BLOCK_PANEL_ID;
}

interface IFortniteLeaderboardBlockPanelEditorProps {
  onChange: (obj: IFortniteLeaderboardBlockPanelRenderer) => void;
  renderer: IFortniteLeaderboardBlockPanelRenderer;
}

const FortniteLeaderboardBlockPanelEditor = ({ renderer, onChange }: IFortniteLeaderboardBlockPanelEditorProps) => {
  const handleChangeBlockData = React.useCallback((blockData: IFortniteLeaderboardBlockData) => {
    onChange({
      ...renderer,
      blockData,
    });
  }, [onChange, renderer]);

  return (
    <>
      <BlockSettings region="panel" item={renderer.blockData} onChange={handleChangeBlockData} />
      <JsonDebug value={renderer} />
    </>
  );
};

export default FortniteLeaderboardBlockPanelEditor;

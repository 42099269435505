import React from 'react';
import path from 'path';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ADMIN_ERROR_MODAL_ROOT } from 'global-ids';
import AdminModal from 'components/admin2/ui/AdminModal';
import MediaLibrary from 'components/admin2/MediaLibrary';
import AjaxFetch from 'components/core/AjaxFetch';
import { camelify } from 'shared/string-utils';
import { ASC_KEY, DESC_KEY, NONE_KEY } from 'components/admin2/MediaLibrary/constants';
import { SERVICE_BASE_URL, LIBRARY_OVERRIDE } from 'config';

const SORT_MAP = {
  [ASC_KEY]: 'asc',
  [DESC_KEY]: 'desc',
  [NONE_KEY]: null,
};

const SORT_MAP_ACCESSORS = {
  groupName: 'renderer.group_name',
  lastModified: 'last_modified',
  personName: 'renderer.person_name',
};

const StyledAdminModal = styled(AdminModal)`
  overflow: visible;
`;

const StyledMediaLibrary = styled(MediaLibrary)`
  width: 100%;
  height: 100%;
`;

const findInitialSort = columns => columns.find(col => Boolean(col.sort));
export default class LibraryModal extends React.Component {
  static propTypes = {
    buttonProps: PropTypes.oneOfType([
      PropTypes.shape({
        labelKey: PropTypes.string,
        onClick: PropTypes.func,
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          labelKey: PropTypes.string,
          onClick: PropTypes.func,
        }),
      ),
    ]),
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    disableEditing: PropTypes.bool,
    fetchObject: PropTypes.shape({
      endpoint: PropTypes.string,
      filters: PropTypes.shape({}),
      method: PropTypes.oneOf(['GET']),
      service: PropTypes.string,
    }).isRequired,
    filterResults: PropTypes.func,
    forceRefresh: PropTypes.string,
    helperText: PropTypes.string,
    modalMaxWidth: PropTypes.string,
    NewItemComponent: PropTypes.func,
    newItemLabel: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onEditItem: PropTypes.func,
    onSelectItem: PropTypes.func.isRequired,
    params: PropTypes.shape({
      type: PropTypes.string, // for polls
    }),
    primaryToken: PropTypes.string.isRequired,
    refreshKey: PropTypes.string.isRequired,
    searchPlaceholderKey: PropTypes.string,
    siteId: PropTypes.string.isRequired,
    testIdAddButton: PropTypes.string,
    titleKey: PropTypes.string.isRequired,
    transformRequestWithRenderer: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    buttonProps: [],
    disableEditing: false,
    filterResults: null,
    forceRefresh: null,
    helperText: '',
    modalMaxWidth: '900px',
    NewItemComponent: undefined,
    newItemLabel: '',
    onEditItem: null,
    params: {},
    searchPlaceholderKey: '',
    testIdAddButton: undefined,
    transformRequestWithRenderer: false,
    type: '',
  };

  state = {
    loaded: false,
    modalOpen: false,
    params: {
      ...this.props.params, // eslint-disable-line
      limit: 250,
      sortBy: 'created',
      sortDirection: 'desc',
    },
  };

  componentDidMount() {
  // eslint-disable-next-line react/destructuring-assignment
    this.handleSort(findInitialSort(this.props.columns));
    this.setState({ url: this.createURL() });
  }

  createURL = () => {
    const LEGACY_LIBRARY_SERVICE = '/admin/v1';
    const BASE_URL = LIBRARY_OVERRIDE || SERVICE_BASE_URL;
    const { fetchObject } = this.props;
    const {
      endpoint = '', // for admin/v1 library routes, e.g. "/legacy/panels"
      service, // for domain model services, e.g. "https://ruby.maestro.io/quest/v2"
    } = fetchObject;
    return service ?
      new URL(endpoint, service) :
      new URL(path.join(LEGACY_LIBRARY_SERVICE, endpoint), BASE_URL);
  };

  handleResponse = (response) => {
    const { transformRequestWithRenderer, filterResults } = this.props;
    if (transformRequestWithRenderer) {
      this.setState({
        list: camelify(response.results).map(item => ({
          ...item.renderer,
          created: item.created,
          lastModified: item.lastModified,
        })).filter(item => item),
        loaded: true,
      });
    } else {
      const filteredResults = filterResults ? filterResults(response.results) : response.results;
      this.setState({
        list: filteredResults,
        loaded: true,
      });
    }
  };

  handleSearch = (searchText) => {
    const searchParam = { search: searchText };
    this.setQueryParams(searchParam);
    this.setState({ loaded: false });
  };

  setQueryParams = (newParams) => {
    const { params: oldParams } = this.state;
    this.setState({
      params: {
        ...oldParams,
        ...newParams,
      },
    });
  };

  handleSort = (sortObject = {}) => {
    const { sort: sortKey, title: sortBy } = sortObject;
    const sortValue = SORT_MAP[sortKey];
    const sortParams = sortValue ? {
      sortBy: SORT_MAP_ACCESSORS[sortObject.accessor] || sortObject.accessor || sortBy,
      sortDirection: sortValue,
    } : {};

    this.setQueryParams(sortParams);
  };

  handleCreate = async () => {
    this.setState({ modalOpen: false });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleItemModalClose = () => {
    this.setState({
      editingItemId: null,
      modalOpen: false,
    });
  };

  handleEditItem = (item) => {
    const { onEditItem } = this.props;
    if (onEditItem) {
      onEditItem(item);
      return;
    }
    const { id } = item;
    if (id) {
      this.setState({
        editingItemId: id,
      });
      this.handleModalOpen();
    }
  };

  render() {
    const {
      buttonProps,
      columns,
      fetchObject,
      forceRefresh, // used when updating refreshKey takes you out of the modal
      helperText,
      modalMaxWidth,
      NewItemComponent,
      newItemLabel,
      onClose,
      onSelectItem,
      primaryToken,
      refreshKey,
      searchPlaceholderKey,
      titleKey,
      disableEditing,
      siteId,
      testIdAddButton,
      type,
    } = this.props;
    const {
      modalOpen,
      list,
      loaded,
      params,
      editingItemId,
      url,
    } = this.state;

    const allowNewItem = newItemLabel && NewItemComponent;
    const actionButtons = allowNewItem ? {
      labelKey: newItemLabel,
      onClick: () => this.setState({ modalOpen: true }),
    } : buttonProps;

    const {
      filters = {},
      method = 'GET',
    } = fetchObject;

    const headers = {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    };

    return (
      <AdminModal
        id={ADMIN_ERROR_MODAL_ROOT}
        maxHeight="700px"
        maxWidth={modalMaxWidth}
        onClose={onClose}
        overlapAdminbar
      >
        {url && (
          <AjaxFetch
            headers={headers}
            method={method}
            onValue={this.handleResponse}
            params={Object.assign(params, filters)}
            refreshKey={`${refreshKey}${forceRefresh}`}
            url={url?.href}
          />
        )}
        <StyledMediaLibrary
          buttonProps={actionButtons}
          columns={columns}
          disableEditing={disableEditing}
          helperText={helperText}
          list={list}
          loaded={loaded}
          onEditItem={this.handleEditItem}
          onSearch={this.handleSearch}
          onSelectItem={onSelectItem}
          onSort={this.handleSort}
          searchPlaceholderKey={searchPlaceholderKey}
          setLoading={() => this.setState({ loaded: false })}
          testIdAddButton={testIdAddButton}
          titleKey={titleKey}
          type={type}
        />
        {
          modalOpen && (
          <StyledAdminModal
            fixedHeight
            maxWidth="664px"
            onClose={this.handleItemModalClose}
          >
            { allowNewItem && (
            <NewItemComponent
              editingItemId={editingItemId}
              onClose={this.handleItemModalClose}
              onCreate={this.handleCreate}
            />
            ) }
          </StyledAdminModal>
          )
      }
      </AdminModal>
    );
  }
}

import { connect } from 'react-redux';
import { getSettingsIcons } from 'services/app/selectors';
import { settingsTypes } from 'services/admin/constants';
import { saveSiteSettings } from 'services/admin/actions';

import IconsTab from './IconsTab';

const mapStateToProps = state => ({
  iconData: getSettingsIcons(state),
});

const mapDispatchToProps = dispatch => ({
  handleSave: data => dispatch(saveSiteSettings(settingsTypes.ICONS, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IconsTab);

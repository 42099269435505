import React, { useEffect, useState } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  Container,
  Title,
  TitleText,
  ArrowIcon,
  Content,
  ContentWrapper,
} from './styles';

interface ContentDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultCollapsed?: boolean;
  title?: string;
  titleColor?: string;
  titleKey?: string;
}

function ContentDropdown({ children, title, titleKey = '', titleColor, defaultCollapsed, ...props }: ContentDropdownProps) {
  const [collapsed, setCollapsed] = useState(defaultCollapsed || false);
  const [contentHeight, setContentHeight] = useState(0);
  const [contentFullyOpen, setContentFullyOpen] = useState(false);

  useEffect(() => {
    if (collapsed) {
      setContentFullyOpen(false);
      return;
    }
    setTimeout(() => {
      setContentFullyOpen(true);
    }, 500);
  }, [collapsed]);

  const handleClick = () => {
    setCollapsed(collapsedStatus => !collapsedStatus);
  };

  const contentRef = (node: HTMLDivElement | null) => {
    const height = node?.scrollHeight;
    if (!height || height === contentHeight) {
      return;
    }
    setContentHeight(height);
  };

  return (
    <Container>
      <Title onClick={handleClick}>
          <TranslatedText stringKey={titleKey}>
            {(i18nText = title) => <TitleText titleColor={titleColor}>{i18nText}</TitleText>}
          </TranslatedText>
        <ArrowIcon collapsed={collapsed} titleColor={titleColor} />
      </Title>
      <Content ref={contentRef} collapsed={collapsed}  maxHeight={contentHeight} contentFullyOpen={contentFullyOpen} {...props}>
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </Content>
    </Container>
  );
}

export default ContentDropdown;

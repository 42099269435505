import produce from 'immer';
import set from 'lodash/set';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import hash from 'json-stable-stringify';
import styled from 'styled-components';
import CmsNavHeader, { IAction } from 'components/admin2/CmsNavHeader';
import DatetimePicker from 'components/admin2/DatetimePicker';
import InputLabel from 'components/admin2/InputLabel';
import Label from 'components/admin2/Label';
import TabbedView, { Tab } from 'components/admin2/ui/TabbedView';
import ThrottledInput from 'components/admin2/ThrottledInput';
import JsonDebug from 'components/dev/JsonDebug';
import TranslatedText from 'components/i18n/TranslatedText';
import { ADMIN_BACKGROUND_CONTENT } from 'style/mixins';
import { ADMIN_TEXT_200, ADMIN_ALERT_WARNING, SPACING_SMALL } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import { useAdminTranslation } from 'hooks/use-translation';
import RewardDetails from './RewardDetails';
import ActionList from './ActionList';
import {
  DESCRIPTION,
  CLAIMED_MESSAGE,
  REWARD_DESCRIPTION,
  REWARD_IMAGE,
  REWARD_TYPE,
  TITLE,
  TIME_MAXIMUM,
  TIME_MINIMUM,
  WEBHOOK_ID,
  WEBHOOK_TYPE,
  WEBHOOK_VALUE,
} from './constants';
import useDebouncedState from 'hooks/use-debounce-state';

const ACTIONS = 'actions';

const Divider = () => null;

export default function QuestEditorView({
  backButtonKey,
  onBack,
  onChange,
  onSave,
  value: doc,
}) {
  const { t } = useAdminTranslation();
  const {
    actions,
    claimedMessage,
    title,
    description,
    rewardImage,
    rewardDescription,
    rewardType,
    timeMaximum,
    timeMinimum,
    webhookId,
    webhookType,
    webhookValue,
  } = doc;
  const startTimestamp = timeMinimum ? new Date(timeMinimum).getTime() : null;
  const endTimestamp = timeMaximum ? new Date(timeMaximum).getTime() : null;

  // updates draft quest document & triggers re-render for entire editor
  type updateFn = (updater: string | ((value: any) => void), value?: string | number) => void;
  const update: updateFn = useCallback((updater, value=null) => {
    if (typeof updater === 'string') {
      onChange(prevDoc => produce(prevDoc, (draft) => {
        set(draft, updater, value);
      }));
    } else if (typeof updater === 'function') {
      onChange(produce(doc, updater));
    }
  }, [onChange, doc]);

  const updateQuest = useCallback(
    keyField => newValue => update(keyField, newValue),
    [update],
  );

  const [debouncedValue, setDebouncedValue] = useDebouncedState(doc, 1000);

  useEffect(() => {
    setDebouncedValue(doc);
  }, [hash(doc)]);

  const [tempClaimedMessage, setTempClaimedMessage] = useState(undefined);
  const [tempWebhookId, setTempWebhookId] = useState(undefined);
  const [tempWebhookType, setTempWebhookType] = useState(undefined);
  const [tempWebhookValue, setTempWebhookValue] = useState(undefined);

  const onRewardTabChange = useCallback((tab) => {
    if (tab !== 'webhook') {
      if (webhookId) {
        setTempWebhookId(webhookId);
      }
      if (webhookType) {
        setTempWebhookType(webhookType);
      }
      if (webhookValue) {
        setTempWebhookValue(webhookValue);
      }
      setTempClaimedMessage(claimedMessage);
      update((draft) => {
        set(draft, REWARD_TYPE, tab);
        set(draft, CLAIMED_MESSAGE, tempClaimedMessage);
        set(draft, WEBHOOK_ID, undefined);
        set(draft, WEBHOOK_TYPE, undefined);
        set(draft, WEBHOOK_VALUE, undefined);
      });
    } else {
      setTempClaimedMessage(claimedMessage);
      update((draft) => {
        set(draft, REWARD_TYPE, tab);
        set(draft, CLAIMED_MESSAGE, undefined);
        set(draft, WEBHOOK_ID, tempWebhookId);
        set(draft, WEBHOOK_TYPE, tempWebhookType);
        set(draft, WEBHOOK_VALUE, tempWebhookValue);
      });
    }
  }, [update, doc]);

  const setStartTimestamp = (newStart) => {
    const newValue = newStart ? new Date(newStart)?.getTime() : null;
    update(TIME_MINIMUM, newValue);
  };

  const setEndTimestamp = (newEnd) => {
    const newValue = newEnd ? new Date(newEnd)?.getTime() : null;
    update(TIME_MAXIMUM, newValue);
  };

  const setWebhookRewardValue = (newRewardValue) => {
    update(WEBHOOK_VALUE, Number.parseInt(newRewardValue, 10) || undefined);
    const value = Number.isNaN(Number.parseInt(newRewardValue, 10)) ?
      undefined : newRewardValue;
    update(WEBHOOK_VALUE, value);
  };

  const headerActions: IAction[] = useMemo(
    () => ([
      onSave && {
        labelKey: 'ADMIN_ACTION_SAVE',
        onClick: () => onSave(doc),
      } as IAction,
    ].filter((action) => action)),
    [onSave],
  );

  return (
    <Container>
      <CmsNavHeader
        actions={headerActions}
        backButtonKey={backButtonKey}
        onBack={onBack}
        validationCollection="quest"
        validationEnabled={true}
        validationValue={debouncedValue}
      />
      <Body>
        <Section>
          <LabelContainer>
            <InputLabel>{t('NAME')}</InputLabel>
            <TranslatedText component={WarningLabel} stringKey="ADMIN_LABEL_REQUIRED" />
          </LabelContainer>
          <ThrottledInput
            onChange={updateQuest(TITLE)}
            value={title}
          />
        </Section>
        <Section>
          <LabelContainer>
            <InputLabel>{t('ADMIN_LABEL_DESCRIPTION')}</InputLabel>
            <TranslatedText component={WarningLabel} stringKey="ADMIN_LABEL_REQUIRED" />
          </LabelContainer>
          <ThrottledInput
            onChange={updateQuest(DESCRIPTION)}
            placeholderText={t('ADMIN_QUEST_EDITOR_QUEST_DESCRIPTION_EXPLANATION')}
            type="textArea"
            value={description}
          />
        </Section>
        <Section>
          <TabbedView variant="highlighted">
            <InfoTab titleKey="ADMIN_LABEL_QUEST_ACTIONS">
              <ActionList
                list={actions || []}
                onChange={updateQuest(ACTIONS)}
              />
            </InfoTab>
            <InfoTab titleKey="ADMIN_LABEL_QUEST_REWARD">
              <TabbedView
                activeIndex={rewardType}
                onTabChange={onRewardTabChange}
                variant="tail"
              >
                <RewardTab id="prize" titleKey="ADMIN_LABEL_QUEST_REWARD_PRIZE">
                  <RewardDetails
                    rewardDescription={rewardDescription}
                    rewardImageUrl={rewardImage}
                    setRewardDescription={updateQuest(REWARD_DESCRIPTION)}
                    setRewardImageUrl={updateQuest(REWARD_IMAGE)}
                  />
                  <Divider />
                  <LabelContainer>
                    <InputLabel>{t('ADMIN_QUEST_EDITOR_CLAIMED_MESSAGE')}</InputLabel>
                    <TranslatedText component={WarningLabel} stringKey="ADMIN_LABEL_REQUIRED" />
                  </LabelContainer>
                  <ThrottledInput
                    onChange={updateQuest(CLAIMED_MESSAGE)}
                    placeholderText="Message that will appear when the user has claimed a prize"
                    value={claimedMessage}
                  />
                </RewardTab>
                <RewardTab id="raffle" titleKey="ADMIN_LABEL_QUEST_REWARD_RAFFLE">
                  <RewardDetails
                    rewardDescription={rewardDescription}
                    rewardImageUrl={rewardImage}
                    setRewardDescription={updateQuest(REWARD_DESCRIPTION)}
                    setRewardImageUrl={updateQuest(REWARD_IMAGE)}
                  />
                  <Divider />
                  <LabelContainer>
                    <InputLabel>{t('ADMIN_QUEST_EDITOR_CLAIMED_MESSAGE')}</InputLabel>
                    <TranslatedText component={WarningLabel} stringKey="ADMIN_LABEL_REQUIRED" />
                  </LabelContainer>
                  <ThrottledInput
                    onChange={updateQuest(CLAIMED_MESSAGE)}
                    placeholderText="Message that will appear when the user has submitted a raffle entry"
                    value={claimedMessage}
                  />
                </RewardTab>
                <RewardTab id="webhook" titleKey="ADMIN_LABEL_QUEST_REWARD_WEBHOOK">
                  <RewardDetails
                    rewardDescription={rewardDescription}
                    rewardImageUrl={rewardImage}
                    setRewardDescription={updateQuest(REWARD_DESCRIPTION)}
                    setRewardImageUrl={updateQuest(REWARD_IMAGE)}
                    setWebhookType={updateQuest(WEBHOOK_TYPE)}
                    webhookType={webhookType}
                  />
                  <>
                    <div>
                      <InputLabel>{t('ADMIN_QUEST_EDITOR_REWARD_VALUE')}</InputLabel>
                      <ThrottledInput
                        onChange={setWebhookRewardValue}
                        type="number"
                        value={webhookValue}
                      />
                    </div>
                    <div>
                      <InputLabel>
                        <TranslatedText stringKey="ADMIN_LABEL_QUEST_WEBHOOK_ID" />
                      </InputLabel>
                      <ThrottledInput
                        onChange={updateQuest(WEBHOOK_ID)}
                        value={webhookId}
                      />
                    </div>
                  </>
                </RewardTab>
              </TabbedView>
            </InfoTab>
            <InfoTab titleKey="ADMIN_LABEL_QUEST_LIFESPAN">
              <HelperText>
                {t('ADMIN_QUEST_EDITOR_LIFESPAN_EXPLANATION')}
              </HelperText>
              <InputLabel>{t('TIME_PERIOD_START_TIME')}</InputLabel>
              <DatetimePicker
                onTimeChange={setStartTimestamp}
                timestamp={startTimestamp}
              />
              <InputLabel>{t('TIME_PERIOD_END_TIME')}</InputLabel>
              <DatetimePicker
                onTimeChange={setEndTimestamp}
                timestamp={endTimestamp}
              />
            </InfoTab>
          </TabbedView>
        </Section>
      </Body>
      <JsonDebug value={doc} />
    </Container>
  );
}

const Container = styled.div`
  ${ADMIN_BACKGROUND_CONTENT};
  max-height: 100vh;
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  padding: 16px;
`;

const Section = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const InfoTab = styled(Tab)`
  padding-top: 16px;
`;

const RewardTab = styled(Tab)`
  padding-top: 16px;
  overflow-x: hidden;
`;

const HelperText = styled.div`
  font-size: 14px !important;
  line-height: 18px !important;
  display: flex;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 16px;
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
`;

const LabelContainer = styled.div`
  display: flex;
`;

const WarningLabel = styled(Label)`
  color: ${ADMIN_ALERT_WARNING};
  margin-left: ${SPACING_SMALL};
`;

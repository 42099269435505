import styled, { css } from 'styled-components';
import { ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_300, ADMIN_TEXT_100 } from 'style/constants';
import { FLEX_CENTER } from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';

export const PaginationContainer = styled.div`
  ${FLEX_CENTER}
  user-select: none;
`;

export const PaginationTitle = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_300};
  padding-right: 17px;
`;

export const PageSelectorContainer = styled.div`
  ${FLEX_CENTER}
`;

export const PageButtonContainer = styled.div`
  ${FLEX_CENTER}
  padding: 12px;
  margin: 0 -5px;
`;

export const PageButton = styled.div<{active?: boolean, ellipsis?: boolean}>`
  ${FLEX_CENTER}
  ${ADMIN_TEXT_LABEL_L_MEDIUM}
  color: ${({ active }) => active ? ADMIN_TEXT_100 : ADMIN_TEXT_300};
  background: ${({ active, ...props }) => active ? ADMIN_ACCENT_PRIMARY(props) : 'transparent'};
  border-radius: 5px;
  min-width: 23px;
  height: 22px;
  margin: 0 5px;
  cursor: pointer;
  padding: 0 5px;
  ${({ ellipsis }) => ellipsis && css`
    cursor: default;
    &:after {
      content: "...";
    }
  `}
`;

import React from 'react';
import JsonDebug from 'components/dev/JsonDebug';
import { IShopifyBlockData } from 'components/page-blocks/Shopify/utils';
import { SHOPIFY_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';
import BlockSettings from 'components/page-blocks/Shopify/BlockSettings';

interface IShopifyBlockPanelRenderer {
  blockData: IShopifyBlockData;
  iconName: string;
  id: string;
  panelType: typeof SHOPIFY_BLOCK_PANEL_ID;
}

interface IShopifyBlockPanelEditorProps {
  onChange: (obj: IShopifyBlockPanelRenderer) => void;
  renderer: IShopifyBlockPanelRenderer;
}

const ShopifyBlockPanelEditor = ({ renderer, onChange }: IShopifyBlockPanelEditorProps) => {
  const handleChangeBlockData = React.useCallback((blockData: IShopifyBlockData) => {
    onChange({
      ...renderer,
      blockData,
    });
  }, [onChange, renderer]);

  return (
    <>
      <BlockSettings region="panel" item={renderer.blockData} onChange={handleChangeBlockData} />
      <JsonDebug value={renderer} />
    </>
  );
};

export default ShopifyBlockPanelEditor;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'hooks';
import { getCustomTextModal } from 'services/app/selectors';
import hash from 'json-stable-stringify';
import isEqual from 'lodash/isEqual';
import { saveSiteSettings, settingsTypes } from 'services/admin';
import { getAdminAlertError } from 'services/themes';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import TranslatedText from 'components/i18n/TranslatedText';
import Divider from 'components/admin2/ui/Divider';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import TextInput from 'components/admin2/TextInput';
import { ErrorMessage } from './styles';

const LogInTab = () => {
  const currentCustomModaltext = useSelector(getCustomTextModal, isEqual);
  const alertError = useSelector(getAdminAlertError);
  const dispatch = useDispatch();
  const [newCustomModalText, setCustomModalText] = useState({ ...currentCustomModaltext });
  const [needSaved, setNeedSaved] = useState(false);
  const [hasOptinError, setOptinError] = useState(newCustomModalText.marketingOptin &&
                                                  !newCustomModalText.marketingOptinLabel);
  const [hasPrivacyError, setPrivacyError] = useState(false);
  const [hasTermsError, setTermsError] = useState(false);

  const [saved, setSaved] = useState(false);

  useEffect(() => {
    setNeedSaved(!isEqual(currentCustomModaltext, newCustomModalText) &&
      (!hasTermsError && !hasPrivacyError && !hasOptinError));
  }, [
    hash(currentCustomModaltext),
    hash(newCustomModalText),
    hasTermsError,
    hasPrivacyError,
    hasOptinError,
  ]);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
  }, [hash(newCustomModalText)]);

  const handleSave = useCallback(() => {
    dispatch(saveSiteSettings(settingsTypes.LOGIN, newCustomModalText));
    setSaved(true);
  }, [
    hash(newCustomModalText),
  ]);

  const isUrl = (url) => {
    const match = /^(https):\/\//i;
    const str = url;
    const beginsWithHttp = !url.length || match.test(str);
    return beginsWithHttp;
  };

  return (
    <AdminBarTab>
      <AdminBarTabHeader
        hasUnsavedChanges={needSaved}
        headerKey="ADMIN_LABEL_SETTINGS"
        onSave={() => handleSave(newCustomModalText)}
        saved={saved}
        subHeaderKey="ADMIN_SETTINGS_LOGIN"
        subtitleKey="ADMIN_LABEL_LOG_IN"
      />
      <TextInput
        descriptionKey="ADMIN_CUSTOM_MODAL_TEXT"
        labelIcon="designtabText"
        labelIconSize={15}
        labelKey="ADMIN_CUSTOM_MODAL_TEXT_LABEL"
        onChange={(text) => {
          setCustomModalText((prevModalText) => ({ ...prevModalText, text }));
        }}
        value={newCustomModalText.text}
      />
      <TextInput
        descriptionKey="ADMIN_TERMS_OF_SERVICE_URL_DESCRIPTION"
        inputBorder={hasTermsError ? `1px solid ${alertError}` : undefined}
        labelIcon="linkOutline"
        labelKey="ADMIN_TERMS_OF_SERVICE_URL"
        onChange={(url) => {
          setTermsError(!isUrl(url));
          setCustomModalText((prevModalText) => ({ ...prevModalText, terms: url }));
        }}
        value={newCustomModalText.terms}
      />
      {hasTermsError && (
        <ErrorMessage>
          <TranslatedText stringKey="URL_MUST_BEGIN_WITH_HTTPS" />
        </ErrorMessage>
      )}
      <TextInput
        descriptionKey="ADMIN_PRIVACY_POLICY_URL_DESCRIPTION"
        inputBorder={hasPrivacyError ? `1px solid ${alertError}` : undefined}
        labelIcon="linkOutline"
        labelKey="ADMIN_PRIVACY_POLICY_URL"
        onChange={(url) => {
          setPrivacyError(!isUrl(url));
          setCustomModalText((prevModalText) => ({ ...prevModalText, privacy: url }));
        }}
        value={newCustomModalText.privacy}
      />
      {hasPrivacyError && (
        <ErrorMessage>
          <TranslatedText stringKey="URL_MUST_BEGIN_WITH_HTTPS" />
        </ErrorMessage>
      )}
      <Divider />
      <LabelToggle
        checked={newCustomModalText.marketingOptin}
        labelKey="ADMIN_MARKETING_OPTIN_TOGGLE"
        onChange={status => {
          setOptinError(status && !newCustomModalText.marketingOptinLabel?.trim());
          setCustomModalText((prevModalText) => ({ ...prevModalText, marketingOptin: status }));
        }}
        uppercase
      />
      {newCustomModalText.marketingOptin && (
        <TextInput
          descriptionKey="ADMIN_MARKETING_OPTIN_DESCRIPTION"
          errorKey="ADMIN_LABEL_REQUIRED"
          hasError={hasOptinError}
          labelKey="ADMIN_MARKETING_OPTIN_LABEL"
          onChange={(message) => {
            setOptinError(!message?.trim());
            setCustomModalText((prevModalText) => ({
              ...prevModalText,
              marketingOptinLabel: message,
            }));
          }}
          value={newCustomModalText.marketingOptinLabel}
        />
      )}
    </AdminBarTab>
  );
};

export default LogInTab;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import MediaLibraryTable from 'components/admin2/MediaLibraryTable';
import TranslatedText from 'components/i18n/TranslatedText';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import SearchInput from 'components/admin2/ui/SearchInput';
import Label from 'components/admin2/ui/Label';
import { LargeButton } from 'components/admin2/ui/Button';
import {
  SPACING_LARGE,
  ADMIN_SURFACE_4,
  ADMIN_LIBRARY_MODAL_WIDTH_WITH_UNITS,
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_200,
} from 'style/constants';
import {
  ADMIN_BACKGROUND_CONTENT,
} from 'style/mixins';
import {
  ASC_KEY,
  DESC_KEY,
  NONE_KEY,
} from 'components/admin2/MediaLibrary/constants';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

// TODO responsive widths
const Container = styled.div`
  height: 700px;
  width: ${ADMIN_LIBRARY_MODAL_WIDTH_WITH_UNITS};
  background-color: ${ADMIN_SURFACE_4};
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  & .ReactTable.ReactTable.admin-dark .rt-tbody .rt-tr {
    background-color: ${ADMIN_SURFACE_2};
    :hover {
      background-color: ${ADMIN_SURFACE_5};
    }
  }
`;

const Header = styled.div`
  padding: ${SPACING_LARGE} ${SPACING_LARGE} 0 ${SPACING_LARGE};
  background-color: ${ADMIN_SURFACE_4};
  display: flex;
  flex-shrink: 0;
  flex-flow: row nowrap;
  min-height: 84px;
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const UpperFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LowerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
`;

const Button = styled(LargeButton)`
  &:not(:first-child) {
    margin-left: ${SPACING_LARGE};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  margin-top: ${SPACING_LARGE};
  width: 100%;
  padding-left: 360px;
`;

const HelperText = styled.div`
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  width: 100%;
`;

const SearchContainer = styled.div`
  ${ADMIN_BACKGROUND_CONTENT}
  display: flex;
  align-items: center;
  padding: 22px;
  padding-bottom: 6px;
  & > div {
    width: 100%;
  }
`;

const Placeholder = styled(LoadingSpinner).attrs({
  fade: false,
})`
  padding: 16px;
  & * {
    color: white;
  }
`;

const TableContainer = styled.div`
  padding: 13px;
  max-height: calc(100% - 170px);
`;

const SORT_TYPES = [ASC_KEY, DESC_KEY];

const getNextSortType = (currentType) => {
  const currentIndex = SORT_TYPES.indexOf(currentType);
  return SORT_TYPES[(currentIndex + 1) % SORT_TYPES.length];
};

const transformColumns = columns => (
  columns.map(colData => ({
    ...colData,
    sort: colData.sort || NONE_KEY,
  }))
);

export default class MediaLibrary extends React.Component {
  static propTypes = {
    adminText100: PropTypes.string.isRequired,
    buttonProps: PropTypes.oneOfType([
      PropTypes.shape({
        labelKey: PropTypes.string,
        onClick: PropTypes.func,
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          labelKey: PropTypes.string,
          onClick: PropTypes.func,
        }),
      ),
    ]),
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    disableEditing: PropTypes.bool,
    disableKeyClick: PropTypes.bool,
    disableSelecting: PropTypes.bool,
    helperText: PropTypes.string,
    list: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    loaded: PropTypes.bool,
    onDeleteItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onKeyClick: PropTypes.func,
    onSearch: PropTypes.func,
    onSelectItem: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    searchPlaceholderKey: PropTypes.string,
    setLoading: PropTypes.func,
    showHeader: PropTypes.bool,
    testIdAddButton: PropTypes.string,
    testIdSearcher: PropTypes.string,
    testIdTable: PropTypes.string,
    titleKey: PropTypes.string.isRequired,
    type: PropTypes.string,
  };

  static defaultProps = {
    buttonProps: null,
    className: '',
    disableEditing: false,
    disableKeyClick: true,
    disableSelecting: false,
    helperText: '',
    list: [],
    loaded: true,
    onDeleteItem: null,
    onEditItem: () => null,
    onKeyClick: () => null,
    onSearch: () => { },
    searchPlaceholderKey: null,
    setLoading: () => { },
    showHeader: true,
    testIdAddButton: undefined,
    testIdSearcher: undefined,
    testIdTable: undefined,
    type: '',
  };

  state = {
    // eslint-disable-next-line
    columns: transformColumns(this.props.columns),
    itemSelected: false,
  };

  handleSelectTimeout;

  componentWillUnmount() {
    clearTimeout(this.handleSelectTimeout);
  }

  handleSearch = (searchText) => {
    const { onSearch, setLoading } = this.props;
    setLoading();
    onSearch(searchText);
  };

  handleHeaderClick = (index) => {
    const { onSort } = this.props;
    const { columns: oldColumns } = this.state;
    const columns = oldColumns.map(({ sort, ...colData }, i) => {
      if (i === index) {
        return {
          sort: getNextSortType(sort),
          ...colData,
        };
      }
      return { sort: NONE_KEY, ...colData };
    });
    this.setState({ columns });
    const sortData = columns[index];
    onSort(sortData);
  };

  handleSelectItem = (index) => {
    const { onSelectItem, list } = this.props;
    onSelectItem({ ...list[index] });
    this.setState({ itemSelected: true });

    this.handleSelectTimeout = setTimeout(() => {
      this.setState({ itemSelected: false });
    }, 2000);
  };

  handleEditItem = (index) => {
    const { onEditItem, list } = this.props;
    onEditItem({ ...list[index] });
  };

  handleClickItem = (index) => {
    const { onKeyClick, list } = this.props;
    onKeyClick({ ...list[index] });
  };

  handleDeleteItem = (index) => {
    const { onDeleteItem, list } = this.props;
    onDeleteItem({ ...list[index] });
  };

  renderButtons() {
    const { buttonProps } = this.props;
    const buttonFromProps = ({ onClick, labelKey, testId }) => (
      <Button key={labelKey} data-testid={testId} onClick={onClick}>
        { labelKey && <TranslatedText stringKey={labelKey} />}
      </Button>
    );

    if (Array.isArray(buttonProps)) {
      return buttonProps.map(buttonFromProps);
    }
    return buttonFromProps(buttonProps);
  }

  render() {
    const {
      buttonProps,
      helperText,
      loaded,
      titleKey,
      searchPlaceholderKey,
      list,
      disableEditing,
      disableSelecting,
      showHeader,
      className,
      onDeleteItem,
      disableKeyClick,
      type,
      testIdAddButton,
      testIdSearcher,
      testIdTable,
      adminText100,
    } = this.props;
    const {
      columns,
      itemSelected,
    } = this.state;
    return (
      <Container className={className} data-testid="media-library">
        {
          showHeader && (
            <Header>
              <TitleContainer>
                <UpperFlex>
                  <Label
                    color={adminText100}
                    compact
                    labelKey={titleKey}
                    textSize="h2"
                  />
                </UpperFlex>
                <LowerFlex>
                  {helperText && (
                    <HelperText>
                      {/* TODO: translate here */}
                      <TranslatedText stringKey={helperText} />
                    </HelperText>
                  )}
                  {(type === 'playlistVideoSelect' && itemSelected) && (
                  <TextWrapper>
                    <TranslatedText stringKey="ADMIN_LABEL_VIDEO_ADDED" />
                  </TextWrapper>
                  )}
                </LowerFlex>
              </TitleContainer>
              {buttonProps && (
                <ButtonWrapper>
                  { this.renderButtons() }
                </ButtonWrapper>
              )}
            </Header>
          )
        }
        {
          searchPlaceholderKey && (
            <SearchContainer>
              <SearchInput
                data-testid={testIdSearcher || 'media-library-search-input'}
                onSearch={this.handleSearch}
                padding="10px 6px 0 10px"
                searchPlaceholderKey={searchPlaceholderKey}
              />
            </SearchContainer>
          )
        }
        {
          !loaded && <Placeholder />
        }
        {
          loaded && list && (
            <TableContainer data-testid={testIdTable}>
              <MediaLibraryTable
                columns={columns}
                data={list}
                disableEditing={disableEditing}
                disableKeyClick={disableKeyClick}
                disableSelecting={disableSelecting}
                onDeleteClick={onDeleteItem ? this.handleDeleteItem : null}
                onEditClick={this.handleEditItem}
                onHeaderClick={this.handleHeaderClick}
                onKeyClick={this.handleClickItem}
                onSelectItem={this.handleSelectItem}
                testIdAddButton={testIdAddButton}
              />
            </TableContainer>
          )
        }
      </Container>
    );
  }
}

import styled, { css } from 'styled-components';
import { ADMIN_BUTTON_BORDER_RADIUS, ADMIN_SURFACE_3, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_300, ADMIN_TEXT_200, ALERT_WARNING, SPACING_SMALL } from 'style/constants';
import withTooltip from 'components/core/withTooltip';
import { IconButtonBase } from 'components/ui/Button';
import { ADMIN_TEXT_LABEL_XS_MEDIUM, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_LABEL_S_MEDIUM, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import AdminButton from 'components/admin2/ui/Button';
import type { TranslationKey } from 'hooks/use-translation';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HeaderText = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
`;

export const LabelToggleButton = styled(LabelToggle)`
  && div label div {
    color: ${ADMIN_TEXT_200};
  }
`;

export const HeaderTextDefaultLang = styled.div`
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
  color: ${ADMIN_TEXT_100};
`;

export const HeaderTextSupportedLang = styled.div`
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
  color: ${ADMIN_TEXT_200};
`;

export const CardContainer = styled.div<{ isValid: boolean }>`
  background: ${ADMIN_SURFACE_3};
  height: ${props => (props.isValid ? '52px' : '64px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING_SMALL} ${SPACING_SMALL};
  border-radius: 4px;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 24px;
`;

export const LanguageCardTitle = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD};
  ${ADMIN_TEXT_100};
`;

export const LanguageCardSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
`;

export const LanguageCardDescription = styled.div`
  ${ADMIN_TEXT_LABEL_XS_MEDIUM};
  ${ADMIN_TEXT_300};
`;

export const LanguageCardError = styled.div`
  ${ADMIN_TEXT_LABEL_XS_MEDIUM};
  color: ${ALERT_WARNING};
  position: relative;
  flex-shrink: 0;
  margin-left: 6px;

  ::before {
    content: '';
    width: 4px;
    min-width: 4px;
    height: 4px;
    border-radius: 50%;
    left: -6px;
    position: absolute;
    top: calc(50% - 2px);
    background: #F4D73D;
  }
`;

const iconTooltip = <T>(component: T, tooltipKey?: TranslationKey) => (
  withTooltip(component, { admin: true, tooltipKey })
);

const baseIconButtonCss = css`
  border-radius: 50%;
  padding: 0;
  width: 24px;
  height: 24px;
  min-width: 24px;
  cursor: pointer;
  & svg {
    width: 14px;
    height: 14px;
  }
`;

export const DownloadButton = iconTooltip(styled(IconButtonBase).attrs(props => ({
  ...props,
  name: 'download',
}))`
  ${baseIconButtonCss};
`, 'ADMIN_LABEL_DOWNLOAD');

export const UploadButton = iconTooltip(styled(IconButtonBase).attrs(props => ({
  ...props,
  name: 'uploadFile',
}))`
  ${baseIconButtonCss};
`, 'ADMIN_LABEL_UPLOAD');

export const RemoveButton = iconTooltip(styled(IconButtonBase).attrs(props => ({
  ...props,
  name: 'trash',
}))`
  ${baseIconButtonCss};
`, 'REMOVE');

export const AddLanguageButton = styled(AdminButton)`
  height: 42px;
  background: ${ADMIN_SURFACE_5};
  ${ADMIN_TEXT_LABEL_S_MEDIUM};
  color: ${ADMIN_TEXT_100};
  border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};
`;

/* tslint:disable: jsx-boolean-value */
import React, { ChangeEvent, HTMLAttributes, useMemo } from 'react';
import withLabel from 'components/core/withLabel';
import withPadding from 'components/core/withPadding';
import {
  URLLabelContainer,
  Constant,
  Input,
} from './styles';

export interface URLInputProps extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  hostname?: string;
  onChange?: (url: string) => void;
  protocol?: string;
  url?: string;
}

function URLInput({ className, onChange, protocol = 'https', hostname = 'maestro.io', url, ...props }: URLInputProps) {
  const subdomain = useMemo(() => {
    if (!url) {
      return '';
    }
    let validatedURL = url;
    if (!/^(?:f|ht)tps?\:\/\//.test(validatedURL)) {
      validatedURL = `https://${validatedURL}`;
    }
    const URLObject = new URL(validatedURL);
    return URLObject.hostname.split(hostname, 1)?.[0]?.slice(0, -1) || '';
  }, [url]);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange?.(`${protocol}://${value}${value && '.'}${hostname}`);
  };

  return (
    <URLLabelContainer className={className}>
      <Constant>{protocol}://</Constant>
      <Input onChange={handleChange} defaultValue={subdomain} {...props} />
      <Constant right>{hostname}</Constant>
    </URLLabelContainer>
  );
}

export default withPadding(withLabel(URLInput));

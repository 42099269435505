import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import Icon from 'components/ui/Icon';
import {
  ADMIN_ALERT_CONFIRMATION,
  ADMIN_TEXT_500,
} from 'style/constants';
import {
  ADMIN_TEXT_TITLE_FIELD_ALERT,
  ADMIN_TEXT_TITLE_FIELD_SUCCESS,
  ADMIN_TEXT_TITLE_FIELD_ERROR,
  CIRCLE,
  FLEX_CENTER,
} from 'style/mixins';
import type { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';

interface StatusIndicatorProps {
  className?: string,
  errorKey?: TI18nKey | null,
  fetching: boolean,
  fetchingLabelKey?: TI18nKey,
  loaded: boolean,
}

export default function StatusIndicator({
  className,
  errorKey,
  fetching,
  fetchingLabelKey = 'ADMIN_LABEL_FETCHING_METADATA',
  loaded,
}: StatusIndicatorProps) {
  const [hide, setHide] = React.useState(true);

  useEffect(() => {
    setHide(false);
    const timeoutId = setTimeout(() => setHide(true), 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [loaded, errorKey, fetching]);

  return useMemo(() => hide ? null : (
    <>
      {
        loaded && (
          <StyledStatusIndicator className={className} status="loaded">
            <Checkmark />
            <TranslatedText stringKey="ADMIN_LABEL_SUCCESS" />
          </StyledStatusIndicator>
        )
      }
      {
        errorKey && (
          <StyledStatusIndicator className={className} status="error">
            <TranslatedText stringKey={errorKey} />
          </StyledStatusIndicator>
        )
      }
      {
        fetching && (
          <StyledStatusIndicator className={className} status="fetching">
            <TranslatedText stringKey={fetchingLabelKey} />
          </StyledStatusIndicator>
        )
      }
    </>
  ), [loaded, errorKey, fetching, hide]);
}

const StyledStatusIndicator = styled.div<{status: string}>`
  ${props => props.status === 'fetching' && ADMIN_TEXT_TITLE_FIELD_ALERT}
  ${props => props.status === 'loaded' && ADMIN_TEXT_TITLE_FIELD_SUCCESS}
  ${props => props.status === 'error' && ADMIN_TEXT_TITLE_FIELD_ERROR}
  display: flex;
  text-transform: lowercase;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
`;

const Checkmark = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_500(props),
  name: 'successCheckmark',
  radius: '16px',
}))`
  ${CIRCLE}
  ${FLEX_CENTER}
  background: ${ADMIN_ALERT_CONFIRMATION};
  color: ${ADMIN_TEXT_500};
  padding: 3px;
  margin-right: 10px;
  & svg {
    width: 10px;
    height: 10px;
  }
`;

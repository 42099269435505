import themeCardMobile from 'assets/icons/theme-card-mobile.svg';
import themeCardDesktop from 'assets/icons/theme-card-desktop.svg';
import React from 'react';
import {
  SvgContainer,
} from './styles';
import { IThemeColors } from 'models';
import { THEME_TYPES, ThemeTypes } from 'models/ITheme';

interface IThemeCardProps {
  height?: number;
  mobile?: boolean;
  onClick?: () => void;
  themeColors: IThemeColors;
  type: ThemeTypes;
  width?: number;
}

const ThemeCard = ({
  height,
  mobile = false,
  onClick,
  themeColors,
  type = THEME_TYPES.CLASSIC,
  width,
}: IThemeCardProps) => {
  const renderedCard = React.useMemo(() => {
    if (mobile) {
      return themeCardMobile;
    }
    return themeCardDesktop;
  }, [mobile]);

  return (
    <SvgContainer
      dangerouslySetInnerHTML={{ __html: renderedCard }}
      mobile={mobile}
      type={type}
      themeColors={themeColors}
      width={width}
      height={height}
      onClick={onClick}
    />
  );
};

export default ThemeCard;

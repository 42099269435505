import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminTranslation } from 'hooks/use-translation';
import { getDefaultNavigation, updateNavigation } from 'services/navigationv2';
import { FolderParentPageContainer, StyledDropdown } from './styles';
import { getPageId, getPageName, getPageSlug, getPageType } from 'services/app';
import INavigation from 'models/INavigation';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { dismissModal, showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { constructPathForSlug } from 'services/navigationv2/utils';
import { replace } from 'services/app-router';
import Label from 'components/admin2/ui/Label';

const FolderParentPageField = () => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const defaultNavigation = useSelector(getDefaultNavigation) as INavigation;
  const currentPageId = useSelector(getPageId);
  const currentPageSlug = useSelector(getPageSlug);
  const currentPageType = useSelector(getPageType);
  const currentPageName = useSelector(getPageName);

  const dropdownOptions = useMemo(() => {
    return [
      { id: 'none', name: t('ADMIN_LABEL_NONE') },
      ...(defaultNavigation?.parents || [])
        .filter((parent) => parent.id !== currentPageId)
        .filter((parent) => parent.type !== 'channel'),
    ];
  }, [defaultNavigation]);

  const [selectedParent, setSelectedParent] = useState<Record<string, any>>(() => {
    const parent = defaultNavigation?.parents.find((prt) =>
      prt.children.some((child) => child.id === currentPageId),
    );
    return parent || { id: 'none', name: t('ADMIN_LABEL_NONE') };
  });

  const updateActivePagePath = (updatedNavigation: INavigation) => {
    const path = constructPathForSlug(updatedNavigation, currentPageSlug);
    return dispatch(replace({ path }));
  };

  const handleParentPageChange = useCallback(
    (newSelectedParent: Record<string, any>) => {
      const previousParent = selectedParent;
      setSelectedParent(newSelectedParent);

      const updatedNavigation: INavigation = {
        ...defaultNavigation,
      };

      const currentPage = {
        id: currentPageId,
        type: currentPageType,
        children: [],
        name: currentPageName!,
        slug: currentPageSlug,
      };

      // Filter current page from root and all parents
      updatedNavigation.parents = updatedNavigation.parents.map((parent) => ({
        ...parent,
        children: parent.children.filter((child) => child.id !== currentPageId),
      }));

      updatedNavigation.parents = updatedNavigation.parents.filter((parent) => parent.id !== currentPageId);

      // If selected parent is none, add the current page to the root level
      if (newSelectedParent?.id === 'none') {
        updatedNavigation.parents.unshift(currentPage);
      } else {
        const parentIndex = updatedNavigation.parents.findIndex((parent) => parent.id === newSelectedParent?.id);
        updatedNavigation.parents[parentIndex].children.push(currentPage);
      }

      const isEqual = JSON.stringify(defaultNavigation.parents) === JSON.stringify(updatedNavigation.parents);
      if (isEqual) return;

      const titleKey: TI18nKey =
        currentPageType === 'landing'
          ? 'ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_PAGE_CONFIRMATION_TITLE'
          : 'ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_CHANNEL_CONFIRMATION_TITLE';
      const subtitleKey: TI18nKey =
        currentPageType === 'landing'
          ? 'ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_PAGE_CONFIRMATION_SUBTITLE'
          : 'ADMIN_SITE_STRUCTURE_DRAG_AND_DROP_CHANNEL_CONFIRMATION_SUBTITLE';

      return dispatch(
        showModal({
          kind: ModalKinds.adminConfirmation,
          data: {
            onConfirmClick: () => {
              dispatch(updateNavigation(updatedNavigation));
              dispatch(dismissModal('adminConfirmation'));
              updateActivePagePath(updatedNavigation);
            },
            onDismiss: () => {
              setSelectedParent(previousParent);
            },
            titleKey,
            subtitleKey,
          },
        }),
      );
    },
    [defaultNavigation, currentPageId, currentPageSlug, currentPageType, currentPageName, selectedParent, dispatch],
  );

  const getOptionLabel = (option: Record<string, any>) => option.name;

  return (
    <FolderParentPageContainer>
      <Label
        labelKey="ADMIN_LABEL_FOLDER_OR_PARENT_PAGE_TAG"
        descriptionKey="ADMIN_LABEL_FOLDER_OR_PARENT_PAGE_DESCRIPTION"
      />
      <StyledDropdown
        admin={true}
        options={dropdownOptions}
        valueKey="name"
        getOptionLabel={getOptionLabel}
        value={selectedParent}
        onChange={handleParentPageChange}
        padding="0"
      />
    </FolderParentPageContainer>
  );
};

export default FolderParentPageField;

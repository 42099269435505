import styled from 'styled-components';
import Dropdown from 'components/admin2/ui/Dropdown';
import { ADMIN_TEXT_100 } from 'style/constants';

export const TimeContainer = styled.div`
  display: flex;
  margin-left: -1.5px;
  flex-wrap: nowrap;
  align-items: center;
  && {
    color: ${ADMIN_TEXT_100};
  }
`;

export const TimeDropdown = styled(Dropdown).attrs({
  compact: true,
})`
  margin: 0 1.5px;
` as typeof Dropdown;

export const PeriodDropdown = styled(Dropdown).attrs({
  compact: true,
})`
  margin-left: 5.5px;
` as typeof Dropdown;

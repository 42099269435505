import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { DescriptionWithoutPadding, TitleWithoutPadding } from 'components/admin2/ui/Text';
import {
  ADMIN_TEXT_200,
  ADMIN_TEXT_100,
} from 'style/constants';
import {
  TEXT_P1,
  TEXT_P2,
  TEXT_H1,
  TEXT_H2,
  TEXT_H3,
  TEXT_H4,
  TEXT_H5,
  TEXT_H6,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_M_REGULAR, ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_BODY_XL_REGULAR, ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_BODY_XXL_REGULAR, ADMIN_TEXT_HEADLINE_M, ADMIN_TEXT_HEADLINE_S } from 'style/design-system/textStyles';

interface StyleProps {
  admin?: boolean;
  bold?: boolean;
  color?: string;
  maxWidth?: string;
  spacing?: string;
  textSize?: string;
  uppercase?: boolean;
}

const ADMIN_TEXT_SIZE_MAP: Record<string, ReturnType<typeof css>> = {
  p1: ADMIN_TEXT_BODY_M_REGULAR,
  p2: ADMIN_TEXT_BODY_S_REGULAR,
  h1: ADMIN_TEXT_HEADLINE_M,
  h2: ADMIN_TEXT_HEADLINE_S,
  h3: ADMIN_TEXT_BODY_XXL_REGULAR,
  h4: ADMIN_TEXT_BODY_XL_REGULAR,
  h5: ADMIN_TEXT_BODY_S_REGULAR,
  h6: ADMIN_TEXT_BODY_XS_REGULAR,
};

const TEXT_SIZE_MAP = {
  p1: TEXT_P1,
  p2: TEXT_P2,
  h1: TEXT_H1,
  h2: TEXT_H2,
  h3: TEXT_H3,
  h4: TEXT_H4,
  h5: TEXT_H5,
  h6: TEXT_H6,
};

export const Container = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth = '100%' }) => maxWidth};
  ${({ admin, bold = true, color, textSize: size = 'h5', uppercase }) => css`
    & ${TitleWithoutPadding as any} {
      ${color && css`color: ${color};`}
      ${size in TEXT_SIZE_MAP ?
        (admin ? ADMIN_TEXT_SIZE_MAP : TEXT_SIZE_MAP)[size as keyof typeof TEXT_SIZE_MAP] :
        `font-size: ${size}`
      }
      ${bold && 'font-weight: bold;'}
    }
  `}
  margin: ${({ spacing = 0 }) => spacing};
`;

export const LabelContainer = styled.label`
  display: flex;
  align-items: center;
`;

interface LabelIconProps {
  alignRight?: boolean;
  color?: string;
  iconCss?: ReturnType<typeof css> | string;
  size?: number;
  spacing?: number;
  white?: boolean;
}

export const StyledIcon = styled(Icon).attrs<LabelIconProps>(({ color = ADMIN_TEXT_200, white, ...props }) => ({
  color: white ? ADMIN_TEXT_100(props) : color,
})) <LabelIconProps>`
  margin-left: 5px;
  margin-${({ alignRight }) => (
    alignRight ? 'left' : 'right'
  )}: ${({ spacing = 10 }) => spacing}px;
  line-height: 0;
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size = 18 }) => size && css`
    & svg {
      width: ${size}px;
      height: ${size}px;
    }
  `}
  ${({ iconCss }) => iconCss}
`;

export const Title = styled(TitleWithoutPadding)`
  overflow: unset;
`;

export const StyledLabelHint = styled(DescriptionWithoutPadding)`
  overflow: unset;
  margin-left: 6px;
`;

export const TitleContainer = styled.label<{ compact?: boolean, padding?: string }>`
  align-items: center;
  display: flex;
  justify-content: left;
  padding: ${({ padding }) => padding || '10px 0'};
  ${({ compact }) => compact && css`padding: 0;`}
`;

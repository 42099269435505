import React, { InputHTMLAttributes, ReactNode, useMemo, ChangeEvent } from 'react';
import type { css } from 'styled-components';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import {
  Container,
  Box,
  Input,
  CheckIcon,
  Label,
  LabelText,
  Radio,
  StyledDescription,
} from './styles';
import withPadding from 'components/core/withPadding';
import { isMobileLayout } from 'services/device';
import { useSelector } from 'react-redux';
import type { TranslationKey } from 'hooks/use-translation';

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  descriptionKey?: TranslationKey;
  label?: string;
  labelKey?: TranslationKey;
  onChange?: (() => void )| ((e: any) => void);
  onCheckedChange?: (checked: boolean) => void;
  testId?: string;
}

export function Checkbox({
  children,
  className,
  descriptionKey,
  label,
  labelKey,
  onChange,
  onCheckedChange,
  testId = 'checkbox',
  type = 'checkbox',
  ...props
}: CheckboxProps) {
  const isMobile = useSelector(isMobileLayout);
  const renderIcon = React.useCallback(() => {
    return type === 'checkbox' ? <CheckIcon /> : <Radio />;
  }, [type]);

  const inputProps = useMemo(() => {
    return {
      ...props,
      ...!isMobile && { onChange },
    };
  }, [props, isMobile]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange?.(e);
    onCheckedChange?.(e.target.checked);
  };

  return (
    <Container onClick={isMobile ? onChange : undefined} data-testid={testId} className={className}>
      <Label type={type}>
        <Box type={type}>
          <Input type={type} {...inputProps} onChange={handleChange} />
          {renderIcon()}
        </Box>
        <OptionalTranslated
          component={LabelText}
          stringKey={labelKey}
        >
          {label}
        </OptionalTranslated>
      </Label>
      <OptionalTranslated component={StyledDescription} stringKey={descriptionKey}>
        {children}
      </OptionalTranslated>
    </Container>
  );
}

export default withPadding(Checkbox);

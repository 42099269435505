import styled from 'styled-components';
import TextInput from 'components/admin2/TextInput';
import TextArea from 'components/admin2/TextArea';
import UploadZone from 'components/admin2/UploadZone';
import IconButton from 'components/admin2/ui/IconButton';
import IconDropdown from 'components/admin2/ui/IconDropdown';
import BackButton from 'components/admin2/NavControls/BackButton';
import {
  SPACING_SMALL,
  SPACING_MEDIUM,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_6,
  ADMIN_TEXT_100,
  ADMIN_SURFACE_1,
} from 'style/constants';
import { ADMIN_TEXT_TITLE_L } from 'style/design-system/textStyles';

// TODO responsive, fix these magic heights and widths

export const Container = styled.div`
  display: flex;
  width: 664px;
  height: 100%;
  min-height: 500px;
  background-color: ${ADMIN_SURFACE_4};
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px;
  height: 86px;
  font-weight: bold;
  border-bottom: 0.5px solid ${ADMIN_SURFACE_6};
`;

export const HeaderTitle = styled.div`
  ${ADMIN_TEXT_TITLE_L}
  color: ${ADMIN_TEXT_100};
  margin-right: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: ${SPACING_MEDIUM};
`;

export const StyledBackButton = styled(BackButton).attrs(props => ({
  background: ADMIN_SURFACE_1(props),
  large: true,
}))``;

export const SaveButtonWrapper = styled.div`
  display: flex;
`;

export const SaveButton = styled(IconButton).attrs({
  icon: 'librarySave',
  iconSize: 18,
  iconSpacing: 12,
  kind: 'large',
})`
  padding: 0 21px;
`;

export const InputContainer = styled.div`
  flex: 1;
`;

export const Halves = styled.div`
  display: flex;
  margin-bottom: ${SPACING_SMALL};
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${SPACING_SMALL};

`;

export const Gutter = styled.div`
  width: 16px;
`;

export const StyledTextArea = styled(TextArea)`
  height: 201px;
`;

export const StyledUploadZone = styled(UploadZone).attrs({
  height: 163,
})``;

export const ListLink = styled.div`
  padding: ${SPACING_SMALL};
`;

export const LinkFlex = styled.div`
  display: flex;
`;

export const ListLinkTextInput = styled(TextInput)`
  flex: 1;
`;

export const StyledIconPicker = styled(IconDropdown)``;

import { ADMIN_SURFACE_4 } from 'style/constants';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import styled from 'styled-components';
import CmsNavHeader from '../CmsNavHeader';
import TextArea from '../TextArea';
import UploadZone from '../UploadZone';

const IMAGE_ACCEPTED_TYPES = 'image/jpeg, image/png, image/jpg';

export const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  ${ADMIN_SCROLL_BAR}
  background-color: ${ADMIN_SURFACE_4};
  max-height: 60vh !important;
  padding-right: 6px;
  position: relative;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  max-width: 544px;
`;

export const PaddingWrapper = styled.div`
  background-color: ${ADMIN_SURFACE_4};
  padding: 30px 24px 20px 30px;
  border-radius: 0 0 10px 10px;
`;

export const StyledCmsNavHeader = styled(CmsNavHeader)`
  border-radius: 10px 10px 0 0;
`;


export const StyledTextArea = styled(TextArea).attrs(() => ({
  'data-testid': 'bundleDescriptionInput',
  descriptionKey: 'BUNDLE_DESCRIPTION',
  labelKey: 'ADMIN_LABEL_DESCRIPTION',
  maxlength: '250',
  placeholderKey: 'ADMIN_PLACEHOLDER_ENTER_A_DESCRIPTION',
  rows: 5,
}))`
  flex-direction: row;
  margin-bottom: 0;
`;

export const StyledUploadZone = styled(UploadZone).attrs({
  acceptedTypes: IMAGE_ACCEPTED_TYPES,
  canDrop: true,
  dimensionRequirements: {
    pixelHeight: 270,
    pixelWidth: 480,
    exactDimensions: true,
    verify: true,
  },
  height: 270,
})`padding: 0;`;

import React, { useCallback, useMemo } from 'react';
import { BUY } from 'injection-classes';
import { StyledButton, EntitlementPurchaseText, EntitlementPrice } from './styles';
import { formatPrice } from 'shared/string-utils';
import FormattedPriceWithSuffix from 'components/payment/FormattedPriceWithSuffix';
import IBundle from 'models/IBundle';
import { useEndUserTranslation, DynamicTranslationType } from 'hooks/use-translation';

interface IBundleButton {
  bundle: IBundle;
  disabled: boolean;
  onClick?: (bundle: IBundle) => void;
}

export function BundleButton({
  bundle,
  disabled = false,
  onClick,
}: IBundleButton) {
  const { endUserT } = useEndUserTranslation();

  const handlePurchaseClick = useCallback(() => {
    onClick?.(bundle);
  }, [bundle, onClick]);

  const formattedPrice = useMemo(() => {
    return formatPrice(bundle.presentmentCurrency, bundle.price);
  }, [bundle]);

  if (!bundle) return null;

  return (
    <StyledButton
      className={BUY}
      disabled={disabled}
      onClick={handlePurchaseClick}
      data-testid="bundles-gate-buybutton"
    >
      <>
        <EntitlementPurchaseText>
          {
            endUserT(
              [DynamicTranslationType.gateBundleBuy],
              ['BUNDLE_BUTTON'],
            )
          }
        </EntitlementPurchaseText>
        <EntitlementPrice data-testid="bundles-gate-priceLabel">
          <FormattedPriceWithSuffix
            formattedPrice={formattedPrice!}
          />
        </EntitlementPrice>
      </>
    </StyledButton>
  );
}

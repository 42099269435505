import React, { useCallback, useContext } from 'react';

import { HeaderText, HeaderTextDefaultLang, HeaderTextSupportedLang, Container, LabelToggleButton } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { LocalizationTabContext } from './LocalizationTabContext';
import LanguageCard from './LanguageCard';
import DefaultLanguageDropdown from './DefaultLanguageDropdown';
import AddLanguage from './AddLanguage';

const LocalizationTabBody = () => {
  const { t } = useAdminTranslation();
  const {
    isUserLanguageSelectionEnabled,
    setIsUserLanguageSelectionEnabled,
    supportedLanguages,
  } =
    useContext(LocalizationTabContext);

  const handleToggle = useCallback(() => {
    setIsUserLanguageSelectionEnabled(selection => !selection);
  }, []);

  return (
    <Container>
      <HeaderText>{t('ADMIN_LOCALIZATION_HEADER_TEXT')}</HeaderText>
      <LabelToggleButton
        checked={isUserLanguageSelectionEnabled}
        labelKey="ADMIN_LOCALIZATION_ENABLE_USER_SELECTION"
        padding="0"
        onChange={handleToggle}
      />
      <HeaderTextDefaultLang>{t('ADMIN_LOCALIZATION_DEFAULT_LANGUAGE')}</HeaderTextDefaultLang>
      <DefaultLanguageDropdown />
      <HeaderTextSupportedLang>{t('ADMIN_LOCALIZATION_ADDITIONAL_SUPPORTED_LANGUAGES')}</HeaderTextSupportedLang>
      {supportedLanguages?.map((item) => {
        return (
          <LanguageCard
            locale={item.locale}
            language={item.language}
            isValid={item.isValid}
            key={item.locale}
          />
        );
      })}
      <AddLanguage />
    </Container>
  );
};

export default LocalizationTabBody;

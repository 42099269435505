/* tslint:disable: jsx-boolean-value */
import React, { Children, HTMLAttributes, MouseEvent, MouseEventHandler, PropsWithChildren, ReactNode, useCallback, useMemo, useState } from 'react';
import hash from 'json-stable-stringify';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import Image from 'components/ui/Image';
import { css } from 'styled-components';
import {
  Row,
  StyledDragButt,
  NameCell,
  KindCell,
  StyledIcon,
  Name,
  Type,
  EditCell,
  StyledActionContainer,
  StyledTimeElapsed,
  NameContainer,
  ExtraCell,
} from './styles';
import withPadding, { PaddedComponentProps } from 'components/core/withPadding';
import OptionalTranslated from '../OptionalTranslated';
import type { TranslationKey } from 'hooks/use-translation';

export interface RowItem<T> extends PaddedComponentProps {
  actionsWidth?: number;
  draggable?: boolean;
  icon?: string;
  lastModified?: number | Date;
  name?: string;
  renderer?: T;
  testIdAdd?: string;
  testIdBroadcast?: string;
  testIdDelete?: string;
  testIdEdit?: string;
  testIdPreview?: string;
  testIdRemove?: string;
  type?: string;
  typeKey?: TranslationKey;
}

export type ClickHandler<T> = (renderer?: T | null) => void;

export interface ClickHandlers<T, CH = ClickHandler<T>> {
  onAdd?: (renderer: T | null | undefined, e: MouseEvent) => void;
  onBroadcast?: CH;
  onDelete?: CH;
  onEdit?: CH;
  onPreview?: MouseEventHandler;
  onRemove?: MouseEventHandler;
  onSelect?: CH;
  onToggleChange?: (checked: boolean) => void;
}

interface LibraryRowProps<T> extends RowItem<T>, ClickHandlers<T>, Pick<HTMLAttributes<HTMLElement>, 'className'> {
  confirmRemoval?: boolean;
  'data-testid'?: string;
  extraActions?: ReactNode;
  fallbackImageIcon?: string;
  iconCss?: ReturnType<typeof css> | string;
  imageSrc?: string;
  isCatalogItem?: boolean;
  nameKey?: TranslationKey;
  nameTestId?: string;
  noDefaultIcon?: boolean;
  reversePositionActions?: boolean;
  secondaryRowColor?: boolean;
  toggleChecked?: boolean;
  typeNameTestId?: string;
  verticalCenterActions?: boolean;
  verticalPositionActions?: boolean;
}

function LibraryRow<T>({
  actionsWidth,
  children,
  className,
  confirmRemoval,
  draggable,
  extraActions,
  renderer,
  fallbackImageIcon,
  icon,
  iconCss,
  imageSrc,
  isCatalogItem,
  name,
  nameKey,
  nameTestId,
  noDefaultIcon,
  onAdd,
  onBroadcast,
  onDelete,
  onEdit,
  onPreview,
  onRemove,
  onSelect,
  onToggleChange,
  reversePositionActions,
  toggleChecked = false,
  type,
  typeKey,
  lastModified,
  testIdAdd,
  testIdBroadcast,
  testIdDelete,
  testIdEdit,
  testIdPreview,
  testIdRemove,
  verticalCenterActions,
  verticalPositionActions,
  typeNameTestId,
  secondaryRowColor,
  ...props
}: PropsWithChildren<LibraryRowProps<T>>) {
  const [checked, setChecked] = useState(toggleChecked);
  const rowIcon = useMemo(() => {
    const userIcon = (renderer as any)?.iconName as string || icon;
    if (userIcon) {
      return userIcon;
    }
    return noDefaultIcon ? '' : 'circle-empty';
  }, [noDefaultIcon, icon]);
  const showImage = useMemo(() => !!(
    imageSrc || fallbackImageIcon
  ), [imageSrc, fallbackImageIcon]);

  const onRowClick = useCallback(() => {
    onSelect!(renderer);
  }, [onSelect, hash(renderer)]);

  const onEditClick = useCallback((e) => {
    e.stopPropagation();
    onEdit!(renderer);
  }, [onEdit, hash(renderer)]);

  const onAddClick: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
    onAdd!(renderer, e);
  }, [onAdd, hash(renderer)]);

  const onDeleteClick = useCallback((e) => {
    e.stopPropagation();
    onDelete!(renderer);
  }, [onDelete, hash(renderer)]);

  const onBroadcastClick = useCallback((e) => {
    e.stopPropagation();
    onBroadcast!(renderer);
  }, [onBroadcast, hash(renderer)]);

  const handleToggleChange = useCallback((isChecked: boolean) => {
    setChecked(isChecked);
    onToggleChange!(isChecked);
  }, [onToggleChange]);

  return (
    <Row
      className={className}
      data-testid={props['data-testid']}
      draggable={draggable}
      isCatalogItem={isCatalogItem}
      largeFont={showImage}
      onClick={onSelect && onRowClick}
      secondaryColor={secondaryRowColor}
    >
      {draggable && <StyledDragButt />}
      <NameContainer verticalCenter={showImage}>
        {showImage ? (
          <Image
            fallbackIcon={fallbackImageIcon}
            radius="40px"
            spacing="0 10px 0 0"
            src={imageSrc}
            variant="circle"
          />
        ) : (
          isCatalogItem && rowIcon && <StyledIcon iconCss={iconCss} name={rowIcon} />
        )}
        {showImage ? (
          <NameCell>
            {
              (typeKey || type) && (
                <KindCell>
                  <OptionalTranslated component={Type} data-testid={typeNameTestId} stringKey={typeKey}>
                    {type}
                  </OptionalTranslated>
                </KindCell>
              )
            }
            <OptionalTranslated component={Name} data-testid={nameTestId} smallFont maxLines={isCatalogItem ? 2 : 1} stringKey={nameKey}>
              {name}
            </OptionalTranslated>
          </NameCell>
        ) : (
          <NameCell>
            {
              (typeKey || type) && (
                <KindCell>
                  {!isCatalogItem && rowIcon && <StyledIcon iconCss={iconCss} name={rowIcon} />}
                  <OptionalTranslated component={Type} data-testid={typeNameTestId} stringKey={typeKey}>
                    {type}
                  </OptionalTranslated>
                </KindCell>
              )
            }
            <OptionalTranslated component={Name} data-testid={nameTestId} maxLines={isCatalogItem ? 2 : 1} stringKey={nameKey}>
              {name}
            </OptionalTranslated>
          </NameCell>
        )}
      </NameContainer>
      {Children.map(children, child => (
        <ExtraCell>
          {child}
        </ExtraCell>
      ))}
      <EditCell
        verticalCenter={verticalCenterActions}
        minWidth={actionsWidth}
      >
        <StyledTimeElapsed date={lastModified} />
        <StyledActionContainer
          confirmRemoval={confirmRemoval}
          onAdd={onAdd && onAddClick}
          onBroadcast={onBroadcast && onBroadcastClick}
          onDelete={onDelete && onDeleteClick}
          onEdit={onEdit && onEditClick}
          onPreview={onPreview}
          onRemove={onRemove}
          reverse={reversePositionActions}
          testIdAdd={testIdAdd}
          testIdBroadcast={testIdBroadcast}
          testIdDelete={testIdDelete}
          testIdEdit={testIdEdit}
          testIdPreview={testIdPreview}
          testIdRemove={testIdRemove}
          vertical={verticalPositionActions}
        >
          {onToggleChange && <ToggleSwitch checked={checked} onChange={handleToggleChange} />}
          {extraActions}
        </StyledActionContainer>
      </EditCell>
    </Row>
  );
}

export default withPadding(LibraryRow, '5px 0') as typeof LibraryRow;

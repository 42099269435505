import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLegacyCollection, useDispatch, useSelector } from 'hooks';
import usePagesV3Collection from 'hooks/use-pages-v3-collection';
import hash from 'json-stable-stringify';
import isEmail from 'validator/lib/isEmail';
import {
  getInviteLink,
  getInviteURLSelector,
  getInviteSelector,
  updateInvite,
  sendInvite,
  isSuccessEmailSelector,
  getLoadingInviteSelector,
  isEditingInviteSelector,
  accountExistsSelector,
} from 'services/invite';
import TranslatedText from 'components/i18n/TranslatedText';
import Radio from 'components/admin2/ui/Radio';
import LoadingSpinner from 'components/admin2/ui/EllipsisLoader';
import useClipboard from 'hooks/use-clipboard';
import Page from 'models/IObject';
import Invite from 'models/IInvite';
import { IOption, ChannelAndPagesSelect } from 'components/ui/MultiSelectV2';
import {
  TabTitle,
  TabContent,
  TabSubtitle,
  RadioWrapper,
  StyledLargeButton,
  OrTag,
  LinkInput,
  EmailTextInput,
  ValidateMessage,
  StyledIcon,
  SuccessMessage,
  CopiedIcon,
  StyledCloseButton,
} from './styles';
import { IAccount } from 'services/auth/models';
import { getAdminAlertError } from 'services/themes';
import { getAdminText300 } from 'services/themes';
import { AdminBarTab } from '../ui/AdminBarTabHeader';
import { useAdminTranslation } from 'hooks/use-translation';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';
import { Checkbox } from '../ui/Checkbox';

interface IInviteTabPros {
  closeModal: (e: any) => any;
  filteredUsers: IAccount[];
  pendingInvitations: Invite[];
}

const InviteTab: React.FC<IInviteTabPros> = ({
  closeModal,
  filteredUsers,
  pendingInvitations,
}) => {
  const { t } = useAdminTranslation();
  const adminAlertError = useSelector(getAdminAlertError);
  const adminText300 = useSelector(getAdminText300);
  const invite = useSelector<Invite>(getInviteSelector);
  const fetching = useSelector(getLoadingInviteSelector);
  const shareLink = useSelector(getInviteURLSelector);
  const successEmail = useSelector(isSuccessEmailSelector);
  const isEditing = useSelector(isEditingInviteSelector);
  const accountExists = useSelector(accountExistsSelector);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [channelsSelection, setChannelsSelection] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({
    chatModerator: false,
    siteAdmin: false,
  });
  const [allChannels, setAllChannels] = useState(true);
  const [errors, setErrors] = useState<{
    validEmail: string | null;
    validSelectedChannels: string | null;
  }>({
    validEmail: null,
    validSelectedChannels: null,
  });
  const [selectedChannels, setselectedChannels] = useState<string[]>([]);
  const features = useSelector(getActiveSiteFeatures);
  const pagesHook = features[Feature.PAGES_V3] ? usePagesV3Collection : useLegacyCollection;
  const [channelsData] = pagesHook<Page>({ collection: 'pages' });

  const selectedChannelsParsed = useMemo(() => {
    const pageMap = channelsData.reduce<{ [key: string]: string }>(
      (map, currentPage) => {
        map[currentPage._id] = currentPage.data.name!;
        return map;
      },
      {},
    );
    const specificChannels = selectedChannels.map((channelId) => ({
      id: channelId,
      slug: pageMap[channelId],
    }));
    return specificChannels;
  }, [hash(channelsData), hash(selectedChannels)]);

  const isOptionSelected = useMemo(
    () => Object.values(permissions).some((value) => value),
    [permissions],
  );

  const { copyToClipboard } = useClipboard();

  const cleanMessages = () => {
    setErrors({
      validEmail: null,
      validSelectedChannels: null,
    });
  };

  useEffect(() => {
    setEmail(isEditing ? invite.email! : email || '');
  }, [invite]);

  useEffect(() => {
    if (!invite._id) {
      if (email) {
        setEmail('');
      }
      if (permissions.chatModerator || permissions.siteAdmin) {
        setPermissions({
          chatModerator: false,
          siteAdmin: false,
        });
      }
      if (errors.validEmail) {
        cleanMessages();
      }
    }
  }, [invite._id]);

  useEffect(() => {
    if (isEditing) {
      return;
    }
    if (!isOptionSelected) {
      return;
    }
    let targetRoles = {
      pageId: [] as any[],
      scope: '*',
      write: true,
    };
    if (permissions.chatModerator) {
      targetRoles = {
        ...targetRoles,
        pageId: selectedChannels,
        scope: 'Chat_Moderator',
      };
    }
    if (invite._id) {
      dispatch(updateInvite({ targetRoles: [targetRoles] }));
    } else {
      dispatch(getInviteLink([targetRoles]));
    }
  }, [permissions, selectedChannels]);

  useEffect(() => {
    cleanMessages();
    setChannelsSelection(
      !(
        permissions.chatModerator &&
        !allChannels &&
        selectedChannels.length === 0
      ),
    );
  }, [allChannels, permissions, selectedChannels]);

  useEffect(() => {
    let timer: number | null = null;
    if (linkCopied) {
      timer = setTimeout(() => setLinkCopied(false), 3000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [linkCopied]);

  const changePermission = (permission: string) => {
    cleanMessages();
    let initialPermissions: { [key: string]: boolean } = permissions;
    if (permission === 'siteAdmin' && !initialPermissions.siteAdmin) {
      initialPermissions = {
        chatModerator: false,
        siteAdmin: false,
      };
    }
    initialPermissions = {
      ...initialPermissions,
      [permission]: !initialPermissions[permission],
    };
    setPermissions(initialPermissions);
  };
  const doSendInvite = () => {
    cleanMessages();
    if (!email || email === '') {
      setErrors({
        ...errors,
        validEmail: t('ADMIN_USER_INVITE_EMAIL_VALIDATION_ERROR_EMPTY'),
      });
      return;
    }
    if (!isEmail(email)) {
      setErrors({
        ...errors,
        validEmail: t('ADMIN_USER_INVITE_EMAIL_VALIDATION_ERROR'),
      });
      return;
    }
    if (!channelsSelection) {
      setErrors({
        ...errors,
        validSelectedChannels: t('ADMIN_USER_INVITE_MUST_SELECT_CHANNEL'),
      });
      return;
    }
    const inviteExist = pendingInvitations.every(
      (item) => item.email !== email,
    );
    if (!inviteExist) {
      setErrors({
        ...errors,
        validEmail: t('ADMIN_USER_INVITE_EMAIL_INVITATION_SENT_ERROR'),
      });
      return;
    }
    const emailAdminExist = filteredUsers.every((item) => item.email !== email);
    if (!emailAdminExist) {
      setErrors({
        ...errors,
        validEmail: t('ADMIN_USER_INVITE_EMAIL_REGISTERED_ERROR'),
      });
      return;
    }
    dispatch(sendInvite(email));
  };
  const copyURL = () => {
    if (!linkCopied) {
      copyToClipboard(shareLink);
      setLinkCopied(true);
    }
  };

  const handleChange = useCallback((input) => {
    setEmail(input.toLowerCase());
  }, []);

  const handleOnSelectedChannelsChange = (selectedOptions: IOption[]) => {
    setselectedChannels(selectedOptions.map((option) => option.value));
  };

  const handleOnDeleteSelectedChannels = (selectedOption: IOption) => {
    const filteredOptions = selectedChannels.filter(
      (channelId) => channelId !== selectedOption.value,
    );
    setselectedChannels(filteredOptions);
  };

  const handleSelectSiteAdmin = () => changePermission('siteAdmin');
  const handleSelectModerator = () => changePermission('chatModerator');
  const handleSelectAllChannels = () => setAllChannels(true);
  const handleSelectSpecificChannels = () => setAllChannels(false);

  const copyLinkButtonText = linkCopied
    ? 'ADMIN_LINK_COPIED'
    : 'ADMIN_COPY_LINK';

  return (
    <AdminBarTab>
      <StyledCloseButton onClick={closeModal} tooltipPosition="left" />
      <TranslatedText component={TabTitle} stringKey="ADMIN_INVITE" />
      <TranslatedText
        component={TabContent}
        stringKey="ADMIN_ADD_COLABORATOR"
      />
      <EmailTextInput
        inputTestId="adminEmailInput"
        accountExist={!!accountExists}
        labelKey="ADMIN_EMAIL_ADRESS"
        onChange={handleChange}
        readOnly={Boolean(successEmail || accountExists)}
        valid={!!errors.validEmail}
        value={email}
      />
      {accountExists && (
        <SuccessMessage>
          {t('ADMIN_USER_INVITE_EMAIL_REGISTERED_SUCCESS')}
        </SuccessMessage>
      )}
      {errors.validEmail && (
        <ValidateMessage>
          <StyledIcon color={adminAlertError} name="warning" />
          {errors.validEmail}
        </ValidateMessage>
      )}
      <TranslatedText component={TabSubtitle} stringKey="ADMIN_SELECT_ROLE" />
      <Checkbox
        checked={permissions.siteAdmin}
        data-testid="siteAdminCheckbox"
        descriptionKey="ADMIN_ROLE_SITE_ADMIN_DESC"
        disabled={Boolean(successEmail || accountExists)}
        label={t('ADMIN_INVITE_TAB_SITE_ADMIN')}
        onChange={handleSelectSiteAdmin}
      />
      <Checkbox
        checked={permissions.siteAdmin || permissions.chatModerator}
        data-testid="chatModeratorCheckbox"
        descriptionKey="ADMIN_ROLE_MODERATOR"
        disabled={Boolean(
          permissions.siteAdmin || successEmail || accountExists,
        )}
        labelKey="ADMIN_INVITE_TAB_MODERATOR"
        onChange={handleSelectModerator}
      />
      {permissions.chatModerator && (
        <RadioWrapper>
          <Radio
            checked={allChannels}
            data-testid="allChannelsRadioBtn"
            labelKey="ADMIN_ROLE_CHAT_ADMIN_ENTIRE_SITE"
            onChange={handleSelectAllChannels}
          />
          <Radio
            checked={!allChannels}
            data-testid="specificChannelsRadioBtn"
            labelKey="ADMIN_ROLE_CHAT_ADMIN_SPECIFIC_ACCESS"
            onChange={handleSelectSpecificChannels}
          />
          {!allChannels && (
            <ChannelAndPagesSelect
              name="channelAndPagesTags"
              placeholder={t('ADMIN_PLACEHOLDER_SEARCH_FOR_A_CHANNEL')}
              isDisabled={allChannels}
              isSearchable={true}
              options={channelsData.map((channel) => ({
                value: channel._id,
                label: channel.data.name!,
              }))}
              onChange={handleOnSelectedChannelsChange}
              onDeleteOption={handleOnDeleteSelectedChannels}
              value={selectedChannelsParsed.map((channel) => ({
                value: channel.id,
                label: channel.slug,
              }))}
            />
          )}
          {errors.validSelectedChannels && (
            <ValidateMessage>
              <StyledIcon color={adminAlertError} name="warning" />
              {errors.validSelectedChannels}
            </ValidateMessage>
          )}
        </RadioWrapper>
      )}
      {!isEditing && (
        <>
          {fetching ? (
            <LoadingSpinner />
          ) : (
            <>
              <StyledLargeButton
                data-testid="sendInviteBtn"
                disabled={Boolean(
                  !isOptionSelected || successEmail || accountExists,
                )}
                onClick={doSendInvite}
              >
                <TranslatedText stringKey="ADMIN_SEND_INVITE" />
              </StyledLargeButton>
              {successEmail && (
                <SuccessMessage>
                  <StyledIcon name="circledCheckmark" />
                  {t('ADMIN_USER_INVITE_SENT')}
                </SuccessMessage>
              )}
              <OrTag>{t('OR')}</OrTag>
              <LinkInput data-testid="inviteUrlString">
                {isOptionSelected && channelsSelection && (
                  <>
                    <StyledIcon color={adminText300} name="linkOutline" />
                    <p>{shareLink}</p>
                  </>
                )}
              </LinkInput>
              <StyledLargeButton
                data-testid="copyLinkBtn"
                disabled={!isOptionSelected || !channelsSelection}
                onClick={copyURL}
                success={linkCopied}
              >
                {linkCopied && <CopiedIcon />}
                <TranslatedText stringKey={copyLinkButtonText} />
              </StyledLargeButton>
            </>
          )}
        </>
      )}
    </AdminBarTab>
  );
};

export default InviteTab;

import styled from 'styled-components';
import Label from '../Label';
import {
  ADMIN_TEXT_300,
  ADMIN_TEXT_200,
  TEXT_100,
  MAESTRO_WHITE,
  ADMIN_SURFACE_5,
} from 'style/constants';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { ADMIN_TEXT_BODY_L_REGULAR, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${ADMIN_SURFACE_5};
  border-radius: 10px;
  height: 102px;
  padding: 16px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 13px;
  min-width: 0;
`;

export const Name = styled.div`
  ${ADMIN_TEXT_TITLE_S}
  color: ${ADMIN_TEXT_200};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Tag = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${MAESTRO_WHITE};
`;

export const EmailLabel = styled(Label).attrs((props) => ({
  color: ADMIN_TEXT_300(props),
  iconSize: 17,
  iconSpacing: 5,
  textSize: 'p2',
}))`
  flex: 1;
  color: ${ADMIN_TEXT_300};
  overflow: hidden;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  position: relative;
  min-height: 28px;
`;

export const MarginWrapper = styled.div`
  margin: 4px 3px 0 3px;
`;

export const StyledSpinner = styled(LoadingSpinner)`
  width: unset;
  height: 25px;
`;

export const DeleteMessage = styled(Tag)`
  text-align: center;
  width: 100%;
  ${ADMIN_TEXT_BODY_L_REGULAR}
`;

export const AccountExistContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AccountExistTitle = styled.div`
color: ${TEXT_100};
${ADMIN_TEXT_BODY_S_BOLD}
`;

export const AccountExistContent = styled(AccountExistTitle)`
color: ${ADMIN_TEXT_300};
text-align: center;
`;

export const ExpiredTag = styled.div`
color: ${ADMIN_TEXT_200};
${ADMIN_TEXT_BODY_S_REGULAR}
align-self: center;
position: absolute;
left: 0;
`;


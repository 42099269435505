import React, { useCallback, ElementType } from 'react';
import { Dot, Menu, MenuButton, QuickAction, QuickActionAdmin, Wrapper } from './styles';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { useAdminTranslation } from 'hooks/use-translation';
import { getRectPositionCheckingBounds } from 'dom-utils';
import useFloatMenu from 'hooks/use-float-menu';

export interface QuickActionMenuProps {
  actions: {
    icon?: React.ReactNode;
    onClick: () => void;
    stringKey: TI18nKey;
  }[];
  admin: boolean;
  testId: string;
}

const QuickActionMenu = ({ actions, testId, admin, ...rest }: QuickActionMenuProps) => {
  const { t } = useAdminTranslation();
  const setMenuPosition = useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    const menuContainerRect = menuContainer.getBoundingClientRect();
    const safePosition = getRectPositionCheckingBounds({
      elem: menu,
      position: {
        x: menuContainerRect.right,
        y: menuContainerRect.top,
      },
      margin: 20,
    });

    menu.style.top = `${safePosition.y}px`;
    menu.style.left = `${safePosition.x}px`;
  }, []);

  const { containerRef, isOpen, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);

  const Button: ElementType = React.useMemo(() => admin ? QuickActionAdmin : QuickAction, [admin]);

  return (
    <Wrapper {...rest} ref={containerRef} data-testid={testId}>
      <MenuButton admin={admin} isOpen={isOpen} onClick={toggleMenu}>
        <Dot admin={admin} />
        <Dot admin={admin} />
        <Dot admin={admin} />
      </MenuButton>
      {isOpen && (
        <Menu ref={positionMenu} onClick={toggleMenu} admin={admin}>
          {
            actions.map(({ stringKey, icon, onClick }) => (
              <Button onClick={onClick} key={stringKey}>
                {icon}
                {t(stringKey)}
              </Button>
            ))
          }
        </Menu>
      )}
    </Wrapper>
  );
};

export default QuickActionMenu;

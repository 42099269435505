import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FancyButton from 'components/admin2/FancyButton';
import VideoLibraryModal from 'components/admin2/videoPanels/VideoLibraryModal';
import videoLibraryModalTypes from 'components/modals/videoLibraryModalTypes';

const VideoSelect = (props) => {
  const {
    className,
    buttonPlaceholderKey,
    docToButtonProps,
    onChange,
    value,
    showVideosAndPlaylists,
  } = props;
  const [editing, setEditing] = useState(false);
  const onSelectItem = (doc) => {
    onChange(doc);
    setEditing(false);
  };

  const onRemove = () => onChange(null);

  const libraryModalType = (
    showVideosAndPlaylists ? videoLibraryModalTypes.EDIT_MODE : videoLibraryModalTypes.QUEST_EDIT
  );
  return (
    <>
      <FancyButton
        buttonPlaceholderKey={buttonPlaceholderKey}
        className={className}
        docToButtonProps={docToButtonProps}
        hideIcon
        onAdd={() => setEditing(true)}
        onRemove={onRemove}
        rectanglePreview
        value={value}
      />
      {editing && (
        <VideoLibraryModal
          onClose={() => setEditing(false)}
          onSelectItem={onSelectItem}
          type={libraryModalType}
        />
      )}
    </>
  );
};

VideoSelect.propTypes = {
  buttonPlaceholderKey: PropTypes.string,
  className: PropTypes.string,
  docToButtonProps: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  showVideosAndPlaylists: PropTypes.bool,
  value: PropTypes.string,
};

VideoSelect.defaultProps = {
  buttonPlaceholderKey: undefined,
  className: undefined,
  showVideosAndPlaylists: false,
  value: undefined,
};

export default VideoSelect;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import shortid from 'shortid';

import Dragger from 'components/admin2/LegacyDragger';
import QuickPickLibraryModal from 'components/admin2/QuickPickLibraryModal';
import QuickPickLibraryButton from 'components/admin2/RendererEditor/QuickPickLibraryButton';
import ActionButton from 'components/admin2/ActionButton';
import withLabel from 'components/core/withLabel';
import { useAdminTranslation } from 'hooks/use-translation';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    type: PropTypes.string,
  }),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      arrayId: PropTypes.string.isRequired,
      pollId: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

const defaultProps = {
  options: {},
  value: [],
};

// TODO update this to work with the new quick fix modal

function QuickPickSelect({ onChange, options: { type = null }, value }) {
  const { t } = useAdminTranslation();
  const [open, setOpen] = useState(false);

  const replaceIndex = (index, pollId) => {
    const newValue = value.map((item, i) => i === index ? { ...item, pollId } : item);
    onChange(newValue);
  };

  const removeIndex = (index) => {
    const newValue = value.filter((item, i) => i !== index);
    onChange(newValue);
  };

  const addId = ({ _id: pollId }) => {
    const arrayId = shortid.generate();
    onChange([
      ...value,
      { arrayId, pollId },
    ]);
  };

  return (
    <div>
      <Dragger
        direction="vertical"
        droppableStyles={{ width: '100%' }}
        innerDivStyles={{ width: '100%' }}
        keyField="arrayId"
        list={value}
        onDragEnd={onChange}
        stretch
      >
        {(item, index) => {
          return (
            <QuickPickLibraryButton
              onChange={_id => replaceIndex(index, _id)}
              onDelete={() => removeIndex(index)}
              options={{
                draggable: true,
                fancyButtonProps: {
                  buttonPlaceholderKey: 'ADMIN_POLL_BUTTON_PLACEHOLDER',
                },
                valueField: '_id',
              }}
              value={item.pollId}
            />
          );
        }}
      </Dragger>
      <ActionButton
        bigSize
        onClick={() => setOpen(true)}
      >
        {t('ADMIN_RENDERER_EDITOR_ADD_QUICKPICK')}
      </ActionButton>
      {/* TODO fix this to work with the new quick fix modal */}
      {open && (
        <QuickPickLibraryModal
          onClose={() => setOpen(false)}
          onSelectItem={addId}
          type={type}
        />
      )}
    </div>
  );
}

QuickPickSelect.propTypes = propTypes;
QuickPickSelect.defaultProps = defaultProps;

export default withLabel(QuickPickSelect);

import { ComponentType, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import ActionContainer from 'components/admin2/ActionContainer';
import Icon from 'components/ui/Icon';
import TimeElapsed from 'components/ui/TimeElapsed';
import DragButt from 'components/admin2/ui/DragButt';
import {
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_3,
  ADMIN_TEXT_300,
  ADMIN_TEXT_200,
  ADMIN_TEXT_100,
} from 'style/constants';
import {
  ADMIN_BACKGROUND_CONTENT,
  ADMIN_BACKGROUND_TITLE,
  ADMIN_ACTION_NORMAL_SMALL,
  MULTI_LINE_ELLIPSIS,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_XS_BOLD, ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

type Component = ComponentType<HTMLAttributes<HTMLDivElement>>;

export const Container = styled.div`
  ${ADMIN_BACKGROUND_CONTENT};
`;

export const BaseRow = styled.div`
  height: 76px;
`;

export const Table = styled.div`
  border-collapse: collapse;
  width: 100%;
`;

export const Head = styled.div`
  display: flex;
  border-bottom: 2px solid ${ADMIN_SURFACE_5};
  text-transform: uppercase;
  ${ADMIN_BACKGROUND_CONTENT};
  margin-bottom: 10px;
`;

export const Body = styled.div`
  padding 0 20px;
  padding-bottom: 40px;
`;

export const NameCell = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_200};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-self: center;
`;

export const Name = styled.div<{largeFont?: boolean, maxLines?: number, smallFont?: boolean}>`
  ${({ largeFont }) => largeFont ? ADMIN_TEXT_BODY_S_REGULAR : ADMIN_TEXT_BODY_XS_REGULAR}
  ${({ smallFont }) => smallFont && ADMIN_TEXT_LABEL_XS_MEDIUM}
  ${MULTI_LINE_ELLIPSIS}
  white-space: normal;
  color: ${ADMIN_TEXT_300};
`;

export const Type = styled.div`
  display: flex;
  align-items: center;
`;

export const NameHeader = styled(NameCell as Component)`
  ${ADMIN_ACTION_NORMAL_SMALL}
  color: ${ADMIN_TEXT_300};
  padding: 10px;
  padding-left: 22px !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

export const KindCell = styled.div`
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${ADMIN_TEXT_100};
  height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 235px;
`;

export const EditCell = styled.div<{minWidth?: number, verticalCenter?: boolean}>`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_200};
  display: flex;
  align-items: flex-end;
  padding-bottom: 9px;
  margin-left: auto;
  min-width: 50px;
  ${({ minWidth }) => minWidth && `
    min-width: ${minWidth}px;
  `}
  ${({ verticalCenter }) => verticalCenter && `
    padding-bottom: 0;
    align-items: center;
    & > div {
      align-items: center;
    }
  `}
`;

export const EditHeader = styled(EditCell as Component)<{minWidth?: number}>`
  ${ADMIN_ACTION_NORMAL_SMALL}
  color: ${ADMIN_TEXT_300};
  padding: 10px 0;
  align-items: center;
  margin-left: 0;
  min-width: 153px;
  ${({ minWidth }) => minWidth && `
    min-width: ${minWidth + 37}px;
  `}
`;

export const StyledActionContainer = styled(ActionContainer)`
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 0;
  margin-left: auto;
`;

export const StyledIcon = styled(Icon).attrs(props => ({
  color: ADMIN_TEXT_100(props),
}))<{iconCss?: ReturnType<typeof css> | string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: ${ADMIN_SURFACE_4};
  border-radius: 5px;
  margin-right: 10px;
  width: 43px;
  height: 43px;
  padding: 10px;
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: ${ADMIN_TEXT_100};
  flex-shrink: 0;
  flex-grow: 0;
  & svg {
    width: 20px;
    height: 20px;
  }
  ${({ name }) => name !== 'shopifyColored' && css`
    & svg, svg > path {
      fill: ${ADMIN_TEXT_100};
    }
  `}
  ${({ iconCss }) => iconCss}
`;

export const SortArrowIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_300(props),
  name: 'designUpArrow',
}))<{descending?: boolean}>`
  line-height: 0;
  margin-left: 7px;
  & svg {
    width: 11px;
    height: 11px;
  }
  ${({ descending }) => !descending && css`
    transform: rotateX(180deg);
  `}
`;

export const SearchContainer = styled.div`
  padding: 0 22px;
  display: fleX;
  justify-content: center;
  flex-direction: column;
  & div {
    flex: 1;
  }
`;

export const SearchHelperText = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_200};
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const StyledTimeElapsed = styled(TimeElapsed)`
  padding-right: 10px;
  min-width: 64px;
  max-width: 64px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NameContainer = styled.div<{verticalCenter?: boolean}>`
  display: flex;
  ${({ verticalCenter }) => verticalCenter && css`align-items: center;`}
  flex: 1;
  padding: 0 16px;
  min-width: 0;
`;

export const ExtraCell = styled.div`
  display: flex;
  align-items: flex-end;
  padding-right: 10px;
  padding-bottom: 13px;
`;

export const StyledDragButt = styled(DragButt)``;

export const Row = styled(BaseRow as Component)<{draggable?: boolean, isCatalogItem?: boolean, largeFont?: boolean, secondaryColor?: boolean}>`
  ${ADMIN_BACKGROUND_TITLE};
  display: flex;
  background: ${({ secondaryColor }) => secondaryColor ? ADMIN_SURFACE_3 : ADMIN_SURFACE_2};
  cursor: ${({ draggable }) => draggable ? 'grab' : 'pointer'};
  border-radius: 4px;
  padding-right: 16px;
  min-height: 74px;
  max-height: 74px;
  & > ${StyledDragButt} {
    ${({ secondaryColor }) => secondaryColor && css`
      background: ${ADMIN_SURFACE_2};
    `}
  }
  &:hover${({ secondaryColor }) => !secondaryColor && ', &:hover i'} {
    background: ${({ secondaryColor }) => secondaryColor ? ADMIN_SURFACE_5 : ADMIN_SURFACE_3} !important;
    color: ${ADMIN_TEXT_100};
  }
  ${({ isCatalogItem, largeFont }) => !isCatalogItem && css`
    & > ${EditCell} {
      flex-direction: column;
      justify-content: flex-end;
      min-width: 0;
      padding-bottom: 12px;
      & > ${StyledTimeElapsed} {
        padding-right: 0;
        text-align: right;
      }
      & > ${StyledActionContainer} {
        padding-top: 7px;
      }
    }
    & > ${NameContainer} > ${NameCell} {
      height: 100%;
      justify-content: ${largeFont ? 'center' : 'space-between'};
      padding: 13px 0;
      & > ${Name} {
        color: ${ADMIN_TEXT_100};
      }
      & > ${KindCell} {
        display: flex;
        & > ${StyledIcon} {
          width: 25px;
          height: 25px;
          padding: 5px;
        }
        & > ${Type} {
          ${ADMIN_TEXT_LABEL_XS_BOLD}
          text-transform: uppercase;
          color: ${ADMIN_TEXT_200};
          ${largeFont && css`
            ${ADMIN_TEXT_BODY_XS_MEDIUM}
            color: ${ADMIN_TEXT_300};
            text-transform: capitalize;
          `}
        }
      }
    }
  `}
`;

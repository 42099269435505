import { capitalize } from 'lodash';
import React, { HTMLAttributes } from 'react';
import { ADMIN_SURFACE_4, ADMIN_TEXT_300, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_ERROR, ADMIN_ALERT_CONFIRMATION } from 'style/constants';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import EllipsisLoader from 'components/admin2/ui/EllipsisLoader';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import { useAdminTranslation } from 'hooks/use-translation';

export interface IUploaderProgressBarProps extends HTMLAttributes<HTMLElement> {
  hasFailed?: boolean;
  onRetryUpload: () => void;
  progress?: number;
  showLoader?: boolean;
  status: string;
  videoUploadProgressId?: string;
}

const UploaderProgressBar: React.FC<IUploaderProgressBarProps> = ({
  hasFailed,
  onRetryUpload,
  progress,
  showLoader,
  status: uploadStatus,
  videoUploadProgressId,
  ...htmlProps
}) => {
  const { t } = useAdminTranslation();
  return (
    <ProgressBarWrapper
      {...htmlProps}
    >
      <ProgressBarLabel>
        {capitalize(uploadStatus)}
        {
          showLoader && (
            <EllipsisLoader
              loading={true}
              style={{
                marginLeft: 5,
              }}
            />
          )
        }
      </ProgressBarLabel>
      {hasFailed && <RetryIcon onClick={onRetryUpload} />}
      {!showLoader && uploadStatus && (
        <>
          <ProgressBarBackground>
            <ProgressBar
              progress={progress!}
              hasFailed={Boolean(hasFailed)}
            />
          </ProgressBarBackground>
          <ProgressBarPercentage hasFailed={Boolean(hasFailed)}>
            {t('PROGRESS_BAR_X_PERCENT', { x: progress?.toFixed(0) })}
          </ProgressBarPercentage>
        </>
      )}
    </ProgressBarWrapper>
  );
};

export default UploaderProgressBar;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const ProgressBarLabel = styled.div`
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  display: flex;
  align-items: center;
`;

const ProgressBarPercentage = styled(ProgressBarLabel)<{ hasFailed: boolean }>`
   color: ${({ hasFailed }) => hasFailed ? ADMIN_TEXT_300 : ADMIN_TEXT_200};
   width: 5ch;
   text-align: center;
   overflow: hidden;
   white-space: nowrap;
`;

const ProgressBarBackground = styled.div`
  position: relative;
  background-color: ${ADMIN_SURFACE_4};
  flex: 1;
  height: 4px;
  border-radius: 50px;
  margin: 0px 7px;
`;

const ProgressBar = styled.div<{ hasFailed: boolean, progress: number }>`
  width: ${({ progress }) => progress}%;
  background-color: ${({ progress, hasFailed, ...props }) => {
    if (hasFailed) return ADMIN_ALERT_ERROR;
    if (progress === 100) return ADMIN_ALERT_CONFIRMATION;
    return ADMIN_ACCENT_PRIMARY(props);
  }};
  height: 100%;
  position: absolute;
  border-radius: 50px;
  transition: width 1s;
`;

const RetryIcon = styled(Icon).attrs({
  name: 'refresh',
})`
  cursor: pointer;
  margin-left: 9px;
  svg {
    width: 13px;
    height: 13px
  }
  svg > path {
    fill: red;
  }
`;

import {
  ADMIN_SURFACE_1,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_300,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_100,
} from 'style/constants';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import ActionContainer from 'components/admin2/ActionContainer';
import { rgba } from 'colors';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';

export const IMAGE_ITEM_PX = 160;
export const IMAGE_ITEM_PADDING = 10;
export const IMAGE_PX = IMAGE_ITEM_PX - IMAGE_ITEM_PADDING * 2;
export const IMAGE_ITEM_SPACING = 12;
export const IMAGE_ITEM_SIDE_MARGIN = IMAGE_ITEM_SPACING / 2;
export const IMAGE_ITEM_SPACE = IMAGE_ITEM_PX + IMAGE_ITEM_SPACING;

export const StyledActionContainer = styled(ActionContainer)`
  & > span {
    width: 30px;
    height: 30px;
    background: ${ADMIN_SURFACE_5};
    &:hover {
      background: ${ADMIN_ACCENT_PRIMARY};
      & svg > path {
        fill: ${ADMIN_TEXT_100} !important;
      }
    }
    &:first-child svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const Container = styled.div`
  overflow: auto;
  padding:24px;
  padding-top: 104px;
  ${ADMIN_SCROLL_BAR};
`;

export const CardGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 -7px;
  padding-bottom: 24px;
`;

export const Image = styled.img`
  height: ${IMAGE_PX}px;
  width: ${IMAGE_PX}px;
  position: absolute;
  border-radius: 5px;
  background-color: ${ADMIN_SURFACE_1};
  background-repeat: no-repeat;
  object-fit: contain;
  object-position: center;
`;

export const ImageCover = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  background-color: ${props => rgba(ADMIN_SURFACE_1(props), 0.4)};
  opacity: ${props => props.isLoading ? 1 : 0};
  transition: opacity 0.2s ease;
`;

export const ImageCard = styled.div`
  max-height: ${IMAGE_ITEM_PX}px;
  max-width: ${IMAGE_ITEM_PX}px;
  min-height: ${IMAGE_ITEM_PX}px;
  min-width: ${IMAGE_ITEM_PX}px;
  position: relative;
  overflow: hidden;
  flex-basis: 20%;
  padding: 10px;
  background: ${ADMIN_SURFACE_1};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    ${props => !props.isLoading && css`
      & ${ImageCover} {
        opacity: 1;
      }
      & ${Image} {
        filter: blur(4px);
      }
    `}
  }
`;

export const ImageName = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  white-space: nowrap;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  color: ${ADMIN_TEXT_100};
`;

export const UploadTime = styled.span`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  letter-spacing: 0em;
  margin-top: 4px;
  color: ${ADMIN_TEXT_300};
`;

export const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${IMAGE_ITEM_SPACING}px 20px ${IMAGE_ITEM_SPACING}px;
  max-width: ${IMAGE_ITEM_PX}px;
`;

export const EmptyItem = styled(ImageItem)`
  min-width: ${IMAGE_ITEM_PX}px;
`;

export const ImageInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

import React from 'react';
import styled from 'styled-components';
import TextInput from 'components/admin2/TextInput';
import StatusIndicator from 'components/admin2/StatusIndicator';
import { useSelector } from 'react-redux';
import { isLandingPageType } from 'services/app/selectors';
import { isLivestreamPublished } from 'services/livestream/selectors';
import { slugify } from 'shared/string-utils';
import FullURLPreview from './FullURLPreview';
import { getAdminAlertError } from 'services/themes';
import { useAdminTranslation } from 'hooks/use-translation';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import FolderParentPageField from './FolderParentPageField';

interface IChannelNameFieldsProps {
  isSlugError: TI18nKey | null;
  isSlugFetching: boolean;
  isSlugValid: boolean;
  name: string;
  onNameChange: (input: string) => void;
  onSlugChange: (input: string) => void;
  slug: string;
}

export default function({
  isSlugError,
  isSlugFetching,
  isSlugValid,
  name,
  onNameChange,
  onSlugChange,
  slug,
}: IChannelNameFieldsProps) {
  const { t } = useAdminTranslation();
  const alertError = useSelector(getAdminAlertError);
  const isLandingPage = useSelector(isLandingPageType);
  const livestreamPublished = useSelector(isLivestreamPublished);
  const [slugIsAuto, setSlugIsAuto] = React.useState(slugify(name) === slug);


  const handleNameChange = (input: string) => {
    onNameChange(input);
    if (slugIsAuto) {
      onSlugChange(slugify(input));
    }
  };

  const handleSlugChange = (input: string) => {
    setSlugIsAuto(false);
    onSlugChange(slugify(input));
  };

  return (
    <ChannelNameFields>
      <TextInput
        descriptionKey={`${isLandingPage ? 'PAGE' : 'CHANNEL'}_NAME_DESCRIPTION`}
        inputTestId="channelName"
        labelKey="NAME"
        onChange={handleNameChange}
        padding="0"
        value={name}
      />
      <TextInput
        descriptionKey="ADMIN_LABEL_CHANNEL_URL_DESCRIPTION"
        disabled={livestreamPublished}
        inputBorder={isSlugError ? `1px solid ${alertError}` : undefined}
        inputTestId="channelURL"
        inputTooltip={livestreamPublished ? t('ADMIN_CHANNEL_SLUG_EDITION_BLOCKED') : undefined}
        labelKey="ADMIN_LABEL_URL"
        onChange={handleSlugChange}
        padding="0"
        value={slug}
      />
      <StyledStatusIndicator
        errorKey={isSlugError}
        fetching={isSlugFetching}
        fetchingLabelKey="ADMIN_LABEL_VALIDATING"
        loaded={isSlugValid}
      />
      <FolderParentPageField />
      <FullURLPreview slug={slug} />
    </ChannelNameFields>
  );
}

const ChannelNameFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledStatusIndicator = styled(StatusIndicator)`
  margin-top: -16px;
  height: 18px;
`;

import axios from 'axios';
import { LOCALIZATION_SERVICE_BASE_URL, SITE_SERVICE_BASE_URL } from 'config';
import maestroApiRequest from 'services/maestro-api-client';

export type IPayload = {
    primaryToken: string;
    siteId: string;
};

export type ILocalePayload = IPayload & { localeCode: string };

export const getLocalCodes = async ({ primaryToken, siteId }: IPayload): Promise<string[]> => {
  const { data } = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<string[]>(`${LOCALIZATION_SERVICE_BASE_URL}/localeCodes`);

  return data;
};

export const downloadTemplateCSV = async ({
  siteId,
  primaryToken,
  localeCode,
}: ILocalePayload): Promise<string | null> => {
  const { data } = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<string | null>(`${LOCALIZATION_SERVICE_BASE_URL}/siteCsv/${localeCode}`);

  return data;
};

export const uploadLocaleJSON = async ({
  siteId,
  primaryToken,
  localeCode,
  file,
} : ILocalePayload & { file: object}): Promise<void> => {
  const headers = {
    'x-maestro-client-id': siteId,
    Authorization: `Bearer ${primaryToken}`,
  };
  await axios.put(`${LOCALIZATION_SERVICE_BASE_URL}/${localeCode}`, file, { headers });
};

export const deleteLocale = async ({
  siteId,
  primaryToken,
  localeCode,
} : ILocalePayload): Promise<void> => {
  const headers = {
    'x-maestro-client-id': siteId,
    Authorization: `Bearer ${primaryToken}`,
  };
  await axios.delete(`${LOCALIZATION_SERVICE_BASE_URL}/${localeCode}`, { headers });
};

export const updateDefaultLanguage = async ({
  siteId,
  primaryToken,
  defaultLanguage,
} : IPayload & { defaultLanguage: string | null}): Promise<void> => {
  const headers = {
    'x-maestro-client-id': siteId,
    Authorization: `Bearer ${primaryToken}`,
  };
  await axios.put(`${SITE_SERVICE_BASE_URL}/settings/defaultLanguage`, { default_language: defaultLanguage }, { headers });
};

export const updateUserLanguageSelection = async ({
  siteId,
  primaryToken,
  userSelectionEnabled,
} : IPayload & { userSelectionEnabled: boolean}): Promise<void> => {
  const headers = {
    'x-maestro-client-id': siteId,
    Authorization: `Bearer ${primaryToken}`,
  };
  await axios.put(`${SITE_SERVICE_BASE_URL}/settings/languagePicker`, { show_user_language_picker: userSelectionEnabled }, { headers });
};


import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import hash from 'json-stable-stringify';
import useAjax from 'hooks/use-ajax';
import TranslatedText from 'components/i18n/TranslatedText';
import { ExitButton } from 'components/admin2/AccessControlModal/styles';
import { ACCESSCODE_SERVICE_BASE_URL } from 'config';
import { getSiteId } from 'services/app/selectors';
import { getPrimaryToken } from 'services/auth';
import { getAdminText100 } from 'services/themes';
import { useAdminTranslation } from 'hooks/use-translation';
import ExportStatus, { ExportStatusKeys } from './ExportStatus';
import {
  AmountInput,
  Container,
  AmountGenerateContainer,
  CodeGenerationInstructions,
  CenteredLabel,
  Header,
  HeaderGroup,
  HelpTextContainer,
  Button,
  ErrorContainer,
  ErrorMessage,
  ErrorIcon,
  Label,
  ExitButtonContainer,
} from './styles';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedSubscription: PropTypes.string.isRequired,
};

const SubscriptionAccessCodeModal = (props) => {
  const adminText100 = useSelector(getAdminText100);
  const [amount, setAmount] = useState('');
  const [generate, setGenerate] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [exportStatus, setExportStatus] = useState(ExportStatusKeys.CREATE);

  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);
  const { t } = useAdminTranslation();

  const {
    onClose,
    selectedSubscription,
  } = props;

  const handleAmountChange = (e) => {
    const newAmount = e.target.value.replace(/[^0-9,]/, '');
    setAmount(newAmount);
  };

  const handleGenerate = () => {
    if (!amount || parseInt(amount, 10) <= 0) {
      return;
    }

    if (isError) {
      setIsError(false);
    }

    setDisabled(true);
    setExportStatus(ExportStatusKeys.CREATING);
    setGenerate(true);
  };

  const exportBody = useMemo(() => {
    const newBody = { qty: +amount.replace(/[,]/g, ''), sku: selectedSubscription };
    return newBody;
  }, [
    amount,
    selectedSubscription,
  ]);

  const { data: exportData, loaded: exportLoaded, error: exportError } = useAjax({
    body: exportBody,
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'POST',
    responseType: 'blob',
    url: generate ? `${ACCESSCODE_SERVICE_BASE_URL}/bulk?kind=csv` : null,
  });

  useEffect(() => {
    if (exportError) {
      setDisabled(false);
      setExportStatus(ExportStatusKeys.FAILURE);
      setGenerate(false);
      setIsError(true);
      return;
    }

    if (!exportLoaded || !exportData || !generate) {
      return;
    }

    const blobURL = window.URL.createObjectURL(exportData);
    const link = document.createElement('a');
    link.href = blobURL;
    link.setAttribute('download', 'accesscodes.csv');
    document.body.appendChild(link);
    link.click();
    setExportStatus(ExportStatusKeys.SUCCESS);
    setGenerate(false);
  }, [
    exportLoaded,
    hash(exportBody),
    exportError,
  ]);

  return (
    <Container>
      <Header>
        <HeaderGroup>
          <Label
            color={adminText100}
            labelKey="ADMIN_LABEL_ACCESS_CODE"
            textSize="h4"
          />
          <ExitButtonContainer>
            <ExitButton onClick={onClose} />
          </ExitButtonContainer>
        </HeaderGroup>
        <HelpTextContainer>
          <TranslatedText stringKey="ADMIN_LABEL_ACCESS_CODE_HELP_TEXT" />
        </HelpTextContainer>
      </Header>
      <AmountGenerateContainer
        isError={isError}
      >
        <CenteredLabel>
          <TranslatedText stringKey="ADMIN_LABEL_AMOUNT_OF_CODES_LABEL" />
        </CenteredLabel>
        <AmountInput
          onChange={handleAmountChange}
          placeholder={t('ADMIN_PLACEHOLDER_ENTER_ACCESS_CODE_AMOUNT')}
          type="text"
          value={amount}
        />
        <Button disabled={disabled} onClick={handleGenerate}>
          <ExportStatus status={exportStatus} />
        </Button>
        {isError && (
        <ErrorContainer>
          <ErrorIcon />
          <ErrorMessage>
            <TranslatedText stringKey="ADMIN_LABEL_ACCESS_CODES_ERROR_MESSAGE" />
          </ErrorMessage>
        </ErrorContainer>
        )}
      </AmountGenerateContainer>
      <CodeGenerationInstructions>
        <TranslatedText stringKey="ADMIN_LABEL_ACCESS_CODES_GENERATE_INSTRUCTIONS" />
      </CodeGenerationInstructions>
    </Container>
  );
};

SubscriptionAccessCodeModal.propTypes = propTypes;
export default SubscriptionAccessCodeModal;

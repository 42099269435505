import React, { useCallback, useMemo, useState, useEffect, FC, SyntheticEvent } from 'react';
import VideoLibraryList, { IVideoLibraryListProps } from 'components/admin-bridge/VideoLibrary/components/VideoLibraryList/VideoLibraryList';
import AdminModal from 'components/admin2/ui/AdminModal';
import { useVideoLibraryContext } from 'contexts/VideoLibraryContext';
import { pickFromVideoOrFile } from 'utils/video';
import DeleteConfirmationModal, { DeleteConfirmationModalProps } from 'components/admin-bridge/DeleteConfirmationModal';
import { ActionButton } from 'components/admin-bridge/VideoLibrary/components/VideoLibraryList/styles';
import {
  ModalContainer,
  TableContainer,
  EmptyListNotice,
} from './styles';
import { VideoMetadataModalV2 } from 'components/modals/VideoMetadataModal';
import { IVideo } from 'models';
import videoLibraryModalTypes, {
  TVideoLibraryModalTypes,
} from 'components/modals/videoLibraryModalTypes';
import { useToast } from 'components/Toast';
import { useGoToVideo } from 'hooks/use-go-to-video';
import { modalTypeActionsMap } from './actionsMap';
import { Header } from './Header';
import { EditingItem } from './types';
import { AddVideoSection } from './AddVideoSection';
import { useAdminTranslation } from 'hooks/use-translation';
import type { IVideoUploadFile } from 'services/video-upload/state';

export type VideoLibraryModalV2Props = {
  data?: {
    // for use with the services/modals
    onSelectItem?: (video: IVideo) => unknown;
    type?: TVideoLibraryModalTypes;
  };
  onClose?: () => unknown;
  onDismiss?: () => unknown;
  onSelectItem?: (video: IVideo) => unknown;
  type?: TVideoLibraryModalTypes;
};

export const VideoLibraryModalV2: FC<VideoLibraryModalV2Props> = ({
  data,
  onClose,
  onDismiss,
  onSelectItem,
  type: modalType,
}) => {
  const { t } = useAdminTranslation();
  const {
    files,
    loading,
    filters,
    setFilters,
    videos,
    removeFile,
    resetFilters,
    retryUpload,
    refresh,
  } = useVideoLibraryContext();

  const type = modalType ?? data?.type;

  const goToVideo = useGoToVideo();

  const {
    renderToasts,
    showToast,
  } = useToast();

  // show slightly different buttons and UI based on the context
  const mode = useMemo(
    () => type && modalTypeActionsMap(
      t,
      type,
      (content) => showToast({
        content,
      }),
    ),
    [type, showToast, t],
  );

  // update filters according to the current selected mode
  useEffect(
    () => {
      setFilters(
        (prevFilters) => ({
          ...prevFilters,
          ...mode?.filters,
        }),
      );
      return () => resetFilters();
    },
    [mode, resetFilters],
  );

  const onSearch = useCallback<IVideoLibraryListProps['onSearch']>(
    (text) => setFilters(
      (prevFilters) => ({
        ...prevFilters,
        search: text,
      }),
    ),
    [setFilters],
  );

  // add id to videos to feed to VideoLibraryList
  const formattedVideos = useMemo<IVideoLibraryListProps['videos']>(
    () => [
      ...files,
      ...videos.map(
        (v) => ({
          ...v,
          id: v._id!,
        }),
      ),
    ],
    [videos, files],
  );

  const actions = useCallback<NonNullable<IVideoLibraryListProps['actions']>>(
    (item, rowNumber) => {
      const uploadStatus = pickFromVideoOrFile(item, 'uploadStatus', 'uploadingStatus');
      const disabled = !!uploadStatus && uploadStatus !== 'complete';
      const handleSelectItem = (ev: SyntheticEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        onSelectItem?.(item as IVideo);
        data?.onSelectItem?.(item as IVideo);
        mode?.postSelectItemAction?.();

        if (type === videoLibraryModalTypes.ADMIN_LIBRARY)
          goToVideo(item.id);
          onDismiss?.();
      };

      return (
        <ActionButton
          disabled={disabled}
          onClick={handleSelectItem}
          data-testid={`useVideo-${rowNumber}`}
        >
          {mode?.rowActionButtonLabel || t('ADMIN_LABEL_USE')}
        </ActionButton>
      );
    },
    [mode, type, goToVideo, onDismiss, t, onSelectItem],
  );

  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [itemsToDelete, setItemsToDelete] = useState<NonNullable<DeleteConfirmationModalProps['bulk']>>([]);
  const [itemsToEdit, setItemsToEdit] = useState<EditingItem>([]);

  const handleDeleteSingleItem = useCallback<IVideoLibraryListProps['onDelete']>(
    (id: string) => {
      if (files.some((f) => f.id === id)) {
        setItemsToDelete([{
          onDelete: () => removeFile(id),
        }]);
        return;
      }

      setItemsToDelete([{
        collection: 'videos',
        objectId: id,
      }]);
    },
    [files, videos, removeFile],
  );

  const handleEditSingleItem = useCallback<IVideoLibraryListProps['onEdit']>(
    (id: string) => {
      const fileOrVideo = videos.find(
        (v) => v._id === id,
      ) || files.find(
        (f) => f.id === id,
      );

      if (fileOrVideo)
        setItemsToEdit([fileOrVideo]);
    },
    [videos, files],
  );

  const handleBulkDelete = useCallback(
    () => {
      const items: typeof itemsToDelete = [];

      for (const { _id } of videos) {
        if (selectedItems.has(_id!))
          items.push({
            collection: 'videos',
            objectId: _id!,
          });
      }

      for (const { id } of files) {
        if (selectedItems.has(id))
          items.push({
            onDelete: () => removeFile(id),
          });
      }

      setItemsToDelete(items);
    },
    [videos, files, removeFile, selectedItems],
  );

  const handleBulkEdit = useCallback(
    () => {
      const items: typeof itemsToEdit = [];

      for (const video of videos) {
        if (selectedItems.has(video._id!))
          items.push(video);
      }

      for (const file of files) {
        if (selectedItems.has(file.id))
          items.push(file);
      }

      setItemsToEdit(items);
    },
    [videos, files, selectedItems],
  );

  const handleCloseConfirmationModal = useCallback(
    () => {
      setItemsToDelete([]);
      refresh();
    },
    [refresh],
  );

  const handleCloseEditModal = useCallback(
    () => setItemsToEdit([]),
    [],
  );

  const handleClose = useCallback(
    () => {
      if (onClose)
        return onClose();

      if (onDismiss)
        return onDismiss();
    },
    [onClose, onDismiss],
  );

  // synchronize editing items with realtime updates
  useEffect(
    () => {
      const newItemsToEdit: typeof itemsToEdit = [];
      let changed = false;

      itemsToEdit.forEach(
        (fileOrVideo) => {
          const itemId = fileOrVideo._id ?? (fileOrVideo as IVideoUploadFile).id;

          if (!itemId) {
            newItemsToEdit.push(fileOrVideo);
            return;
          }

          const newItem = files.find(
            (file) => file.id === itemId,
          ) || videos.find(
            (video) => video._id === itemId,
          );

          if (newItem && newItem !== fileOrVideo) {
            changed = true;
            newItemsToEdit.push(newItem);
            return;
          }
        },
      );

      if (changed) {
        setItemsToEdit(newItemsToEdit);
      }
    },
    [files, videos, itemsToEdit],
  );

  return (
    <AdminModal
      fixedHeight={true}
      onClose={handleClose}
    >
      <ModalContainer>
        <Header
          handleBulkDelete={handleBulkDelete}
          handleBulkEdit={handleBulkEdit}
          handleClose={handleClose}
          mode={mode}
          selectedItems={selectedItems}
        />
        <AddVideoSection
          setItemsToEdit={setItemsToEdit}
        />
        <TableContainer>
          {
            !loading
            && formattedVideos.length === 0
            && !filters.search?.trim()
              ? (
                <EmptyListNotice>
                  {t('ADMIN_VIDEO_LIBRARY_NO_VIDEOS')}
                </EmptyListNotice>
              )
              : (
                <VideoLibraryList
                  actions={actions}
                  loading={loading}
                  onDelete={handleDeleteSingleItem}
                  onEdit={handleEditSingleItem}
                  onRetryUpload={retryUpload}
                  onSearch={onSearch}
                  videos={formattedVideos}
                  handleSelectionChanged={setSelectedItems}
                />
              )
          }
        </TableContainer>
      </ModalContainer>
      {
        itemsToDelete.length > 0 && (
          <DeleteConfirmationModal
            bulk={itemsToDelete}
            onClose={handleCloseConfirmationModal}
          />
        )
      }
      {
        itemsToEdit.length > 0 && (
          <VideoMetadataModalV2
            data={{
              videos: itemsToEdit,
            }}
            onDismiss={handleCloseEditModal}
          />
        )
      }
      {renderToasts()}
    </AdminModal>
  );
};

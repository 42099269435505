import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import Dragger from 'components/admin2/LegacyDragger';
import QuestLibraryModal from 'components/admin2/QuestLibraryModal';
import { LargeButton } from 'components/admin2/ui/Button';
import withLabel from 'components/core/withLabel';
import { useAdminTranslation } from 'hooks/use-translation';
import QuestSelect from './QuestSelect';

const StyledActionButton = styled(LargeButton).attrs({
  fullwidth: true,
})`
  /* flex: 1;
  align-items: center;
  justify-content: center; */
`;
// const ButtonBody = styled.div`
//     ${ADMIN_BACKGROUND_TITLE}
//     display: flex;
//     flex: 1;
//     height: 40px;
//     width: 100%;
// `;
const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      arrayId: PropTypes.string.isRequired,
      questId: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

const defaultProps = {
  value: [],
};

function MultiQuestSelect({ onChange, value }) {
  const { t } = useAdminTranslation();
  const [open, setOpen] = useState(false);

  const replaceIndex = (index, questId) => {
    const newValue = value.map((item, i) => i === index ? { ...item, questId } : item);
    onChange(newValue);
  };

  const removeIndex = (index) => {
    const newValue = value.filter((item, i) => i !== index);
    onChange(newValue);
  };

  const addId = (questId) => {
    const arrayId = shortid.generate();
    onChange([
      ...value,
      { arrayId, questId },
    ]);
  };

  return (
    <div>
      <Dragger
        direction="vertical"
        droppableStyles={{ width: '100%' }}
        innerDivStyles={{ width: '100%' }}
        keyField="arrayId"
        list={value}
        onDragEnd={onChange}
        stretch
      >
        {(item, index) => {
          return (
            <QuestSelect
              draggable
              onChange={id => replaceIndex(index, id)}
              onDelete={() => removeIndex(index)}
              value={item.questId}
            />
          );
        }}
      </Dragger>
      <StyledActionButton
        bigSize
        onClick={() => setOpen(true)}
      >
        {t('ADMIN_ACTION_ADD_QUEST')}
      </StyledActionButton>
      {open && (
        <QuestLibraryModal
          onClose={() => setOpen(false)}
          onSelectItem={addId}
        />
      )}
    </div>
  );
}

MultiQuestSelect.propTypes = propTypes;
MultiQuestSelect.defaultProps = defaultProps;

export default withLabel(MultiQuestSelect);

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isLandingPageType } from 'services/app/selectors';
import { setActiveAction } from 'services/admin/actions';
import withConfirmation from 'components/core/withConfirmation';
import { TertiaryButton } from 'components/admin2/ui/Button';
import TranslatedText from 'components/i18n/TranslatedText';

interface IDeletePageButtonProps {
  onDelete?: (() => void) | null;
}

export default function({
  onDelete,
}: IDeletePageButtonProps) {
  const dispatch = useDispatch();
  const isLandingPage = useSelector(isLandingPageType);

  const DELETE_KEY = isLandingPage ? 'ADMIN_LABEL_DELETE_PAGE' : 'ADMIN_LABEL_DELETE_CHANNEL';

  const deletePage = onDelete ? () => {
    onDelete();
    dispatch(setActiveAction(null));
  } : null;

  return deletePage ? (
    <TranslatedText
      component={DeleteChannelButton}
      componentProps={{
        action: deletePage,
        'data-testid': 'channelDeleteButton',
        messageKey: `ADMIN_PROMPT_CONFIRM_${isLandingPage ? 'PAGE' : 'CHANNEL'}_DELETION`,
      }}
      stringKey={DELETE_KEY}
    />
  ) : null;
}

const DeleteChannelButton = withConfirmation(TertiaryButton);

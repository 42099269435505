import React, { useMemo } from 'react';
import { BUY } from 'injection-classes';
import { StyledButton, EntitlementPurchaseText, EntitlementPrice } from './styles';
import { ENTITLEMENT_ID } from 'global-ids';
import { formatPrice } from 'shared/string-utils';
import { useSelector } from 'react-redux';
import { getNativeCurrency } from 'services/billing';
import { getSubscriptionPrice } from 'utils';
import { useEndUserTranslation, DynamicTranslationType } from 'hooks/use-translation';
import ISubscription, { SubscriptionType } from 'models/ISubscription';
import FormattedPriceWithSuffix from 'components/payment/FormattedPriceWithSuffix';

export { BundleButton } from './BundleButton';

interface ISubscriptionButton {
  handleSubscriptionButtonClick?: (selectedSubscription: ISubscription) => void;
  subscription: ISubscription;
}

export default function SubscriptionButton({
  handleSubscriptionButtonClick,
  subscription,
}: ISubscriptionButton) {
  const nativeCurrency = useSelector(getNativeCurrency);
  const price = getSubscriptionPrice(subscription, nativeCurrency);
  const { endUserT } = useEndUserTranslation();

  const formattedPrice = useMemo(() => {
    if (!price?.value) {
      return;
    }
    return formatPrice(price?.currency, price?.value);
  }, [price?.currency, price?.value]);

  const handlePurchaseClick = () => {
    if (!handleSubscriptionButtonClick) return; // it was fired from admin AccessEditor. do nothing.

    handleSubscriptionButtonClick(subscription);
  };

  const entilementPurchaseText = useMemo(() => {
    if (subscription.type === SubscriptionType.subscription) {
      return endUserT(
        [DynamicTranslationType.gateSubscriptionSubscribe],
        ['SUBSCRIPTION_BUTTON'],
      );
    }
    return endUserT(
      [DynamicTranslationType.gateTicketBuy],
      ['TICKET_BUTTON'],
    );
  }, [subscription.type, endUserT]);

  if (!subscription || !price.value) return null;

  return (
    <StyledButton
      id={`${ENTITLEMENT_ID}-${subscription.sku}`}
      className={BUY}
      onClick={handlePurchaseClick}
      data-testid="buyEntitlementButton"
    >
      <>
        <EntitlementPurchaseText>
          {entilementPurchaseText}
        </EntitlementPurchaseText>
        <EntitlementPrice data-testid="priceLabel">
          <FormattedPriceWithSuffix
            formattedPrice={formattedPrice!}
            recurrence={subscription.recurrence}
          />
        </EntitlementPrice>
      </>
    </StyledButton>
  );
}

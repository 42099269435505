import React from 'react';
import PropTypes from 'prop-types';
import TranslatedText from 'components/i18n/TranslatedText';
import CollapsibleList from 'components/admin2/CollapsibleList';
import RealtimeDocument from 'components/core/RealtimeDocument';
import facebookLogo from 'assets/facebook-logo.png';
import twitterLogo from 'assets/twitter-logo.png';
import produce from 'immer';
import shortid from 'shortid';
import {
  Container,
  EditorContainer,
  Header,
  Body,
  Halves,
  InputContainer,
  StyledTextInput,
  Gutter,
  StyledTextArea,
  StyledUploadZone,
  ListLink,
  LinkFlex,
  ListLinkTextInput,
  StyledIconPicker,
  StyledBackButton,
  SaveButton,
  HeaderTitle,
} from './styles';

const PERSON_NAME_KEY = 'personName';
const PERSON_TITLE_KEY = 'personTitle';
const PERSON_IMAGE_KEY = 'personImageUrl';
const PERSON_BIO_KEY = 'personBio';
const LINKS_ARRAY_KEY = 'personLinks';

export const LINK_TEXT_KEY = 'linkText';
export const LINK_URL_KEY = 'linkUrl';
export const LINK_ICON_KEY = 'linkIconName';

const defaultLogos = {
  'facebook.com': facebookLogo,
  'twitter.com': twitterLogo,
};

export const getLogoFromLink = link => {
  try {
    if (link[LINK_ICON_KEY]) {
      return null;
    }
    let { linkUrl } = link;
    if (!/^(?:f|ht)tps?:\/\//.test(linkUrl)) {
      linkUrl = `https://${linkUrl}`;
    }
    const url = new URL(linkUrl);
    const strippedHostname = url.hostname.replace('www.', '');
    return defaultLogos[strippedHostname] || null;
  } catch (e) {
    return null;
  }
};

export default class NewPersonEditor extends React.Component {
  static propTypes = {
    editingItemId: PropTypes.string,
    handleEdit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
  };

  static defaultProps = {
    editingItemId: null,
    onClose: () => { },
    onCreate: () => { },
  };

  state = {
    [LINK_ICON_KEY]: '',
    [LINK_TEXT_KEY]: '',
    [LINK_URL_KEY]: '',
    [LINKS_ARRAY_KEY]: [],
    [PERSON_BIO_KEY]: '',
    [PERSON_IMAGE_KEY]: '',
    [PERSON_NAME_KEY]: '',
    [PERSON_TITLE_KEY]: '',
  };

  handleChange = (value, key) => {
    this.setState({
      [key]: value,
    });
  };

  handleLinkChange = (value, key, index) => {
    const newState = produce(this.state, (draft) => {
      draft[LINKS_ARRAY_KEY][index][key] = value;
    });
    this.setState(newState);
  };

  handleAddLink = () => {
    const { [LINKS_ARRAY_KEY]: links } = this.state;
    this.setState({
      [LINKS_ARRAY_KEY]: [
        ...links,
        {
          generatedKey: shortid.generate(),
          [LINK_ICON_KEY]: '',
          [LINK_TEXT_KEY]: '',
          [LINK_URL_KEY]: '',
        },
      ],
    });
  };

  handleDeleteLink = (index) => {
    const { [LINKS_ARRAY_KEY]: oldLinks } = this.state;
    const links = [...oldLinks];
    links.splice(index, 1);
    this.setState({
      [LINKS_ARRAY_KEY]: links,
    });
  };

  handleSave = () => {
    const {
      [LINKS_ARRAY_KEY]: links,
      [PERSON_BIO_KEY]: personBio,
      [PERSON_IMAGE_KEY]: personImageUrl,
      [PERSON_NAME_KEY]: personName,
      [PERSON_TITLE_KEY]: personTitle,
    } = this.state;
    const { handleSave, handleEdit, onCreate, onClose, editingItemId } = this.props;
    const editing = Boolean(editingItemId);
    if (editing) {
      handleEdit('person', editingItemId, this.state);
    } else {
      handleSave({
        links,
        personBio,
        personImageUrl,
        personName,
        personTitle,
      });
    }
    onCreate();
    onClose();
  };

  handleValue = (doc) => {
    if (!doc) {
      return;
    }
    const { id } = this.state;
    const { renderer } = doc;
    const previouslyLoaded = Boolean(id);
    if (renderer && !previouslyLoaded) {
      this.setState(renderer);
    }
  };

  getNewLinkText = item => item[LINK_TEXT_KEY] || <TranslatedText stringKey="ACTION_NEW_LINK" />;

  getNewLinkLabel = item => item[LINK_URL_KEY] || <TranslatedText stringKey="ADMIN_LOGO_LINK_LABEL" />

  render() {
    const {
      [LINKS_ARRAY_KEY]: links,
      [PERSON_BIO_KEY]: personBio,
      [PERSON_IMAGE_KEY]: personImageUrl,
      [PERSON_NAME_KEY]: personName,
      [PERSON_TITLE_KEY]: personTitle,
    } = this.state;
    const { editingItemId, onClose } = this.props;
    const { handleChange, handleLinkChange } = this;
    return (
      <Container>
        <EditorContainer>
          <RealtimeDocument
            collection="objects"
            id={editingItemId}
            onValue={this.handleValue}
          />
          <Header>
            <TranslatedText component={HeaderTitle} stringKey="ADMIN_LABEL_NEW_PERSON" />
            <StyledBackButton onClick={onClose} />
            <SaveButton labelKey="ADMIN_ACTION_SAVE" onClick={this.handleSave} />
          </Header>
          <Body>
            <Halves>
              <InputContainer>
                <StyledTextInput
                  labelKey="ADMIN_LABEL_NAME"
                  onChange={value => handleChange(value, PERSON_NAME_KEY)}
                  placeholderKey="ADMIN_PLACEHOLDER_ENTER_NAME"
                  value={personName}
                />
                <StyledUploadZone
                  dimensionRequirements={{
                    exactDimensions: true,
                    pixelHeight: 460,
                    pixelWidth: 600,
                    verify: false,
                  }}
                  imagePreview={personImageUrl}
                  labelKey="ADMIN_LABEL_PROFILE_PHOTO"
                  onClearImage={() => handleChange('', PERSON_IMAGE_KEY)}
                  onFileSubmit={url => handleChange(url, PERSON_IMAGE_KEY)}
                />
              </InputContainer>
              <Gutter />
              <InputContainer>
                <StyledTextInput
                  labelKey="ADMIN_LABEL_TITLE"
                  onChange={value => handleChange(value, PERSON_TITLE_KEY)}
                  placeholderKey="ADMIN_PLACEHOLDER_ENTER_TITLE"
                  value={personTitle}
                />
                <StyledTextArea
                  descriptionKey="ADMIN_LABEL_PERSON_DESCRIPTION"
                  labelKey="ADMIN_LABEL_BIO"
                  onChange={value => handleChange(value, PERSON_BIO_KEY)}
                  placeholderKey="ADMIN_PLACEHOLDER_BIO"
                  value={personBio}
                />
              </InputContainer>
            </Halves>
            <CollapsibleList
              addItemKey="ACTION_ADD_LINK"
              draggable
              emptyListKey="NO_LINKS"
              fallbackImageIcon={item => item[LINK_ICON_KEY] || 'link'}
              headerText={this.getNewLinkText}
              imageSrc={getLogoFromLink}
              labelKey="ADMIN_LABEL_LINKS"
              list={links}
              nameText={this.getNewLinkLabel}
              noPadding
              onAddItem={this.handleAddLink}
              onDelete={this.handleDeleteLink}
              onDragEnd={items => handleChange(items, LINKS_ARRAY_KEY)}
              secondaryColor
            >
              {
                (item, i) => (
                  <ListLink>
                    <LinkFlex>
                      <ListLinkTextInput
                        labelKey="TEXT"
                        onChange={value => handleLinkChange(value, LINK_TEXT_KEY, i)}
                        placeholderKey="ADMIN_PLACEHOLDER_ENTER_TEXT"
                        value={links[i][LINK_TEXT_KEY]}
                      />
                      <Gutter />
                      <ListLinkTextInput
                        labelHintKey="URL_MUST_BEGIN_WITH_HTTP_HTTPS"
                        labelKey="URL"
                        onChange={value => handleLinkChange(value, LINK_URL_KEY, i)}
                        placeholderKey="ADMIN_PLACEHOLDER_ENTER_URL"
                        value={links[i][LINK_URL_KEY]}
                      />
                    </LinkFlex>
                    <StyledIconPicker
                      onChange={value => handleLinkChange(value, LINK_ICON_KEY, i)}
                      value={links[i][LINK_ICON_KEY]}
                    />
                  </ListLink>
                )
              }
            </CollapsibleList>
          </Body>
        </EditorContainer>
      </Container>
    );
  }
}

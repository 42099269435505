import React, { HTMLAttributes, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NavControls from 'components/admin2/NavControls';
import TranslatedText from 'components/i18n/TranslatedText';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import SaveButton, { SaveTypeEnum } from 'components/admin2/ui/SaveButton';
import Label from 'components/admin2/ui/Label';
import { useDispatch } from 'react-redux';
import { setActiveAction, setHasUnsavedChanges } from 'services/admin/actions';
import NavLink from './NavLink';
import { BroadcastButton } from 'components/admin2/ui/Button';
import {
  Header,
  Title,
  Subtitle,
  SubHeader,
  ButtonContainer,
} from './styles';
import { getIsPendingOverlay } from 'services/overlays/selectors';
import { SidebarHeaderWrapper } from 'components/admin-bridge/SidebarEditSheet/styles';
import { TranslationKey } from 'hooks/use-translation';

interface TabHeaderProps extends HTMLAttributes<HTMLElement> {
  adminbarTab?: boolean;
  disabled?: boolean;
  hasUnsavedChanges?: boolean;
  headerKey?: TranslationKey;
  history?: NavHistory[];
  onBack?: (e?: React.MouseEvent) => void;
  onBroadcast?: (e?: React.MouseEvent) => void;
  onClose?: (e?: React.MouseEvent) => void;
  onSave?: (e?: React.MouseEvent) => void;
  saved?: boolean;
  saveExisting?: boolean;
  saveType?: SaveTypeEnum;
  subHeaderIcon?: string;
  subHeaderKey?: TranslationKey;
  subtitleKey?: TranslationKey;
  testIdSaveButton?: string;
}

interface NavHistory {
  name?: string;
  nameKey?: TranslationKey;
  navigate?: (() => void) | ((e: any) => void);
}

export default function TabHeader({
  adminbarTab,
  className,
  onBack,
  onBroadcast,
  onClose,
  onSave,
  hasUnsavedChanges = false,
  history = [],
  saved,
  subHeaderKey,
  headerKey = 'ADMIN_LABEL_UNNAMED_TAB',
  subtitleKey,
  saveExisting,
  saveType,
  subHeaderIcon,
  disabled,
  testIdSaveButton,
}: TabHeaderProps) {
  const dispatch = useDispatch();

  const isPendingOverlay = useSelector(getIsPendingOverlay);

  const handleClose = () => {
    onClose?.();
    if (adminbarTab) {
      dispatch(setActiveAction(null));
    }
  };

  useEffect(() => {
    dispatch(setHasUnsavedChanges(hasUnsavedChanges));
  }, [hasUnsavedChanges]);

  return (
    <SidebarHeaderWrapper>
      <Header className={className}>
        <Title><TranslatedText stringKey={headerKey} /></Title>
        <Subtitle>
          {subtitleKey && <NavLink stringKey={subtitleKey} />}
          <NonKeyedListMapper list={history}>
            {(key: string, { name, nameKey, navigate }: NavHistory, index: number) => (
              <React.Fragment key={key}>
                <NavLink
                  action={navigate}
                  key={key}
                  shouldAskConfirmation={hasUnsavedChanges}
                  stringKey={nameKey}
                >
                  {name}
                </NavLink>
                {index + 1 < history.length && ' > '}
              </React.Fragment>
            )}
          </NonKeyedListMapper>
        </Subtitle>
        <NavControls onBack={onBack} onClose={handleClose} shouldAskConfirmation={hasUnsavedChanges} disabled={disabled} />
      </Header>
      {subHeaderKey && (
        <SubHeader>
          <Label icon={subHeaderIcon} labelKey={subHeaderKey} />
          <ButtonContainer>
            <SaveButton
              data-testid={testIdSaveButton}
              disabled={!hasUnsavedChanges}
              existing={saveExisting}
              onSave={onSave}
              saved={saved}
              type={saveType}
            />
            {onBroadcast && <BroadcastButton onClick={onBroadcast} disabled={isPendingOverlay} />}
          </ButtonContainer>
        </SubHeader>
      )}
    </SidebarHeaderWrapper>
  );
}

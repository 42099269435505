import React from 'react';
import { DROPDOWN_BUTTON } from 'css-classes';
import useChildRenderer from 'hooks/use-child-renderer';
import DefaultButtonView from './Button';
import {
  Button,
  SearchInput,
} from './styles';
import {
  ButtonRendererProps,
} from './interfaces';
import { DownArrow } from './icons';

export default function ButtonRenderer<T>({
  children,
  displaySearchInput,
  hideSearchIcon,
  isOpen,
  onClick,
  replace,
  ...props
}: ButtonRendererProps<T>) {
  const buttonRenderer = useChildRenderer(replace ? children : null);

  return (
    <Button onClick={onClick} className={DROPDOWN_BUTTON} {...props}>
      {displaySearchInput && (
        <SearchInput
          isAdmin={props.isAdmin}
          compact={props.compact}
          hideIcon={hideSearchIcon}
          {...props.searchInputProps}
        />
      )}
      {buttonRenderer({ ...props, isButton: true, isOpen }) || (
        <DefaultButtonView {...props} isOpen={isOpen} />
      )}
      <DownArrow isAdmin={props.isAdmin} isOpen={isOpen} />
    </Button>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  SPACING_SMALL,
  SPACING_X_SMALL,
  ADMIN_TEXT_100,
} from 'style/constants';
import {
  peopleColumns as peopleLibraryColumns,
  groupColumns as groupLibraryColumns,
} from 'components/admin2/MediaLibrary/constants';
import { ADMIN_PANEL_EDITOR_BODY } from 'style/mixins';
import Label from 'components/admin2/ui/Label';
import ButtonSelect from 'components/admin2/ui/ButtonSelect';
import CollapsibleList from 'components/admin2/CollapsibleList';
import LibraryModal from 'components/admin2/LibraryModal';
import NewPersonEditor from 'components/admin2/NewPersonEditor';
import NewGroupEditor from 'components/admin2/NewGroupEditor';
import PanelEditorHeader from '../PanelEditorHeader';

/* eslint-disable arrow-body-style */

const Container = styled.div`
  ${ADMIN_PANEL_EDITOR_BODY}
`;

const ListItem = styled.div`
  padding: ${SPACING_X_SMALL};
  display: flex;
  align-items: center;
  height: 64px;
  color: ${ADMIN_TEXT_100};
`;

const Image = styled.img`
  max-height: 100%;
  object-fit: contain;
  margin-right: ${SPACING_SMALL};
`;

const ICON_KEY = 'iconName';
const NAME_KEY = 'panelName';
const TAB_SELECTED_KEY = 'tabSelected';
const PEOPLE = 'people';
const GROUPS = 'groups';
const LIB_MODAL_KEY = 'libModalType';

const OPTION_MAP = {
  [GROUPS]: {
    icon: 'user',
    label: 'ADMIN_LABEL_GROUP',
  },
  [PEOPLE]: {
    icon: 'gamer',
    label: 'ADMIN_LABEL_PERSON',
  },
};

const getInitialTab = (renderer) => {
  const {
    [PEOPLE]: peopleList,
    [GROUPS]: groupsList,
  } = renderer;
  if (!peopleList && !groupsList) {
    return PEOPLE;
  }
  return peopleList ? PEOPLE : GROUPS;
};
export default class PeoplePanelEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  state = {
    // eslint-disable-next-line
    [TAB_SELECTED_KEY]: getInitialTab(this.props.renderer),
  };

  handleDelete = (listKey, index) => {
    const { renderer } = this.props;
    const { [listKey]: oldList } = renderer;
    const list = [...oldList];
    list.splice(index, 1);
    this.updateRenderer({ [listKey]: list });
  };

  handleChange = (value, key) => {
    this.setState({ [key]: value });
  };

  updateRenderer = (newValueObject) => {
    const { renderer, onChange } = this.props;
    onChange({
      ...renderer,
      ...newValueObject,
    });
  };

  handleSelectItem = (item) => {
    const {
      [TAB_SELECTED_KEY]: tabSelected,
    } = this.state;
    const { renderer, onChange } = this.props;
    const {
      [GROUPS]: groupsList,
      [PEOPLE]: peopleList,
    } = renderer;
    if (tabSelected === PEOPLE) {
      const peopleCopy = peopleList ? [...peopleList] : [];
      // Clear out groups so we dont have dupe data.
      // would rather do this somewhere else but since this is a custom panel
      // shit is weird.
      const { [GROUPS]: _, ...rendererWithoutGroups } = renderer;
      onChange({
        ...rendererWithoutGroups,
        [PEOPLE]: [...peopleCopy, item],
      });
    } else {
      const groupsCopy = groupsList ? [...groupsList] : [];
      const { [PEOPLE]: _, ...rendererWithoutPeople } = renderer;
      onChange({
        ...rendererWithoutPeople,
        [GROUPS]: [...groupsCopy, item],
      });
    }
    this.handleChange(null, LIB_MODAL_KEY);
  };

  renderTab() {
    const {
      [TAB_SELECTED_KEY]: tabSelected,
    } = this.state;
    const { renderer } = this.props;
    const {
      [PEOPLE]: peopleList,
      [GROUPS]: groupsList,
    } = renderer;
    return tabSelected === PEOPLE ? (
      <CollapsibleList
        addItemKey="ADMIN_ACTION_ADD_PERSON"
        emptyListKey="ADMIN_LABEL_NO_PEOPLE"
        headerText={item => item.personName}
        iconName={OPTION_MAP[PEOPLE].icon}
        keyField={item => item.arrayId || item._id}
        labelKey="ADMIN_LABEL_ADD_PERSON"
        list={peopleList || []}
        nameKey={OPTION_MAP[PEOPLE].label}
        noPadding
        onAddItem={() => this.handleChange(PEOPLE, LIB_MODAL_KEY)}
        onDelete={i => this.handleDelete(PEOPLE, i)}
      >
        {
          item => (
            <ListItem>
              <Image src={item.imageSrc} />
              <div>
                { item.personName }
              </div>
            </ListItem>
          )
        }
      </CollapsibleList>
    ) : (
      <CollapsibleList
        addItemKey="ADMIN_ACTION_ADD_GROUP"
        emptyListKey="ADMIN_LABEL_NO_GROUPS"
        headerText={item => item.groupName}
        iconName={OPTION_MAP[GROUPS].icon}
        keyField={item => item.arrayId || item._id}
        labelKey="ADMIN_LABEL_ADD_GROUP"
        list={groupsList || []}
        nameKey={OPTION_MAP[GROUPS].label}
        noPadding
        onAddItem={() => this.handleChange(GROUPS, LIB_MODAL_KEY)}
        onDelete={i => this.handleDelete(GROUPS, i)}
      >
        {
          item => (
            <ListItem>
              <Image src={item.imageSrc} />
              <div>
                { item.groupName }
              </div>
            </ListItem>
          )
        }
      </CollapsibleList>
    );
  }

  renderModal() {
    const {
      [LIB_MODAL_KEY]: libModalType,
    } = this.state;
    if (libModalType === PEOPLE) {
      return (
        <LibraryModal
          columns={peopleLibraryColumns}
          fetchObject={{
            endpoint: '/legacy/people',
            method: 'GET',
          }}
          helperText="ADMIN_LABEL_PEOPLE_HELPER_TEXT"
          NewItemComponent={NewPersonEditor}
          newItemLabel="ADMIN_LABEL_NEW_PERSON"
          onClose={() => this.handleChange(null, LIB_MODAL_KEY)}
          onSelectItem={this.handleSelectItem}
          searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH"
          titleKey="ADMIN_LABEL_PEOPLE"
          transformRequestWithRenderer
        />
      );
    } if (libModalType === GROUPS) {
      return (
        <LibraryModal
          columns={groupLibraryColumns}
          fetchObject={{
            endpoint: '/legacy/groups',
            method: 'GET',
          }}
          helperText="ADMIN_LABEL_GROUPS_HELPER_TEXT"
          NewItemComponent={NewGroupEditor}
          newItemLabel="ADMIN_LABEL_NEW_GROUP"
          onClose={() => this.handleChange(null, LIB_MODAL_KEY)}
          onSelectItem={this.handleSelectItem}
          searchPlaceholderKey="ADMIN_PLACEHOLDER_SEARCH"
          titleKey="ADMIN_LABEL_GROUPS"
          transformRequestWithRenderer
        />
      );
    }
    return null;
  }

  render() {
    const {
      [TAB_SELECTED_KEY]: tabSelected,
    } = this.state;

    const { renderer } = this.props;
    const {
      [NAME_KEY]: panelName,
      [ICON_KEY]: iconName,
    } = renderer;

    return (
      <Container>
        <PanelEditorHeader
          iconValue={iconName}
          nameValue={panelName}
          onIconChange={value => this.updateRenderer({ [ICON_KEY]: value })}
          onNameChange={value => this.updateRenderer({ [NAME_KEY]: value })}
        />
        <>
          <Label labelKey="ADMIN_LABEL_PEOPLE_PANEL_DISPLAY" />
          <ButtonSelect
            onChange={(value) => this.handleChange(value, TAB_SELECTED_KEY)}
            options={[{
              value: PEOPLE,
              ...OPTION_MAP[PEOPLE],
            }, {
              value: GROUPS,
              ...OPTION_MAP[GROUPS],
            }]}
            value={tabSelected}
          >
            { this.renderTab() }
          </ButtonSelect>
          { this.renderModal() }
        </>
      </Container>
    );
  }
}

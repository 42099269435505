import styled, { css } from 'styled-components';
import EllipsisLoader from 'components/admin2/ui/EllipsisLoader';
import {
  ADMIN_TEXT_100,
  ADMIN_TEXT_300,
  ADMIN_TEXT_400,
  ADMIN_SURFACE_2,
  ADMIN_HIGHLIGHT_PRIMARY,
} from 'style/constants';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const SuggestionContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: pre;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
`;

export const Suggestion = styled.div`
  color: ${ADMIN_TEXT_400};
  text-overflow: ellipsis;
  overflow: hidden;
  transition: margin-right 0.2s;
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background: transparent !important;
  padding: 9px 16px 9px 13px;
  &::placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SearchIcon = styled(Icon).attrs(props => ({
  color: ADMIN_HIGHLIGHT_PRIMARY(props),
  name: 'search',
}))`
  color: ${ADMIN_HIGHLIGHT_PRIMARY};
  line-height: 0;
`;

export const IconContainer = styled.div`
  min-width: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SearchContainer = styled.div<{ hasSuggestion?: boolean, height?: number, short?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  &, & ${Input} {
    ${ADMIN_TEXT_BODY_S_MEDIUM};
    background: ${ADMIN_SURFACE_2};
    color: ${ADMIN_TEXT_100};
    border-radius: 20px;
    & ::placeholder {
      color: ${ADMIN_TEXT_300};
    }
  }
  & ${Input} {
    ${({ hasSuggestion }) => hasSuggestion && css`
      position: absolute;
      color: transparent;
    `}
  }
  ${({ short }) => short && css`
    &, & ${Input} {
      ${ADMIN_TEXT_BODY_XS_MEDIUM};
      border-radius: 25px;
    }
    background: ${ADMIN_SURFACE_2};
    height: 35px;
    & ${IconContainer} {
      min-width: 27px;
    }
  `}
  & ${SearchIcon} {
    font-size: ${({ short }) => short ? 11 : 16}px;
    ${({ short }) => short && css`
      margin-top: -1px;
      &, &:before {
        line-height: 10px;
      }
    `}
  }
  & ${Input} {
    padding-left: 8px;
    padding-right: 7px;
  }
  ${({ height }) => height && css`height: ${height}px;`}
`;

export const StyledLoader = styled(EllipsisLoader)`
  margin-right: 20px;
  margin-left: auto;
`;

import React from 'react';
import { DROPDOWN_LABEL } from 'css-classes';
import { ICustomNavigationBlockDropdownOption } from 'components/header/NavigationBar/BlockSettings/CustomNavigationBlockDropdown';
import { CheckIcon, FolderIcon, LinkIcon, PlusTinyIcon } from '../../icons';
import { OptionViewProps } from '../../interfaces';
import { Label, LabelText } from '../../styles';
import { Row } from './styles';
import { SiteNavigationItemTypeIcon } from 'components/admin-bridge/SiteNavigation/SiteNavigationItem/styles';

export function CustomNavigationOptionView<T>({
  children, isActive, isCreateFolder, isCreateLink, isFirstRow, isAdmin, ...props
}: OptionViewProps<T> & { isCreateFolder: boolean, isCreateLink: boolean, isFirstRow: boolean }) {
  const item = props.data as ICustomNavigationBlockDropdownOption;

  let classNames = DROPDOWN_LABEL;
  if (isFirstRow) classNames += ' first-row';
  if (isCreateFolder) classNames += ' create-folder';
  if (isCreateLink) classNames += ' create-link';

  return (
    <Label
      isActive={isActive}
      isAdmin={isAdmin}
      className={classNames}
      {...props}
    >
      {isActive && <CheckIcon isAdmin={isAdmin} />}
      {isCreateFolder && (
        <Row>
          <FolderIcon isAdmin={isAdmin} />
          <PlusTinyIcon isAdmin={isAdmin} />
        </Row>
      )}
      {isCreateLink && (
        <Row>
          <LinkIcon isAdmin={isAdmin} />
          <PlusTinyIcon isAdmin={isAdmin} />
        </Row>
      )}
      <Row>
        {(!isFirstRow && !isCreateFolder && !isCreateLink) && <SiteNavigationItemTypeIcon iconName={item.icon || ''} />}
        <LabelText>
          {children}
        </LabelText>
      </Row>
    </Label>
  );
}

export default CustomNavigationOptionView;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInput from 'components/admin2/TextInput';
import Label from 'components/admin2/ui/Label';
import TextArea from 'components/admin2/TextArea';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import UploadZone from 'components/admin2/UploadZone';
import defaultPersonImage from 'assets/default-person.svg';
import {
  SPACING_SMALL,
  SPACING_LARGE,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_1,
  ADMIN_INPUT_BORDER_COLOR,
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
} from 'style/constants';
import {
  ADMIN_PANEL_EDITOR_BODY,
} from 'style/mixins';
import TranslatedText from 'components/i18n/TranslatedText';
import KeyedListMapper from 'components/core/KeyedListMapper';
import RealtimeChannel from 'components/core/RealtimeChannel';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import PanelEditorHeader from '../PanelEditorHeader';
// eslint-disable-next-line no-multi-str
const areaHelperText = 'Words in this list are filtered from chat and replaced with asterisks.\
 This filtering happens before localization translations.';

const Container = styled.div`
  ${ADMIN_PANEL_EDITOR_BODY}
  background-color: ${ADMIN_SURFACE_4};
`;

const ListItem = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${SPACING_SMALL};
  height: 44px;
  width: 100%;
  background-color: ${ADMIN_SURFACE_1};
  margin-top: 2px;
`;

const ListItemText = styled.div`
  margin-left: ${SPACING_SMALL};
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const NoItems = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: ${ADMIN_SURFACE_4};
  border: 2px dashed ${ADMIN_INPUT_BORDER_COLOR};
`;

const NoItemsText = styled.span`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
`;

const SystemWrapper = styled.div`
  display: flex;
  & > div:not(:last-child) {
    margin-right: ${SPACING_LARGE};
  }
`;

const SystemInput = styled(TextInput)``;

const SystemWelcomeMessageInput = styled(TextInput)`
  width: 100%;
`;

const SystemUploadZone = styled(UploadZone).attrs({
  height: 150,
})`
  width: 100%;
`;

const UploadZoneWrapper = styled.div`
  flex: 1;
`;

const ProfPic = styled.div`
  background: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;
  height: 40px;
  width: 40px;
`;

const UnsilenceButton = styled.div`
  box-shadow: 0 2px 4px #111111;
  cursor: pointer;
  text-transform: uppercase;
  background: white;
  color: black;
  padding: 0 12px;
  border-radius: 4px;
  border: none;
`;

const PANEL_NAME_KEY = 'panelName';
const ICON_NAME_KEY = 'iconName';
const SYSTEM_NAME_KEY = 'systemName';
const SYSTEM_WELCOME_MESSAGE_KEY = 'systemWelcomeMessage';
const SYSTEM_IMAGE_KEY = 'systemImage';
const FILTERED_WORDS_KEY = 'filteredWords';
const ALLOW_NON_ADMIN_LINKS_KEY = 'allowNonAdminLinks';

const List = ({ list, children, emptyMsg, ...props }) => {
  return !list.length ? (
    <NoItems>
      <TranslatedText component={NoItemsText} stringKey={emptyMsg} />
    </NoItems>
  ) : (
    <KeyedListMapper list={list} {...props}>
      { children }
    </KeyedListMapper>
  );
};

List.propTypes = {
  children: PropTypes.func.isRequired,
  emptyMsg: PropTypes.string,
  list: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default class ChatPanelEditor extends React.Component {
  static propTypes = {
    channelId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    silencedUsers: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    syncSilencedUsers: PropTypes.func.isRequired,
    unsilenceUser: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { channelId, syncSilencedUsers } = this.props;
    syncSilencedUsers({ channelId });
  }

  get chatChannel() {
    const { channelId } = this.props;
    return `objects:${channelId}:chat:message`;
  }

  handleChange = (value, key) => {
    const { renderer, onChange } = this.props;
    onChange({
      ...renderer,
      [key]: value,
    });
  };

  truncateChanges = (value, key) => {
    if (value.length <= 140) {
      this.handleChange(value, key);
    }
  };

  render() {
    const { channelId, renderer, silencedUsers, unsilenceUser, syncSilencedUsers } = this.props;
    const {
      [FILTERED_WORDS_KEY]: filteredWordsString,
      [ICON_NAME_KEY]: iconName,
      [PANEL_NAME_KEY]: panelName,
      [SYSTEM_IMAGE_KEY]: systemImageUrl,
      [SYSTEM_NAME_KEY]: systemName,
      [SYSTEM_WELCOME_MESSAGE_KEY]: systemWelcomeMessage,
      [ALLOW_NON_ADMIN_LINKS_KEY]: allowNonAdminLinks = false,
    } = renderer;
    const { handleChange } = this;

    // TODO refactor to use <EditPanelHeader/> in later pr
    return (
      <Container>
        <PanelEditorHeader
          iconValue={iconName}
          nameValue={panelName}
          onIconChange={value => handleChange(value, ICON_NAME_KEY)}
          onNameChange={value => handleChange(value, PANEL_NAME_KEY)}
        />
        <LabelToggle
          checked={allowNonAdminLinks}
          labelKey="ADMIN_LABEL_ALLOW_NON_ADMIN_LINKS"
          onChange={value => handleChange(value, ALLOW_NON_ADMIN_LINKS_KEY)}
        />
        <SystemWrapper>
          <SystemInput
            compact
            labelKey="ADMIN_LABEL_SYSTEM"
            onChange={value => handleChange(value, SYSTEM_NAME_KEY)}
            placeholderKey="ADMIN_PLACEHOLDER_SYSTEM_NAME"
            value={systemName}
          />
          <UploadZoneWrapper>
            <SystemUploadZone
              canDrop={false}
              dimensionRequirements={{
                exactDimensions: false,
                pixelHeight: 200,
                pixelWidth: 200,
              }}
              imagePreview={systemImageUrl}
              labelKey="ADMIN_LABEL_SYSTEM_IMAGE"
              onClearImage={() => handleChange('', SYSTEM_IMAGE_KEY)}
              onFileSubmit={url => handleChange(url, SYSTEM_IMAGE_KEY)}
            />
          </UploadZoneWrapper>
        </SystemWrapper>
        <SystemWrapper>
          <SystemWelcomeMessageInput
            compact
            labelKey="ADMIN_LABEL_WELCOME_MESSAGE"
            onChange={value => this.truncateChanges(value, SYSTEM_WELCOME_MESSAGE_KEY)}
            placeholderKey="ADMIN_PLACEHOLDER_SYSTEM_MESSAGE"
            value={systemWelcomeMessage}
          />
        </SystemWrapper>
        <TextArea
          description={areaHelperText}
          labelKey="ADMIN_LABEL_FILTERED_WORDS"
          onChange={value => handleChange(value, FILTERED_WORDS_KEY)}
          value={filteredWordsString}
        />
        <Label
          descriptionKey="ADMIN_SILENCED_USERS_HELPER_TEXT"
          labelKey="ADMIN_LABEL_SILENCED_USERS"
        />
        <List emptyMsg="ADMIN_LABEL_NO_SILENCED_USERS" keyField="value" list={silencedUsers}>
          {
            (key, { displayName, userId, userType }) => (
              <ListItem key={key}>
                <ProfPic src={defaultPersonImage} />
                <ListItemText>
                  { displayName }
                </ListItemText>
                <UnsilenceButton
                  onClick={() => {
                    unsilenceUser({ channel: this.chatChannel, userId, userType });
                  }}
                >
                  <TranslatedText stringKey="ADMIN_LABEL_UNSILENCE" />
                </UnsilenceButton>
              </ListItem>
            )
          }
        </List>
        <RealtimeChannel
          channel={this.chatChannel}
          onUpdated={() => {
            syncSilencedUsers({ channelId });
          }}
          property="chatSilence"
        />
      </Container>
    );
  }
}

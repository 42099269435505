import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { Option } from 'hooks/use-scroll-to-view';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import RemoveButton, { RemoveHandler } from './RemoveButton';
import {
  Box,
  Tag,
  TagName,
} from './styles';

interface BoxTagProps<T, O = Option<T>> extends HTMLAttributes<HTMLDivElement> {
  content?: O[];
  getLabel?: (obj: O) => string | undefined;
  onRemove?: RemoveHandler<O>;
  selectedIndex?: number;
}

export default function BoxTag<T>({
  children,
  content,
  getLabel = (obj) => obj.label as string,
  onRemove,
  selectedIndex,
  ...props
}: PropsWithChildren<BoxTagProps<T>>) {
  return (
    <Box isEmpty={!content?.length} {...props}>
      {content && (
        <NonKeyedListMapper list={content}>
          {(key: string, item: Option<T>, index: number) => (
            <Tag key={key} isSelected={selectedIndex === index}>
              <RemoveButton index={index} onRemove={onRemove} tag={item} />
              <TagName>
                {getLabel(item)}
              </TagName>
            </Tag>
          )}
        </NonKeyedListMapper>
      )}
      {children}
    </Box>
  );
}

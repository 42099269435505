import moment from 'moment-timezone';
import { SERVICE_BASE_URL } from 'config';

export const ASC_KEY = 'asc';
export const DESC_KEY = 'desc';
export const NONE_KEY = 'none';
export const SORT_MAP = {
  [ASC_KEY]: 'asc',
  [DESC_KEY]: 'desc',
  [NONE_KEY]: null,
};
export const groupColumns = [
  {
    accessor: 'id',
    id: 'id',
    show: false,
  },
  {
    accessor: 'groupName',
    defaultThumbnail: 'user',
    headerKey: 'ADMIN_LABEL_NAME',
    id: 'groupName',
    isTitle: true,
    sort: ASC_KEY,
    thumbnailAccessor: 'groupBannerUrl',
    title: 'name',
  },
  {
    accessor: 'groupTitle',
    id: 'groupTitle',
    title: 'title',
  },
  {
    accessor: 'created',
    headerKey: 'ADMIN_LABEL_ADDED',
    title: 'added',
    transformValue: value => moment().from(value),
  },
];

export const ENDPOINT_ROUTE = '/admin/v1/legacy';
export const BASE_URL = SERVICE_BASE_URL;

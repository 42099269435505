import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import hash from 'json-stable-stringify';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import TranslatedText from 'components/i18n/TranslatedText';
import KeyedListMapper from 'components/core/KeyedListMapper';
import { AdminTabTextBlock } from 'components/admin2/ui/Text';
import UploadZone from 'components/admin2/UploadZone';
import { ICONS_TYPES } from './constants';
import { UploadBody } from './styles';

const iconsTabProps = {
  handleSave: PropTypes.func.isRequired,
  iconData: PropTypes.shape({}).isRequired,
};

const IconTab = (props) => {
  const {
    handleSave,
    iconData,
  } = props;

  const [icons, setIcons] = useState(ICONS_TYPES.map(icon => ({
    ...icon,
    image: iconData[icon.id],
  })));

  const [hasChanges, setChanged] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
  }, [hash(icons)]);

  const onSave = () => {
    const resultData = {};
    icons.forEach((icon) => {
      if (icon.image) {
        resultData[icon.id] = icon.image;
      }
    });
    handleSave(resultData);
    setChanged(false);
    setSaved(true);
  };

  return (
    <AdminBarTab>
      <AdminBarTabHeader
        hasUnsavedChanges={hasChanges}
        headerKey="ADMIN_LABEL_SETTINGS"
        onSave={onSave}
        saved={saved}
        subHeaderKey="ADMIN_SETTINGS_ICONS_TITLE"
        subtitleKey="ADMIN_LABEL_ICONS"
      />
      <TranslatedText component={AdminTabTextBlock} stringKey="ADMIN_SETTINGS_ICONS_DESCRIPTION" />
      <UploadBody>
        <TranslatedText stringKey="ADMIN_SETTINGS_ICONS_UPLOAD_TITLE">
          {(translatedTitle) => (
            <KeyedListMapper keyField={icon => icon.id} list={icons}>
              {(key, icon, index) => (
                <UploadZone
                  key={key}
                  dimensionRequirements={{
                    exactDimensions: false,
                    pixelHeight: icon.length,
                    pixelWidth: icon.width,
                    verify: true,
                  }}
                  imagePreview={icon.image}
                  label={icon.id === 'favicon' ? 'Favicon' : `${icon.length}X${icon.width} ${translatedTitle}`}
                  onClearImage={() => {
                    const mutableIcons = [...icons];
                    delete mutableIcons[index].image;
                    setIcons(mutableIcons);
                    setChanged(true);
                    setSaved(false);
                  }}
                  onFileSubmit={(imageUrl) => {
                    const mutableIcons = [...icons];
                    mutableIcons[index].image = imageUrl;
                    setIcons(mutableIcons);
                    setChanged(true);
                  }}
                />
              )}
            </KeyedListMapper>
          )}
        </TranslatedText>
      </UploadBody>
    </AdminBarTab>
  );
};

IconTab.propTypes = iconsTabProps;

export default IconTab;

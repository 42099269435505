import React, { forwardRef, Ref } from 'react';
import { DROPDOWN_LABEL } from 'css-classes';
import {
  Label,
  LabelText,
  OptionWrapper,
} from './styles';
import {
  OptionProps,
  OptionViewProps,
} from './interfaces';
import { CheckIcon } from './icons';
import { LocalizationOptionView, SiteNavigationOptionView, CustomNavigationOptionView } from './CustomOptionViews';

function Option<T>({ children, data, isActive, onChange, ...props }: OptionProps<T>, ref: Ref<HTMLDivElement>) {
  const handleClick = () => {
    onChange(data);
  };

  return (
    <OptionWrapper className={isActive ? 'active' : ''} isActive={isActive} onClick={handleClick} {...props} ref={ref}>
      {children}
    </OptionWrapper>
  );
}

export function DefaultOptionView<T>({ children, isActive, isAdmin, ...props }: OptionViewProps<T>) {
  return (
    <Label isActive={isActive} isAdmin={isAdmin} {...props} className={DROPDOWN_LABEL}>
      {isActive && <CheckIcon isAdmin={isAdmin} />}
      <LabelText>{children}</LabelText>
    </Label>
  );
}

export { LocalizationOptionView, SiteNavigationOptionView, CustomNavigationOptionView };

export default forwardRef(Option) as typeof Option;

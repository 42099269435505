import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_ACTION_COLOR_DISABLED,
  ADMIN_BUTTON_TEXT_CAPITALIZATION,
} from 'style/constants';
import withConfirmation from 'components/core/withConfirmation';
import BackButton from './BackButton';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';

export const NavContainer = styled.div`
  display: fleX;
  position: absolute;
  right: 25px;
  height: 100%;
  align-items: center;
  background: inherit;
  padding-left: 8px;
`;

export const Label = styled.label<{large?: boolean}>`
  ${ADMIN_TEXT_LABEL_S_BOLD}
  color: inherit;
  text-align: center;
  text-transform: ${ADMIN_BUTTON_TEXT_CAPITALIZATION};
  cursor: pointer;
  ${({ large }) => large && ADMIN_TEXT_BODY_S_BOLD}
`;

export const GradIcon: any = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
}))`
  line-height: normal;
  & svg {
    margin: -20px;
    width: 50px;
    height: 50px;
  }
`;

export const GTIcon = styled(GradIcon).attrs(() => ({
  name: 'rightArrowGrad',
}))`
  margin-right: 8px;
`;

export const LTIcon = styled(GradIcon).attrs((props) => ({
  name: 'leftArrowGrad',
  color: props.disabled ? ADMIN_ACTION_COLOR_DISABLED : ADMIN_ACCENT_PRIMARY(props),
}))`
  margin-left: 8px;
`;

export const ConfirmNavButton = withConfirmation(BackButton);
